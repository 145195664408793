import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import PlaceIcon from '@mui/icons-material/Place';
import EventIcon from '@mui/icons-material/Event';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { EventEntity } from 'libs/domain/src/lib/entities/Event';

interface PreviewProps {
  event: EventEntity;
  loading: boolean;
  onPublishEvent: (eventId: string) => void;
}

export const EventCardClientPreview = ({
  event,
  loading,
  onPublishEvent,
}: PreviewProps) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const formattedDates =
    event?.eventDates
      ?.map((eventDate) =>
        format(new Date(eventDate.date), "dd 'de' MMMM yyyy", { locale: es }),
      )
      .slice(0, event?.eventDates.length - 1)
      .join(', ')
      .concat(
        `${event?.eventDates.length > 1 ? ' y ' : ''}`,
        (event?.eventDates.length > 0 &&
          format(
            new Date(event?.eventDates[event?.eventDates.length - 1].date),
            "dd 'de' MMMM yyyy",
            { locale: es },
          )) ||
          'No hay fechas disponibles',
      ) || 'No hay fechas disponibles';

  return (
    <Box
      sx={{
        position: 'relative',
        overflow: 'hidden',
        color: '#fff',
        padding: {
          xs: '2rem 1rem',
          sm: '2rem 1.5rem',
          md: '3rem',
        },
        borderRadius: '8px',
        width: '100%',
        minHeight: {
          xs: '500px',
          md: '450px',
          lg: '550px',
        },
        maxWidth: '100%',
        margin: '1rem auto',
        display: 'flex',
        alignSelf: 'center',
        alignItems: 'center',
        justifyContent: 'space-around',
        flexDirection: isSmallScreen ? 'column' : 'row',
        gap: '1rem',
        backgroundColor: '#212121',
        boxSizing: 'border-box',
      }}
    >
      {/* Background Image with Blur */}
      <Box
        component="div"
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100vw',
          minHeight: '100vh',
          backgroundImage: event?.cardImage
            ? `url(${event.cardImage})`
            : 'none',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          zIndex: 1,
          opacity: 0.6,
          filter: 'blur(10px)',
        }}
      />

      {/* Overlay to Darken Background */}
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100vw',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.6)',
          zIndex: 2,
        }}
      />

      {/* Text Section */}
      <Box
        sx={{
          maxWidth: isSmallScreen ? '100%' : '50%',
          zIndex: 3,
          position: 'relative',
          padding: isSmallScreen ? '1.5rem 1rem' : '0',
        }}
      >
        <Typography
          variant="h4"
          sx={{
            fontWeight: 700,
            color: '#C33186',
            textTransform: 'uppercase',
            textAlign: isSmallScreen ? 'center' : 'left',
          }}
        >
          {event?.name}
        </Typography>
        <Typography
          variant="subtitle1"
          sx={{
            display: 'flex',
            alignItems: 'center',
            color: '#C33186',
            mt: 1,
          }}
        >
          <EventIcon sx={{ color: '#C33186', fontSize: 25, mr: 1 }} />
          {formattedDates}
        </Typography>
        <Typography
          variant="subtitle1"
          sx={{
            display: 'flex',
            alignItems: 'center',
            color: '#FFFFFF',
            mt: 0.75,
            fontStyle: 'italic',
          }}
        >
          <PlaceIcon sx={{ color: '#FFFFFF', fontSize: 25, mr: 1 }} />
          {event?.venueName}
        </Typography>
        <Box mt={2}>
          <Typography
            variant="body1"
            sx={{
              color: '#FFFFFF',
              textAlign: 'justify',
              lineHeight: 1.5,
              wordBreak: 'break-word',
            }}
          >
            {event?.longDescription || 'No descripción disponible.'}
          </Typography>
        </Box>
      </Box>

      {/* Image Section - Only visible on larger screens */}
      {!isSmallScreen && (
        <Box
          component="img"
          src={event?.cardImage || ''}
          alt={event?.name}
          sx={{
            position: 'relative',
            zIndex: 3,
            width: '40%',
            borderRadius: '8px',
            objectFit: 'cover',
            maxWidth: '100%',
          }}
        />
      )}
    </Box>
  );
};
