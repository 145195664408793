import { axiosRequest } from '../api-client';

export const getSectionById = (sectionId: string) => {
  return axiosRequest({
    method: 'GET',
    url: `/sections/${sectionId}`,
  });
};

export const AddSection = (data: FormData) => {
  return axiosRequest({
    method: 'POST',
    url: '/sections/',
    data,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const GetAllSections = () => {
  return axiosRequest({
    method: 'GET',
    url: '/sections/',
  });
};

export const CreateRowsWithSeats = (sectionId: string, data: FormData) => {
  return axiosRequest({
    method: 'POST',
    url: `/sections/${sectionId}/rows`,
    data,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const GetSectionTypeRow = (sectionId: string, eventDateId: string) => {
  return axiosRequest({
    method: 'GET',
    url: `/sections/${sectionId}/rows?eventDateId=${eventDateId}`,
  });
};

export const GetSectionTypeTable = (sectionId: string, eventDateId: string) => {
  return axiosRequest({
    method: 'GET',
    url: `/sections/${sectionId}/rows/seats?eventDateId=${eventDateId}`,
  });
};

export const DeleteSection = (sectionId: string) => {
  return axiosRequest({
    method: 'DELETE',
    url: `/sections/${sectionId}`,
  });
};

export const UpdateSection = (sectionId: string, data: FormData) => {
  return axiosRequest({
    method: 'PUT',
    url: `/sections/${sectionId}`,
    data,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const GetMaxNumberOfSeatsByGeneralSectionId = (
  sectionId: string,
  eventDateId: string,
) => {
  return axiosRequest({
    method: 'GET',
    url: `/sections/${sectionId}/max-number-of-seats?eventDateId=${eventDateId}`,
  });
};
