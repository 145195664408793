import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import TextField from '@mui/material/TextField';
import { TextFieldProps } from '@mui/material/TextField';
import { useState } from 'react';
import {
  Card,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import { SeatBox } from './seat';
import { TableBox } from './table';
import { toCurrency, toNumber } from '../../utils/currency';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<unknown>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CustomTextField = ({ ...rest }: TextFieldProps) => {
  return (
    <TextField
      {...rest}
      variant="filled"
      sx={{
        ...rest.sx,
        '& .MuiInputBase-root': {
          minWidth: '120px',
          minHeight: '50px',
        },
      }}
    />
  );
};

interface CreateSectionModalProps {
  open: boolean;
  onClose: () => void;
}

export interface Row {
  name: string;
  seats: number;
  initialSeat?: number;
  prefixSeat?: string;
  finalSeat?: number;
  sortOrder: number;
}

export interface Table {
  name: string;
  seats: string | undefined;
  price: string;
}

enum SectionType {
  ROW = 'ROW',
  TABLE = 'TABLE',
  GENERAL = 'GENERAL',
}

const sectionTypeNames = {
  [SectionType.ROW]: 'Fila',
  [SectionType.TABLE]: 'Mesa',
  [SectionType.GENERAL]: 'General',
};

export default function CreateSectionModal({
  open,
  onClose,
}: CreateSectionModalProps) {
  // SECTION INFO
  const [sectionName, setSectionName] = useState<string>('');
  const [sectionColor, setSectionColor] = useState<string>('');
  const [sectionPrice, setSectionPrice] = useState<string>('');
  const [sectionType, setSectionType] = useState<SectionType | undefined>(
    undefined,
  );

  // CHECKBOXES
  const [useRowNameAsPrefix, setUseRowNameAsPrefix] = useState(true);
  const [useLastSeatNumberAsNextSeat, setUseLastSeatNumberAsNextSeat] =
    useState(false);
  // RANGE OF ROWS AND TABLES
  const [generateRangeOfRows, setGenerateRangeOfRows] = useState(false);
  const [generateRangeOfTables, setGenerateRangeOfTables] = useState(false);
  // ROWS
  const [initialLetter, setInitialLetter] = useState<string>('');
  const [finalLetter, setFinalLetter] = useState<string>('');
  const [seats, setSeats] = useState<number>(0);
  const [rows, setRows] = useState<Row[]>([]);
  // TABLES
  const [tables, setTables] = useState<Table[]>([]);
  const [tablePrefix, setTablePrefix] = useState<string>('');
  const [numberOfTables, setNumberOfTables] = useState<number | undefined>(
    undefined,
  );
  const [numberOfSeatsPerTable, setNumberOfSeatsPerTable] = useState<
    string | undefined
  >(undefined);
  // GENERAL
  const [sectionCapacity, setSectionCapacity] = useState<number>(0);

  const [isOpen, setIsOpen] = React.useState(false);
  const handleClickOpen = () => {
    setIsOpen(true);
  };
  const handleClose = () => {
    setIsOpen(false);
    onClose();
    clearRowInfo();
    clearTableInfo();
  };
  const getNextLetterByAlphabet = (letter: string | undefined) => {
    if (!letter) return 'A';
    return String.fromCharCode(letter.charCodeAt(0) + 1);
  };
  const handleAddRow = () => {
    const lastRow =
      rows.length > 0
        ? rows.sort((a, b) => a.sortOrder - b.sortOrder)[rows.length - 1]
        : {
            name: '',
            seats: 0,
            initialSeat: 0,
            prefixSeat: '',
            finalSeat: 0,
            sortOrder: 1,
          };

    const rowName = lastRow.name;
    const seats = lastRow.seats;
    const finalSeat = lastRow.finalSeat;
    const nextLetter = getNextLetterByAlphabet(rowName);

    setRows([
      ...rows,
      {
        name: nextLetter,
        seats: seats || 0,
        initialSeat: useLastSeatNumberAsNextSeat
          ? finalSeat
            ? finalSeat + 1
            : 1
          : 1,
        prefixSeat: useRowNameAsPrefix ? nextLetter.toUpperCase() : '',
        finalSeat: useLastSeatNumberAsNextSeat
          ? finalSeat
            ? finalSeat + (seats || 0)
            : seats
          : seats,
        sortOrder: rows.length + 1,
      },
    ]);
  };

  const handleAddTable = () => {
    setTables([
      ...tables,
      {
        name: '',
        seats: undefined,
        price: sectionPrice,
      },
    ]);
  };

  const handleAddRangeOfRows = () => {
    const upperInitialLetter = initialLetter.toUpperCase();
    const upperFinalLetter = finalLetter.toUpperCase();
    const numberOfSeats = seats;

    if (!upperInitialLetter || !upperFinalLetter || !numberOfSeats) {
      alert('Necesita completar todos los campos');
      return;
    }

    if (upperInitialLetter > upperFinalLetter) {
      alert('La letra inicial no puede ser mayor que la letra final');
      return;
    }

    // generate sequence of letters from initial to final
    const sequenceOfLetters: string[] = [];
    for (
      let i = upperInitialLetter.charCodeAt(0);
      i <= upperFinalLetter.charCodeAt(0);
      i++
    ) {
      sequenceOfLetters.push(String.fromCharCode(i));
    }

    let sortOrder = 1;
    let initialSeat = 1;
    let finalSeat = numberOfSeats;

    const newRows = [...rows];

    const lastRow = rows.sort((a, b) => a.sortOrder - b.sortOrder)[
      rows.length - 1
    ];

    if (lastRow) {
      sortOrder = lastRow.sortOrder + 1;
      initialSeat = lastRow.finalSeat ? lastRow.finalSeat + 1 : 1;
      finalSeat = initialSeat + numberOfSeats;
    }

    sequenceOfLetters.forEach((letter) => {
      newRows.push({
        name: letter,
        seats: numberOfSeats,
        initialSeat: useLastSeatNumberAsNextSeat ? initialSeat : 1,
        finalSeat: useLastSeatNumberAsNextSeat ? finalSeat : numberOfSeats,
        prefixSeat: useRowNameAsPrefix ? letter : '',
        sortOrder,
      });

      initialSeat += numberOfSeats;
      finalSeat += numberOfSeats;
      sortOrder++;
    });

    setRows(newRows);
    setInitialLetter('');
    setFinalLetter('');
    setSeats(0);
  };

  const handleAddRangeOfTables = () => {
    const newTables: Table[] = [];
    Array.from({ length: numberOfTables || 0 }).forEach((_, index) => {
      newTables.push({
        name: tablePrefix + (index + 1),
        seats: numberOfSeatsPerTable || '',
        price: sectionPrice,
      });
    });
    setTables([...tables, ...newTables]);
    setNumberOfTables(undefined);
    setTablePrefix('');
    setNumberOfSeatsPerTable('');
  };
  const handleSeatsChange = (index: number, seats: number) => {
    const row = rows[index];
    if (seats > 0) {
      setRows(
        rows.map((r, i) =>
          i === index
            ? {
                ...r,
                seats: seats,
                finalSeat: +((row.initialSeat || 1) + (seats - 1)),
              }
            : r,
        ),
      );
    } else {
      setRows(
        rows.map((r, i) =>
          i === index ? { ...r, seats: 0, finalSeat: 0 } : r,
        ),
      );
    }
  };
  const handleInitialSeatChange = (index: number, initialSeat: number) => {
    setRows(
      rows.map((r, i) =>
        i === index
          ? {
              ...r,
              initialSeat: initialSeat,
              finalSeat: +((initialSeat || 1) + (r.seats - 1)),
            }
          : r,
      ),
    );
  };
  const handleDeleteRow = (index: number) => {
    setRows(rows.filter((_, i) => i !== index));
  };
  const handleDeleteTable = (index: number) => {
    setTables(tables.filter((_, i) => i !== index));
  };

  const handleDeleteAllRows = () => {
    setRows([]);
  };

  const clearRowInfo = () => {
    setRows([]);
    setInitialLetter('');
    setFinalLetter('');
    setSeats(0);
  };

  const clearTableInfo = () => {
    setTables([]);
    setTablePrefix('');
    setNumberOfTables(0);
    setNumberOfSeatsPerTable(undefined);
  };

  React.useEffect(() => {
    setIsOpen(open);
  }, [open]);

  return (
    <React.Fragment>
      <Dialog
        fullScreen
        open={isOpen}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'sticky' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Crear sección
            </Typography>
            <Button autoFocus color="inherit" onClick={handleClose}>
              Guardar
            </Button>
          </Toolbar>
        </AppBar>

        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <Card sx={{ m: 2 }}>
            <CardContent>
              <Typography variant="h4">Información de la sección</Typography>

              <FormControl variant="filled" sx={{ m: 1, minWidth: 220 }}>
                <InputLabel id="demo-simple-select-label">
                  Tipo de sección
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={sectionType}
                  label="Tipo de sección"
                  onChange={(e) => {
                    setSectionType(e.target.value as SectionType);
                    clearTableInfo();
                    clearRowInfo();
                  }}
                  inputProps={{
                    'aria-label': 'Without label',
                    style: {
                      color: 'white',
                    },
                    variant: 'filled',
                  }}
                >
                  {Object.values(SectionType).map((type) => (
                    <MenuItem key={type} value={type}>
                      {sectionTypeNames[type]}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <CustomTextField
                label="Nombre"
                value={sectionName}
                onChange={(e) =>
                  setSectionName(
                    e.target.value ? e.target.value.toUpperCase() : '',
                  )
                }
                sx={{
                  width: '350px',
                  m: 1,
                }}
              />

              <CustomTextField
                label="Color"
                value={sectionColor}
                onChange={(e) => setSectionColor(e.target.value)}
                sx={{
                  width: '150px',
                  m: 1,
                }}
              />

              <CustomTextField
                label="Precio"
                value={sectionPrice}
                onChange={(e) => {
                  const newPrice = e.target.value;

                  // allow only numbers, commas, and decimal points and key to remove characters
                  if (!/^[\d,]*\.?\d{0,2}$|^[\d,]*\.$/.test(newPrice)) {
                    return;
                  }

                  // If they're just typing a decimal point, allow it by adding a leading zero
                  if (newPrice === '.') {
                    setSectionPrice('0.0');
                    return;
                  }

                  // Remove leading zeros unless it's a decimal
                  if (
                    newPrice.length > 1 &&
                    newPrice[0] === '0' &&
                    newPrice[1] !== '.'
                  ) {
                    setSectionPrice(newPrice.replace(/^0+/, ''));
                    return;
                  }

                  setSectionPrice(newPrice);
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                onBlur={() => {
                  const cleanedPrice = toNumber(sectionPrice);
                  setSectionPrice(
                    toCurrency(Number(cleanedPrice)).replace('$', ''),
                  );
                }}
                sx={{
                  width: '150px',
                  m: 1,
                }}
              />

              {sectionType === SectionType.GENERAL && (
                <CustomTextField
                  label="Cupo"
                  value={sectionCapacity}
                  onChange={(e) => {
                    const newCapacity = e.target.value;
                    if (newCapacity === '') {
                      setSectionCapacity(0);
                      return;
                    }

                    if (!/^$|^\d+$/.test(newCapacity)) {
                      return;
                    }

                    setSectionCapacity(parseInt(newCapacity));
                  }}
                  sx={{
                    width: '150px',
                    m: 1,
                  }}
                />
              )}
            </CardContent>
          </Card>

          {(sectionType === SectionType.ROW ||
            sectionType === SectionType.TABLE) && (
            <Card sx={{ m: 2 }}>
              <CardContent>
                <Box
                  component="section"
                  sx={{
                    border: '1px solid #e0e0e0',
                    borderRadius: '10px',
                    padding: '10px',
                  }}
                >
                  <Box component="div">
                    <FormGroup>
                      {sectionType === SectionType.ROW && (
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={useRowNameAsPrefix}
                              onChange={(e) =>
                                setUseRowNameAsPrefix(e.target.checked)
                              }
                            />
                          }
                          label="Usar nombre de la fila como prefijo de asiento"
                        />
                      )}

                      {sectionType === SectionType.ROW && (
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={useLastSeatNumberAsNextSeat}
                              onChange={(e) =>
                                setUseLastSeatNumberAsNextSeat(e.target.checked)
                              }
                            />
                          }
                          label="Usar numero de asiento final + 1 como numero inicial del siguiente asiento"
                        />
                      )}

                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              sectionType === SectionType.TABLE
                                ? generateRangeOfTables
                                : generateRangeOfRows
                            }
                            onChange={(e) =>
                              sectionType === SectionType.TABLE
                                ? setGenerateRangeOfTables(e.target.checked)
                                : setGenerateRangeOfRows(e.target.checked)
                            }
                          />
                        }
                        label={`Generar rango de ${sectionTypeNames[sectionType].toLowerCase()}s`}
                      />
                    </FormGroup>
                  </Box>
                  <Box component="div">
                    {sectionType === SectionType.ROW && generateRangeOfRows && (
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          width: '100%',
                          '& .MuiTextField-root': {
                            width: '200px',
                            height: '50px',
                            margin: '10px',
                          },
                        }}
                      >
                        <CustomTextField
                          label="Letra inicial"
                          value={initialLetter}
                          onChange={(e) => {
                            const newInitialLetter = e.target.value;
                            // allow only letters Aa - Zz
                            if (
                              !/^[A-Za-z]+$/.test(newInitialLetter) &&
                              newInitialLetter !== ''
                            ) {
                              alert(
                                'La letra inicial debe contener solo letras',
                              );
                              return;
                            }
                            setInitialLetter(newInitialLetter.toUpperCase());
                          }}
                        />
                        <CustomTextField
                          label="Letra final"
                          value={finalLetter}
                          onChange={(e) => {
                            const newFinalLetter = e.target.value.trim();
                            // allow only letters A - Z
                            if (
                              !/^[A-Za-z]+$/.test(newFinalLetter) &&
                              newFinalLetter !== ''
                            ) {
                              alert('La letra final debe contener solo letras');
                              return;
                            }
                            setFinalLetter(newFinalLetter.toUpperCase());
                          }}
                        />
                        <CustomTextField
                          label="Numero de asientos"
                          value={seats || ''}
                          onChange={(e) => {
                            // allow only numbers and key char to remove characters
                            if (!/^$|^\d+$/.test(e.target.value)) {
                              return;
                            }

                            setSeats(parseInt(e.target.value) || 0);
                          }}
                        />
                        <Button
                          onClick={handleAddRangeOfRows}
                          variant="contained"
                          color="success"
                          sx={{ m: 2, color: 'white', fontWeight: 'bold' }}
                        >
                          Generar
                        </Button>
                      </Box>
                    )}
                    {sectionType === SectionType.TABLE &&
                      generateRangeOfTables && (
                        <Box>
                          <CustomTextField
                            label="Cantidad de mesas"
                            value={numberOfTables || ''}
                            onChange={(e) =>
                              setNumberOfTables(parseInt(e.target.value))
                            }
                            sx={{
                              width: '200px',
                              height: '50px',
                              margin: '10px',
                            }}
                          />
                          <CustomTextField
                            label="Prefijo de mesa"
                            value={tablePrefix}
                            onChange={(e) =>
                              setTablePrefix(
                                e.target.value
                                  ? e.target.value.toUpperCase()
                                  : '',
                              )
                            }
                            sx={{
                              width: '200px',
                              height: '50px',
                              margin: '10px',
                            }}
                          />
                          <CustomTextField
                            label="Numero de asientos por mesa"
                            value={numberOfSeatsPerTable || ''}
                            onChange={(e) => {
                              // allow only numbers and key char to remove characters
                              if (!/^$|^\d+$/.test(e.target.value)) {
                                return;
                              }

                              setNumberOfSeatsPerTable(e.target.value);
                            }}
                            sx={{
                              width: '200px',
                              height: '50px',
                              margin: '10px',
                            }}
                          />
                          <Button
                            onClick={handleAddRangeOfTables}
                            variant="contained"
                            color="success"
                            sx={{ m: 2, color: 'white', fontWeight: 'bold' }}
                          >
                            Generar
                          </Button>
                        </Box>
                      )}
                  </Box>
                </Box>
              </CardContent>
            </Card>
          )}

          {(sectionType === SectionType.ROW ||
            sectionType === SectionType.TABLE) && (
            <Card sx={{ m: 2 }}>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Box
                      component="section"
                      sx={{
                        border: '1px solid #e0e0e0',
                        borderRadius: '10px',
                        padding: '10px',
                        minHeight: '300px',
                      }}
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Typography variant="h4">
                            {sectionTypeNames[sectionType] + 's'}
                          </Typography>
                          {rows.length > 0 && (
                            <Box
                              component="a"
                              sx={{
                                fontSize: '14px',
                                mt: 2,
                                color: 'grey',
                                cursor: 'pointer',
                                textDecoration: 'underline',
                              }}
                              onClick={() => handleDeleteAllRows()}
                            >
                              Eliminar todas las filas
                            </Box>
                          )}
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          sx={{ display: 'flex', justifyContent: 'flex-end' }}
                        >
                          <Button
                            color="inherit"
                            onClick={
                              sectionType === SectionType.ROW
                                ? handleAddRow
                                : handleAddTable
                            }
                          >
                            + Agregar {sectionTypeNames[sectionType]}
                          </Button>
                        </Grid>
                      </Grid>
                      <Box component="div" sx={{ mt: 2 }}>
                        {sectionType === SectionType.ROW &&
                          rows.map((row, index) => (
                            <Box
                              key={index}
                              sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                width: '100%',
                                '& .MuiTextField-root': {
                                  width: '200px',
                                  height: '50px',
                                  margin: '10px',
                                },
                              }}
                            >
                              <CustomTextField
                                label="Nombre de la fila"
                                value={row.name}
                                onChange={(e) => {
                                  const newName = e.target.value.trim();

                                  // allow only letters Aa - Zz and empty space
                                  if (
                                    !/^[A-Za-z\s]+$/.test(newName) &&
                                    newName !== ''
                                  ) {
                                    alert(
                                      'El nombre de la fila debe contener solo letras y espacios',
                                    );
                                    return;
                                  }

                                  const prefixSeat = useRowNameAsPrefix
                                    ? newName
                                    : '';
                                  setRows(
                                    rows.map((r, i) =>
                                      i === index
                                        ? {
                                            ...r,
                                            name: newName
                                              ? newName.toUpperCase()
                                              : '',
                                            prefixSeat: prefixSeat
                                              ? prefixSeat.toUpperCase()
                                              : '',
                                          }
                                        : r,
                                    ),
                                  );
                                }}
                              />
                              <CustomTextField
                                label="Numero de asientos"
                                value={row.seats}
                                onChange={(e) =>
                                  handleSeatsChange(
                                    index,
                                    parseInt(e.target.value),
                                  )
                                }
                              />
                              <CustomTextField
                                label="Numero inicial de asiento"
                                value={row.initialSeat || ''}
                                onChange={(e) =>
                                  handleInitialSeatChange(
                                    index,
                                    parseInt(e.target.value),
                                  )
                                }
                              />
                              <CustomTextField
                                label="Prefijo de asiento"
                                value={row.prefixSeat}
                                onChange={(e) => {
                                  const newPrefixSeat = e.target.value;

                                  setRows(
                                    rows.map((r, i) =>
                                      i === index
                                        ? { ...r, prefixSeat: newPrefixSeat }
                                        : r,
                                    ),
                                  );
                                }}
                              />
                              <CustomTextField
                                label="Numero final de asiento"
                                value={row.finalSeat}
                                disabled={true}
                              />
                              <IconButton
                                onClick={() => handleDeleteRow(index)}
                                disableFocusRipple
                                disableRipple
                                disableTouchRipple
                              >
                                <CloseIcon />
                              </IconButton>
                            </Box>
                          ))}
                        {sectionType === SectionType.ROW &&
                          rows.length === 0 && (
                            <Box
                              component="div"
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '200px',
                              }}
                            >
                              <Typography
                                variant="h6"
                                sx={{ textAlign: 'center' }}
                              >
                                Aún no hay filas creadas, Da click en el botón +
                                Agregar fila
                              </Typography>
                            </Box>
                          )}

                        {sectionType === SectionType.TABLE &&
                          tables.map((table, index) => (
                            <Box key={index}>
                              <CustomTextField
                                label="Nombre de la mesa"
                                value={table.name}
                                onChange={(e) => {
                                  const newName = e.target.value;
                                  setTables(
                                    tables.map((t, i) =>
                                      i === index
                                        ? {
                                            ...t,
                                            name: newName
                                              ? newName.toUpperCase()
                                              : '',
                                          }
                                        : t,
                                    ),
                                  );
                                }}
                                sx={{
                                  width: '230px',
                                  height: '50px',
                                  margin: '10px',
                                }}
                              />
                              <CustomTextField
                                label="Cupo"
                                value={table.seats}
                                onChange={(e) => {
                                  const newSeats = e.target.value;
                                  setTables(
                                    tables.map((t, i) =>
                                      i === index
                                        ? { ...t, seats: newSeats }
                                        : t,
                                    ),
                                  );
                                }}
                                sx={{
                                  width: '200px',
                                  height: '50px',
                                  margin: '10px',
                                }}
                              />
                              <CustomTextField
                                label="Precio"
                                value={table.price}
                                onChange={(e) => {
                                  let newPrice = e.target.value;

                                  // allow only numbers, commas, and decimal points and key to remove characters
                                  if (
                                    !/^[\d,]*\.?\d{0,2}$|^[\d,]*\.$/.test(
                                      newPrice,
                                    )
                                  ) {
                                    return;
                                  }

                                  // If they're just typing a decimal point, allow it by adding a leading zero
                                  if (newPrice === '.') {
                                    newPrice = '0.0';
                                  }

                                  // Remove leading zeros unless it's a decimal
                                  if (
                                    newPrice.length > 1 &&
                                    newPrice[0] === '0' &&
                                    newPrice[1] !== '.'
                                  ) {
                                    newPrice = newPrice.replace(/^0+/, '');
                                  }

                                  setTables(
                                    tables.map((t, i) =>
                                      i === index
                                        ? { ...t, price: newPrice }
                                        : t,
                                    ),
                                  );
                                }}
                                onBlur={() => {
                                  const cleanedPrice = toNumber(table.price);
                                  const formattedPrice = toCurrency(
                                    Number(cleanedPrice),
                                  ).replace('$', '');
                                  setTables(
                                    tables.map((t, i) =>
                                      i === index
                                        ? { ...t, price: formattedPrice }
                                        : t,
                                    ),
                                  );
                                }}
                                sx={{
                                  width: '200px',
                                  height: '50px',
                                  margin: '10px',
                                }}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      $
                                    </InputAdornment>
                                  ),
                                }}
                              />
                              <IconButton
                                onClick={() => handleDeleteTable(index)}
                                disableFocusRipple
                                disableRipple
                                disableTouchRipple
                              >
                                <CloseIcon />
                              </IconButton>
                            </Box>
                          ))}
                        {sectionType === SectionType.TABLE &&
                          tables.length === 0 && (
                            <Box
                              component="div"
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '200px',
                              }}
                            >
                              <Typography
                                variant="h6"
                                sx={{ textAlign: 'center' }}
                              >
                                Aún no hay mesas creadas, Da click en el botón +
                                Agregar mesa
                              </Typography>
                            </Box>
                          )}
                      </Box>
                    </Box>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Box
                      component="section"
                      sx={{
                        border: '1px solid #e0e0e0',
                        borderRadius: '10px',
                        padding: '10px',
                        minHeight: '300px',
                        overflowX: 'auto',
                      }}
                    >
                      <Typography variant="h4">
                        {sectionType === SectionType.ROW
                          ? 'Asientos'
                          : 'Información de las mesas'}
                      </Typography>
                      {sectionType === SectionType.ROW && (
                        <Box
                          component="div"
                          sx={{
                            mt: 2,
                          }}
                        >
                          {rows.map((row, index) => (
                            <Box
                              component="div"
                              key={index}
                              sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                width: '100%',
                              }}
                            >
                              <Typography
                                variant="h6"
                                sx={{
                                  width: 40,
                                  margin: '2px',
                                  textAlign: 'center',
                                  verticalAlign: 'middle',
                                  color: 'black',
                                  fontWeight: 'bold',
                                }}
                              >
                                {row.name}
                              </Typography>
                              <Box
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  width: '100%',
                                }}
                              >
                                {Array.from({ length: row.seats }).map(
                                  (_, index) => {
                                    const initialSeat = row.initialSeat || 1;
                                    const seatNumber = row.prefixSeat
                                      ? `${row.prefixSeat}${initialSeat + index}`
                                      : initialSeat + index;
                                    return (
                                      <SeatBox
                                        seat={seatNumber}
                                        index={index}
                                      />
                                    );
                                  },
                                )}
                              </Box>
                            </Box>
                          ))}
                          {rows.length === 0 && (
                            <Box
                              component="div"
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '200px',
                              }}
                            >
                              <Typography
                                variant="h6"
                                sx={{ textAlign: 'center' }}
                              >
                                {sectionType === SectionType.ROW
                                  ? 'Aun no hay filas creadas, aquí se mostrarán los asientos'
                                  : 'Aun no hay mesas creadas, aquí se mostrarán las mesas correspondientes'}
                              </Typography>
                            </Box>
                          )}
                        </Box>
                      )}
                      {sectionType === SectionType.TABLE && (
                        <Box
                          component="div"
                          sx={{
                            mt: 2,
                            display: 'flex',
                            flexDirection: 'row',
                            width: '100%',
                          }}
                        >
                          {tables.map((table, index) => (
                            <TableBox
                              tableName={table.name}
                              tableData={table}
                              index={index}
                              color={sectionColor || '#76f015'}
                            />
                          ))}

                          {tables.length === 0 && (
                            <Box
                              component="div"
                              sx={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '200px',
                              }}
                            >
                              <Typography
                                variant="h6"
                                sx={{ textAlign: 'center' }}
                              >
                                Aún no hay mesas creadas, Da click en el botón +
                                Agregar mesa
                              </Typography>
                            </Box>
                          )}
                        </Box>
                      )}
                    </Box>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          )}
        </Box>
      </Dialog>
    </React.Fragment>
  );
}
