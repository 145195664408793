import { Box, Typography } from '@mui/material';
import { useCart } from '../../hooks/useCart';

export const RemainingTime = () => {
  const {
    state: { cart, minutes, seconds, isExpired },
  } = useCart();

  if (!cart) return null;

  return (
    <Box>
      {isExpired ? (
        <Typography variant="body2" color="error">
          El tiempo para completar la compra ha expirado
        </Typography>
      ) : (
        <Typography variant="body2">
          Tiempo restante para realizar la compra es de:{' '}
          <strong>
            {minutes} minutos
            {' y '}
            {seconds} segundos
          </strong>
        </Typography>
      )}
    </Box>
  );
};
