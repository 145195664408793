import { useEffect, useState, useRef } from 'react';
import { ClearCart, GetCart, GetCartTimeRemaining } from '../../requests/carts';
import { useDispatch, useSelector } from 'react-redux';
import type { RootState } from '../store';
import {
  setCountItems,
  setCart,
  setMinutes,
  setSeconds,
  setIsExpired,
} from '../../features/cart';

interface CartHook {
  state: RootState['cart'];
  loading: boolean;
  error: string | null;
  refreshCart: () => void;
}
const STORAGE_KEY = 'cartExpiration';

export const useCart = (): CartHook => {
  const state = useSelector((state: RootState) => state.cart);
  const { isExpired } = state;
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [refresh, setRefresh] = useState<boolean>(false);
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const dispatch = useDispatch();
  const [remainingTime, setRemainingTime] = useState<number>(() => {
    const storedExpiration = window.localStorage.getItem(STORAGE_KEY);
    if (storedExpiration) {
      const expiration = Number(storedExpiration);
      const diff = expiration - Date.now();
      return diff > 0 ? diff : 0;
    }
    //15 minutos en milisegundos
    return 15 * 60 * 1000 || Number(state.cart?.updatedAt);
  });

  const refreshCart = async () => {
    try {
      const { response, status } = await GetCart();
      if (status === 'OK' && response.data) {
        dispatch(setCart(response.data));
        dispatch(setCountItems(response.data.items.length));
        dispatch(setIsExpired(false));
        // Revisar si ya hay una fecha de expiración guardada
        const storedExpiration = window.localStorage.getItem(STORAGE_KEY);
        if (storedExpiration && Number(storedExpiration) > Date.now()) {
          // Si existe y aún es válida, calculamos el tiempo restante
          const newRemaining = Number(storedExpiration) - Date.now();
          setRemainingTime(newRemaining);
        } else {
          // Si no existe, llamar a la API para obtener un nuevo tiempo
          const timeRes = await GetCartTimeRemaining();
          if (timeRes?.response?.data?.remainingTime) {
            const timeFromApi = timeRes.response.data.remainingTime;
            const expirationTimestamp = Date.now() + timeFromApi;
            window.localStorage.setItem(
              STORAGE_KEY,
              expirationTimestamp.toString(),
            );
            setRemainingTime(timeFromApi);
          } else {
            setRemainingTime(0);
            window.localStorage.removeItem(STORAGE_KEY);
          }
        }
      } else {
        dispatch(setCart(null));
        dispatch(setCountItems(0));
        dispatch(setMinutes(0));
        dispatch(setSeconds(0));
        setRemainingTime(0);
        window.localStorage.removeItem(STORAGE_KEY);
      }
    } catch (error) {
      setError((error as Error).message);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const { response, status } = await GetCart();
        if (status === 'OK') {
          dispatch(setCart(response.data));
          if (response.data) {
            dispatch(setCountItems(response.data.items.length));
          }

          if (!window.localStorage.getItem(STORAGE_KEY)) {
            const timeRes = await GetCartTimeRemaining();
            if (timeRes?.response?.data?.remainingTime) {
              const timeFromApi = timeRes.response.data.remainingTime;
              const expirationTimestamp = Date.now() + timeFromApi;
              window.localStorage.setItem(
                STORAGE_KEY,
                expirationTimestamp.toString(),
              );
              setRemainingTime(timeFromApi);
            }
          }
        }
      } catch (error) {
        setError((error as Error).message);
      }
      setLoading(false);
    })();
  }, [dispatch, refresh]);

  useEffect(() => {
    // Configurar el intervalo una sola vez
    if (!isExpired) {
      timerRef.current = setInterval(() => {
        const storedExpiration = window.localStorage.getItem(STORAGE_KEY);
        if (storedExpiration) {
          const expiration = Number(storedExpiration);
          const newRemaining = expiration - Date.now();
          if (newRemaining <= 0) {
            dispatch(setIsExpired(true));
            setRemainingTime(0);
            clearInterval(timerRef.current!);
          } else {
            setRemainingTime(newRemaining);
          }
        }
      }, 1000);
    }

    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, [dispatch, isExpired]);

  useEffect(() => {
    const minutes = Math.floor((remainingTime / 1000 / 60) % 60);
    const seconds = Math.floor((remainingTime / 1000) % 60);
    if (seconds >= 0) {
      dispatch(setMinutes(minutes));
      dispatch(setSeconds(seconds));
    }
  }, [remainingTime, dispatch]);

  useEffect(() => {
    // Ha expirado. Resetear para redirigir.
    if (isExpired) {
      ClearCart();
      dispatch(setCart(null));
      dispatch(setCountItems(0));
      dispatch(setMinutes(0));
      dispatch(setSeconds(0));
      window.localStorage.removeItem(STORAGE_KEY);
      setTimeout(() => {
        refreshCart();
      }, 500);
    }
  }, [isExpired, dispatch]);

  useEffect(() => {
    window.localStorage.removeItem('timerReloaded');
  }, []);

  return {
    loading,
    error,
    refreshCart,
    state,
  };
};
