import * as React from 'react';
import {
  TextField,
  IconButton,
  AppBar,
  Toolbar,
  Divider,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from '@mui/material';
import { Form } from 'react-router-dom';
import {
  UpdateUserByAdminDto,
  updateUserByAdminSchema,
} from 'libs/domain/src/lib/dto';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import TTButton from '../shared/TTButton';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

interface UserFormProps {
  handleUpdate: (data: UpdateUserByAdminDto) => void;
  userData: UpdateUserByAdminDto;
  isOpen: boolean;
  handleCloseUpdate: () => void;
}

export default function UpdateUserForm({
  handleUpdate,
  userData,
  isOpen,
  handleCloseUpdate,
}: UserFormProps) {
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    setOpen(isOpen);
    reset();
  }, [isOpen]);

  const handleClose = () => {
    reset();
    handleCloseUpdate();
    setOpen(false);
  };

  const {
    register,
    reset,
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<UpdateUserByAdminDto>({
    resolver: zodResolver(updateUserByAdminSchema),
  });

  const onSubmit = async (data: UpdateUserByAdminDto) => {
    handleUpdate(data);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          borderRadius: '10px',
          overflow: 'hidden',
        },
      }}
    >
      <AppBar
        sx={{
          position: 'relative',
          backgroundColor: '#890050',
        }}
      >
        <Toolbar sx={{ justifyContent: 'flex-end' }}>
          <IconButton edge="end" onClick={handleClose} aria-label="close">
            <HighlightOffIcon sx={{ color: '#FFF' }} />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Form onSubmit={handleSubmit(onSubmit)} method="post" action="">
        <DialogTitle
          sx={{
            color: '#444E6A',
            fontWeight: 700,
            fontSize: '2rem',
            fontStyle: 'bold',
          }}
        >
          <b>Actualizar usuario</b>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            sx={{
              mt: 2,
              mb: 1,
              fontWeight: 600,
              color: '#444E6A',
              fontSize: '1rem',
            }}
          >
            <b>Datos del usuario</b>
            <Divider />
          </DialogContentText>
          <TextField
            {...register('firstName')}
            autoFocus
            error={!!errors.firstName}
            defaultValue={userData?.firstName || ''}
            helperText={errors.firstName?.message}
            id="outlined-required"
            label="Nombre"
            type="text"
            fullWidth
            margin="normal"
            size="small"
            className="customTextField"
          />
          <TextField
            {...register('lastName')}
            error={!!errors.lastName}
            defaultValue={userData?.lastName || ''}
            helperText={errors.lastName?.message}
            margin="normal"
            id="outlined-required"
            label="Apellidos"
            type="text"
            fullWidth
            size="small"
            className="customTextField"
          />
        </DialogContent>
        <DialogActions>
          <TTButton size="small" color="inherit" onClick={handleClose}>
            Cancelar
          </TTButton>
          <Button
            size="small"
            type="submit"
            disabled={isSubmitting}
            onClick={handleSubmit(onSubmit)}
            sx={{
              textTransform: 'none',
              fontSize: '0.9rem',
              fontFamily: 'Popins, sans-serif',
              fontWeight: 600,
              letterSpacing: '0.7px',
              borderRadius: '10px',
              padding: '5px 15px',
              color: '#fff',
              backgroundColor: '#C33186',
              boxShadow: 'none',
              '&:hover': {
                // with shadow
                boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.2)',
                backgroundColor: '#890050',
              },
            }}
          >
            Confirmar
          </Button>
        </DialogActions>
      </Form>
    </Dialog>
  );
}
