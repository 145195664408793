// Define the handleChange to use the GET req from the backend
import React from 'react';
import { ChangeEvent, MouseEvent, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import {
  Box,
  Table,
  TablePagination,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Typography,
  InputBase,
  Paper,
} from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import {
  KeyboardArrowLeft,
  KeyboardArrowRight,
  LastPage,
  FirstPage,
} from '@mui/icons-material';
import { FileDownload } from '@mui/icons-material';
import TTButton from '../shared/TTButton';
import SearchIcon from '@mui/icons-material/Search';
import { SaleHistory } from '../../routes/sales-history';
import { formatDate, formatTZDate, getTime } from '../../utils/dateUtils';
import { toCurrency } from '../../utils/currency';
import * as XLSX from 'xlsx';

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number,
  ) => void;
}

const SearchIconWrapper = styled('div')<{ isTexting: boolean }>(
  ({ theme, isTexting }) => ({
    padding: theme.spacing(0, 1.2),
    height: '100%',
    position: 'absolute',
    display: isTexting ? 'none' : 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#999',
    transition: theme.transitions.create('color', {
      duration: theme.transitions.duration.short,
    }),
  }),
);

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: '10px',
  border: '2px solid #999',
  backgroundColor: '#FFF',
  width: 'auto',
  marginBottom: '4px',
  transition: theme.transitions.create('border-color', {
    duration: theme.transitions.duration.short,
  }),
  '&:focus-within': {
    borderColor: '#000',
  },
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(0),
    width: '30vh',
  },
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: '#000',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create(['width', 'background-color'], {
      duration: theme.transitions.duration.shorter,
    }),
    backgroundColor: 'transparent',
    '&::placeholder': {
      color: '#999',
    },
    '&:focus::placeholder': {
      color: 'transparent',
    },
    [theme.breakpoints.up('sm')]: {
      width: '18ch',
      '&:focus': {
        width: '24ch',
      },
    },
  },
}));

function TablePaginationActions(props: TablePaginationActionsProps) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPage /> : <FirstPage />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPage /> : <LastPage />}
      </IconButton>
    </Box>
  );
}

interface SalesHistoryTableProps {
  salesHistory: SaleHistory[];
  onPageChange: (
    event: MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => void;
  onRowsPerPageChange: (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
  currentPage: number;
  rowsPerPage: number;
  totalSales: number;
  onSearch: (search: string) => void;
  onOpenSaleHistoryDetailModal: (data: SaleHistory) => void;
}

export const SalesHistoryTable = ({
  onPageChange,
  onRowsPerPageChange,
  salesHistory,
  currentPage,
  rowsPerPage,
  totalSales,
  onSearch,
  onOpenSaleHistoryDetailModal,
}: SalesHistoryTableProps) => {
  const [searchSaleQuery, setSearchSaleQuery] = useState('');

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#444E6A',
      color: theme.palette.common.white,
      fontWeight: 600,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 15,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    backgroundColor: '#EBEBEB',
    border: 0,
  }));

  interface SaleData {
    'Id Transaccion': number | string;
    'Fecha de Compra': string;
    'Hora de Compra': string;
    Usuario: string;
    Evento?: string;
    Lugar?: string;
    Secction?: string;
    Fila?: string;
    Asiento?: string;
    Precio?: number | string;
  }

  const exportToExcel = (tableData: SaleHistory[], fileName: string) => {
    const data: SaleData[] = [];

    tableData.forEach((sale) => {
      sale.cart.cart_items?.forEach((cartItem) => {
        console.log('cartItem', cartItem);
        const saleData: SaleData = {
          'Id Transaccion': sale.id,
          'Fecha de Compra': formatDate(new Date(sale.createdAt)),
          'Hora de Compra': getTime(new Date(sale.createdAt)),
          Usuario: `${sale.user.firstName} ${sale.user.lastName}`,
          Evento: cartItem.event?.name,
          Lugar: cartItem.event?.lineAddress,
          Secction: cartItem.section?.name,
          Fila: cartItem.row?.name,
          Asiento: cartItem.seat?.name,
          Precio:
            cartItem.section?.price !== undefined
              ? toCurrency(cartItem.section?.price)
              : 'N/A',
        };

        data.push(saleData);
      });
    });

    const worksheet = XLSX.utils.json_to_sheet(data);

    const workbook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    XLSX.writeFile(workbook, `${fileName}.xlsx`);
  };

  return (
    <TableContainer sx={{ padding: 3 }}>
      <Search>
        <SearchIconWrapper isTexting={!!searchSaleQuery}>
          <SearchIcon />
        </SearchIconWrapper>
        <StyledInputBase
          placeholder="Buscar Venta..."
          value={searchSaleQuery}
          onChange={(user) => {
            setSearchSaleQuery(user.target.value);
            onSearch(user.target.value);
          }}
          inputProps={{ 'aria-label': 'search' }}
        />
      </Search>
      <TTButton
        color="secondary"
        variant="contained"
        sx={{
          float: 'right',
          marginBottom: '10px',
          lineHeight: 2,
          minWidth: '200px',
        }}
        aria-label="Add user"
        onClick={() => exportToExcel(salesHistory, 'TableData')}
        endIcon={<FileDownload />}
      >
        {'Exportar Excel'}
      </TTButton>
      <Typography
        variant="h5"
        component="div"
        sx={{
          flexGrow: 1,
          fontWeight: 900,
          color: '#444E6A',
          fontSize: '2rem',
        }}
      >
        {'Historial de Ventas'}
      </Typography>
      <Box sx={{ borderRadius: '10px', overflowX: 'auto', width: '100%' }}>
        <Table
          aria-label="customized table"
          component={Paper}
          sx={{
            minWidth: 700,
            borderRadius: '10px',
            overflow: 'hidden',
          }}
        >
          <TableHead>
            <TableRow>
              <StyledTableCell align="left">Evento</StyledTableCell>
              <StyledTableCell align="left">Fecha</StyledTableCell>
              <StyledTableCell align="left">Compra</StyledTableCell>
              <StyledTableCell align="left">Total</StyledTableCell>
              <StyledTableCell align="left">Usuario</StyledTableCell>
              <StyledTableCell align="left">Email</StyledTableCell>
              <StyledTableCell align="left"></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {salesHistory.length > 0 ? (
              salesHistory.map((saleHistory) => {
                const {
                  cart: { cart_items },
                  createdAt,
                  amount,
                  user: { firstName, lastName, email },
                } = saleHistory;

                let eventName: string = 'Sin Nombre';
                let eventDate: Date = new Date();

                if (cart_items) {
                  const cartItem = cart_items[0]; // Primer elemento del carrito
                  eventName = cartItem?.event?.name || 'Sin Nombre';
                  eventDate = cartItem?.eventDate?.date || new Date();
                }

                const formattedEventDate = eventDate
                  ? formatTZDate(new Date(eventDate))
                  : 'Fecha no disponible';
                const formattedAmount = toCurrency(amount);

                return (
                  <StyledTableRow key={saleHistory.id}>
                    <StyledTableCell align="left">{eventName}</StyledTableCell>
                    <StyledTableCell align="left">
                      {formattedEventDate}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {formatDate(new Date(createdAt))}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {formattedAmount}
                    </StyledTableCell>
                    <StyledTableCell align="left">{`${firstName} ${lastName}`}</StyledTableCell>
                    <StyledTableCell align="left">{email}</StyledTableCell>
                    <StyledTableCell align="left">
                      <TTButton
                        color="secondary"
                        variant="contained"
                        sx={{
                          float: 'right',
                          marginBottom: '10px',
                          lineHeight: 2,
                          minWidth: '200px',
                        }} // Usar estilos centralizados
                        aria-label="Add user"
                        onClick={() =>
                          onOpenSaleHistoryDetailModal(saleHistory)
                        }
                      >
                        Detalles
                      </TTButton>
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })
            ) : (
              <StyledTableRow style={{ height: 53 * rowsPerPage }}>
                <StyledTableCell colSpan={7} align="center">
                  {'Aún no hay ventas registradas'}
                </StyledTableCell>
              </StyledTableRow>
            )}
          </TableBody>
        </Table>
      </Box>
      {salesHistory.length > 0 && (
        <TablePagination
          sx={{ float: 'right' }}
          labelRowsPerPage="Filas por página"
          rowsPerPageOptions={[1, 10, 25, { label: 'Todo', value: 100000000 }]}
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to} de ${count !== -1 ? count : `más de ${to}`}`
          }
          count={totalSales}
          colSpan={3}
          rowsPerPage={rowsPerPage}
          page={currentPage}
          SelectProps={{
            inputProps: {
              'aria-label': 'Filas por página',
            },
            native: true,
          }}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
          ActionsComponent={TablePaginationActions}
        />
      )}
    </TableContainer>
  );
};
