import { z } from 'zod'

export const signPasswordSchema = z
.object({
  password: z.preprocess((val) => (val === "" ? undefined : val), 
    z.string({
      required_error: 'Por favor, ingrese su contraseña.'
    }).min(8, 'La contraseña debe tener al menos 8 caracteres.')),
  confirmPassword: z.preprocess((val) => (val === "" ? undefined : val), 
    z.string({
      required_error: 'Por favor, confirme su contraseña.'
    }).min(8, 'La contraseña debe tener al menos 8 caracteres.')),
}).refine((data) => data.password === data.confirmPassword, {
  message: 'Las contraseñas no coinciden.',
  path: ['confirmPassword'],
});


export type SignPasswordDto = z.infer<typeof signPasswordSchema>;