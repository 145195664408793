import { useEffect, useState } from 'react';
import {
  IconButton,
  AppBar,
  Toolbar,
  Divider,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { toCurrency } from '../../utils/currency';
import { formatDate } from '../../utils/dateUtils';
import { SaleHistory } from '../../routes/sales-history';

interface SaleHistoryDetailProps {
  isOpen: boolean;
  handleCloseSaleHistoryDetail: () => void;
  saleHistoryData: SaleHistory;
}

export default function SaleHistoryDetail({
  isOpen,
  handleCloseSaleHistoryDetail,
  saleHistoryData,
}: SaleHistoryDetailProps) {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  const handleClose = () => {
    handleCloseSaleHistoryDetail();
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          borderRadius: '10px',
          overflow: 'hidden',
        },
      }}
    >
      <AppBar
        sx={{
          position: 'relative',
          backgroundColor: (theme) => theme.palette.primary.dark,
        }}
      >
        <Toolbar sx={{ justifyContent: 'flex-end' }}>
          <IconButton edge="end" onClick={handleClose} aria-label="close">
            <HighlightOffIcon sx={{ color: '#FFF' }} />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogTitle
        sx={{
          color: (theme) => theme.palette.primary.dark,
          fontWeight: 700,
          fontSize: '2rem',
          fontStyle: 'bold',
        }}
      >
        <b>Información de Pago</b>
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          sx={{
            mt: 2,
            mb: 1,
            fontWeight: 600,
            color: (theme) => theme.palette.primary.dark,
            fontSize: '1rem',
          }}
        >
          <b>Datos de la compra</b>
          <Divider />
        </DialogContentText>
        <Box padding={2}>
          <Box marginBottom={2}>
            <Typography variant="body1" gutterBottom>
              Monto:
            </Typography>
            <Typography
              variant="h6"
              color="textSecondary"
              className="amount-info"
            >
              {saleHistoryData?.amount
                ? toCurrency(saleHistoryData?.amount)
                : ''}
            </Typography>
          </Box>

          <Box marginBottom={2}>
            <Typography variant="body1" gutterBottom>
              Fecha de creación:
            </Typography>
            <Typography
              variant="h6"
              color="textSecondary"
              className="createdat-info"
            >
              {saleHistoryData?.createdAt
                ? formatDate(new Date(saleHistoryData?.createdAt))
                : ''}
            </Typography>
          </Box>

          <Box marginBottom={2}>
            <Typography variant="body1" gutterBottom>
              Moneda:
            </Typography>
            <Typography
              variant="h6"
              color="textSecondary"
              className="currency-info"
            >
              MXN
            </Typography>
          </Box>

          <Divider style={{ margin: '16px 0' }} />

          <Typography variant="h5" gutterBottom>
            Detalles:
          </Typography>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Lugar</TableCell>
                <TableCell>Evento</TableCell>
                <TableCell>Sección</TableCell>
                <TableCell>Fila</TableCell>
                <TableCell>Asiento</TableCell>
                <TableCell>Precio</TableCell>
                <TableCell>Fecha de Compra</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {saleHistoryData?.cart?.cart_items?.map((item) => (
                <TableRow>
                  <TableCell>{item.event?.lineAddress}</TableCell>
                  <TableCell>{item.event?.name}</TableCell>
                  <TableCell>{item.section?.name}</TableCell>
                  <TableCell>{item.row?.name}</TableCell>
                  <TableCell>{item.seat?.name}</TableCell>
                  <TableCell>{item.section?.price}</TableCell>
                  <TableCell>{formatDate(new Date(item.createdAt))}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

          <Typography variant="h6" marginTop={2}>
            Total:{' '}
            <span className="total">
              {saleHistoryData?.cart?.cart_items?.length}
            </span>
          </Typography>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
