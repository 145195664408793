import { z } from 'zod';

export const ForgotPasswordSchema = z.object({
  email: z
    .string({
      required_error: 'Por favor, ingrese su correo electrónico.',
    })
    .email('Por favor, ingrese un email válido.'),
});

export type ForgotPasswordDto = z.infer<typeof ForgotPasswordSchema>;
