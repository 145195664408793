import * as React from 'react';
import { styled, TextField, Autocomplete } from '@mui/material';

interface Option {
  id: string | number;
  label: string | number;
}

interface AutocompleteInputProps {
  options: Option[];
  onSelectOption: (option: string | null | number) => void;
  selectedOption: string | number | null;
  placeholder: string;
  error?: string | null;
}

const CustomPaper = styled('div')(({ theme }) => ({
  borderRadius: '10px',
  '& ul': {
    backgroundColor: '#444E6A',
    '& li.Mui-selected': {
      color: '#FFFFFF',
      backgroundColor: '#7D8291',
      '&:hover': {
        color: '#FFFFFF',
        backgroundColor: '#7D8291',
      },
    },
    '& li': {
      color: '#FFFFFF',
      '&:hover': {
        color: '#FFFFFF',
        backgroundColor: '#7D8291 !important',
      },
      '&[aria-selected="true"]': {
        color: '#FFFFFF',
        backgroundColor: '#7D8291 !important',
      },
    },
  },
}));

export default function AutocompleteInput({
  options,
  onSelectOption,
  selectedOption,
  placeholder,
  error,
}: AutocompleteInputProps) {
  const [value, setValue] = React.useState<string | number | null>(
    selectedOption,
  );

  React.useEffect(() => {
    setValue(selectedOption);
  }, [selectedOption]);

  return (
    <Autocomplete
      disablePortal={false}
      id="autocomplete-input"
      options={options}
      onChange={(event, value) => {
        setValue(value?.id || null);
        onSelectOption(value?.id || null);
      }}
      value={options.find((option) => option.id === value) || null}
      renderInput={(params) => (
        <TextField
          {...params}
          error={!!error}
          helperText={error}
          size="small"
          label={placeholder}
          className="customTextField"
        />
      )}
      PaperComponent={(props) => <CustomPaper {...props} />}
    />
  );
}
