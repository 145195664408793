import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TTButton from './shared/TTButton';

interface DialogProps {
  title: string;
  text: string;
  isOpen: boolean;
  confirmDisabledButton?: boolean;
  cancelDisabledButton?: boolean;
  onCancel: () => void;
  onConfirm: (payload?: any) => void;
  children?: React.ReactNode;
  showConfirmButton?: boolean;
  showCancelButton?: boolean;
  textConfirmButton?: string;
  textCancelButton?: string;
}

export default function DefaultDialog({
  title,
  text,
  isOpen,
  confirmDisabledButton = false,
  cancelDisabledButton = false,
  children,
  onCancel,
  onConfirm,
  showConfirmButton = true,
  showCancelButton = true,
  textConfirmButton = 'Confirmar',
  textCancelButton = 'Cancelar',
}: DialogProps) {
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    onCancel();
    setOpen(false);
  };

  React.useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  return (
    <div>
      <Dialog open={open} onClose={handleClose} PaperProps={{
          sx: {
            borderRadius: '10px',
            overflow: 'hidden',
          },
        }}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{text}</DialogContentText>
          {children}
        </DialogContent>
        <DialogActions>
          {showCancelButton && (
            <TTButton
              size="small"
              color="inherit"
              disabled={cancelDisabledButton}
              onClick={handleClose}
            >
              {textCancelButton}
            </TTButton>
          )}
          {showConfirmButton && (
            <Button
              size="small"
              type="submit"
              disabled={confirmDisabledButton}
              onClick={onConfirm}
              sx={{
                textTransform: 'none',
                color: '#fff',
                backgroundColor: '#C33186',
                fontSize: '0.9rem',
                fontFamily: 'Popins, sans-serif',
                fontWeight: 600,
                letterSpacing: '0.7px',
                borderRadius: '10px',
                padding: '5px 15px',
                boxShadow: 'none',
                '&:hover': {
                  backgroundColor: '#890050',
                  boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.2)',
                },
              }}
            >
              {textConfirmButton}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
