import React from 'react';
import {
  Container,
  Grid,
  Link,
  IconButton,
  Typography,
  Box,
  CardMedia,
  TextField,
  FormControl,
  InputLabel,
  InputAdornment,
  OutlinedInput,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import t1 from '../images/t1.png';
import './css/footer.css';
import { useNavigate } from 'react-router-dom';

const Footer: React.FC = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <footer
      style={{
        backgroundColor: '#CC1580',
        width: '100%',
      }}
    >
      <Container
        sx={{
          padding: isMobile ? '2rem 1rem' : '4rem 4rem',
        }}
      >
        <Grid container alignItems="right" justifyContent="space-between">
          <Box
            sx={{
              display: 'flex',
              margin: '1rem',
            }}
          >
            <CardMedia
              component="img"
              alt="Imagen"
              width={isMobile ? '150' : '200'}
              height={isMobile ? '15px' : '35px'}
              image={t1}
              sx={{ boxShadow: 'none', border: 'none' }}
            />
          </Box>
          <Box
            sx={{
              width: isMobile ? '100%' : '150px',
              display: 'flex',
              margin: '1rem',
              justifyContent: 'space-around',
              flexDirection: 'column',
              color: '#ffffff',
            }}
          >
            <Typography
              variant="h4"
              sx={{
                fontSize: '17px',
                marginBottom: '0.9rem',
                fontFamily: 'Poppins',
                fontWeight: 'bold',
              }}
            >
              ACERCA DE
            </Typography>
            <Link
              onClick={() => {
                navigate('/aviso-privacidad');
                window.scrollTo(0, 0);
              }}
              underline="hover"
              sx={{
                fontSize: '13px',
                cursor: 'pointer',
                color: '#ffffff',
                textDecoration: 'none',
                fontFamily: 'Poppins',
              }}
            >
              Aviso de Privacidad
            </Link>
            <Link
              onClick={() => {
                navigate('/terminos-condiciones');
                window.scrollTo(0, 0);
              }}
              underline="hover"
              sx={{
                fontSize: '13px',
                cursor: 'pointer',
                color: '#ffffff',
                textDecoration: 'none',
                fontFamily: 'Poppins',
              }}
            >
              Términos y Condiciones
            </Link>
            <Link
              href="#"
              underline="hover"
              sx={{
                fontSize: '13px',
                cursor: 'pointer',
                color: '#ffffff',
                textDecoration: 'none',
                fontFamily: 'Poppins',
              }}
            >
              Publica tu Evento
            </Link>
          </Box>
          <Box
            sx={{
              width: isMobile ? '100%' : '150px',
              display: 'flex',
              margin: '1rem',
              justifyContent: 'space-around',
              flexDirection: 'column',
              color: '#ffffff',
            }}
          >
            <Typography
              variant="h4"
              sx={{
                fontSize: '17px',
                marginBottom: '0.9rem',
                fontFamily: 'Poppins',
                fontWeight: 'bold',
              }}
            >
              CONTACTO
            </Typography>
            <Link
              href="#"
              underline="hover"
              sx={{
                fontSize: '13px',
                lineHeight: '15px',
                cursor: 'pointer',
                color: '#ffffff',
                textDecoration: 'none',
                fontFamily: 'Poppins',
              }}
            >
              ventas@tickettogo.com.mx
            </Link>
            <Box
              sx={{
                display: 'flex',
                flexFlow: 'row wrap',
                alignItems: isMobile ? 'center' : 'left',
              }}
            >
              <IconButton
                aria-label="facebook"
                size="large"
                onClick={() =>
                  window.open('https://www.facebook.com', '_blank')
                }
              >
                <svg
                  width="39"
                  height="39"
                  viewBox="0 0 39 39"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M39 19.5489C39 8.75789 30.264 0 19.5 0C8.736 0 0 8.75789 0 19.5489C0 29.0105 6.708 36.8887 15.6 38.7068V25.4135H11.7V19.5489H15.6V14.6617C15.6 10.8887 18.6615 7.81955 22.425 7.81955H27.3V13.6842H23.4C22.3275 13.6842 21.45 14.5639 21.45 15.6391V19.5489H27.3V25.4135H21.45V39C31.2975 38.0226 39 29.6947 39 19.5489Z"
                    fill="white"
                  />
                </svg>
              </IconButton>

              <IconButton
                aria-label="twitter-x"
                size="large"
                onClick={() => window.open('https://www.twitter.com', '_blank')}
              >
                <svg
                  width="41"
                  height="41"
                  viewBox="0 0 36 36"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_890_1587)">
                    <path
                      d="M18.0022 0.804688C8.50516 0.804688 0.804688 8.50516 0.804688 18.0022C0.804688 27.4993 8.50516 35.1998 18.0022 35.1998C27.4993 35.1998 35.1998 27.4993 35.1998 18.0022C35.1998 8.50516 27.4993 0.804688 18.0022 0.804688ZM22.1434 28.3136L21.743 27.7334L16.5823 20.2348L9.63843 28.3136H7.92495L15.8188 19.127L9.39583 9.79451L7.9487 7.69083H13.8848L14.2852 8.27274L19.1151 15.2895L24.186 9.38735L25.6518 7.69083H27.3636L19.8786 16.399L26.6307 26.21L28.0778 28.3136H22.1434Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_890_1587">
                      <rect width="39" height="39" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </IconButton>
            </Box>
          </Box>
          <Box
            sx={{
              width: isMobile ? '100%' : '150px',
              display: 'flex',
              margin: '1rem',
              justifyContent: 'flex-start',
              flexDirection: 'column',
              color: '#ffffff',
            }}
          >
            <Typography
              variant="h4"
              sx={{
                fontSize: '17px',
                marginBottom: '0.9rem',
                fontFamily: 'Poppins',
                fontWeight: 'bold',
              }}
            >
              AYUDA
            </Typography>
            <Link
              href="#"
              underline="hover"
              sx={{
                fontSize: '13px',
                cursor: 'pointer',
                color: '#ffffff',
                textDecoration: 'none',
                fontFamily: 'Poppins',
              }}
            >
              Mapa del sitio
            </Link>
            <Link
              href="#"
              underline="hover"
              sx={{
                fontSize: '13px',
                cursor: 'pointer',
                color: '#ffffff',
                textDecoration: 'none',
                fontFamily: 'Poppins',
              }}
            >
              Preguntas Frecuentes
            </Link>
            {/* <TextField
              placeholder="example@gmail.com"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <EmailIcon />
                  </InputAdornment>
                ),
                sx: {
                  '&::placeholder': {
                    color: '#999999', 
                    opacity: 1,
                  },
                  color: '#000000',
                  backgroundColor: '#FFFFFF',
                  borderRadius: '10px', 
                  width: 'auto',
                  height: '33px',
                },
              }}
              sx={{
                '.MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: '#FFFFFF',
                  },
                  '&:hover fieldset': {
                    borderColor: '#000000',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#000000',
                  },
                },
              }}
            /> */}
          </Box>
        </Grid>
      </Container>
    </footer>
  );
};

export default Footer;
