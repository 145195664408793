import { z } from 'zod';

export const createRowWithSeatsSchema = z.object({
  name: z.preprocess(
    (val) => (val === '' ? undefined : val),
    z.string({
      required_error: 'Por favor, ingrese un nombre para la fila.',
    }),
  ),
  capacity: z
    .preprocess(
      (val) =>
        typeof val === 'string' && val !== '' ? Number(val) : undefined,
      z.number({
        required_error: 'Por favor, indique la capacidad para esta sección.',
      }),
    )
    .refine((val) => typeof val === 'number' && val > 0, {
      message: 'La capacidad debe ser un número mayor que cero.',
    }),
  seatsInitNumber: z
    .preprocess(
      (val) =>
        typeof val === 'string' && val !== '' ? Number(val) : undefined,
      z.number().optional(),
    )
    .refine(
      (val) => {
        if ((val && val <= 0) || val === 0) {
          return false;
        }
        return true;
      },
      {
        message: 'Debe ser un número mayor que cero.',
      },
    ),
  seatsPrefix: z.preprocess(
    (val) => (val === '' ? undefined : val),
    z.string().optional(),
  ),
});

export type CreateRowWithSeatsDto = z.infer<typeof createRowWithSeatsSchema>;
