import { useState } from 'react';
import CreateSectionModal from './createSectionModal';
import Button from '@mui/material/Button';

export const SectionBuilder = () => {
  const [open, setOpen] = useState(false);

  const handleToggleOpen = (condition: boolean) => {
    setOpen(condition);
  };

  return (
    <>
      <Button onClick={() => handleToggleOpen(true)}>Create Section</Button>
      <CreateSectionModal open={open} onClose={() => handleToggleOpen(false)} />
    </>
  );
};
