import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TicketList from '../../components/TicketList';
import { useEffect, useState } from 'react';
import { getMyTickets } from 'apps/client/src/requests/users';
import { useParams } from 'react-router-dom';
import { CartItemEntity } from 'libs/domain/src/lib/entities/CartItem';
import QRTicketDialog from '../../components/QRTicketDialog';
import { formatTZDate } from '../../utils/dateUtils';

export interface QRInfo {
  name: string;
  venueName: string;
  city: string;
  state: string;
  eventDate: string;
  eventTime: string;
}

export const MyTickets = () => {
  const { id } = useParams<{ id: string }>();
  const [pageReady, setPageReady] = useState(false);
  const [tickets, setTickets] = useState<CartItemEntity[]>([]);
  const [openQRTicket, setOpenQRTicket] = useState(false);
  const [hash, setHash] = useState<string | null>(null);
  const [qrData, setQrData] = useState<QRInfo>({} as QRInfo);

  const handleOpenQRTicket = (ticket: CartItemEntity) => {
    const hash = `${ticket.cartId}&&${ticket.eventId}&&${ticket.seatId}&&${ticket.rowId}&&${ticket.sectionId}&&${ticket.id}`;
    setHash(hash);

    const eventData: QRInfo = {
      name: ticket.event?.name || '',
      venueName: ticket.event?.venueName || '',
      city: ticket.event?.city || '',
      state: ticket.event?.state || '',
      eventDate: formatTZDate(ticket.eventDate?.date || new Date()),
      eventTime: ticket.eventDate?.from || '',
    };

    setQrData(eventData as QRInfo);
    setOpenQRTicket(true);
  };

  const handleCloseQRTicket = async () => {
    await getMyTicketList();
    setOpenQRTicket(false);
    setHash(null);
    setQrData({} as QRInfo);
  };

  async function getMyTicketList() {
    if (!id) {
      return;
    }
    try {
      const response = await getMyTickets(id);
      setTickets(response.response.data);
      setPageReady(true);
    } catch (error) {
      setPageReady(true);
    }
  }

  useEffect(() => {
    if (!id) {
      return;
    }

    (async () => {
      try {
        await getMyTicketList();
        setPageReady(true);
      } catch (error) {
        setPageReady(true);
      }
    })();
  }, []);

  if (!pageReady) {
    return <Box>Loading...</Box>;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'start',
        width: '100%',
        height: 'auto',
        minHeight: '100vh',
        mt: {
          xs: 8,
          sm: 8,
          md: 10,
        },
        mb: 4,
      }}
    >
      <Typography
        variant="h6"
        sx={{
          color: (theme) => theme.palette.primary.main,
          fontWeight: 900,
          fontFamily: 'Roboto',
          fontSize: '36px',
          mb: 1,
        }}
      >
        {tickets.length === 0 ? 'No tickets found' : tickets[0].event?.name}
      </Typography>
      <Typography
        variant="h6"
        sx={{
          color: (theme) => theme.palette.primary.main,
          fontWeight: 900,
          fontFamily: 'Roboto',
          fontSize: '20px',
          mb: 1,
        }}
      >
        Mis boletos
      </Typography>
      <TicketList tickets={tickets} openQRTicketDialog={handleOpenQRTicket} />
      <QRTicketDialog
        isOpen={openQRTicket}
        onClose={handleCloseQRTicket}
        hash={hash}
        qrInfo={qrData}
      />
    </Box>
  );
};
