import {
  ChangeEvent,
  Fragment,
  MouseEvent,
  ReactNode,
  useEffect,
  useState,
} from 'react';
import AlertMessage from '../components/AlertMessage';
import { api } from '../../requests/api-client';
import FullPageSpinner from '../components/FullPageSpinner';
import { debounce } from 'lodash';
import { useAuth } from '../../providers/AuthProvider';
import { useNavigate } from 'react-router-dom';

import {
  AdminPanelSettings,
  PointOfSale,
  QrCodeScanner,
  Menu,
  ChevronLeft,
  ChevronRight,
  People,
  EventAvailable,
  Home,
  Sell,
  PersonPin,
} from '@mui/icons-material';

import {
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme,
} from '@mui/material';
import { SalesHistoryTable } from '../components/sales/SalesHistoryTable';
import SaleHistoryDetail from '../components/sales/SaleHistoryDetails';
import { EventEntity } from '../../../../../libs/domain/src/lib/entities/Event';
import { CartEntity } from '../../../../../libs/domain/src/lib/entities/Cart';
import { UserEntity } from '../../../../../libs/domain/src/lib/entities';

interface MenuOptions {
  label?: string;
  path: string;
  icon: ReactNode;
  onClick: () => void;
}

export interface SaleHistory {
  id: string;
  cart: CartEntity;
  event: EventEntity;
  user: UserEntity;
  createdAt: Date;
  amount: number;
}

const initialSaleHistoryValues: SaleHistory = {
  id: '',
  cart: {
    id: '',
    userId: null,
    createdAt: new Date(),
    updatedAt: new Date(),
    status: 'ACTIVE',
  },
  event: {
    id: '',
    name: '',
    shortDescription: '',
    longDescription: '',
    bannerImage: null,
    ticketImage: null,
    cardImage: null,
    status: 'DRAFT',
    lineAddress: '',
    category: 'MUSICA',
    createdAt: new Date(),
    updatedAt: null,
    highlighted: false,
    state: '',
    city: '',
    venueName: '',
  },
  user: {
    id: '',
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    phoneNumber: '',
    birthDate: new Date(),
    role: 'ADMIN',
    createdAt: new Date(),
    updatedAt: null,
    status: 'ACTIVE',
    deletedAt: new Date(),
    customerId: '',
    islegacy: false,
  },
  createdAt: new Date(),
  amount: 0,
};

export const SalesHistory = () => {
  const [saleHistory, setSaleHistory] = useState<SaleHistory[]>([]);
  const [saleHistoryData, setSaleHistoryData] = useState<SaleHistory>(
    initialSaleHistoryValues,
  );
  const [openSaleHistoryDetailDialog, setOpenSaleHistoryDetailDialog] =
    useState(false);

  const [alertProps, setAlertprops] = useState<{
    isOpen: boolean;
    severity: 'success' | 'error' | 'info' | 'warning';
    message: string;
  }>({
    isOpen: false,
    severity: 'success' as 'success',
    message: '',
  });

  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageReady, setPageReady] = useState(false);
  const [searchSalesQuery, setSearchSalesQuery] = useState('');
  const [open, setOpen] = useState(true);
  const { user } = useAuth();
  const navigate = useNavigate();
  const theme = useTheme();

  const menuOptions: MenuOptions[] = [
    {
      label: 'Eventos',
      path: '/admin/eventos',
      icon: <EventAvailable sx={{ color: 'white' }} />,
      onClick: () => navigate('/admin/eventos'),
    },
    {
      label: 'Administradores',
      path: '/admin/administradores',
      onClick: () => navigate('/admin/administradores'),
      icon: <AdminPanelSettings sx={{ color: 'white' }} />,
    },
    {
      label: 'Usuarios Ventas',
      path: '/admin/sales',
      onClick: () => navigate('/admin/sales'),
      icon: <PointOfSale sx={{ color: 'white' }} />,
    },
    {
      label: 'Usuarios Escaner',
      path: '/admin/scan',
      onClick: () => navigate('/admin/scan'),
      icon: <QrCodeScanner sx={{ color: 'white' }} />,
    },
    {
      label: 'Usuarios',
      path: '/admin/usuarios',
      onClick: () => navigate('/admin/usuarios'),
      icon: <People sx={{ color: 'white' }} />,
    },
    {
      label: 'Historial de ventas',
      path: '/admin/historial-de-ventas',
      icon: <Sell sx={{ color: 'white' }} />,
      onClick: () => navigate('/admin/historial-de-ventas'),
    },
    {
      label: 'Página principal',
      path: '/',
      icon: <Home sx={{ color: 'white' }} />,
      onClick: () => navigate('/'),
    },
  ];

  const handleCloseAlert = () => {
    setAlertprops({
      isOpen: false,
      severity: 'success',
      message: '',
    });
  };

  const handleChangePage = (
    event: MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOpenDrawer = () => {
    setOpen(true);
  };

  const handleCloseDrawer = () => {
    setOpen(false);
  };

  /* API Functions */
  const fetchSalesHistory = async () => {
    try {
      const offset = rowsPerPage * page;
      const response = await api.get('/admin/salesHistory', {
        params: {
          search: searchSalesQuery,
          page: page,
          limit: rowsPerPage,
          offset,
        },
      });
      setSaleHistory(response.data.data.payments);
      setTotal(response.data.count);
    } catch (error) {
      console.error('Error fetching admins:', error);
    }
  };

  /* API Functions end */
  const debouncedSalesQuery = debounce(fetchSalesHistory, 500);

  useEffect(() => {
    const delay = setTimeout(() => {
      debouncedSalesQuery();
    }, 150);

    if (pageReady) {
      if (searchSalesQuery === '') {
        debouncedSalesQuery.cancel();
        fetchSalesHistory();
      } else {
        debouncedSalesQuery();
      }
    }

    return () => {
      clearTimeout(delay);
      debouncedSalesQuery.cancel();
    };
  }, [searchSalesQuery]);

  useEffect(() => {
    (async () => {
      await fetchSalesHistory();
      setPageReady(true);
    })();
  }, []);

  useEffect(() => {
    if (pageReady) {
      fetchSalesHistory();
    }
  }, [page, rowsPerPage]);

  if (!pageReady) {
    return <FullPageSpinner />;
  }

  const handleOpenSaleHistoryDetailModal = (saleHistoryData: SaleHistory) => {
    setSaleHistoryData(saleHistoryData);
    setOpenSaleHistoryDetailDialog(true);
  };

  const handleCloseSaleHistoryDetailModal = () => {
    setOpenSaleHistoryDetailDialog(false);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        height: 'auto',
      }}
    >
      {/* New menu here */}
      {open && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            padding: '17.5px 0 0 0',
            position: 'relative',
            borderRadius: '15px',
            width: { xs: '100%', md: '30%' },
            minHeight: '675px',
            backgroundColor: '#444E6A',
            m: '20px 0',
            right: '10px',
          }}
        >
          <Fragment>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                width: '100%',
                justifyContent: 'center',
              }}
            >
              <PersonPin
                sx={{
                  mr: 1,
                  width: 48,
                  height: 48,
                  color: '#FFF',
                }}
              />
              <Typography
                variant="caption"
                sx={{
                  fontSize: 20,
                  fontWeight: 900,
                  color: '#FFF',
                  lineHeight: 1.2,
                  maxWidth: '60%',
                  whiteSpace: 'normal',
                  wordBreak: 'break-word',
                }}
              >
                Bienvenido, {user?.firstName}
              </Typography>
            </Box>
            <Divider
              sx={{
                borderColor: '#FFF',
                borderWidth: '0.5px',
                mt: 1,
              }}
            />
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <List sx={{ p: '10px 35px 0 0' }} className="customAdminList">
                {menuOptions.map((option, index) => (
                  <ListItem
                    key={option.label}
                    disablePadding
                    sx={{ display: 'block' }}
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? 'initial' : 'center',
                        px: 2.5,
                        color: '#FFF',
                        background: '#444E6A',
                      }}
                      onClick={option.onClick}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? '15px' : 'auto',
                          justifyContent: 'center',
                        }}
                      >
                        {option.icon}
                      </ListItemIcon>
                      <ListItemText
                        primary={option.label}
                        sx={{
                          opacity: open ? 1 : 0,
                          fontSize: 13,
                          fontWeight: 500,
                          textAlign: 'start',
                        }}
                      />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </Box>
          </Fragment>
          <IconButton
            onClick={handleCloseDrawer}
            sx={{
              borderRadius: 0,
              alignSelf: 'flex-end',
              top: '150px',
              right: '10px',
            }}
          >
            {theme.direction === 'rtl' ? (
              <ChevronRight
                sx={{
                  color: (theme) => theme.palette.common.white,
                }}
              />
            ) : (
              <ChevronLeft
                sx={{
                  color: (theme) => theme.palette.common.white,
                }}
              />
            )}
          </IconButton>
        </Box>
      )}
      {/* Menu options */}
      {!open && (
        <IconButton
          aria-label="open drawer"
          onClick={handleOpenDrawer}
          edge="start"
          sx={{
            width: 48,
            height: 48,
            top: '20px',
            left: '17.5px',
          }}
        >
          <Menu
            sx={{
              color: '#444E6A',
              width: 36,
              height: 36,
            }}
          />
        </IconButton>
      )}
      <SalesHistoryTable
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        salesHistory={saleHistory}
        currentPage={page}
        rowsPerPage={rowsPerPage}
        totalSales={total}
        onSearch={(search) => setSearchSalesQuery(search)}
        onOpenSaleHistoryDetailModal={handleOpenSaleHistoryDetailModal}
      />
      <SaleHistoryDetail
        handleCloseSaleHistoryDetail={handleCloseSaleHistoryDetailModal}
        isOpen={openSaleHistoryDetailDialog}
        saleHistoryData={saleHistoryData}
      />
      <AlertMessage
        onClose={handleCloseAlert}
        isOpen={alertProps.isOpen}
        message={alertProps.message}
        severity={alertProps.severity}
      />
    </Box>
  );
};
