// CustomTheme.js
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#C33186',
      dark: '#444E6A',
    },
    secondary: {
      main: '#890050',
      dark: '#48002A',
      light: '#C0AEB9',
    },
    success: {
      main: '#3BB007',
    },
    error: {
      main: '#C92603',
    },
    common: {
      white: '#FFFFFF',
      black: '#000000',
    },
  },
  typography: {
    // Add custom typography styles here
    fontFamily: 'Poppins, sans-serif',
    fontSize: 14,
    h1: {
      fontSize: '2.5rem',
    },
    h2: {
      fontSize: '2rem',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          //Outlined
          '&.customOutlinedButton': {
            backgroundColor: '#444E6A',
            color: '#FFFFFF',
            textTransform: 'none',
            fontSize: '0.9rem',
            fontFamily: 'Poppins, sans-serif',
            fontWeight: 600,
            letterSpacing: '0.5px',
            borderRadius: '8px',
            padding: '5px 15px',
            boxShadow: 'none',
            marginTop: '8px',
            '&:hover': {
              backgroundColor: '#FFFFFF',
              border: '2px solid #444E6A',
              color: '#444E6A',
              boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.2)', // Shadow on hover
            },
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          // Outlined
          '&.customTextField': {
            '& .MuiOutlinedInput-input': {
              color: '#000000', // Black text color
            },
            '& .MuiOutlinedInput-root': {
              borderRadius: '10px', // Add borderRadius to the input container
              '&:hover': {
                color: '#444E6A',
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#444E6A',
                },
              },
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: '#444E6A',
                borderWidth: '3px',
                borderRadius: '10px', // Add borderRadius to the outline
              },
              '&.Mui-focused': {
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#444E6A',
                  borderWidth: '3px',
                  borderRadius: '10px', // Ensure focus state retains borderRadius
                },
              },
              '&:hover:not(.Mui-focused)': {
                color: '#444E6A',
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#444E6A',
                },
              },
            },
            '& .MuiInputLabel-outlined': {
              color: '#444E6A', // Label color
              '&.Mui-focused': {
                color: '#444E6A',
              },
            },
          },
          // default textfields stylesheets
          '& .MuiOutlinedInput-root': {
            color: '#890050',
            '&:hover': {
              color: '#890050',
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: '#890050',
              },
            },
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: '#890050',
              borderWidth: '2px',
            },
            '&.Mui-focused': {
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: '#890050',
                borderWidth: '3px',
              },
            },

            '&:hover:not(.Mui-focused)': {
              color: '#890050',
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: '#890050',
              },
            },
          },
          '& .MuiInputLabel-outlined': {
            color: '#890050',
            '&.Mui-focused': {
              color: '#890050',
            },
          },
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          // default
          backgroundColor: '#444E6A',
          paddingTop: '0px',
          paddingBottom: '0px',
          '&.customAdminList': {
            backgroundColor: '#444E6A',
          },
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          '&.customClientPaper': {
            backgroundColor: '#C33186',
            paddingTop: '10px',
            paddingBottom: '10px',
            color: '#FFF',
            '&:hover': {
              backgroundColor: '#890050',
            },
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          '&.customTextPaper': {
            borderRadius: '10px',
            '& ul': {
              backgroundColor: '#444E6A',
              '& li.Mui-selected': {
                color: '#FFFFFF',
                backgroundColor: '#7D8291',
                '&:hover': {
                  color: '#FFFFFF',
                  backgroundColor: '#7D8291',
                },
              },
              '& li': {
                color: '#FFFFFF',
                '&:hover': {
                  color: '#FFFFFF',
                  backgroundColor: '#7D8291 !important',
                },
                '&[aria-selected="true"]': {
                  color: '#FFFFFF',
                  backgroundColor: '#7D8291 !important',
                },
              },
            },
          },
          //default paper
          '& ul': {
            backgroundColor: '#C0AEB9',
            '& li.Mui-selected': {
              color: '#FFFFFF',
              backgroundColor: '#890050',
              '&:hover': {
                color: '#FFFFFF',
                backgroundColor: '#890050',
              },
            },
            '& li': {
              color: '#890050',
              '&:hover': {
                color: '#FFFFFF',
                backgroundColor: '#890050 !important',
              },
              '&[aria-selected="true"]': {
                color: '#FFFFFF',
                backgroundColor: '#890050 !important',
              },
            },
          },
        },
      },
    },
  },
  // Other theme overrides...
});

export default theme;
