import React from 'react';
import EventCards from '../components/EventCards';
import { api } from '../../requests/api-client';
import FullPageSpinner from '../components/FullPageSpinner';
import {
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Pagination,
  Stack,
  PaginationItem,
  CardMedia,
  Paper,
  Menu,
  MenuItem,
  IconButton,
  InputBase,
  createTheme,
  ThemeProvider,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import debounce from 'lodash/debounce';
import LogInModal from '../components/LogInModal';
import { SignUpModal } from '../components/SignUpModal';
import BannerImageSlider from '../components/BannerImageSlider';
import { EventEntity } from 'libs/domain/src/lib/entities/Event';
import NoEventsFoundImage from '../images/no-events-found.png';
import ForgotPasswordModal from '../components/ForgotPasswordModal';
import MenuIcon from '@mui/icons-material/Menu';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';
import TheaterComedyIcon from '@mui/icons-material/TheaterComedy';

const theme = createTheme({
  components: {
    MuiBottomNavigation: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            fontSize: '1rem',
          },
        },
      },
    },
  },
});

const boxContainer = {
  display: 'flex',
  flexDirection: 'column',
  height: 'auto',
  justifyContent: 'space-around',
  alignItems: 'center',
  minHeight: '450px',
  overflowX: 'hidden',
};

export const Home = () => {
  const smallTheme = useTheme();
  const isSmallScreen = useMediaQuery(smallTheme.breakpoints.down('md'));
  const [events, setEvents] = React.useState<EventEntity[]>([]);
  const [bannerEvents, setBannerEvents] = React.useState<EventEntity[]>([]);
  const [noEventsFound, setNoEventsFound] = React.useState<boolean>(false);
  const [page, setPage] = React.useState(1);
  const [total, setTotal] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(6);
  const [pageReady, setPageReady] = React.useState(false);
  const [selectedCategory, setSelectedCategory] = React.useState<string | null>(
    null,
  );
  const [searchQuery, setSearchQuery] = React.useState('');
  const [hoveredCategory, setHoveredCategory] = React.useState<string | null>(
    null,
  );
  const [openLoginModal, setOpenLoginModal] = React.useState(false);
  const [openRegisterModal, setOpenRegisterModal] = React.useState(false);
  const [openForgotPasswordModal, setOpenForgotPasswordModal] =
    React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenLoginModal = () => {
    setOpenLoginModal(true);
  };

  const handleCloseLoginModal = () => {
    setOpenLoginModal(false);
  };

  const handleOpenRegisterModal = () => {
    setOpenRegisterModal(true);
  };

  const handleCloseRegisterModal = () => {
    setOpenRegisterModal(false);
  };

  const handleOpenForgotPasswordModal = () => {
    setOpenForgotPasswordModal(true);
  };

  const handleCloseForgotPasswordModal = () => {
    setOpenForgotPasswordModal(false);
  };

  const handleMouseEnter = (category: string) => {
    setHoveredCategory(category);
  };

  const handleMouseLeave = () => {
    setHoveredCategory(null);
  };

  const handleCategoryChange = (newCategory: string | null) => {
    setSelectedCategory(newCategory);
    handleClose();
  };

  const fetchBannerEvents = async (category?: string | null) => {
    try {
      const response = await api.get('/events/published', {
        params: {
          category,
        },
      });
      const eventData = response.data.data.events;

      if (eventData.length === 0) {
        setNoEventsFound(true);
      } else {
        setNoEventsFound(false);
        setBannerEvents(eventData);
      }
    } catch (error) {
      console.error('Error fetching events:', error);
    }
  };

  const fetchEvents = async (
    search?: string | null,
    category?: string | null,
    newPage = 1,
  ) => {
    try {
      const offset = Math.max(0, rowsPerPage * (newPage - 1));
      const response = await api.get('/events/published', {
        params: {
          page: page,
          limit: rowsPerPage,
          offset,
          search,
          category,
        },
      });
      const eventData = response.data.data.events;

      if (eventData.length === 0) {
        setNoEventsFound(true);
      } else {
        setNoEventsFound(false);
        setEvents(eventData);
        setTotal(response.data.count);
      }
    } catch (error) {
      console.error('Error fetching events:', error);
    }
  };

  const handleChangePage = (
    event: React.ChangeEvent<unknown> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const debouncedFetchEvents = debounce(fetchEvents, 500);

  React.useEffect(() => {
    const delay = setTimeout(() => {
      debouncedFetchEvents(searchQuery, selectedCategory);
    }, 150);

    if (pageReady) {
      if (searchQuery === '') {
        debouncedFetchEvents.cancel();
        fetchEvents(null, selectedCategory);
      } else {
        debouncedFetchEvents(searchQuery, selectedCategory);
      }
    }

    return () => {
      clearTimeout(delay);
      debouncedFetchEvents.cancel();
    };
  }, [searchQuery, selectedCategory]);

  React.useEffect(() => {
    (async () => {
      await fetchBannerEvents(null);
      await fetchEvents(null, null, page);
      setPageReady(true);
    })();
  }, [page]);

  if (!pageReady) {
    return <FullPageSpinner />;
  }

  return (
    <Box>
      <BannerImageSlider events={bannerEvents} />
      <Box sx={boxContainer}>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
            m: isSmallScreen ? '40px' : '40px 40px 20px 40px',
          }}
        >
          {isSmallScreen ? (
            <ThemeProvider theme={theme}>
              <BottomNavigation
                sx={{
                  maxWidth: 795,
                  width: '90%',
                  backgroundColor: '#890050',
                  padding: '10px',
                  borderRadius: '10px',
                  display: 'flex',
                  justifyContent: 'space-between',
                  zIndex: 2,
                }}
                value={selectedCategory}
                onChange={(_, newValue) => handleCategoryChange(newValue)}
                showLabels
              >
                <IconButton
                  id="basic-button"
                  aria-controls={open ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={handleClick}
                  disableRipple
                  sx={{ color: '#FFF' }}
                >
                  <MenuIcon />
                </IconButton>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: 'visible',
                      backgroundColor: '#890050',
                      color: '#FFF',
                      filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                      mt: 1.5,
                      '&::before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: '#890050',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                      },
                    },
                  }}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                >
                  <MenuItem
                    onClick={() => handleCategoryChange(null)}
                    sx={{
                      '&:hover': {
                        backgroundColor: '#C33186',
                      },
                      color: '#FFF',
                    }}
                  >
                    Todos los Eventos
                  </MenuItem>
                  <MenuItem
                    onClick={() => handleCategoryChange('MUSICA')}
                    sx={{
                      '&:hover': {
                        backgroundColor: '#C33186',
                      },
                      color: '#FFF',
                    }}
                  >
                    <MusicNoteIcon />
                    &nbsp;Música
                  </MenuItem>
                  <MenuItem
                    onClick={() => handleCategoryChange('DEPORTES')}
                    sx={{
                      '&:hover': {
                        backgroundColor: '#C33186',
                      },
                      color: '#FFF',
                    }}
                  >
                    <SportsSoccerIcon />
                    &nbsp;Deportes
                  </MenuItem>
                  <MenuItem
                    onClick={() => handleCategoryChange('ENTRETENIMIENTO')}
                    sx={{
                      '&:hover': {
                        backgroundColor: '#C33186',
                      },
                      color: '#FFF',
                    }}
                  >
                    <TheaterComedyIcon />
                    &nbsp;Entretenimiento
                  </MenuItem>
                </Menu>
                <Paper
                  component="form"
                  sx={{
                    p: '10px',
                    alignItems: 'center',
                    width: 190,
                    maxHeight: '20px',
                    display: 'flex',
                    backgroundColor: '#DCDCDC',
                    alignSelf: 'center',
                  }}
                >
                  <InputBase
                    value={searchQuery}
                    onChange={(event) => setSearchQuery(event.target.value)}
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Buscar Evento"
                    inputProps={{
                      disableunderline: 'true',
                      style: {
                        color: '#890050',
                        outline: 'none',
                        fontSize: '10px',
                        fontFamily: 'Poppins',
                        fontWeight: 'bold',
                        backgroundColor: '#DCDCDC',
                      },
                    }}
                  />
                  <IconButton sx={{ p: '10px' }} aria-label="search">
                    <SearchIcon style={{ color: '#890050' }} />
                  </IconButton>
                </Paper>
              </BottomNavigation>
            </ThemeProvider>
          ) : (
            <ThemeProvider theme={theme}>
              <BottomNavigation
                sx={{
                  maxWidth: 795,
                  width: '100%',
                  backgroundColor: '#890050',
                  padding: '10px 16px',
                  borderRadius: '10px',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  gap: '12px',
                  zIndex: 2,
                }}
                value={selectedCategory}
                onChange={(_, newValue) => handleCategoryChange(newValue)}
                showLabels
              >
                {[
                  { label: 'Todos los Eventos', value: null },
                  { label: 'Música', value: 'MUSICA' },
                  { label: 'Entretenimiento', value: 'ENTRETENIMIENTO' },
                  { label: 'Deportes', value: 'DEPORTES' },
                ].map(({ label, value }) => (
                  <BottomNavigationAction
                    key={label}
                    label={label}
                    value={value}
                    sx={{
                      fontSize: '1rem',
                      fontFamily: 'Poppins',
                      fontWeight: 'bold',
                      padding: '8px 16px',
                      backgroundColor:
                        selectedCategory === value ? '#ffffff' : '#890050',
                      color: selectedCategory === value ? '#890050' : '#ffffff',
                      borderRadius: '10px',
                      transition: 'all 0.2s ease',
                      '&:hover': {
                        backgroundColor: '#C33186',
                        color: '#890050',
                        paddingTop: '12px',
                        paddingBottom: '12px',
                      },
                      '& .MuiBottomNavigationAction-label': {
                        fontSize: '1rem',
                        color:
                          selectedCategory === value ? '#890050' : '#ffffff',
                        fontWeight: 'bold',
                      },
                      '&.Mui-selected': {
                        backgroundColor: '#ffffff',
                        color: '#890050',
                        fontSize: '1rem',
                      },
                    }}
                    onMouseEnter={() => handleMouseEnter(label)}
                    onMouseLeave={handleMouseLeave}
                  />
                ))}
                <Paper
                  component="form"
                  sx={{
                    p: '10px',
                    alignItems: 'center',
                    width: 230,
                    maxHeight: '20px',
                    display: 'flex',
                    backgroundColor: '#DCDCDC',
                    alignSelf: 'center',
                  }}
                >
                  <IconButton
                    sx={{ p: '10px', color: '#890050' }}
                    aria-label="menu"
                  >
                    <MenuIcon />
                  </IconButton>
                  <InputBase
                    value={searchQuery}
                    onChange={(event) => setSearchQuery(event.target.value)}
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Buscar Evento"
                    inputProps={{
                      disableunderline: 'true',
                      style: {
                        color: '#890050',
                        outline: 'none',
                        fontSize: '14px',
                        fontFamily: 'Poppins',
                        fontWeight: 'bold',
                        backgroundColor: '#DCDCDC',
                      },
                    }}
                  />
                  <IconButton sx={{ p: '10px' }} aria-label="search">
                    <SearchIcon style={{ color: '#890050' }} />
                  </IconButton>
                </Paper>
              </BottomNavigation>
            </ThemeProvider>
          )}
        </Box>
        {noEventsFound ? (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              minHeight: '600px',
            }}
          >
            <CardMedia
              component="img"
              alt="Imagen"
              image={NoEventsFoundImage}
              sx={{
                width: 400,
                height: 'auto',
                objectFit: 'cover',
                background: 'transparent',
              }}
            />
            <p
              style={{
                textAlign: 'center',
                color: '#8f1b64',
                fontSize: isSmallScreen ? '1.25rem' : '1.8rem',
                fontFamily: 'Poppins',
                fontWeight: 600,
                whiteSpace: 'normal',
                wordBreak: 'break-word',
                lineHeight: '1.5',
                margin: '16px 20px',
                maxWidth: isSmallScreen ? '90%' : '70%',
              }}
            >
              Lo sentimos, parece que no hay eventos disponibles.
            </p>
          </Box>
        ) : (
          <EventCards events={events} />
        )}{' '}
      </Box>
      <Box
        sx={{
          justifyContent: 'center',
          display: 'flex',
          marginBottom: 8,
        }}
      >
        <Stack spacing={2}>
          {events.length > 0 && (
            <Pagination
              size="medium"
              count={Math.ceil(total / rowsPerPage)}
              page={page}
              onChange={handleChangePage}
              renderItem={(item) => (
                <PaginationItem
                  slots={{
                    previous: ArrowBackIosIcon,
                    next: ArrowForwardIosIcon,
                  }}
                  sx={{
                    '& .MuiPaginationItem-icon': {
                      color: '#a1027e',
                    },
                    '&.Mui-selected': {
                      backgroundColor: '#ff4fd9',
                    },
                  }}
                  {...item}
                />
              )}
            />
          )}
        </Stack>
      </Box>
      <LogInModal
        isOpen={openLoginModal}
        onCloseModal={handleCloseLoginModal}
        onOpenRegisterModal={handleOpenRegisterModal}
        onOpenForgotPasswordModal={handleOpenForgotPasswordModal}
      />
      <ForgotPasswordModal
        isOpen={openForgotPasswordModal}
        onCloseModal={handleCloseForgotPasswordModal}
        onOpenRegisterModal={handleOpenRegisterModal}
        onOpenLoginModal={handleOpenLoginModal}
      />
      <SignUpModal
        isOpen={openRegisterModal}
        onOpenLoginModal={handleOpenLoginModal}
        onCloseModal={handleCloseRegisterModal}
      />
    </Box>
  );
};
