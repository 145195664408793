import * as React from 'react';
import {
  Button,
  Box,
  TextField,
  Divider,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  AppBar,
  Toolbar,
  DialogTitle,
  Slide
} from '@mui/material';
import { useForm } from 'react-hook-form';
import {
  CreateTableRowWithSeatsDto,
  createTableRowWithSeatsSchema,
} from 'libs/domain/src/lib/dto';
import {
  CreateRowWithSeatsDto,
  createRowWithSeatsSchema,
} from 'libs/domain/src/lib/dto';
import { zodResolver } from '@hookform/resolvers/zod';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Form } from 'react-router-dom';
import { useState } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { TransitionProps } from '@mui/material/transitions';

interface AddTableRowProps {
  isOpen: boolean;
  onClose: () => void;
  onCreateRowsWithSeats: (data: FormData) => void;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddTableRowModal({
  isOpen,
  onClose,
  onCreateRowsWithSeats,
}: AddTableRowProps) {
  const [open, setOpen] = useState(isOpen);
  const [isPriceChecked, setIsPriceChecked] = useState(false);

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<CreateTableRowWithSeatsDto>({
    resolver: zodResolver(createTableRowWithSeatsSchema),
    defaultValues: {
      name: '',
      capacity: 0,
      price: null,
    },
  });

  const handleBackdropClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    reset();
  };

  const handleClose = () => {
    onClose();
    setOpen(false);
    reset();
  };

  const onSubmit = async (data: CreateTableRowWithSeatsDto) => {
    const formData = new FormData();
    formData.append('name', data.name);
    formData.append('capacity', data.capacity.toString());
    if (data.price) {
      formData.append('price', data.price.toString());
    }
    onCreateRowsWithSeats(formData);
    reset();
  };

  React.useEffect(() => {
    setOpen(isOpen);
  }, [isOpen, onCreateRowsWithSeats]);

  return (
    <Box>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        slotProps={{ backdrop: { onClick: handleBackdropClick } }}
        disableEscapeKeyDown
        TransitionComponent={Transition}
        PaperProps={{
          sx: {
            borderRadius: '10px',
            overflow: 'hidden',
          },
        }}
      >
        <AppBar
          sx={{
            position: 'relative',
            backgroundColor: '#890050',
          }}
        >
          <Toolbar sx={{ justifyContent: 'flex-end' }}>
            <IconButton edge="end" onClick={handleClose} aria-label="close">
              <HighlightOffIcon sx={{ color: '#FFF' }} />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Form onSubmit={handleSubmit(onSubmit)} method="post" action="">
          <DialogTitle
            sx={{
              color: '#444E6A',
              fontWeight: 700,
              fontSize: '2rem',
              fontStyle: 'bold',
            }}
          >
            <b>Agregar mesas a la sección</b>
          </DialogTitle>
          <DialogContent>
          <DialogContentText
              sx={{
                mt: 2,
                mb: 1,
                fontWeight: 600,
                color: '#444E6A',
                fontSize: '1rem',
              }}
            >
              <b>Datos de la mesa</b>
              <Divider />
            </DialogContentText>

            <TextField
              autoFocus
              {...register('name')}
              id="nombre-de-la-fila"
              label="Nombre de la mesa"
              fullWidth
              margin="normal"
              size="small"
              error={!!errors.name}
              helperText={errors.name?.message}
              className="customTextField"
            />

            <TextField
              {...register('capacity')}
              id="numero-de-asientos"
              label="Número de asientos por mesa"
              type="number"
              fullWidth
              margin="normal"
              size="small"
              error={!!errors.capacity}
              helperText={errors.capacity?.message}
              className="customTextField"
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={isPriceChecked}
                  onChange={() => setIsPriceChecked(!isPriceChecked)}
                />
              }
              label="Quiero cambiar el precio de esta mesa"
            />

            {isPriceChecked && (
              <TextField
                {...register('price')}
                id="precio-de-la-mesa"
                label="Precio de la mesa"
                type="number"
                fullWidth
                margin="normal"
                size="small"
                error={!!errors.price}
                helperText={errors.price?.message}
                className="customTextField"
              />
            )}
          </DialogContent>

          <DialogActions
            sx={{
              display: 'flex',
              justifyContent: 'space-evenly',
              alignItems: 'center',
              mb: 5,
            }}
          >
            <Button
              size="medium"
              sx={{
                width: '45%',
                maxHeight: 50,
                borderRadius: '10px',
                fontSize: {
                  xs: '10px',
                  sm: '10px',
                  md: '12px',
                  lg: '14px',
                  xl: '16px',
                },
                fontWeight: 'bold',
                textTransform: 'uppercase',
                color: '#890050',
                mt: 2,
                border: '2px solid #890050',
                backgroundColor: '#FFFFFF',
                '&:hover': {
                  backgroundColor: '#890050',
                  border: '2px solid #890050',
                  color: '#FFFFFF',
                },
              }}
              onClick={handleClose}
            >
              Cancelar
            </Button>
            <Button
              disabled={isSubmitting}
              sx={{
                width: '45%',
                maxHeight: 50,
                borderRadius: '10px',
                fontSize: {
                  xs: '10px',
                  sm: '10px',
                  md: '12px',
                  lg: '14px',
                  xl: '16px',
                },
                fontWeight: 'bold',
                textTransform: 'uppercase',
                color: '#FFF',
                mt: 2,
                border: '2px solid #890050',
                backgroundColor: '#890050',
                '&:hover': {
                  backgroundColor: '#FFF',
                  border: '2px solid #890050',
                  color: '#890050',
                },
              }}
              type="submit"
              size="medium"
              onClick={handleSubmit(onSubmit)}
            >
              Crear
            </Button>
          </DialogActions>
        </Form>
      </Dialog>
    </Box>
  );
}
