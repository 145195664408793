import React, {
  ChangeEventHandler,
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  Divider,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import {
  ShoppingCartOutlined,
  HighlightOff,
  Add,
  Remove,
} from '@mui/icons-material';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  GetSectionTypeRow,
  GetSectionTypeTable,
  GetMaxNumberOfSeatsByGeneralSectionId,
} from '../../requests/sections';
import { GetSeatsByRowId } from '../../requests/rows';
import { getSectionsByEventId } from '../../requests/events';
import {
  CreateCartWithItemsDto,
  createCartWithItemsSchema,
} from 'libs/domain/src/lib/dto/Cart/index';
import {
  SectionEntity,
  TypeSection,
  getSectionType,
} from 'libs/domain/src/lib/entities/Section';
import { RowEntity } from 'libs/domain/src/lib/entities/Row';
import { SeatEntity } from 'libs/domain/src/lib/entities/Seat';
import { EventEntity } from 'libs/domain/src/lib/entities/Event';
import { toCurrency } from '../utils/currency';
import { formatTZDate, formatTime } from '../utils/dateUtils';

interface SeatsSelectorProps {
  event: EventEntity | null;
  sectionSelected: (section: SectionEntity | undefined) => void;
  addDataToCart: (data: CreateCartWithItemsDto) => void;
}

interface FormValues {
  eventDateId: string;
  sectionId: string;
  rowId: string;
  seatIds: SeatEntity[] | RowEntity[];
  quantity: number;
}

export default function SeatsSelector({
  event,
  sectionSelected,
  addDataToCart,
}: SeatsSelectorProps) {
  const [selectedDate, setSelectedDate] = useState<string | null>(null);
  const [selectedSection, setSelectedSection] = useState<string | null>(null);
  const [selectedRow, setSelectedRow] = useState<string | null>(null);
  const [selectedSeats, setSelectedSeats] = useState<SeatEntity[]>([]);
  const [selectedTableSeats, setSelectedTableSeats] = useState<RowEntity[]>([]);
  const [, setSelectedTables] = useState<RowEntity[]>([]);

  const [sectionType, setSectiontype] = useState<string>('ROW');
  const [sections, setSections] = useState<SectionEntity[] | null>(null);
  const [rows, setRows] = useState<RowEntity[] | null>(null);
  const [seats, setSeats] = useState<SeatEntity[]>([]);
  const [maxNumberOfSeats, setMaxNumberOfSeats] = useState<number>(0);
  const [isDisabled, setIsDisabled] = useState(true);

  const [formValues, setFormValues] = useState<FormValues>({
    eventDateId: '',
    sectionId: '',
    rowId: '',
    seatIds: [] as SeatEntity[] | RowEntity[],
    quantity: 1,
  });
  const navigate = useNavigate();

  const handleSelectedDate: ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (ev) => {
    if (!event?.eventDates) {
      return;
    }
    setSelectedDate(ev.target.value);
    setFormValues((prevValues) => ({
      ...prevValues,
      eventDateId: ev.target.value,
      sectionId: '',
      rowId: '',
      seatIds: [],
    }));
  };

  const handleSelectedSection: ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (event) => {
    if (!sections) {
      return;
    }
    const sectionId = event.target.value;
    setSelectedSection(sectionId);
    const filteredSection = sections.find(
      (section) => section.id === sectionId,
    );
    sectionSelected(filteredSection);
    setSectiontype(filteredSection?.type || 'ROW');
    setFormValues((prevValues) => ({
      ...prevValues,
      sectionId: sectionId,
      rowId: '',
      seatIds: [],
      sectionType: filteredSection?.type || 'ROW',
    }));
    reset({
      sectionId: sectionId,
      rowId: '',
      seatIds: [],
      quantity: 1,
      sectionType: filteredSection?.type || 'ROW',
      eventDateId: selectedDate || '',
    });
  };

  const handleSelectedRow: ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = async (event) => {
    const newRowId = event.target.value;

    setSelectedSeats([]);
    setSeats([]);
    setSelectedRow(newRowId);

    setFormValues((prevValues) => ({
      ...prevValues,
      rowId: newRowId,
      seatIds: [],
    }));

    await fetchSeat(newRowId);
  };

  const handleSelectedTable = (values: RowEntity[]) => {
    setSelectedTables(values);
    setFormValues({
      ...formValues,
      seatIds: values,
    });
    setSelectedTableSeats(values);
    setValue('seatIds', getSeatsFromTable(values));
  };

  const getSeatsFromTable = (tables: RowEntity[]) => {
    const tableSeats: string[] = [];

    tables.forEach((table) => {
      if (table.seats && table.seats?.length > 0) {
        tableSeats.push(table.seats[0].id);
      }
    });

    return tableSeats;
  };

  const handleIncrementQuantity = () => {
    const currentQuantity = formValues.quantity;
    if (currentQuantity === maxNumberOfSeats) {
      return;
    }
    setFormValues((prevValues) => ({
      ...prevValues,
      quantity: prevValues.quantity + 1,
    }));

    setValue('quantity', formValues.quantity + 1);
  };

  const handleDecrementQuantity = () => {
    const currentQuantity = formValues.quantity;
    if (currentQuantity === 1) {
      return;
    }
    setFormValues((prevValues) => ({
      ...prevValues,
      quantity: prevValues.quantity - 1,
    }));

    setValue('quantity', formValues.quantity - 1);
  };

  const handleQuantityChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const value = event.target.value;

    if (value && isNaN(Number(value))) {
      return;
    }

    if (Number(value) > maxNumberOfSeats) {
      return;
    }

    setFormValues((prevValues) => ({
      ...prevValues,
      quantity: Number(event.target.value),
    }));

    setValue('quantity', Number(event.target.value));
  };

  const {
    setValue,
    reset,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<CreateCartWithItemsDto>({
    resolver: zodResolver(createCartWithItemsSchema),
    defaultValues: {
      sectionId: undefined,
      rowId: undefined,
      seatIds: [],
      quantity: 1,
      sectionType: sectionType as TypeSection,
      eventDateId:
        event?.eventDates && event.eventDates.length === 1
          ? event.eventDates[0].id
          : '',
    },
  });

  const handleSelectedSeat = useCallback(
    (values: SeatEntity[]) => {
      setSelectedSeats([...values]);
      setFormValues((prevValues) => ({
        ...prevValues,
        seatIds: values,
      }));
      setValue(
        'seatIds',
        values.map((seat) => seat.id),
      );
    },
    [setSelectedSeats, setFormValues, setValue],
  );

  const fetchSection = async () => {
    if (!event) {
      return;
    }
    const { response, status } = await getSectionsByEventId(event.id);
    if (status === 'OK') {
      const sectionsData = response.data.sections;
      setSections(sectionsData);
    }
  };

  const fetchMaxNumberOfSeats = async () => {
    if (!selectedSection || !selectedDate) {
      return;
    }
    const { response, status } = await GetMaxNumberOfSeatsByGeneralSectionId(
      selectedSection,
      selectedDate,
    );
    if (status === 'OK') {
      const maxNumberOfSeatsResponse = response.data.maxNumberOfSeats;
      setMaxNumberOfSeats(maxNumberOfSeatsResponse);
    }
  };

  const fetchRow = async () => {
    if (!selectedSection || !selectedDate) {
      return;
    }
    const { response, status } = await GetSectionTypeRow(
      selectedSection,
      selectedDate,
    );
    if (status === 'OK') {
      const rowData = response.data.rows;
      setRows(rowData);
    }
  };

  const fetchSeat = async (rowId: string) => {
    if (!rowId || !selectedDate) return;

    try {
      setSeats([]);
      const { response, status } = await GetSeatsByRowId(rowId, selectedDate);

      if (status === 'OK') {
        const seatsData = response.data.seats;
        setSeats(seatsData);
        setMaxNumberOfSeats(seatsData.length);
      }
    } catch (error) {
      console.log('SeatSelectorError: ', error);
      setMaxNumberOfSeats(0);
    }
  };

  const sendItemsToCart = async (data: any) => {
    if (!event) {
      return;
    }

    const eventId = event.id;
    const itemsToCart = {
      eventDateId: data.eventDateId,
      sectionId: data.sectionId,
      rowId: data.rowId,
      seatIds: data.seatIds,
      eventId,
      quantity: data.quantity,
      sectionType: sectionType as TypeSection,
    };

    addDataToCart(itemsToCart);
    setSelectedSeats([]);
    if (event && event.eventDates && event.eventDates.length > 1) {
      setSelectedDate(null);
    }
    setSelectedSection(null);
    setSelectedRow(null);
    setFormValues({
      eventDateId: '',
      sectionId: '',
      rowId: '',
      seatIds: [],
      quantity: 1,
    });
    reset({
      sectionId: '',
      rowId: '',
      seatIds: [],
      quantity: 1,
      eventDateId: '',
    });
    sectionSelected(undefined);
  };

  const sortedEventDates = React.useMemo(() => {
    return event?.eventDates
      ? [...event.eventDates]
          .filter((eventDate) => {
            const eventDateTime = new Date(eventDate.date).getTime();
            return eventDateTime >= new Date().setHours(0, 0, 0, 0);
          })
          .sort((a, b) => {
            const dateA = new Date(a.date).getTime();
            const dateB = new Date(b.date).getTime();
            return dateA - dateB;
          })
      : [];
  }, [event?.eventDates]);

  const sortedSections = React.useMemo(() => {
    return [...(sections || [])].sort((a, b) => a.name.localeCompare(b.name));
  }, [sections]);

  const sortedRows = React.useMemo(() => {
    return [...(rows || [])].sort((a, b) => a.name.localeCompare(b.name));
  }, [rows]);

  useEffect(() => {
    setSelectedSection(null);
    (async () => {
      await fetchSection();
    })();
  }, [event, selectedDate]);

  useEffect(() => {
    setSelectedRow(null);
    setSelectedSeats([]);
    setSelectedTableSeats([]);
    (async () => {
      if (sectionType === 'ROW') {
        await fetchRow();
      }
      if (sectionType === 'GENERAL') {
        await fetchMaxNumberOfSeats();
      }
    })();
  }, [selectedSection]);

  useEffect(() => {
    if (!selectedRow) return;

    setSelectedSeats([]);
    setSelectedTableSeats([]);
    setSeats([]);

    (async () => {
      await fetchSeat(selectedRow);
    })();
  }, [event, selectedRow]);

  useEffect(() => {
    if (event && event.eventDates && event.eventDates.length === 1) {
      const selectedDate = event.eventDates[0].id;
      setSelectedDate(selectedDate);
      setValue('eventDateId', selectedDate);
      setFormValues((prevValues) => ({
        ...prevValues,
        eventDateId: selectedDate,
      }));
    }
  }, []);

  useEffect(() => {
    if (sectionType === 'TABLE' && selectedSection && selectedDate) {
      (async () => {
        const { response, status } = await GetSectionTypeTable(
          selectedSection,
          selectedDate,
        );
        if (status === 'OK') {
          const rowData = response.data.rows;
          setRows(rowData);
        }
      })();
    }
  }, [sectionType, selectedSection, selectedDate]);

  useEffect(() => {
    if (sectionType === 'GENERAL') {
      setIsDisabled(
        maxNumberOfSeats === 0 || formValues.quantity < 1 || isSubmitting,
      );
    } else if (sectionType === 'ROW') {
      setIsDisabled(selectedSeats.length === 0 || isSubmitting || !selectedRow);
    } else if (sectionType === 'TABLE') {
      setIsDisabled(selectedTableSeats.length === 0 || isSubmitting);
    } else {
      setIsDisabled(true);
    }
  }, [
    selectedSeats,
    selectedTableSeats,
    maxNumberOfSeats,
    isSubmitting,
    sectionType,
    formValues.quantity,
    selectedRow,
  ]);

  return (
    <Card
      sx={{
        width: '45%',
        borderRadius: '10px',
        overflowY: 'auto',
        boxShadow:
          '5px 2px 5px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
        mt: { xs: 2, md: 0 },
      }}
    >
      <>
        <CardContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography
            sx={{
              alignSelf: 'center',
              marginBottom: '12px',
              padding: '10px 0',
              fontSize: '25px',
              fontWeight: '900',
              color: (theme) => theme.palette.secondary.main,
            }}
          >
            Tu selección
          </Typography>
          <Divider
            sx={{
              border: '1px solid',
              color: (theme) => theme.palette.secondary.dark,
              width: '95%',
              alignSelf: 'center',
            }}
          />

          {/* Date */}
          <Box sx={{ marginTop: '30px' }}>
            {event?.eventDates?.length === 1 ? (
              <Typography
                sx={{
                  alignSelf: 'center',
                  marginBottom: '8px',
                  textTransform: 'uppercase',
                  fontSize: '15px',
                  fontWeight: '900',
                  color: (theme) => theme.palette.secondary.main,
                }}
              >
                {formatTZDate(event.eventDates[0].date)} -{' '}
                {formatTime(event.eventDates[0].from)}
              </Typography>
            ) : (
              <TextField
                {...register('eventDateId')}
                fullWidth
                size="medium"
                id="select-date"
                select
                label="Fecha"
                error={!!errors.eventDateId}
                helperText={errors.eventDateId?.message}
                onChange={handleSelectedDate}
                value={formValues.eventDateId}
              >
                {sortedEventDates?.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {formatTZDate(option.date)} - {formatTime(option.from)}
                  </MenuItem>
                ))}
              </TextField>
            )}
          </Box>
          {/* Date */}

          {/* Section */}
          <Box sx={{ marginTop: '30px' }}>
            {selectedDate && (
              <TextField
                {...register('sectionId')}
                fullWidth
                size="medium"
                id="select-section"
                select
                label="Sección"
                defaultValue={''}
                error={!!errors.sectionId}
                helperText={errors.sectionId?.message}
                onChange={handleSelectedSection}
                value={formValues.sectionId}
                variant="outlined"
                color="secondary"
              >
                {sortedSections?.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                      }}
                    >
                      <Box
                        component="div"
                        sx={{
                          m: 1,
                          borderRadius: 1,
                          width: 20,
                          height: 20,
                          backgroundColor: `${option.color}`,
                        }}
                      />
                      <Box>
                        {option.name} - {getSectionType(option.type)} -
                        {toCurrency(option.price)}
                      </Box>
                    </Box>
                  </MenuItem>
                ))}
              </TextField>
            )}
          </Box>
          {/* Section */}

          {/* Row */}
          <Box sx={{ marginTop: '20px' }}>
            {sectionType === 'ROW' && selectedSection && (
              <TextField
                {...register('rowId')}
                fullWidth
                size="medium"
                id="select-row"
                select
                label="Fila"
                defaultValue={''}
                error={!!errors.rowId}
                helperText={errors.rowId?.message}
                onChange={handleSelectedRow}
                value={formValues.rowId}
                variant="outlined"
                color="secondary"
              >
                {sortedRows?.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
            )}

            {sectionType === 'TABLE' && selectedSection && rows && (
              <FormControl sx={{ width: '100%' }}>
                <Autocomplete
                  id="select-seat"
                  {...register('seatIds')}
                  multiple
                  value={selectedTableSeats}
                  limitTags={5}
                  defaultValue={[]}
                  options={rows}
                  getOptionLabel={(option) => option.name}
                  onChange={(event, values) => handleSelectedTable(values)}
                  // error={!!errors.seatIds}
                  inputValue="" // '' empty to block input
                  sx={{
                    '& .MuiAutocomplete-endAdornment': {
                      '& .MuiAutocomplete-popupIndicator': {
                        display: 'none',
                      },
                    },
                  }}
                  clearIcon={<HighlightOff />}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        label={option.name}
                        {...getTagProps({ index })}
                        onDelete={undefined}
                        variant="filled"
                        sx={{
                          backgroundColor: (theme) =>
                            theme.palette.secondary.light,
                          color: (theme) => theme.palette.secondary.main,
                          borderRadius: '10px',
                        }}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField {...params} label="Mesa" />
                  )}
                />
                {errors.seatIds && (
                  <FormHelperText
                    sx={{ color: (theme) => theme.palette.error.main }}
                  >
                    {errors.seatIds?.message}
                  </FormHelperText>
                )}
              </FormControl>
            )}
          </Box>
          {/* Row */}

          {/* Seat */}
          <Box sx={{ marginTop: '20px' }}>
            {seats && sectionType === 'ROW' && selectedRow && (
              <FormControl sx={{ width: '100%' }}>
                <Autocomplete
                  id="select-seat"
                  {...register('seatIds')}
                  multiple
                  value={selectedSeats}
                  limitTags={5}
                  disableCloseOnSelect
                  defaultValue={[]}
                  options={seats}
                  getOptionLabel={(option) => option.name}
                  onChange={(event, values) => {
                    handleSelectedSeat(values);
                  }}
                  inputValue="" // '' empty to block input
                  sx={{
                    '& .MuiAutocomplete-endAdornment': {
                      '& .MuiAutocomplete-popupIndicator': {
                        display: 'none',
                      },
                    },
                  }}
                  clearIcon={<HighlightOff />}
                  // defaultValue={selectedSeats}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        label={option.name}
                        {...getTagProps({ index })}
                        onDelete={undefined}
                        variant="filled"
                        sx={{
                          backgroundColor: (theme) =>
                            theme.palette.secondary.light,
                          color: (theme) => theme.palette.secondary.main,
                          borderRadius: '10px',
                        }}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField {...params} label="Selecciona los asientos" />
                  )}
                />
                {errors.seatIds && (
                  <FormHelperText
                    sx={{ color: (theme) => theme.palette.error.main }}
                  >
                    {errors.seatIds?.message}
                  </FormHelperText>
                )}
              </FormControl>
            )}

            {sectionType === 'GENERAL' &&
              selectedSection &&
              maxNumberOfSeats > 0 && (
                <TextField
                  {...register('quantity')}
                  sx={{
                    '& input': {
                      textAlign: 'center',
                    },
                  }}
                  fullWidth
                  size="medium"
                  id="quantity"
                  type="text"
                  inputMode="numeric"
                  label={`Cantidad de boletos (Disponibles: ${maxNumberOfSeats})`}
                  defaultValue={1}
                  error={!!errors.quantity}
                  helperText={errors.quantity?.message}
                  value={formValues.quantity}
                  onChange={handleQuantityChange}
                  inputProps={{ min: 1, max: maxNumberOfSeats }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          disabled={formValues.quantity === maxNumberOfSeats}
                          aria-label="increment"
                          onClick={handleIncrementQuantity}
                        >
                          <Add />
                        </IconButton>
                      </InputAdornment>
                    ),
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton
                          disabled={formValues.quantity === 1}
                          aria-label="decrement"
                          onClick={handleDecrementQuantity}
                        >
                          <Remove />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}

            {sectionType === 'GENERAL' &&
              selectedSection &&
              maxNumberOfSeats === 0 && (
                <Typography
                  sx={{
                    color: (theme) => theme.palette.error.main,
                    fontSize: '12px',
                    marginTop: '10px',
                  }}
                >
                  No hay boletos disponibles
                </Typography>
              )}
          </Box>
          {/* Seat */}
        </CardContent>
        <CardActions
          sx={{ display: 'flex', flexDirection: 'column', p: '20px', mb: 1 }}
        >
          <Button
            size="large"
            sx={{
              fontWeight: 700,
              textTransform: 'initial',
              background: (theme) => theme.palette.primary.main,
              border: '3px solid #CC1580',
              borderRadius: '8px',
              color: (theme) => theme.palette.common.white,
              fontFamily: 'Poppins',
              boxSizing: 'border-box',
              transition: 'all 0.3s ease',
              '&:hover': {
                backgroundColor: (theme) => theme.palette.common.white,
                color: (theme) => theme.palette.primary.main,
                boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
              },
            }}
            startIcon={<ShoppingCartOutlined />}
            fullWidth
            onClick={handleSubmit(sendItemsToCart)}
            disabled={isDisabled}
          >
            Agregar al carrito
          </Button>

          <Button
            size="large"
            sx={{
              fontWeight: 700,
              textTransform: 'initial',
              marginTop: '10px',
              marginLeft: '0px !important',
              background: (theme) => theme.palette.common.white,
              border: '3px solid #48002A',
              borderRadius: '8px',
              color: (theme) => theme.palette.secondary.dark,
              fontFamily: 'Poppins',
              boxSizing: 'border-box',
              transition: 'all 0.3s ease',
              '&:hover': {
                backgroundColor: (theme) => theme.palette.secondary.dark,
                color: (theme) => theme.palette.common.white,
                boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
              },
            }}
            fullWidth
            onClick={() => navigate('/carrito')}
          >
            Ir al carrito
          </Button>
        </CardActions>
      </>
    </Card>
  );
}
