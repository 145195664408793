import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../../providers/AuthProvider';

type Roles = 'ADMIN' | 'SALES' | 'SCANNER' | 'USER';

interface Props {
  children?: React.ReactNode;
  allowedRoles: Roles[];
  redirectTo?: string;
}

const ProtectedRoute: React.FC<Props> = ({
  children,
  allowedRoles = [],
  redirectTo,
}) => {
  const { isAuth, user } = useAuth();
  const redirectPath = redirectTo || '/';

  if (!user || !isAuth) {
    return <Navigate to={redirectPath} replace />;
  }

  if (isAuth && !allowedRoles.includes(user?.role as Roles)) {
    return <Navigate to={'/forbidden'} replace />;
  }

  return <>{children}</>;
};

export default ProtectedRoute;
