import { MouseEvent, useEffect } from 'react';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Typography } from '@mui/material';
import { CardMedia } from '@mui/material';
import { useState } from 'react';
import { Form, useNavigate } from 'react-router-dom';
import { useAuth } from '../../providers/AuthProvider';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import t1 from '../images/t1.png';
import {
  AccountCircleRounded,
  PlaylistAddCheckCircle,
} from '@mui/icons-material';
import {
  ResetPasswordDto,
  resetPasswordSchema,
} from '../../../../../libs/domain/src/lib/dto';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  height: 'auto',
  bgcolor: 'background.paper',
  borderRadius: '10px',
  boxShadow: 24,
  p: 4,
  paddingTop: '5px',
  paddingBottom: '0px',
  fontFamily: 'Helvetica Neue, ,Helvetica,Arial,sans-serif',
};

const styleHeader = {
  width: 465,
  marginLeft: '-32px',
  height: 70,
  marginTop: '-5px',
  borderTopLeftRadius: '10px',
  borderTopRightRadius: '10px',
  background: '#A90063',
};

const styleForm = {
  marginTop: '20px',
  marginBottom: '30px',
  width: 390,
  marginLeft: '3.5px',
};

const styleButton = {
  mt: 1,
  background: '#A90063',
  border: '2px solid #A90063',
  borderRadius: '8px',
  color: '#FFFFFF',
  fontFamily: 'Poppins',
  '&:hover': {
    backgroundColor: '#FFFFFF',
    border: '2px solid #890050',
    color: '#890050',
    boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.2)',
  },
};

const styleBoxFooter = {
  width: 464,
  marginLeft: -4,
  marginRight: -4,
  marginBottom: 0,
  height: 'auto',
  top: 400,
  background: '#A90063',
  borderEndStartRadius: '10px',
  borderEndEndRadius: '10px',
  paddingBottom: 2,
  paddingTop: 1,
};

const initialFormData = {
  password: '',
  repeatPassword: '',
  rpt: '',
};

interface ResetPasswordModalProps {
  isOpen: boolean;
  onCloseModal: () => void;
}

function ResetPasswordModal({ isOpen, onCloseModal }: ResetPasswordModalProps) {
  const { resetPassword } = useAuth();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<ResetPasswordDto>({
    resolver: zodResolver(resetPasswordSchema),
  });

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [rptValue, setRPTValue] = useState('');
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [message, setMessage] = useState<string | undefined>(undefined);
  const [isEmptyError, setIsEmptyError] = useState(false);
  const [open, setOpen] = useState(isOpen);
  const [loading, setLoading] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);
  const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleCloseModal = () => {
    reset(initialFormData);
    setError(false);
    onCloseModal();
  };

  const handleBackdropClick = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
  };

  const handleCloseAlert = () => {
    setError(false);
  };

  const submit = async (data: ResetPasswordDto) => {
    setIsEmptyError(false);
    try {
      const { status, message } = await resetPassword(data);

      if (status) {
        reset(initialFormData);
        setMessage(message);
        setTimeout(() => {
          onCloseModal();
          setMessage(undefined);
          navigate('/');
        }, 5000);
      } else {
        setErrorMessage(message || '');
        setError(true);
        setLoading(false);
      }
    } catch (error: any) {
      setErrorMessage(error.message || '');
      setError(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    setOpen(isOpen);

    const queryParams = new URLSearchParams(location.search);
    const showTokenParam = queryParams.get('rpt');

    if (showTokenParam) {
      setRPTValue(showTokenParam);
    }
  }, [isOpen]);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        slotProps={{ backdrop: { onClick: handleBackdropClick } }}
      >
        <Box sx={style}>
          <Box sx={styleHeader}>
            <Box
              sx={{
                position: 'absolute',
                top: '20px',
                left: '140px',
              }}
            >
              <CardMedia
                component="img"
                alt="Imagen"
                height="30"
                image={t1}
                sx={{
                  boxShadow: 'none',
                  border: 'none',
                }}
              />
            </Box>
          </Box>
          <Box sx={styleForm}>
            {message ? (
              <>
                <PlaylistAddCheckCircle
                  sx={{
                    color: (theme) => theme.palette.secondary.main,
                    fontSize: '80px',
                    fontWeight: 'bold',
                    textAlign: 'center',
                    fontFamily: 'Poppins',
                    width: '100%',
                  }}
                />
                <Typography
                  variant="h5"
                  component="div"
                  sx={{
                    color: (theme) => theme.palette.secondary.main,
                    fontSize: '30px',
                    fontWeight: 'bold',
                    textAlign: 'center',
                    fontFamily: 'Poppins',
                  }}
                >
                  {message}
                </Typography>
              </>
            ) : (
              <>
                <Form onSubmit={handleSubmit(submit)} method="post" action="">
                  <AccountCircleRounded
                    sx={{
                      color: (theme) => theme.palette.secondary.main,
                      fontSize: '80px',
                      fontWeight: 'bold',
                      textAlign: 'center',
                      fontFamily: 'Poppins',
                      width: '100%',
                    }}
                  />
                  <Typography
                    variant="h5"
                    component="div"
                    sx={{
                      color: (theme) => theme.palette.secondary.main,
                      fontSize: '30px',
                      fontWeight: 'bold',
                      textAlign: 'center',
                      fontFamily: 'Poppins',
                    }}
                  >
                    Cambio de contraseña
                  </Typography>
                  <TextField
                    {...register('password')}
                    fullWidth
                    id="outlined-password-input"
                    label="Nueva Contraseña"
                    type={showPassword ? 'text' : 'password'}
                    autoComplete="password"
                    margin="normal"
                    size="small"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    error={!!errors.password}
                    helperText={errors.password?.message}
                  />
                  <br />
                  <TextField
                    {...register('confirmPassword')}
                    fullWidth
                    id="outlined-password-input"
                    label="Confirmar Contraseña"
                    type={showConfirmPassword ? 'text' : 'password'}
                    autoComplete="confirm-password"
                    margin="normal"
                    size="small"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowConfirmPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showConfirmPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    error={!!errors.confirmPassword}
                    helperText={errors.password?.message}
                  />
                  <TextField
                    {...register('rpt')}
                    fullWidth
                    id="outlined-password-input"
                    label="Token"
                    type="text"
                    autoComplete="token"
                    margin="normal"
                    size="small"
                    value={rptValue}
                    sx={{
                      display: 'none',
                    }}
                  />
                  <LoadingButton
                    loading={isSubmitting}
                    variant="contained"
                    fullWidth
                    sx={styleButton}
                    type="submit"
                  >
                    Registrar Nueva Contraseña
                  </LoadingButton>
                </Form>
                <Stack sx={{ width: '100%', marginTop: '15px' }} spacing={2}>
                  {isEmptyError && (
                    <Alert severity="error">
                      Por favor, completa todos los campos.
                    </Alert>
                  )}
                  {error && (
                    <Alert severity="error" onClose={handleCloseAlert}>
                      {errorMessage}
                    </Alert>
                  )}
                </Stack>
              </>
            )}
          </Box>
          <Box sx={styleBoxFooter} />
        </Box>
      </Modal>
    </div>
  );
}
export default ResetPasswordModal;
