import { useState } from 'react';
import EventImagesList from '../EventImagesList';
import { Box } from '@mui/material';
import WithStepContainer, { StepContainerProps } from './StepContainer';
import { FieldErrors, UseFormSetValue } from 'react-hook-form';
import { CreateEventImagesDto } from 'libs/domain/src/lib/dto';
import FullScreenDialog from '../FullScreenDialog';
import EventCardPreview from '../EventCardPreview';
import EventBannerPreview from '../EventBannerPreview';
import EventTicketPreview from '../EventTicketPreview';

interface EventImagesStepProps extends StepContainerProps {
  errors: FieldErrors<CreateEventImagesDto>;
  setValue: UseFormSetValue<CreateEventImagesDto>;
  eventName: string;
  venueName: string;
}

const EventImagesStep = ({
  setValue,
  errors,
  eventName,
  venueName,
}: EventImagesStepProps) => {
  const [openUploadImageModal, setOpenUploadImageModal] = useState(false);
  const [openUploadBannerImageModal, setOpenUploadBannerImageModal] =
    useState(false);
  const [openUploadTicketImageModal, setOpenUploadTicketImageModal] =
    useState(false);
  const [bannerImageStr, setBannerImageStr] = useState<string | null>('');
  const [ticketImageStr, setTicketImageStr] = useState<string | null>('');
  const [cardImageStr, setCardImageStr] = useState<string | null>('');
  const [cardImage, setCardImage] = useState<File | null>(null);

  const closeUploadImageModal = () => {
    setOpenUploadImageModal(false);
  };

  const closeUploadBannerImageModal = () => {
    setOpenUploadBannerImageModal(false);
  };

  const closeUploadTicketImageModal = () => {
    setOpenUploadTicketImageModal(false);
  };

  const handleBannerImageSave = (file: File) => {
    closeUploadBannerImageModal();
    setValue('bannerImage', file);
    setBannerImageStr(URL.createObjectURL(file));
  };

  const handleBannerImageRemove = () => {
    setBannerImageStr('');
    closeUploadBannerImageModal();
  };

  const handleCardImageSave = (file: File) => {
    closeUploadImageModal();
    setCardImage(file);
    setValue('cardImage', file);
    setCardImageStr(URL.createObjectURL(file));
  };

  const handleCardImageRemove = () => {
    setCardImage(null);
    setCardImageStr('');
  };

  const handleTicketImageSave = (file: File) => {
    closeUploadTicketImageModal();
    setValue('ticketImage', file);
    setTicketImageStr(URL.createObjectURL(file));
  };

  const handleTicketImageRemove = () => {
    setTicketImageStr('');
    closeUploadTicketImageModal();
  };

  return (
    <Box>
      <EventImagesList
        bannerImage={{
          image: bannerImageStr,
          title: 'Imagen del banner',
          subtitle: 'Se mostrará en la parte superior de la página del evento.',
          onChangeImage: () => setOpenUploadBannerImageModal(true),
          onRemoveImage: handleBannerImageRemove,
          error: errors.bannerImage?.message as string,
        }}
        cardImage={{
          image: cardImageStr,
          title: 'Imagen de la tarjeta',
          subtitle: 'Se mostrará esta imagen en la página principal.',
          onChangeImage: () => setOpenUploadImageModal(true),
          onRemoveImage: handleCardImageRemove,
          error: errors.cardImage?.message as string,
        }}
        ticketImage={{
          image: ticketImageStr,
          title: 'Imagen del boleto',
          subtitle: 'Esta imagen se mostrará en los boletos del evento.',
          onChangeImage: () => setOpenUploadTicketImageModal(true),
          onRemoveImage: handleTicketImageRemove,
          error: errors.ticketImage?.message as string,
        }}
      />
      <FullScreenDialog
        title="Agregar imagen de banner para el evento"
        open={openUploadBannerImageModal}
        handleClose={closeUploadBannerImageModal}
      >
        <EventBannerPreview
          currentImage={bannerImageStr}
          onSave={handleBannerImageSave}
          onCancel={handleBannerImageRemove}
        />
      </FullScreenDialog>
      <FullScreenDialog
        title="Vista previa de la tarjeta del evento"
        open={openUploadImageModal}
        handleClose={closeUploadImageModal}
      >
        <EventCardPreview
          name={eventName}
          venueName={venueName}
          image={cardImage}
          currentImage={cardImageStr}
          onSave={handleCardImageSave}
          onCancel={handleCardImageRemove}
        />
      </FullScreenDialog>
      <FullScreenDialog
        title="Agregar imagen de ticket para el evento (Centra el Codigo QR y los datos de boleto)"
        open={openUploadTicketImageModal}
        handleClose={closeUploadTicketImageModal}
      >
        <EventTicketPreview
          currentImage={ticketImageStr}
          onSave={handleTicketImageSave}
          onCancel={handleTicketImageRemove}
        />
      </FullScreenDialog>
    </Box>
  );
};

export default WithStepContainer(EventImagesStep);
