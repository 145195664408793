import { Box } from '@mui/material';

export const BoxMainContainer = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <Box
      sx={{
        width: '100%',
        minHeight: '90vh',
        mt: { xs: '80px', lg: '85px' },
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        padding: 0,
        mb: '30px',
      }}
    >
      {children}
    </Box>
  );
};
