import { Box } from '@mui/material';
import React from 'react';

interface SeatBoxProps {
  seat: string | number;
  index: number;
  color?: string;
}

export const SeatBox: React.FC<SeatBoxProps> = ({
  seat,
  index,
  color = '#3c87d7',
}) => {
  return (
    <Box
      key={index}
      sx={{
        minWidth: '35px',
        minHeight: '35px',
        m: 1,
        backgroundColor: color,
        color: 'white',
        fontSize: 14,
        fontWeight: 'bold',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {seat}
    </Box>
  );
};
