import { Box } from '@mui/material';
import { EventEntity } from 'libs/domain/src/lib/entities/Event';
import EventCard from './EventCard';

interface EventProps {
  events: EventEntity[];
}

export default function EventCards({ events }: EventProps) {
  return (
    <Box
      sx={{
        width: '100%',
        borderRadius: '10px',
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        flexDirection: {
          xs: 'column',
          md: 'row',
        },
        flexWrap: 'wrap',
        maxWidth: '1200px',
        margin: '0 auto',
        overflowX: 'hidden',
      }}
    >
      {events.length > 0 &&
        events.map((event, index) => (
          <Box
            key={index}
            sx={{
              flexBasis: {
                xs: 'calc(33.33% - 20px)',
                md: 'calc(33.33% - 20px)',
                xl: 'calc(33.33% - 20px)',
              },
              margin: '5px',
            }}
          >
            <EventCard event={event} buttonUrl={`/eventos/${event.id}`} />
          </Box>
        ))}
    </Box>
  );
}
