import { z } from 'zod';
import { TypeSection, TYPE_SECTION } from '../../entities/Section';

const MAX_FILE_SIZE = 50000000;
const ACCEPTED_IMAGE_TYPES = ['image/jpeg', 'image/jpg', 'image/png'];

export const updateSectionSchema = z.object({
  name: z.preprocess(
    (val) => (val === '' ? undefined : val),
    z.string({
      required_error: 'Por favor, ingrese un nombre para la sección.',
    }),
  ),
  color: z.preprocess(
    (val) => (val === '' ? undefined : val),
    z.string({
      required_error: 'Ingresa un color para la sección.',
    }),
  ),
  type: z
    .preprocess(
      (val) => (val === '' ? undefined : val),
      z.string({
        required_error: 'Por favor, ingrese un tipo de sección.',
      }),
    )
    .refine(
      (type) => TYPE_SECTION.includes(type as TypeSection),
      'El tipo de sección no es válido.',
    ),
  capacity: z
    .preprocess(
      (val) => (typeof val === 'string' && val !== '' ? Number(val) : val),
      z.number({
        required_error: 'Por favor, indique la capacidad para esta sección.',
      }),
    )
    .refine((val) => typeof val === 'number' && val > 0, {
      message: 'La capacidad debe ser un número mayor a cero.',
    }),
  price: z
    .preprocess(
      (val) => (typeof val === 'string' ? Number(val) : val),
      z.number({ required_error: 'Por favor, indique un precio válido.' }),
    )
    .refine((val) => val > 0, {
      message: 'El precio debe ser un número mayor que cero.',
    })
    .refine((val) => /^\d+(\.\d{1,2})?$/.test(val.toString()), {
      message: 'El precio solo puede tener hasta dos decimales.',
    }),
  layout:
    typeof window === 'undefined'
      ? z.any()
      : z
          .custom<File>(
            (file) => {
              if (file) {
                return file instanceof File;
              }
              return true;
            },
            {
              message:
                'Por favor, ingrese una imagen para la tarjeta del evento.',
            },
          )
          .refine(
            (file) => {
              if (file) {
                return ACCEPTED_IMAGE_TYPES.includes(file.type);
              }
              return true;
            },
            {
              message:
                'Formato de imagen no válido, por favor, ingrese una imagen válida.',
            },
          )
          .refine(
            (file) => {
              if (file) {
                return file.size <= MAX_FILE_SIZE;
              }
              return true;
            },
            {
              message:
                'Por favor, ingrese una imagen que pese menos de 500 kB.',
            },
          ),
});

export type UpdateSectionDto = z.infer<typeof updateSectionSchema>;
