import { axiosRequest } from '../api-client';

export const GetAllRows = () => {
  return axiosRequest({
    method: 'GET',
    url: '/rows/',
  });
};

export const GetRowById = (rowId: string) => {
  return axiosRequest({
    method: 'GET',
    url: `/rows/${rowId}`,
  });
};

export const GetSeatsByRowId = (rowId: string, eventDateId: string) => {
  return axiosRequest({
    method: 'GET',
    url: `/rows/${rowId}/seats?eventDateId=${eventDateId}`,
  });
};

export const DeleteRowById = (rowId: string) => {
  return axiosRequest({
    method: 'DELETE',
    url: `/rows/${rowId}`,
  });
};
