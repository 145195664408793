import React from 'react';
import { Typography } from '@mui/material';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import RemoveShoppingCartOutlinedIcon from '@mui/icons-material/RemoveShoppingCartOutlined';
import { NoTickets } from 'apps/client/src/app/components/NoTickets';

interface CartHeaderProps {
  cart: any;
}

const CartHeader: React.FC<CartHeaderProps> = ({ cart }) => {
  if (!cart) {
    return (
      <>
        <Typography
          variant="h1"
          sx={{
            alignSelf: 'start',
            width: '80%',
            ml: { xs: '5%', md: '10%' },
            textTransform: 'uppercase',
            fontFamily: 'Roboto',
            fontSize: '2.5rem',
            fontWeight: '900',
            color: '#4D012E',
            alignItems: 'bottom',
            mb: 2,
          }}
        >
          <RemoveShoppingCartOutlinedIcon
            sx={{
              fontSize: '2.2rem',
              color: '#4D012E',
            }}
          />
          &nbsp;Mi Carrito
        </Typography>
        <NoTickets label="Aún no cuentas con boletos. ¡Añádelos y prepárate para la acción!" />
      </>
    );
  }

  return (
    <>
      <Typography
        variant="h1"
        sx={{
          alignSelf: 'start',
          width: '80%',
          ml: { xs: '5%', md: '10%' },
          textTransform: 'uppercase',
          fontFamily: 'Roboto',
          fontSize: '2.5rem',
          fontWeight: '900',
          color: '#4D012E',
          alignItems: 'bottom',
          mb: 2,
        }}
      >
        <ShoppingCartOutlinedIcon
          sx={{
            fontSize: '2.2rem',
            color: '#4D012E',
          }}
        />
        &nbsp;Mi Carrito
      </Typography>
    </>
  );
};

export default CartHeader;
