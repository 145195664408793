import React from 'react';
import FullPageSpinner from '../../components/FullPageSpinner';

export const SuccessRedirect = () => {

  React.useEffect(() => {
    window.location.replace("/checkout/success");
    window.close();
  }, []);
 
  return (<FullPageSpinner />);
}