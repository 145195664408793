// Define the handleChange to use the GET req from the backend
import * as React from 'react';
import { styled, useTheme, alpha } from '@mui/material/styles';
import {
  Box,
  Table,
  TablePagination,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Typography,
  InputBase,
  Paper,
} from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import {
  Delete,
  Edit,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  LastPage,
  FirstPage,
} from '@mui/icons-material';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import TTButton from '../shared/TTButton';
import SearchIcon from '@mui/icons-material/Search';
import { UpdateUserByAdminDto } from 'libs/domain/src/lib/dto';

interface User {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  role: string;
  status: string;
}

type StatusTranslations = {
  [key: string]: string;
};

type RoleTranslations = {
  [key: string]: string;
};

const statusTranslations: StatusTranslations = {
  INVITED: 'Invitado',
  ACTIVE: 'Activo',
  DISABLED: 'Deshabilitado',
};

const RoleTranslations: RoleTranslations = {
  SCANNER: 'ESCANER',
  ADMIN: 'ADMINISTRADOR',
  SALES: 'VENTAS',
};

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number,
  ) => void;
}

const SearchIconWrapper = styled('div')<{ isTexting: boolean }>(
  ({ theme, isTexting }) => ({
    display: isTexting ? 'none' : 'flex',
    color: '#999',
    transition: theme.transitions.create('color', {
      duration: theme.transitions.duration.short,
    }),
  }),
);

function TablePaginationActions(props: TablePaginationActionsProps) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPage /> : <FirstPage />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPage /> : <LastPage />}
      </IconButton>
    </Box>
  );
}

interface ScanUsersTableProps {
  users: User[];
  onOpenRegisterModal: () => void;
  onOpenUpdateModal: (userData: UpdateUserByAdminDto, id: string) => void;
  onOpenDeleteModal: (userId: string) => void;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => void;
  onRowsPerPageChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
  currentPage: number;
  rowsPerPage: number;
  totalUsers: number;
  onSearch: (search: string) => void;
}

export const ScanUsersTable = ({
  onOpenRegisterModal,
  onOpenUpdateModal,
  onOpenDeleteModal,
  onPageChange,
  onRowsPerPageChange,
  users,
  currentPage,
  rowsPerPage,
  totalUsers,
  onSearch,
}: ScanUsersTableProps) => {
  const [searchUserQuery, setSearchUserQuery] = React.useState('');

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#444E6A',
      color: theme.palette.common.white,
      fontWeight: 600,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 15,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    backgroundColor: '#EBEBEB',
    border: 0,
  }));

  return (
    <TableContainer sx={{ padding: 3 }}>
      {/* Search input */}
      <Paper
        component="form"
        sx={{
          p: '4px 4px',
          display: 'flex',
          alignItems: 'center',
          width: 'auto',
          maxWidth: 350,
          borderRadius: '10px',
          boxShadow: 1,
          border: '2px solid black',
        }}
        elevation={3}
      >
        <InputBase
          sx={{
            ml: 1,
            flex: 1,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
          placeholder="Buscar escaneadores"
          value={searchUserQuery}
          onChange={(user) => {
            setSearchUserQuery(user.target.value);
            onSearch(user.target.value);
          }}
          inputProps={{ 'aria-label': 'search' }}
        />
        <SearchIconWrapper isTexting={!!searchUserQuery}>
          <SearchIcon />
        </SearchIconWrapper>
      </Paper>
      {/* Search input */}
      <TTButton
        color="secondary"
        variant="contained"
        sx={{
          float: 'right',
          marginTop: '10px',
          lineHeight: 2,
          minWidth: '200px',
        }}
        aria-label="Add user"
        onClick={onOpenRegisterModal}
        endIcon={<PersonAddIcon />}
      >
        {'Registrar Usuario'}
      </TTButton>
      <Typography
        variant="h5"
        component="div"
        sx={{
          flexGrow: 1,
          fontWeight: 900,
          color: '#444E6A',
          fontSize: '1.75rem',
          m: '15px 0px',
        }}
      >
        {'Lista de Escaneadores'}
      </Typography>
      <Table
        aria-label="customized table"
        component={Paper}
        sx={{
          minWidth: 700,
          borderRadius: '10px',
          overflow: 'hidden',
        }}
      >
        <TableHead>
          <TableRow>
            <StyledTableCell align="left">Nombres&nbsp;</StyledTableCell>
            <StyledTableCell align="left">Apellidos&nbsp;</StyledTableCell>
            <StyledTableCell align="left">Correo&nbsp;</StyledTableCell>
            <StyledTableCell align="left">Rol&nbsp;</StyledTableCell>
            <StyledTableCell align="left">Estatus&nbsp;</StyledTableCell>
            <StyledTableCell align="left">Opciones&nbsp;</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users.length > 0 ? (
            users.map((user) => (
              <StyledTableRow key={user.id}>
                <StyledTableCell align="left">{user.firstName}</StyledTableCell>
                <StyledTableCell align="left">{user.lastName}</StyledTableCell>
                <StyledTableCell align="left">{user.email}</StyledTableCell>
                <StyledTableCell align="left">
                  {RoleTranslations[user.role] || user.role}
                </StyledTableCell>
                <StyledTableCell align="left">
                  {statusTranslations[user.status] || user.status}
                </StyledTableCell>
                <StyledTableCell component="th" scope="row" align="left">
                  <IconButton
                    aria-label="Editar usuario"
                    onClick={() => onOpenUpdateModal(user, user.id)}
                  >
                    <Edit />
                  </IconButton>
                  <IconButton
                    aria-label="Eliminar usuario"
                    onClick={() => onOpenDeleteModal(user.id)}
                  >
                    <Delete />
                  </IconButton>
                </StyledTableCell>
              </StyledTableRow>
            ))
          ) : (
            <StyledTableRow style={{ height: 53 * rowsPerPage }}>
              <StyledTableCell colSpan={6} align="center">
                {
                  'Aún no hay usuarios registrados, puedes registrar uno nuevo dando click en el botón "Registrar usuario"'
                }
              </StyledTableCell>
            </StyledTableRow>
          )}
        </TableBody>
      </Table>
      {users.length > 0 && (
        <TablePagination
          sx={{ float: 'right' }}
          labelRowsPerPage="Filas por página"
          rowsPerPageOptions={[1, 10, 25, { label: 'Todo', value: 100000000 }]}
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to} de ${count !== -1 ? count : `más de ${to}`}`
          }
          count={totalUsers}
          colSpan={3}
          rowsPerPage={rowsPerPage}
          page={currentPage}
          SelectProps={{
            inputProps: {
              'aria-label': 'Filas por página',
            },
            native: true,
          }}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
          ActionsComponent={TablePaginationActions}
        />
      )}
    </TableContainer>
  );
};
