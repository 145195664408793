import * as React from 'react';
import {
  Button,
  TextField,
  Dialog,
  MenuItem,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Box,
  Divider,
  AppBar,
  Toolbar,
  DialogProps,
} from '@mui/material';
import { UpdateSectionDto, updateSectionSchema } from 'libs/domain/src/lib/dto';
import { useForm, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { ColorInput } from './ColorInput';
import { SectionEntity } from 'libs/domain/src/lib/entities/Section';
import UploadSectionImage from './UploadSectionLayout';
import FullScreenDialog from '../FullScreenDialog';
import SectionLayoutPreview from './SectionLayoutPreview';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

interface SectionsModalProps {
  section: SectionEntity | null;
  isOpen: boolean;
  onUpdateSection: (data: FormData) => void;
  onClose: () => void;
}

export default function AddNewSection({
  isOpen,
  onUpdateSection,
  onClose,
  section,
}: SectionsModalProps) {
  const [open, setOpen] = React.useState(isOpen);
  const [sectionImage, setSectionImage] = React.useState<File | null>(null);
  const [sectionImageStr, setSectionImageStr] = React.useState<string | null>(
    '',
  );
  const [openUploadImageModal, setOpenUploadImageModal] = React.useState(false);
  const [scroll, setScroll] = React.useState<DialogProps['scroll']>('body');

  const closeUploadImageModal = () => {
    setOpenUploadImageModal(false);
  };

  const handleSectionImageRemove = () => {
    setSectionImageStr('');
    closeUploadImageModal();
  };

  const handleClose = () => {
    reset();
    setOpen(false);
    onClose();
  };

  const {
    setValue,
    register,
    reset,
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<UpdateSectionDto>({
    resolver: zodResolver(updateSectionSchema),
    defaultValues: {
      name: section?.name,
      color: section?.color,
      type: section?.type,
      capacity: section?.capacity,
      price: section?.price,
      layout: section?.layout,
    },
  });

  const handleSectionImageSave = (file: File) => {
    closeUploadImageModal();
    setSectionImage(file);
    setValue('layout', file);
    setSectionImageStr(URL.createObjectURL(file));
  };

  const onSubmit = async (data: UpdateSectionDto) => {
    if (!section) {
      return;
    }
    const formData = new FormData();
    formData.append('name', data.name);
    formData.append('color', data.color);
    formData.append('type', data.type);
    formData.append('capacity', data.capacity.toString());
    formData.append('price', data.price.toString());
    if (data.layout) {
      formData.append('layout', data.layout);
    }
    await onUpdateSection(formData);
    handleClose();
  };

  React.useEffect(() => {
    reset({
      name: section?.name,
      color: section?.color,
      type: section?.type,
      capacity: section?.capacity,
      price: section?.price,
    });

    if (section?.layout) {
      setSectionImageStr(section.layout);
    }
  }, [section]);

  React.useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  return (
    <React.Fragment>
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <Dialog
          open={open}
          onClose={handleClose}
          scroll={scroll}
          fullWidth
          PaperProps={{
            sx: {
              borderRadius: '10px',
              overflow: 'hidden',
            },
          }}
        >
          <AppBar
            sx={{
              position: 'relative',
              backgroundColor: '#890050',
            }}
          >
            <Toolbar sx={{ justifyContent: 'flex-end' }}>
              <IconButton edge="end" onClick={handleClose} aria-label="close">
                <HighlightOffIcon sx={{ color: '#FFF' }} />
              </IconButton>
            </Toolbar>
          </AppBar>
          <DialogTitle
            sx={{
              color: '#444E6A',
              fontWeight: 700,
              fontSize: '2rem',
              fontStyle: 'bold',
            }}
          >
            <b>Editar sección</b>
          </DialogTitle>{' '}
          <DialogContent>
            <DialogContentText
              sx={{
                mt: 2,
                mb: 1,
                fontWeight: 600,
                color: '#444E6A',
                fontSize: '1rem',
              }}
            >
              <b>Datos de la sección</b>
              <Divider />
            </DialogContentText>
            <TextField
              {...register('name')}
              autoFocus
              id="outlined-required"
              label="Nombre"
              fullWidth
              margin="normal"
              defaultValue={section?.name}
              size="small"
              error={!!errors.name}
              helperText={errors.name?.message}
              className="customTextField"
            />

            <Controller
              name="color"
              control={control}
              defaultValue={section?.color || ''}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <ColorInput
                  value={value}
                  onChange={onChange}
                  error={error?.message}
                />
              )}
            />
            <Box sx={{ marginTop: '15px' }}>
              <TextField
                {...register('type')}
                fullWidth
                size="small"
                id="outlined-select-currency"
                select
                label="Tipo"
                defaultValue={section?.type || ''}
                error={!!errors.type}
                helperText={errors.type?.message}
                className="customTextField"
                SelectProps={{
                  MenuProps: {
                    classes: {
                      paper: 'customTextPaper',
                    },
                  },
                }}
              >
                {[
                  { value: 'ROW', label: 'Fila' },
                  { value: 'TABLE', label: 'Mesa' },
                  { value: 'GENERAL', label: 'General' },
                ].map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                {...register('capacity')}
                id="outlined-required"
                label="Capacidad"
                fullWidth
                type="number"
                margin="dense"
                defaultValue={section?.capacity || 0}
                size="small"
                error={!!errors.capacity}
                helperText={errors.capacity?.message}
                sx={{ margin: '17px 0 15px 0' }}
                className="customTextField"
              />
              <TextField
                {...register('price')}
                id="outlined-required"
                label="Precio"
                fullWidth
                type="number"
                margin="dense"
                defaultValue={section?.price || 0}
                size="small"
                error={!!errors.price}
                helperText={errors.price?.message}
                sx={{ margin: '17px 0 15px 0' }}
                className="customTextField"
              />
            </Box>
            <DialogContentText sx={{ mt: 2, mb: 2 }}>
              <b>Layout de la sección</b>
              <Divider />
            </DialogContentText>
            <UploadSectionImage
              sectionImage={{
                image: sectionImageStr,
                title: 'Layout de la sección',
                subtitle: 'Esta imagen se mostrará en la vista de la sección.',
                onChangeImage: () => setOpenUploadImageModal(true),
                onRemoveImage: handleSectionImageRemove,
              }}
            />
          </DialogContent>
          <DialogActions
            sx={{
              display: 'flex',
              justifyContent: 'space-evenly',
              alignItems: 'center',
              mb: 7.5,
            }}
          >
            {' '}
            <Button
              sx={{
                width: '45%',
                maxHeight: 50,
                borderRadius: 2,
                fontSize: {
                  xs: '10px',
                  sm: '10px',
                  md: '12px',
                  lg: '14px',
                  xl: '16px',
                },
                fontWeight: 'bold',
                textTransform: 'uppercase',
                color: '#890050',
                mt: 2,
                border: '2px solid #890050',
                backgroundColor: '#FFFFFF',
                '&:hover': {
                  backgroundColor: '#890050',
                  border: '2px solid #890050',
                  color: '#FFFFFF',
                },
              }}
              size="medium"
              onClick={handleClose}
            >
              Cancelar
            </Button>
            <Button
              disabled={isSubmitting}
              sx={{
                width: '45%',
                maxHeight: 50,
                borderRadius: 2,
                fontSize: {
                  xs: '10px',
                  sm: '10px',
                  md: '12px',
                  lg: '14px',
                  xl: '16px',
                },
                fontWeight: 'bold',
                textTransform: 'uppercase',
                color: '#FFF',
                mt: 2,
                border: '2px solid #890050',
                backgroundColor: '#890050',
                '&:hover': {
                  backgroundColor: '#FFF',
                  border: '2px solid #890050',
                  color: '#890050',
                },
              }}
              type="submit"
              size="medium"
              onClick={handleSubmit(onSubmit)}
            >
              Confirmar
            </Button>
          </DialogActions>
        </Dialog>
        <FullScreenDialog
          title="Layout de la sección"
          open={openUploadImageModal}
          handleClose={closeUploadImageModal}
        >
          <SectionLayoutPreview
            image={sectionImage}
            section={section}
            onCancel={handleSectionImageRemove}
            currentImage={sectionImageStr}
            onSave={handleSectionImageSave}
          />
        </FullScreenDialog>
      </Box>
    </React.Fragment>
  );
}
