import React from 'react';
import { Avatar, Box, Paper, Typography } from '@mui/material';

interface Event {
  cardImage: string;
  name: string;
  eventDate?: { date: string };
  venueName: string;
}

interface EventInfoCardProps {
  event: Event;
}

const EventInfoCard: React.FC<EventInfoCardProps> = ({ event }) => {
  return (
    <Paper
      elevation={2}
      sx={{
        p: 2,
        borderRadius: '10px',
        display: 'flex',
        flexDirection: { xs: 'column', sm: 'row' },
        alignItems: { xs: 'flex-start', sm: 'center' },
        gap: 2,
      }}
    >
      <Avatar
        src={event.cardImage}
        alt="Imagen del evento"
        variant="rounded"
        sx={{
          width: { xs: '100%', sm: 140 },
          height: { xs: 140, sm: 140 },
          borderRadius: '10px',
        }}
      />
      <Box>
        <Typography
          sx={{
            fontFamily: 'Roboto',
            fontSize: { xs: '18px', sm: '24px' },
            fontWeight: 'bold',
            color: 'primary.main',
            textTransform: 'uppercase',
            mb: 1,
          }}
        >
          {event.name}
        </Typography>
        <Typography
          sx={{
            fontFamily: 'Roboto',
            fontSize: { xs: '14px', sm: '18px' },
            color: 'primary.main',
            mb: 0.5,
          }}
        >
          {event.eventDate
            ? new Date(event.eventDate.date).toLocaleDateString()
            : ''}
        </Typography>
        <Typography
          sx={{
            fontFamily: 'Roboto',
            fontSize: { xs: '14px', sm: '18px' },
            color: '#303030',
          }}
        >
          {event.venueName}
        </Typography>
      </Box>
    </Paper>
  );
};

export default EventInfoCard;
