import { z } from 'zod';

export const createCartWithItemsSchema = z
  .object({
    eventDateId: z.preprocess(
      (val) => {
        if (val === '') {
          return undefined;
        }

        return val;
      },
      z.string({
        required_error: 'Por favor, selecciona una fecha.',
      }),
    ),
    sectionType: z.string({
      required_error: 'Por favor, selecciona una sección.',
    }),
    sectionId: z.preprocess(
      (val) => (val === '' ? undefined : val),
      z.string({
        required_error: 'Por favor, selecciona una sección.',
      }),
    ),
    rowId: z.preprocess(
      (val) => (val === '' ? undefined : val),
      z.string().optional(),
    ),
    seatIds: z.preprocess((val) => {
      if (val === '') {
        return undefined;
      }

      return val;
    }, z.array(z.string()).optional()),
    quantity: z
      .preprocess((val) => (val === '' ? undefined : Number(val)), z.number())
      .optional()
      .refine(
        (val) => {
          if (val === undefined) {
            return true;
          }
          return val > 0;
        },
        {
          message: 'La cantidad debe ser mayor a 0.',
        },
      ),
  })
  .refine(
    (val) => {
      if (val.sectionType === 'ROW' && !val.rowId) {
        return false;
      }

      return true;
    },
    {
      message: 'Por favor, selecciona una fila.',
      path: ['rowId'],
    },
  )
  .refine(
    (val) => {
      if (
        val.sectionType === 'ROW' &&
        val.rowId &&
        val.seatIds &&
        val.seatIds.length <= 0
      ) {
        return false;
      }

      return true;
    },
    {
      message: 'Por favor, selecciona al menos un asiento.',
      path: ['seatIds'],
    },
  );

export type CreateCartWithItemsDto = z.infer<typeof createCartWithItemsSchema>;
