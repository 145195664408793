import React, { useEffect } from 'react';
import {
  CardMedia,
  Box,
  Button,
  TextField,
  Modal,
  FormGroup,
  FormControlLabel,
  Checkbox,
  IconButton,
  InputAdornment,
  Alert,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {
  AccountCircleRounded,
  FacebookRounded,
  Visibility,
  VisibilityOff,
  Instagram,
} from '@mui/icons-material';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { RegisterDto, registerSchema } from 'libs/domain/src/lib/dto';
import { useAuth } from '../../providers/AuthProvider';
import t1 from '../images/t1.png';
import Link from '@mui/material/Link';
import { grey } from '@mui/material/colors';

const initialFormValues: RegisterDto = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  confirmPassword: '',
};

interface SignUpModalProps {
  isOpen: boolean;
  onCloseModal: () => void;
  onOpenLoginModal: () => void;
}

export const SignUpModal = ({
  isOpen,
  onCloseModal,
  onOpenLoginModal,
}: SignUpModalProps) => {
  const { registerNewUser } = useAuth();
  const [showPassword, setShowPassword] = React.useState(false);
  const [error, setError] = React.useState<string | null>(null);
  const [open, setOpen] = React.useState(isOpen);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [termsAccepted, setTermsAccepted] = React.useState(false);
  const [privacyAccepted, setPrivacyAccepted] = React.useState(false);

  const handleTermsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    setTermsAccepted(checked);
    if (!checked) {
      setPrivacyAccepted(false);
    }
  };

  const handlePrivacyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPrivacyAccepted(event.target.checked);
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<RegisterDto>({
    resolver: zodResolver(registerSchema),
  });

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };

  const handleOpenLoginModal = () => {
    reset(initialFormValues);
    onCloseModal();
    setError(null);
    onOpenLoginModal();
  };

  const handleCloseModal = () => {
    reset(initialFormValues);
    setTermsAccepted(false);
    setPrivacyAccepted(false);
    onCloseModal();
    setError(null);
  };

  const onSubmit = async (data: RegisterDto) => {
    try {
      const { status, message } = await registerNewUser(data);
      if (status) {
        onCloseModal();
      }
      if (!status) {
        setError(message || 'Ha ocurrido un error');
      }
    } catch (error: any) {
      setError(error.message || '');
    }
  };

  useEffect(() => {
    if (!isOpen) {
      reset(initialFormValues);
    }
    setOpen(isOpen);
  }, [isOpen]);

  return (
    <React.Fragment>
      <Modal
        open={open}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: isMobile ? '85%' : 465,
            maxWidth: 465,
            bgcolor: 'background.paper',
            borderRadius: '10px',
            boxShadow: 24,
            display: 'flex',
            flexDirection: 'column',
            fontFamily: 'Helvetica Neue, Helvetica, Arial, sans-serif',
            maxHeight: '90vh',
            overflow: 'hidden',
          }}
        >
          <Box
            sx={{
              width: '100%',
              height: 70,
              borderTopLeftRadius: '10px',
              borderTopRightRadius: '10px',
              background: '#A90063',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'relative',
            }}
          >
            <Box
              sx={{
                position: 'absolute',
                top: '20px',
                alignSelf: 'center',
              }}
            >
              <CardMedia
                component="img"
                alt="Imagen"
                height="30"
                image={t1}
                sx={{
                  boxShadow: 'none',
                  border: 'none',
                }}
              />
            </Box>
          </Box>
          <Box
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1,
              pr: 2,
              overflow: 'auto',
            }}
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              <AccountCircleRounded
                sx={{
                  color: (theme) => theme.palette.secondary.main,
                  fontSize: '80px',
                  fontWeight: 'bold',
                  textAlign: 'center',
                  fontFamily: 'Poppins',
                  width: '100%',
                }}
              />
              <Typography
                variant="h5"
                component="div"
                sx={{
                  color: (theme) => theme.palette.secondary.main,
                  fontSize: '30px',
                  fontWeight: 'bold',
                  textAlign: 'center',
                  fontFamily: 'Poppins',
                  wordBreak: 'break-word',
                }}
              >
                Regístrate
              </Typography>
              <TextField
                {...register('firstName')}
                defaultValue={''}
                fullWidth
                id="first-name"
                label="Nombre(s)"
                variant="outlined"
                error={!!errors.firstName}
                helperText={errors.firstName?.message}
                size="small"
                margin="normal"
                sx={{ marginBottom: '-2px' }}
              />
              <TextField
                {...register('lastName')}
                defaultValue={''}
                error={!!errors.lastName}
                helperText={errors.lastName?.message}
                fullWidth
                id="last-name"
                label="Apellidos"
                variant="outlined"
                name="lastName"
                margin="normal"
                size="small"
                sx={{ marginBottom: '-2px' }}
              />
              <TextField
                {...register('email')}
                defaultValue={''}
                error={!!errors.email}
                helperText={errors.email?.message}
                fullWidth
                id="email"
                label="Correo"
                variant="outlined"
                name="email"
                margin="normal"
                size="small"
                sx={{ marginBottom: '-2px' }}
              />
              <TextField
                {...register('password')}
                fullWidth
                id="password-input"
                label="Contraseña"
                type={showPassword ? 'text' : 'password'}
                autoComplete="current-password"
                margin="normal"
                size="small"
                sx={{ marginBottom: '-2px' }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                error={!!errors.password}
                helperText={errors.password?.message}
              />
              <TextField
                {...register('confirmPassword')}
                fullWidth
                id="confirm-password-input"
                label="Confirmar Contraseña"
                type={showPassword ? 'text' : 'password'}
                autoComplete="current-password"
                margin="normal"
                size="small"
                sx={{ marginBottom: '-2px' }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                error={!!errors.confirmPassword}
                helperText={errors.confirmPassword?.message}
              />
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={termsAccepted}
                      onChange={handleTermsChange}
                    />
                  }
                  label="Términos y condiciones"
                  sx={{ fontSize: '15px', color: '#827d7d' }}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={privacyAccepted}
                      onChange={handlePrivacyChange}
                      disabled={!termsAccepted}
                    />
                  }
                  label="Aviso de privacidad"
                  sx={{
                    marginTop: '-10px',
                    fontSize: '15px',
                    color: '#827d7d',
                  }}
                />
              </FormGroup>
              <Button
                disabled={isSubmitting || !termsAccepted || !privacyAccepted}
                variant="contained"
                type="submit"
                fullWidth
                sx={{
                  background: '#A90063',
                  border: '2px solid #A90063',
                  borderRadius: '8px',
                  color: '#FFFFFF',
                  fontFamily: 'Poppins',
                  textTransform: 'initial',
                  fontWeight: 600,
                  minWidth: '50px',
                  minHeight: '35px',
                  fontSize: '14px',
                  padding: '4px',
                  marginBottom: '15px',
                  '&:hover': {
                    backgroundColor: '#FFFFFF',
                    border: '2px solid #890050',
                    color: '#890050',
                    boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.2)',
                  },
                }}
              >
                Registrar
              </Button>
              <Stack sx={{ width: '100%', marginBottom: '15px' }} spacing={2}>
                {error && <Alert severity="error">{error}</Alert>}
              </Stack>
            </form>
          </Box>
          <Box sx={{ mt: 0 }} display="none">
            <Typography
              sx={{
                mt: 2,
                width: '100%',
                textAlign: 'center',
                color: (theme) => theme.palette.primary.main,
              }}
            >
              O INGRESA CON TUS REDES SOCIALES
            </Typography>
            <Stack direction="row" sx={{ ml: 16 }}>
              <IconButton size="large">
                <FacebookRounded fontSize="large" sx={{ color: grey[500] }} />
              </IconButton>
              <IconButton size="large">
                <Instagram fontSize="large" sx={{ color: grey[500] }} />
              </IconButton>
            </Stack>
          </Box>
          <Box
            sx={{
              width: '100%',
              height: 'auto',
              top: 400,
              background: '#A90063',
              borderEndStartRadius: '10px',
              borderEndEndRadius: '10px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              pt: 1,
              pb: 2,
            }}
          >
            <Typography
              sx={{
                mt: 1,
                color: (theme) => theme.palette.common.white,
                fontSize: '15px',
                fontFamily: 'Poppins',
                textAlign: 'center',
                wordBreak: 'break-word',
              }}
            >
              ¿Ya tienes cuenta?
            </Typography>
            <Link
              component="button"
              variant="body2"
              onClick={handleOpenLoginModal}
              sx={{
                mb: 1,
                width: '50%',
                textAlign: 'center',
                fontFamily: 'Poppins',
                fontSize: '15px',
                color: (theme) => theme.palette.common.white,
                fontWeight: 'bold',
                textTransform: 'initial',
              }}
            >
              Iniciar sesión
            </Link>
          </Box>
        </Box>
      </Modal>
    </React.Fragment>
  );
};
