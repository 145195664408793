import * as React from 'react';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CachedIcon from '@mui/icons-material/Cached';
import ClearIcon from '@mui/icons-material/Clear';
import { Button } from '@mui/material';

interface EventImagesMenuProps {
  onEdit: () => void;
  onDelete: () => void;
}

export default function EventImagesMenu({
  onEdit,
  onDelete,
}: EventImagesMenuProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    onEdit();
    handleClose();
  };

  const handleDelete = () => {
    onDelete();
    handleClose();
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          textAlign: 'center',
        }}
      >
        <Tooltip title="Opciones de imagen" arrow>
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ color: '#444E6A' }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <MoreVertIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="basic-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          className: 'customTextPaper',
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={handleEdit}>
          <Button
            startIcon={
              <CachedIcon
                fontSize="small"
                sx={{
                  color: '#FFF',
                }}
              />
            }
            sx={{
              justifyContent: 'flex-start',
              width: '100%',
              color: '#FFFFFF',
              textTransform: 'none',
              fontSize: '1rem',
              fontFamily: 'Poppins, sans-serif',
            }}
          >
            Cambiar imagen
          </Button>
        </MenuItem>
        <MenuItem onClick={handleDelete}>
          <Button
            startIcon={
              <ClearIcon
                fontSize="small"
                sx={{
                  color: '#FFF',
                }}
              />
            }
            sx={{
              justifyContent: 'flex-start',
              width: '100%',
              color: '#FFFFFF',
              textTransform: 'none',
              fontSize: '1rem',
              fontFamily: 'Poppins, sans-serif',
            }}
          >
            Eliminar
          </Button>
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}
