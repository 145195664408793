import React from 'react';
import { Box } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { getEventById } from '../../requests/events';
import EventSeatsSelector from '../components/EventSeatsSelector';
import { EventEntity } from 'libs/domain/src/lib/entities/Event';
import { useScrollTop } from '../hooks/useScrollTop';
import { EventCardClientPreview } from '../components/EventCardClientPreview';

export const PreviewClients = () => {
  const navigate = useNavigate();

  const { id } = useParams<{ id: string }>();
  const [event, setEvent] = useState<EventEntity | null>(null);
  const [loading, setLoading] = useState(true);

  useScrollTop();

  const fetchEvent = async () => {
    if (!id) {
      return;
    }
    const { response, status } = await getEventById(id);

    if (status === 'OK') {
      const eventData = response.data.event;

      if (eventData.status === 'PUBLISHED') {
        setEvent(eventData);
      } else {
        navigate('/404');
      }
    } else {
      navigate('/404');
    }
  };

  useEffect(() => {
    (async () => {
      await fetchEvent();

      setLoading(false);
    })();
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        minHeight: '125vh',
        mt: 5,
        alignItems: 'center',
        overflowX: 'hidden',
        margin: 0,
      }}
    >
      {event && (
        <React.Fragment>
          <EventCardClientPreview
            event={event}
            loading={loading}
            onPublishEvent={function (eventId: string): void {
              throw new Error('Function not implemented.');
            }}
          />
          <EventSeatsSelector event={event} />
        </React.Fragment>
      )}
    </Box>
  );
};
