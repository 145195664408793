import React, { MouseEvent, useEffect } from 'react';
import {
  Modal,
  Box,
  Typography,
  CardMedia,
  TextField,
  InputAdornment,
  IconButton,
  Link,
  Alert,
  Stack,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import LoadingButton from '@mui/lab/LoadingButton';
import { useState } from 'react';
import { Form, useNavigate } from 'react-router-dom';
import { useAuth } from '../../providers/AuthProvider';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { LoginDto, loginSchema } from 'libs/domain/src/lib/dto';
import { grey } from '@mui/material/colors';
import t1 from '../images/t1.png';
import {
  AccountCircleRounded,
  FacebookRounded,
  Instagram,
} from '@mui/icons-material';

const initialFormData = {
  email: '',
  password: '',
};

interface LoginModalProps {
  isOpen: boolean;
  onCloseModal: () => void;
  onOpenRegisterModal: () => void;
  onOpenForgotPasswordModal: () => void;
}

function LogInModal({
  isOpen,
  onCloseModal,
  onOpenRegisterModal,
  onOpenForgotPasswordModal,
}: LoginModalProps) {
  const { login } = useAuth();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<LoginDto>({
    resolver: zodResolver(loginSchema),
  });

  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isEmptyError, setIsEmptyError] = useState(false);
  const [open, setOpen] = useState(isOpen);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleCloseModal = () => {
    reset(initialFormData);
    setError(false);
    onCloseModal();
  };

  const handleOpenRegisterModal = () => {
    reset(initialFormData);
    onOpenRegisterModal();
    onCloseModal();
    setError(false);
  };

  const handleOpenForgotPasswordModal = () => {
    onCloseModal();
    onOpenForgotPasswordModal();
  };

  const handleBackdropClick = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
  };

  const handleCloseAlert = () => {
    setError(false);
  };

  const submit = async (data: LoginDto) => {
    setIsEmptyError(false);
    try {
      const user = await login(data.email, data.password);

      if (user && ['ADMIN'].includes(user.role)) {
        reset(initialFormData);
        onCloseModal();
        setLoading(false);
        navigate('/admin/eventos');
      } else if (user && ['SCANNER'].includes(user.role)) {
        reset(initialFormData);
        onCloseModal();
        setLoading(false);
        navigate('/admin/scanner');
      } else if (user && ['SALES'].includes(user.role)) {
        reset(initialFormData);
        onCloseModal();
        setLoading(false);
        navigate('/admin/punto-de-venta');
      } else {
        reset(initialFormData);
        onCloseModal();
        setLoading(false);
        window.location.reload();
      }
    } catch (error: any) {
      setErrorMessage(error.message || '');
      setError(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  return (
    <React.Fragment>
      <Modal
        open={open}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        slotProps={{ backdrop: { onClick: handleBackdropClick } }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: isMobile ? '85%' : 465,
            maxWidth: 465,
            bgcolor: 'background.paper',
            borderRadius: '10px',
            boxShadow: 24,
            display: 'flex',
            flexDirection: 'column',
            fontFamily: 'Helvetica Neue, Helvetica, Arial, sans-serif',
          }}
        >
          <Box
            sx={{
              width: '100%',
              height: 70,
              borderTopLeftRadius: '10px',
              borderTopRightRadius: '10px',
              background: '#A90063',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'relative',
            }}
          >
            <Box
              sx={{
                position: 'absolute',
                top: '20px',
                alignSelf: 'center',
              }}
            >
              <CardMedia
                component="img"
                alt="Imagen"
                height="30"
                image={t1}
                sx={{
                  boxShadow: 'none',
                  border: 'none',
                }}
              />
            </Box>
          </Box>
          <Box
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1,
            }}
          >
            <Form onSubmit={handleSubmit(submit)} method="post" action="">
              <AccountCircleRounded
                sx={{
                  color: (theme) => theme.palette.secondary.main,
                  fontSize: '80px',
                  fontWeight: 'bold',
                  textAlign: 'center',
                  fontFamily: 'Poppins',
                  width: '100%',
                }}
              />
              <Typography
                variant="h5"
                component="div"
                sx={{
                  color: (theme) => theme.palette.secondary.main,
                  fontSize: isMobile ? '24px' : '30px',
                  fontWeight: 'bold',
                  textAlign: 'center',
                  fontFamily: 'Poppins',
                  wordBreak: 'break-word',
                }}
              >
                ¡Bienvenido de nuevo!
              </Typography>
              <TextField
                {...register('email')}
                fullWidth
                type="email"
                id="outlined-required"
                label="Correo electrónico"
                margin="normal"
                size="small"
                error={!!errors.email}
                helperText={errors.email?.message}
              />
              <br />
              <TextField
                {...register('password')}
                fullWidth
                id="outlined-password-input"
                label="Contraseña"
                type={showPassword ? 'text' : 'password'}
                autoComplete="current-password"
                margin="normal"
                size="small"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                error={!!errors.password}
                helperText={errors.password?.message}
              />
              <LoadingButton
                loading={isSubmitting}
                variant="contained"
                fullWidth
                sx={{
                  mt: 1,
                  background: '#A90063',
                  border: '2px solid #A90063',
                  borderRadius: '8px',
                  color: '#FFFFFF',
                  fontFamily: 'Poppins',
                  textTransform: 'initial',
                  fontWeight: 600,
                  '&:hover': {
                    backgroundColor: '#FFFFFF',
                    border: '2px solid #A90063',
                    color: '#A90063',
                    boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.2)',
                  },
                }}
                type="submit"
              >
                Entrar
              </LoadingButton>
            </Form>
            <Stack sx={{ width: '100%', marginTop: '15px' }} spacing={2}>
              {isEmptyError && (
                <Alert severity="error">
                  Por favor, completa todos los campos.
                </Alert>
              )}
              {error && (
                <Alert severity="error" onClose={handleCloseAlert}>
                  {errorMessage}
                </Alert>
              )}
            </Stack>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <Link
              component="button"
              variant="body2"
              onClick={handleOpenForgotPasswordModal}
              sx={{
                width: '100%',
                textAlign: 'center',
                color: (theme) => theme.palette.primary.main,
                textDecorationLine: 'none',
                marginBottom: '20px',
              }}
            >
              ¿Olvidaste tu contraseña?
            </Link>
            <Stack direction="row" sx={{ ml: 16 }} display="none">
              <IconButton size="large">
                <FacebookRounded fontSize="large" sx={{ color: grey[500] }} />
              </IconButton>
              <IconButton size="large">
                <Instagram fontSize="large" sx={{ color: grey[500] }} />
              </IconButton>
            </Stack>
          </Box>
          <Box
            sx={{
              width: '100%',
              height: 'auto',
              top: 400,
              background: '#A90063',
              borderEndStartRadius: '10px',
              borderEndEndRadius: '10px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              pt: 1,
              pb: 2,
            }}
          >
            <Typography
              sx={{
                mt: 1,
                color: (theme) => theme.palette.common.white,
                fontSize: '15px',
                fontFamily: 'Poppins',
                textAlign: 'center',
                wordBreak: 'break-word',
              }}
            >
              ¿Aún no tienes cuenta?
            </Typography>
            <Link
              component="button"
              variant="body2"
              onClick={handleOpenRegisterModal}
              sx={{
                mb: 1,
                width: '50%',
                textAlign: 'center',
                fontFamily: 'Poppins',
                fontSize: '15px',
                color: (theme) => theme.palette.common.white,
                fontWeight: 'bold',
                textTransform: 'capitalize',
              }}
            >
              Regístrate
            </Link>
          </Box>
        </Box>
      </Modal>
    </React.Fragment>
  );
}

export default LogInModal;
