import { useState } from 'react';
import { Box, Typography, Button } from '@mui/material';
import TableContainer from '@mui/material/TableContainer';
import AddIcon from '@mui/icons-material/Add';
import { Section as NewSection } from './BoxSections';
import { SectionEntity } from 'libs/domain/src/lib/entities/Section';

interface SectionAndRowsProps {
  onOpenAddRowsModal: (sectionId: string) => void;
  onOpenRegisterSectionModal: () => void;
  sections: SectionEntity[];
  onOpenAddTableRowsModal: (sectionId: string) => void;
  onOpenDeleteRowModal: (rowId: string) => void;
  onOpenDeleteSectionModal: (sectionId: string) => void;
  onOpenUpdateSectionModal: (section: SectionEntity) => void;
}

const SectionAndRows = ({
  onOpenAddRowsModal,
  onOpenAddTableRowsModal,
  onOpenRegisterSectionModal,
  sections,
  onOpenDeleteRowModal,
  onOpenDeleteSectionModal,
  onOpenUpdateSectionModal,
}: SectionAndRowsProps) => {
  const [openBoxId, setOpenBoxId] = useState<string | null>(null);

  const handleToggle = (id: string | null) => {
    setOpenBoxId((prevId) => (prevId === id ? null : id));
  };

  return (
    <Box
      sx={{
        width: '95%',
        background: '#FFF',
        borderRadius: '10px',
        alignSelf: 'center',
        alignItems: 'center',
        p: 5,
        boxShadow: 3,
      }}
    >
      <Button
        sx={{
          width: 200,
          maxHeight: 50,
          borderRadius: '10px',
          fontSize: {
            xs: '10px',
            sm: '10px',
            md: '12px',
            lg: '14px',
            xl: '16px',
          },
          fontWeight: 'bold',
          textTransform: 'uppercase',
          color: '#890050',
          mt: 2,
          border: '2px solid #890050',
          backgroundColor: '#FFFFFF',
          '&:hover': {
            backgroundColor: '#890050',
            border: '2px solid #890050',
            color: '#FFFFFF',
          },
        }}
        onClick={onOpenRegisterSectionModal}
        startIcon={<AddIcon />}
      >
        Crear Sección
      </Button>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
          pt: 2,
        }}
      >
        <Typography
          sx={{
            color: '#444E6A',
            fontWeight: 700,
            fontSize: '1rem',
          }}
        >
          Secciones
        </Typography>
        <Typography
          sx={{
            color: '#444E6A',
            fontWeight: 700,
            fontSize: '1rem',
          }}
        >
          TOTAL: {sections?.length}
        </Typography>
      </Box>
      <TableContainer
        component={Box}
        sx={{
          width: '100%',
          mt: 2,
        }}
      >
        {sections.map((section) => (
          <NewSection
            onOpenDeleteRowModal={onOpenDeleteRowModal}
            key={section.id}
            section={section}
            isOpen={openBoxId === section.id}
            onToggle={() => handleToggle(section.id)}
            onOpenAddRowsModal={onOpenAddRowsModal}
            onOpenAddTableRowsModal={onOpenAddTableRowsModal}
            onOpenDeleteSectionModal={onOpenDeleteSectionModal}
            onUpdateSectionModal={onOpenUpdateSectionModal}
          />
        ))}
      </TableContainer>
    </Box>
  );
};

export default SectionAndRows;
