import { MouseEvent, useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Modal,
  TextField,
  Link,
  Alert,
  Stack,
  CardMedia,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { Form, useNavigate } from 'react-router-dom';
import { useAuth } from '../../providers/AuthProvider';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  ForgotPasswordDto,
  ForgotPasswordSchema,
} from 'libs/domain/src/lib/dto';
import t1 from '../images/t1.png';
import { ManageAccounts, MarkEmailRead } from '@mui/icons-material';

const initialFormData = {
  email: '',
};

interface ForgotPasswordModalProps {
  isOpen: boolean;
  onCloseModal: () => void;
  onOpenRegisterModal: () => void;
  onOpenLoginModal: () => void;
}

function ForgotPasswordModal({
  isOpen,
  onCloseModal,
  onOpenRegisterModal,
  onOpenLoginModal,
}: ForgotPasswordModalProps) {
  const { forgotPassword } = useAuth();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<ForgotPasswordDto>({
    resolver: zodResolver(ForgotPasswordSchema),
  });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [error, setError] = useState<string | null>(null);
  const [message, setMessage] = useState<string | undefined>(undefined);
  const [open, setOpen] = useState(isOpen);
  const [loading, setLoading] = useState(false);

  const handleCloseModal = () => {
    reset(initialFormData);
    setError(null);
    onCloseModal();
  };

  const handleOpenRegisterModal = () => {
    reset(initialFormData);
    onOpenRegisterModal();
    onCloseModal();
    setError(null);
  };

  const handleOpenLoginModal = () => {
    reset(initialFormData);
    onOpenLoginModal();
    onCloseModal();
    setError(null);
  };

  const handleBackdropClick = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
  };

  const handleCloseAlert = () => {
    setError(null);
  };

  const submit = async (data: ForgotPasswordDto) => {
    setError(null);

    try {
      const { status, message } = await forgotPassword(data);

      if (status) {
        reset(initialFormData);
        setMessage(message);
        setTimeout(() => {
          onCloseModal();
          setMessage(undefined);
          navigate('/');
        }, 5000);
      }

      if (!status) {
        setError(message || 'Ha ocurrido un error');
      }
    } catch (error: any) {
      setError(error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        slotProps={{ backdrop: { onClick: handleBackdropClick } }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: isMobile ? '85%' : 465,
            maxWidth: 465,
            bgcolor: 'background.paper',
            borderRadius: '10px',
            boxShadow: 24,
            display: 'flex',
            flexDirection: 'column',
            fontFamily: 'Helvetica Neue, Helvetica, Arial, sans-serif',
          }}
        >
          <Box
            sx={{
              width: '100%',
              height: 70,
              borderTopLeftRadius: '10px',
              borderTopRightRadius: '10px',
              background: '#A90063',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'relative',
            }}
          >
            <Box
              sx={{
                position: 'absolute',
                top: '20px',
                alignSelf: 'center',
              }}
            >
              <CardMedia
                component="img"
                alt="Imagen"
                height="30"
                image={t1}
                sx={{
                  boxShadow: 'none',
                  border: 'none',
                }}
              />
            </Box>
          </Box>
          <Box
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1,
            }}
          >
            {message ? (
              <>
                <MarkEmailRead
                  sx={{
                    color: (theme) => theme.palette.secondary.main,
                    fontSize: '80px',
                    fontWeight: 'bold',
                    textAlign: 'center',
                    fontFamily: 'Poppins',
                    width: '100%',
                  }}
                />
                <Typography
                  variant="h5"
                  component="div"
                  sx={{
                    color: (theme) => theme.palette.secondary.main,
                    fontSize: '30px',
                    fontWeight: 'bold',
                    textAlign: 'center',
                    fontFamily: 'Poppins',
                  }}
                >
                  {message}
                </Typography>
              </>
            ) : (
              <>
                <Form onSubmit={handleSubmit(submit)} method="post" action="">
                  <ManageAccounts
                    sx={{
                      color: (theme) => theme.palette.secondary.main,
                      fontSize: '80px',
                      fontWeight: 'bold',
                      textAlign: 'center',
                      fontFamily: 'Poppins',
                      width: '100%',
                    }}
                  />
                  <Typography
                    variant="h5"
                    component="div"
                    sx={{
                      color: (theme) => theme.palette.secondary.main,
                      fontSize: '30px',
                      fontWeight: 'bold',
                      textAlign: 'center',
                      fontFamily: 'Poppins',
                      wordBreak: 'break-word',
                    }}
                  >
                    Recuperar mi contraseña
                  </Typography>
                  <TextField
                    {...register('email')}
                    fullWidth
                    type="email"
                    id="outlined-required"
                    label="Correo electrónico"
                    margin="normal"
                    size="small"
                    error={!!errors.email}
                    helperText={errors.email?.message}
                  />
                  <LoadingButton
                    loading={isSubmitting}
                    variant="contained"
                    fullWidth
                    sx={{
                      mt: 1,
                      background: '#A90063',
                      border: '2px solid #A90063',
                      borderRadius: '8px',
                      color: '#FFFFFF',
                      fontFamily: 'Poppins',
                      textTransform: 'initial',
                      fontWeight: 600,
                      '&:hover': {
                        backgroundColor: '#FFFFFF',
                        border: '2px solid #890050',
                        color: '#890050',
                        boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.2)',
                      },
                    }}
                    type="submit"
                  >
                    Enviar correo de recuperación
                  </LoadingButton>
                </Form>
                <Stack sx={{ width: '100%', marginTop: '15px' }} spacing={2}>
                  {error && (
                    <Alert severity="error" onClose={handleCloseAlert}>
                      {error}
                    </Alert>
                  )}
                </Stack>
              </>
            )}
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <Link
              component="button"
              variant="body2"
              onClick={handleOpenLoginModal}
              sx={{
                mt: 2,
                width: '50%',
                textAlign: 'center',
                color: (theme) => theme.palette.primary.main,
                textDecorationLine: 'none',
                marginBottom: '20px',
              }}
            >
              Iniciar sesión{' '}
            </Link>
          </Box>
          <Box
            sx={{
              width: '100%',
              height: 'auto',
              top: 400,
              background: '#A90063',
              borderEndStartRadius: '10px',
              borderEndEndRadius: '10px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              pt: 1,
              pb: 2,
            }}
          >
            <Typography
              sx={{
                mt: 1,
                color: (theme) => theme.palette.common.white,
                fontSize: '15px',
                fontFamily: 'Poppins',
                textAlign: 'center',
                wordBreak: 'break-word',
              }}
            >
              ¿Aún no tienes cuenta?
            </Typography>
            <Link
              component="button"
              variant="body2"
              onClick={handleOpenRegisterModal}
              sx={{
                mb: 1,
                width: '50%',
                textAlign: 'center',
                fontFamily: 'Poppins',
                fontSize: '15px',
                color: (theme) => theme.palette.common.white,
                fontWeight: 'bold',
                textTransform: 'capitalize',
              }}
            >
              Regístrate
            </Link>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}

export default ForgotPasswordModal;
