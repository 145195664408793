import React from 'react';
import {
  Box,
  DialogContentText,
  TextField,
  Typography,
  Button,
  IconButton,
  Divider,
  InputAdornment,
} from '@mui/material';
import { CreateEventDatesAndPlaceInfoDto } from 'libs/domain/src/lib/dto';
import {
  Controller,
  Control,
  FieldArrayWithId,
  UseFormRegister,
  FieldErrors,
  UseFormResetField,
  UseFormSetValue,
} from 'react-hook-form';
import WithStepContainer, { StepContainerProps } from './StepContainer';
import {
  TimePicker,
  DatePicker,
  LocalizationProvider,
} from '@mui/x-date-pickers/';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import AddIcon from '@mui/icons-material/Add';
import { Close } from '@mui/icons-material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import SearchIcon from '@mui/icons-material/Search';
import { GoogleMap, Marker } from '@react-google-maps/api';
interface PlaceInformationStepProps extends StepContainerProps {
  register: UseFormRegister<CreateEventDatesAndPlaceInfoDto>;
  errors: FieldErrors<CreateEventDatesAndPlaceInfoDto>;
  control: Control<CreateEventDatesAndPlaceInfoDto>;
  onRemove: (index: number) => void;
  fields: FieldArrayWithId<CreateEventDatesAndPlaceInfoDto['eventDates']>[];
  onAppend: () => void;
  resetField: UseFormResetField<CreateEventDatesAndPlaceInfoDto>;
  setValue: UseFormSetValue<CreateEventDatesAndPlaceInfoDto>;
}

const PlaceInformationStep = ({
  register,
  errors,
  control,
  onRemove,
  fields,
  onAppend,
  resetField,
  setValue,
}: PlaceInformationStepProps) => {
  const [position, setPosition] = React.useState<{
    lat: number;
    lng: number;
  } | null>(null);
  const [isAddressFilled, setIsAddressFilled] = React.useState<boolean>(false);
  const mapRef = React.useRef<google.maps.Map | null>(null);
  const autocompleteRef = React.useRef<google.maps.places.Autocomplete>();
  const autocompleteInputRef = React.useRef<HTMLInputElement | undefined>();

  const handlePlaceChanged = React.useCallback(
    (place: google.maps.places.PlaceResult) => {
      if (place.geometry && place.geometry.location) {
        const { lat, lng } = place.geometry.location;
        setPosition({ lat: lat(), lng: lng() });
        if (mapRef.current) {
          mapRef.current.panTo({ lat: lat(), lng: lng() });
        }
      }

      // Address components
      const addressComponents = place.address_components;
      if (addressComponents) {
        for (const component of addressComponents) {
          const types = component.types;
          if (types.includes('locality')) {
            setValue('city', component.long_name);
          }
          if (types.includes('administrative_area_level_1')) {
            setValue('state', component.long_name);
          }
        }
      }

      const establishmentName = place.name;
      if (establishmentName) {
        setValue('venueName', establishmentName);
      }
      setIsAddressFilled(true);
    },
    [],
  );

  const handleResetLineAddress = () => {
    resetField('lineAddress');
    resetField('city');
    resetField('state');
    resetField('venueName');
    setIsAddressFilled(false);
  };

  React.useEffect(() => {
    if (!!autocompleteInputRef.current) {
      autocompleteRef.current = new google.maps.places.Autocomplete(
        autocompleteInputRef.current,
        {
          componentRestrictions: { country: ['mx'] },
          fields: [
            'address_components',
            'formatted_address',
            'geometry.location',
            'place_id',
            'name',
          ],
        },
      );

      autocompleteRef.current.addListener('place_changed', () => {
        const place = autocompleteRef.current?.getPlace();
        if (place) handlePlaceChanged(place);
      });
    }
  });

  return (
    <React.Fragment>
      <Box sx={{ textAlign: 'center', width: '100%' }}>
        <DialogContentText>
          <Button
            className="customOutlinedButton"
            aria-label="display-row"
            onClick={onAppend}
            disabled={fields.length >= 3}
          >
            {'Agregar fecha'}
            &nbsp;
            <AddIcon />
          </Button>
        </DialogContentText>
        <Box
          component="div"
          sx={{
            p: '20px',
            display: 'flex',
            justifyContent: 'center',
            gap: 2,
            flexDirection: 'column',
            width: '100%',
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            {fields.map((eventDate, index) => (
              <>
                <DemoContainer
                  key={eventDate.id}
                  components={['DatePicker', 'TimePicker']}
                >
                  <DemoItem>
                    <IconButton
                      title={'Eliminar fecha'}
                      disabled={fields.length === 1}
                      sx={{ mt: 0 }}
                      onClick={() => onRemove(index)}
                      aria-label="close"
                    >
                      <Close />
                    </IconButton>
                  </DemoItem>
                  <DemoItem label={'Fecha del evento'}>
                    <Controller
                      name={`eventDates.${index}.date`}
                      control={control}
                      render={({
                        field: { value, onChange },
                        fieldState: { error },
                      }) => (
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                          <DatePicker
                            value={value}
                            onChange={(value) => onChange(value)}
                            disablePast
                            views={['year', 'month', 'day']}
                            format="yyyy-MM-dd"
                            slotProps={{ textField: { size: 'small' } }}
                            className="customTextField"
                          />
                          {errors?.eventDates?.[index]?.date?.message && (
                            <Typography variant="caption" color="error">
                              {errors?.eventDates?.[index]?.date?.message}
                            </Typography>
                          )}
                        </Box>
                      )}
                    />
                  </DemoItem>
                  <DemoItem label={'Hora de inicio'}>
                    <Controller
                      name={`eventDates.${index}.from`}
                      control={control}
                      render={({
                        field: { value, onChange },
                        fieldState: { error },
                      }) => (
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: 150,
                          }}
                        >
                          <TimePicker
                            value={value}
                            onChange={(value) => onChange(value)}
                            views={['hours', 'minutes']}
                            format="HH:mm"
                            ampm={false}
                            slotProps={{ textField: { size: 'small' } }}
                            className="customTextField"
                          />
                          {errors?.eventDates?.[index]?.from?.message && (
                            <Typography variant="caption" color="error">
                              {errors?.eventDates?.[index]?.from?.message}
                            </Typography>
                          )}
                        </Box>
                      )}
                    />
                  </DemoItem>
                  <DemoItem label={'Hora de cierre'}>
                    <Controller
                      name={`eventDates.${index}.to`}
                      control={control}
                      render={({
                        field: { value, onChange },
                        fieldState: { error },
                      }) => (
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: 150,
                          }}
                        >
                          <TimePicker
                            value={value}
                            onChange={(value) => onChange(value)}
                            views={['hours', 'minutes']}
                            format="HH:mm"
                            ampm={false}
                            slotProps={{ textField: { size: 'small' } }}
                            className="customTextField"
                          />
                          {errors?.eventDates?.[index]?.to?.message && (
                            <Typography variant="caption" color="error">
                              {errors?.eventDates?.[index]?.to?.message}
                            </Typography>
                          )}
                        </Box>
                      )}
                    />
                  </DemoItem>
                </DemoContainer>
                {index !== fields.length - 1 && (
                  <Divider
                    sx={{
                      mt: 1,
                      mb: 1,
                    }}
                  />
                )}
              </>
            ))}
          </LocalizationProvider>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          justifyContent: 'center',
          alignItems: 'center',
          border: '1px solid #cfcfcf',
          p: 2,
        }}
      >
        <TextField
          label="Dirección"
          variant="outlined"
          id="location-input"
          inputRef={autocompleteInputRef}
          {...register('lineAddress')}
          error={!!errors.lineAddress}
          helperText={errors.lineAddress?.message}
          className="customTextField"
          InputLabelProps={{ shrink: true }}
          placeholder="Ingresa una dirección o Lugar para el evento"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon
                  sx={{
                    color: '#49454F',
                  }}
                />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleResetLineAddress} edge="end">
                  <HighlightOffIcon
                    sx={{
                      color: '#49454F',
                    }}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        {/* Map Box */}
        <Box
          sx={{
            borderRadius: '15px',
            border: 0,
            width: '100%',
            minHeight: '400px',
          }}
        >
          <GoogleMap
            id="searchBoxMap"
            mapContainerStyle={{ height: '100%', width: '100%' }}
            zoom={position ? 15 : 2}
            center={position || { lat: 23.6345, lng: 102.5528 }}
            onLoad={(map: google.maps.Map) => {
              mapRef.current = map;
            }}
          >
            {position && <Marker position={position} />}
          </GoogleMap>
        </Box>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-evenly',
          }}
        >
          {isAddressFilled ? (
            <React.Fragment>
              <TextField
                label="Ciudad"
                variant="outlined"
                {...register('city')}
                error={!!errors.city}
                helperText={errors.city?.message}
                size="small"
                sx={{ mr: '5px !important' }}
                className="customTextField"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => resetField('city')} edge="end">
                        <HighlightOffIcon sx={{ color: '#49454F' }} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                label="Estado"
                variant="outlined"
                {...register('state')}
                error={!!errors.state}
                helperText={errors.state?.message}
                size="small"
                sx={{ ml: '5px !important' }}
                className="customTextField"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => resetField('state')}
                        edge="end"
                      >
                        <HighlightOffIcon sx={{ color: '#49454F' }} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                label="Establecimiento"
                variant="outlined"
                {...register('venueName')}
                error={!!errors.venueName}
                helperText={errors.venueName?.message}
                size="small"
                className="customTextField"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => resetField('venueName')}
                        edge="end"
                      >
                        <HighlightOffIcon
                          sx={{
                            color: '#49454F',
                          }}
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </React.Fragment>
          ) : (
            <React.Fragment>
              <TextField
                label="Ciudad"
                variant="outlined"
                {...register('city')}
                error={!!errors.city}
                helperText={errors.city?.message}
                size="small"
                sx={{ mr: '5px !important' }}
                className="customTextField"
                disabled
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                label="Estado"
                variant="outlined"
                {...register('state')}
                error={!!errors.state}
                helperText={errors.state?.message}
                size="small"
                sx={{ ml: '5px !important' }}
                className="customTextField"
                disabled
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                label="Establecimiento"
                variant="outlined"
                {...register('venueName')}
                error={!!errors.venueName}
                helperText={errors.venueName?.message}
                size="small"
                sx={{ ml: '5px !important' }}
                className="customTextField"
                disabled
                InputLabelProps={{ shrink: true }}
              />
            </React.Fragment>
          )}
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default WithStepContainer(PlaceInformationStep);
