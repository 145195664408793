import { createSlice } from '@reduxjs/toolkit';
import { GetCartWithItemsResponse } from 'libs/domain/src/lib/dto/Cart/getCartWithItemsResponse';

export interface CartState {
  count: number;
  cart: GetCartWithItemsResponse | null;
  minutes: number;
  seconds: number;
  isExpired: boolean;
}

const initialState: CartState = {
  count: 0,
  cart: null,
  minutes: 0,
  seconds: 0,
  isExpired: false,
};

const cartSlice = createSlice({
  name: 'cart',
  initialState: initialState,
  reducers: {
    setCountItems: (state, action) => {
      state.count = action.payload;
    },
    setCart: (state, action) => {
      state.cart = action.payload;
    },
    setMinutes: (state, action) => {
      state.minutes = action.payload;
    },
    setSeconds: (state, action) => {
      state.seconds = action.payload;
    },
    setIsExpired: (state, action) => {
      state.isExpired = action.payload;
    },
  },
});

export const { setCountItems, setCart, setMinutes, setSeconds, setIsExpired } =
  cartSlice.actions;

export default cartSlice.reducer;
