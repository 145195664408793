import React, { useState, useCallback, useRef, useEffect } from 'react';
import { Box, Button, Typography } from '@mui/material';
import Draggable from 'react-draggable';
import { Rnd } from 'react-rnd';
import QRCode from 'react-qr-code';
import {
  Stage,
  Layer,
  Image as KonvaImage,
  Text as KonvaText,
  Rect as KonvaRect,
} from 'react-konva';
import useImage from 'use-image';
import TGDropzone from './TgDropzone';
import TicketImageSlider from './TicketImageSlider';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import SaveIcon from '@mui/icons-material/Save';
import { useTheme, useMediaQuery } from '@mui/material';

// Auxiliar: convertir dataURL a File
async function dataURLToFile(dataURL: string, filename = 'ticketFinal.jpg') {
  const res = await fetch(dataURL);
  const blob = await res.blob();
  return new File([blob], filename, { type: 'image/jpeg' });
}

interface FieldPosition {
  x: number;
  y: number;
}

type Mode = 'drop' | 'drag' | 'preview';

interface TicketDesignerProps {
  currentImage: string | null;
  onSave: (file: File) => void;
  onCancel: () => void;
}

function useDataUrlImage(url: string | null) {
  const [image, setImage] = useState<HTMLImageElement | null>(null);

  useEffect(() => {
    if (!url) {
      setImage(null);
      return;
    }
    const img = new Image();
    img.src = url;
    img.onload = () => setImage(img);
  }, [url]);

  return image;
}

export default function EventTicketPreview({
  onSave,
  onCancel,
  currentImage,
}: TicketDesignerProps) {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));
  const isSm = useMediaQuery(theme.breakpoints.only('sm'));
  const isMd = useMediaQuery(theme.breakpoints.only('md'));
  const isLg = useMediaQuery(theme.breakpoints.only('lg'));
  const isXl = useMediaQuery(theme.breakpoints.only('xl'));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'));

  // Valores base
  const BASE_WIDTH = 539;
  const BASE_HEIGHT = 265;
  const BASE_POS_SECCION = { x: 299, y: 218 };
  const BASE_POS_FILA = { x: 390, y: 218 };
  const BASE_POS_ASIENTO = { x: 310, y: 236 };
  const BASE_POS_PRECIO = { x: 420, y: 236 };
  const BASE_POS_CARGO = { x: 490, y: 236 };
  const BASE_POS_QR = { x: 255, y: 100 };
  const BASE_QR_SIZE = 100;
  // Estado de modo
  const [mode, setMode] = useState<Mode>(currentImage ? 'preview' : 'drop');

  // Imagen de fondo y su tamaño
  const [backgroundImage, setBackgroundImage] = useState<string | null>(null);
  const [imgSize, setImgSize] = useState({ width: 800, height: 400 });
  // Dimensiones del canvas (basadas en la imagen natural)
  const [canvasSize, setCanvasSize] = useState<{
    width: number;
    height: number;
  }>({ width: 800, height: 400 });
  const [konvaImage] = useImage(backgroundImage || '');

  useEffect(() => {
    if (konvaImage) {
      let width = 800,
        height = 400;

      if (isXs) {
        width = 300;
        height = 150;
      } else if (isSm) {
        width = 600;
        height = 300;
      } else if (isMd) {
        width = 800;
        height = 400;
      } else if (isLg) {
        width = 1200;
        height = 600;
      } else if (isXl) {
        width = 1400;
        height = 700;
      }
      setImgSize({ width, height });
      setCanvasSize({
        width,
        height,
      });
    }
  }, [konvaImage, isXs, isSm, isMd, isLg, isXl]);

  // Overlays
  const [posSeccion, setPosSeccion] = useState<FieldPosition>({
    x: 446,
    y: 324,
  });

  const [posFila, setPosFila] = useState<FieldPosition>({ x: 563, y: 326 });

  const [posAsiento, setPosAsiento] = useState<FieldPosition>({
    x: 461,
    y: 352,
  });

  const [posPrecio, setPosPrecio] = useState<FieldPosition>({ x: 617, y: 351 });

  const [posCargo, setPosCargo] = useState<FieldPosition>({ x: 717, y: 351 });

  // QR: posición y tamaño
  const [posQR, setPosQR] = useState<FieldPosition>({ x: 378, y: 150 });
  const [qrSize, setQrSize] = useState({ width: 150, height: 150 });

  // dataURL del QR (fondo blanco o transparente, como gustes)
  const [qrDataUrl, setQrDataUrl] = useState<string | null>(null);
  const qrImage = useDataUrlImage(qrDataUrl);

  // Stage ref para guardado final
  const stageRef = useRef<any>(null);

  const offset = isSmallScreen
    ? 0.02 * imgSize.height
    : isMediumScreen
      ? 0.03 * imgSize.height
      : 0.04 * imgSize.height;

  // Genera el QR en un useEffect
  useEffect(() => {
    import('qrcode').then((qrcode) => {
      // Ajusta margin: 0 y width: 200 si quieres más resolución
      qrcode.toDataURL(
        'ticket-to-go',
        { margin: 0, width: 200 },
        (err, url) => {
          if (!err) {
            setQrDataUrl(url);
          }
        },
      );
    });
  }, []);
  useEffect(() => {
    if (imgSize.width && imgSize.height) {
      setPosQR({
        x: (BASE_POS_QR.x / BASE_WIDTH) * imgSize.width,
        y: (BASE_POS_QR.y / BASE_HEIGHT) * imgSize.height,
      });
      setPosSeccion({
        x: (BASE_POS_SECCION.x / BASE_WIDTH) * imgSize.width,
        y: (BASE_POS_SECCION.y / BASE_HEIGHT) * imgSize.height,
      });
      setPosFila({
        x: (BASE_POS_FILA.x / BASE_WIDTH) * imgSize.width,
        y: (BASE_POS_FILA.y / BASE_HEIGHT) * imgSize.height,
      });
      setPosAsiento({
        x: (BASE_POS_ASIENTO.x / BASE_WIDTH) * imgSize.width,
        y: (BASE_POS_ASIENTO.y / BASE_HEIGHT) * imgSize.height,
      });
      setPosPrecio({
        x: (BASE_POS_PRECIO.x / BASE_WIDTH) * imgSize.width,
        y: (BASE_POS_PRECIO.y / BASE_HEIGHT) * imgSize.height,
      });
      setPosCargo({
        x: (BASE_POS_CARGO.x / BASE_WIDTH) * imgSize.width,
        y: (BASE_POS_CARGO.y / BASE_HEIGHT) * imgSize.height,
      });
    }
  }, [imgSize]);

  useEffect(() => {
    if (imgSize.width) {
      setQrSize({
        width: (BASE_QR_SIZE / BASE_WIDTH) * imgSize.width,
        height: (BASE_QR_SIZE / BASE_WIDTH) * imgSize.width,
      });
    }
  }, [imgSize]);

  // Modo drop: subir imagen
  const handleDropFile = (file: File) => {
    const reader = new FileReader();
    reader.onload = () => {
      setBackgroundImage(reader.result as string);
      setMode('drag');
    };
    reader.readAsDataURL(file);
  };

  // Guardar fusión final
  const handleSaveDesign = useCallback(async () => {
    if (!stageRef.current) return;
    const finalUrl = stageRef.current.toDataURL({ pixelRatio: 2 });
    const file = await dataURLToFile(finalUrl);
    onSave(file);
  }, [onSave]);

  // Render de texto, draggable si mode === 'drag'
  function renderOverlay(
    text: string,
    pos: FieldPosition,
    onDragStop: (e: any, data: any) => void,
  ) {
    if (mode === 'drag') {
      return (
        <Draggable
          key={text}
          bounds="parent"
          position={{ x: pos.x, y: pos.y }}
          onStop={onDragStop}
        >
          <Typography
            sx={{
              position: 'absolute',
              fontSize: '14px',
              fontWeight: 'bold',
              cursor: 'move',
              userSelect: 'none',
            }}
          >
            {text}
          </Typography>
        </Draggable>
      );
    }
    return (
      <Typography
        key={text}
        sx={{
          position: 'absolute',
          top: pos.y,
          left: pos.x,
          fontSize: '14px',
          fontWeight: 'bold',
        }}
      >
        {text}
      </Typography>
    );
  }

  // Render QR con <Rnd> para arrastrar y redimensionar
  function renderDraggableQR() {
    if (mode !== 'drag') return null;

    return (
      <Rnd
        bounds="parent"
        lockAspectRatio
        position={{ x: posQR.x, y: posQR.y }}
        size={{ width: qrSize.width, height: qrSize.height }}
        onDragStop={(e, data) => {
          setPosQR({ x: data.x, y: data.y });
        }}
        onResizeStop={(e, direction, ref, delta, position) => {
          const newWidth = parseFloat(ref.style.width);
          const newHeight = parseFloat(ref.style.height);
          setQrSize({ width: newWidth, height: newHeight });
          setPosQR({ x: position.x, y: position.y });
        }}
        style={{
          border: '1px dashed #999',
          backgroundColor: '#fff',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          cursor: 'move',
        }}
      >
        {qrDataUrl && (
          <QRCode
            value="ticket-to-go"
            size={Math.floor(Math.min(qrSize.width, qrSize.height))}
          />
        )}
      </Rnd>
    );
  }

  // Render en modo "drag" (imagen <img> + overlays) o "preview" (Konva)
  function renderDragOrPreview() {
    if (!backgroundImage) return null;
    // Emular object-fit: contain en la imagen con Konva
    let displayWidth = canvasSize.width;
    let displayHeight = canvasSize.height;
    let offsetX = 0;
    let offsetY = 0;
    if (konvaImage && konvaImage.naturalWidth && konvaImage.naturalHeight) {
      const scale = Math.min(
        canvasSize.width / konvaImage.naturalWidth,
        canvasSize.height / konvaImage.naturalHeight,
      );
      displayWidth = konvaImage.naturalWidth * scale;
      displayHeight = konvaImage.naturalHeight * scale;
      offsetX = (canvasSize.width - displayWidth) / 2;
      offsetY = (canvasSize.height - displayHeight) / 2;
    }

    if (mode === 'drag') {
      return (
        <Box
          sx={{
            position: 'relative',
            maxWidth: canvasSize.width,
            height: canvasSize.height,
            background: '#f0f0f052',
          }}
        >
          <img
            src={backgroundImage}
            alt="Ticket"
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'contain',
              position: 'absolute',
              top: 0,
              left: 0,
            }}
          />
          {renderDraggableQR()}
          {renderOverlay('Sección', posSeccion, (e, data) =>
            setPosSeccion({ x: data.x, y: data.y }),
          )}
          {renderOverlay('Fila', posFila, (e, data) =>
            setPosFila({ x: data.x, y: data.y }),
          )}
          {renderOverlay('Asiento', posAsiento, (e, data) =>
            setPosAsiento({ x: data.x, y: data.y }),
          )}
          {renderOverlay('Precio', posPrecio, (e, data) =>
            setPosPrecio({ x: data.x, y: data.y }),
          )}
          {renderOverlay('Cargo', posCargo, (e, data) =>
            setPosCargo({ x: data.x, y: data.y }),
          )}
        </Box>
      );
    }

    // mode === 'preview'
    return (
      <Stage ref={stageRef} width={canvasSize.width} height={canvasSize.height}>
        <Layer width={canvasSize.width} height={canvasSize.height}>
          {konvaImage && (
            <KonvaImage
              image={konvaImage}
              x={offsetX}
              y={offsetY}
              width={displayWidth}
              height={displayHeight}
            />
          )}

          {/* Overlays de texto */}
          <KonvaText
            text="Sección"
            fontSize={14}
            fontStyle="bold"
            x={posSeccion.x}
            y={posSeccion.y + offset - 18}
            fill="black"
          />
          <KonvaText
            text="Fila"
            fontSize={14}
            fontStyle="bold"
            x={posFila.x}
            y={posFila.y + offset - 18}
            fill="black"
          />
          <KonvaText
            text="Asiento"
            fontSize={14}
            fontStyle="bold"
            x={posAsiento.x}
            y={posAsiento.y + offset - 18}
            fill="black"
          />
          <KonvaText
            text="Precio"
            fontSize={14}
            fontStyle="bold"
            x={posPrecio.x}
            y={posPrecio.y + offset - 18}
            fill="black"
          />
          <KonvaText
            text="Cargo"
            fontSize={14}
            fontStyle="bold"
            x={posCargo.x}
            y={posCargo.y + offset - 18}
            fill="black"
          />

          {/* El QR en modo preview */}
          {qrImage && (
            <>
              <KonvaRect
                x={posQR.x}
                y={posQR.y}
                width={qrSize.width}
                height={qrSize.height}
                fill="white"
              />
              <KonvaImage
                image={qrImage}
                x={posQR.x}
                y={posQR.y}
                width={qrSize.width}
                height={qrSize.height}
              />
            </>
          )}
        </Layer>
      </Stage>
    );
  }

  const handleImageChange = () => {
    setMode('drop');
  };
  const hidePreview = () => {
    setMode('drag');
  };
  const showPreview = () => {
    setMode('preview');
  };

  return (
    <Box sx={{ p: 2 }}>
      {(mode === 'drop' || (!currentImage && !backgroundImage)) && (
        <Box sx={{ mb: 2 }}>
          <TGDropzone file={null} onReceiveFile={handleDropFile} />
        </Box>
      )}
      {currentImage && mode === 'preview' && !konvaImage && (
        <TicketImageSlider events={[]} images={[currentImage]} />
      )}

      {(mode === 'drag' || mode === 'preview') && renderDragOrPreview()}

      <Box
        sx={{
          width: '400px',
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'column-reverse',
          alignItems: 'center',
          p: 2,
          m: '0 auto',
          gap: 2,
          mt: 1,
        }}
      >
        {konvaImage && mode === 'preview' && (
          <Button
            sx={{
              width: '100%',
              minHeight: '35px',
              background: '#444E6A',
              borderRadius: '10px',
              color: (theme) => theme.palette.common.white,
              fontWeight: 600,
              letterSpacing: '1px',
              fontFamily: 'Poppins',
              textTransform: 'capitalize',
              fontSize: '1rem',
              '&:hover': {
                backgroundColor: (theme) => theme.palette.common.white,
                color: '#444E6A',
              },
              mb: 2,
            }}
            variant="contained"
            size="small"
            onClick={handleSaveDesign}
            startIcon={<SaveIcon />}
          >
            Guardar
          </Button>
        )}
        {konvaImage && mode !== 'drop' && (
          <Button
            sx={{
              width: '100%',
              minHeight: '35px',
              background: (theme) => theme.palette.common.white,
              borderRadius: '10px',
              color: '#444E6A',
              fontWeight: 600,
              letterSpacing: '1px',
              fontFamily: 'Poppins',
              textTransform: 'capitalize',
              fontSize: '1rem',
              '&:hover': {
                backgroundColor: '#444E6A',
                color: (theme) => theme.palette.common.white,
              },
              mb: 2,
            }}
            variant="contained"
            onClick={
              mode === 'preview' ? () => hidePreview() : () => showPreview()
            }
            size="small"
            startIcon={mode === 'preview' ? <EditIcon /> : <VisibilityIcon />}
          >
            {mode === 'preview' ? 'Seguir editando' : 'Pre visualizar'}
          </Button>
        )}
        {mode !== 'drop' && currentImage && (
          <Button
            sx={{
              width: '100%',
              minHeight: '35px',
              background: '#444E6A',
              borderRadius: '10px',
              color: (theme) => theme.palette.common.white,
              fontWeight: 600,
              letterSpacing: '1px',
              fontFamily: 'Poppins',
              textTransform: 'capitalize',
              fontSize: '1rem',
              '&:hover': {
                backgroundColor: (theme) => theme.palette.common.white,
                color: '#444E6A',
              },
              mb: 2,
            }}
            variant="contained"
            size="small"
            onClick={handleImageChange}
            startIcon={<AddPhotoAlternateIcon />}
          >
            Cambiar imagen
          </Button>
        )}
      </Box>
    </Box>
  );
}
