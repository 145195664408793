import { DateTime } from 'luxon';

export const fromTimeStrToDate = (timeStr: string, date: Date): Date => {
  const [hours, minutes] = timeStr.split(':').map((time) => parseInt(time, 10));
  return new Date(date.setHours(hours, minutes));
};

export const formatDate = (date: Date, dateFormat = 'dd MMMM yyyy'): string => {
  return DateTime.fromJSDate(date, {
    zone: 'America/Mexico_City',
  })
    .setLocale('es')
    .toFormat(dateFormat);
};

export const getTime = (date: Date, dateFormat = 'HH:mm'): string => {
  return DateTime.fromJSDate(date, {
    zone: 'America/Mexico_City',
  })
    .setLocale('es')
    .toFormat(dateFormat);
};

export const parseDate = (
  dateString: string,
  dateFormat = 'dd MMMM yyyy',
): Date => {
  return DateTime.fromFormat(dateString, dateFormat).toJSDate();
};

export const addDaysToDate = (date: Date, days: number): Date => {
  return DateTime.fromJSDate(date).plus({ days }).toJSDate();
};

export const subtractDaysFromDate = (date: Date, days: number): Date => {
  return DateTime.fromJSDate(date).minus({ days }).toJSDate();
};

export const getDifferenceInDays = (date1: Date, date2: Date): number => {
  return Math.round(
    DateTime.fromJSDate(date1).diff(DateTime.fromJSDate(date2), 'days').days,
  );
};

export const isDateAfter = (date1: Date, date2: Date): boolean => {
  return DateTime.fromJSDate(date1) > DateTime.fromJSDate(date2);
};

export const isDateBefore = (date1: Date, date2: Date): boolean => {
  return DateTime.fromJSDate(date1) < DateTime.fromJSDate(date2);
};

export const formatTZDate = (date: Date): string => {
  const formatter = new Intl.DateTimeFormat('es-ES', {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
    timeZone: 'UTC',
  });

  return formatter.format(new Date(date));
};

export const formatTime = (time: string) => {
  return DateTime.fromISO(time).toFormat('hh:mm a');
};
