import { ValidateQRStringDto } from 'libs/domain/src/lib/dto';
import { axiosRequest } from '../api-client';

export const validateQR = async (data: ValidateQRStringDto) => {
  return axiosRequest({
    method: 'POST',
    url: '/scanner',
    data,
  });
};
