import {
  AddUserDto,
  SignPasswordDto,
  UpdateUserByAdminDto,
} from 'libs/domain/src/lib/dto';
import { axiosRequest } from '../api-client';

export const removeUser = (userId: string) => {
  return axiosRequest({
    method: 'DELETE',
    url: `/admin/users/${userId}`,
  });
};

export const updateUser = (userId: string, data: UpdateUserByAdminDto) => {
  return axiosRequest({
    method: 'PUT',
    url: `Admin/users/${userId}`,
    data,
  });
};

export const addUser = async (data: AddUserDto) => {
  return axiosRequest({
    method: 'POST',
    url: `/admin/users/`,
    data,
  });
};

export const invitationUser = async (token: string, data: SignPasswordDto) => {
  return axiosRequest({
    method: 'PUT',
    url: `/invitation/${token}`,
    data,
  });
};

export const getInvitatedUserInfo = async (token: string) => {
  return axiosRequest({
    method: 'GET',
    url: `/admin/invitation/${token}`,
  });
};

export const getMyEvents = async () => {
  return axiosRequest({
    method: 'GET',
    url: `/my-events`,
  });
};

export const getMyTickets = async (eventId: string) => {
  return axiosRequest({
    method: 'GET',
    url: `/my-tickets?eventId=${eventId}`,
  });
};
