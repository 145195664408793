import React from 'react';
import { Box, Button, CircularProgress } from '@mui/material';
import { useAuth } from 'apps/client/src/providers/AuthProvider';
import RemoveShoppingCartOutlinedIcon from '@mui/icons-material/RemoveShoppingCartOutlined';

interface CartActionsProps {
  loading: boolean;
  createOrder: () => void;
  openLogin: () => void;
  onOpenClearCartDialog: (cartId: string) => void;
  cartId: string;
}

const CartActions: React.FC<CartActionsProps> = ({
  loading,
  createOrder,
  onOpenClearCartDialog,
  cartId,
  openLogin,
}) => {
  const { user } = useAuth();

  return (
    <Box
      sx={{
        width: '90%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        p: '15px 0',
        mx: 'auto',
      }}
    >
      {!user ? (
        <Button
          sx={{
            width: '100%',
            minHeight: '40px',
            textTransform: 'initial',
            m: '10px 0',
            backgroundColor: 'secondary.main',
            borderRadius: '10px',
            fontFamily: 'Poppins',
            fontSize: '1rem',
            fontWeight: 600,
            color: 'common.white',
            '&:hover': {
              backgroundColor: 'primary.main',
            },
          }}
          onClick={() => {
            openLogin();
          }}
        >
          Iniciar sesión
        </Button>
      ) : (
        <Button
          sx={{
            width: '100%',
            minHeight: '40px',
            textTransform: 'initial',
            m: '10px 0',
            backgroundColor: 'secondary.main',
            borderRadius: '10px',
            fontFamily: 'Poppins',
            fontSize: '1rem',
            fontWeight: 600,
            color: 'common.white',
            '&:hover': {
              backgroundColor: 'primary.main',
            },
          }}
          disabled={!cartId}
          onClick={createOrder}
        >
          {loading ? <CircularProgress size={24} /> : 'Siguiente'}
        </Button>
      )}

      {cartId && (
        <Button
          sx={{
            width: '100%',
            minHeight: '40px',
            textTransform: 'initial',
            m: '10px 0',
            backgroundColor: 'secondary.main',
            borderRadius: '10px',
            fontFamily: 'Poppins',
            fontSize: '1rem',
            fontWeight: 600,
            color: 'common.white',
            '&:hover': {
              backgroundColor: 'primary.main',
            },
          }}
          onClick={() => onOpenClearCartDialog(cartId)}
        >
          <RemoveShoppingCartOutlinedIcon
            sx={{
              fontSize: '25px',
              color: (theme) => theme.palette.common.white,
              marginRight: '10px',
            }}
          />
          Vaciar carrito
        </Button>
      )}
    </Box>
  );
};

export default CartActions;
