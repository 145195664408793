import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import LockIcon from '@mui/icons-material/Lock';
import { useAuth } from '../../providers/AuthProvider';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export const ForbiddenPage = () => {
  const { redirectAdminUserToMainPage } = useAuth();
  const navigate = useNavigate();

  const redirectToMainPage = () => {
    const mainPage = redirectAdminUserToMainPage();
    navigate(mainPage);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        mt: 4,
      }}
    >
      <LockIcon sx={{ fontSize: '8rem', color: 'red' }} />
      <Typography variant="h4" component="h1" gutterBottom>
        Acceso denegado
      </Typography>
      <Typography variant="body1" gutterBottom>
        No tienes permisos para acceder a esta página
      </Typography>
      <Button
        variant="contained"
        onClick={redirectToMainPage}
        sx={{
          textTransform: 'none',
          fontSize: '0.9rem',
          fontFamily: 'Popins, sans-serif',
          fontWeight: 600,
          letterSpacing: '0.7px',
          borderRadius: '10px',
          padding: '5px 20px',
          color: '#fff',
          backgroundColor: '#C33186',
          boxShadow: 'none',
          '&:hover': {
            // with shadow
            boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.2)',
            backgroundColor: '#890050',
          },
        }}
      >
        Ir a la página principal
      </Button>
    </Box>
  );
};
