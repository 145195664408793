import { z } from 'zod';
import { UserRole, USER_ROLE } from '../../entities/User/index';

export const updateUserByAdminSchema = z.object({
  firstName: z.preprocess(
    (val) => (val === '' ? undefined : val),
    z
      .string({
        required_error: 'Por favor, ingrese los nombres del usuario.',
      })
      .regex(
        /^[a-zA-ZáéíóúÁÉÍÓÚüÜñÑ]+(?: [a-zA-ZáéíóúÁÉÍÓÚüÜñÑ]+)*$/,
        'Los nombres deben contener solo letras.',
      ),
  ).optional(),
  lastName: z.preprocess(
    (val) => (val === '' ? undefined : val),
    z
      .string({
        required_error: 'Por favor, ingrese los apellidos del usuario.',
      })
      .regex(
        /^[a-zA-ZáéíóúÁÉÍÓÚüÜñÑ]+(?: [a-zA-ZáéíóúÁÉÍÓÚüÜñÑ]+)*$/,
        'Los apellidos deben contener solo letras.',
      ),
  ).optional(),
  role: z
    .preprocess(
      (val) => (val === '' ? undefined : val),
      z.string({
        required_error: 'Por favor, ingrese un rol para el usuario.',
      }),
    )
    .refine(
      (role) => USER_ROLE.includes(role as UserRole),
      'El rol ingresado no es permitido, los roles permitidos son: "Administrador", "Escáner de boletos" y "Punto de venta".',
    )
    .optional(),
});

export type UpdateUserByAdminDto = z.infer<typeof updateUserByAdminSchema>;
