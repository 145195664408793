import * as React from 'react';
import Button from '@mui/material/Button';
import { styled, useTheme } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import QRCode from 'react-qr-code';
import { Box } from '@mui/material';
import { QRInfo } from '../routes/buyer/MyTickets';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

interface QRTicketDialogProps {
  isOpen: boolean;
  hash: string | null;
  onClose: () => void;
  qrInfo: QRInfo;
}

export default function QRTicketDialog({
  isOpen,
  hash,
  onClose,
  qrInfo,
}: QRTicketDialogProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
    onClose();
  };

  React.useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  return (
    <React.Fragment>
      <BootstrapDialog
        fullScreen={isMobile}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          QR Ticket
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={(theme) => ({
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          {hash && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                height: isMobile ? 'auto' : '100%',
                alignSelf: 'center',
                py: 2,
              }}
            >
              <div style={{ width: 256, height: 256 }}>
                <QRCode
                  size={256}
                  style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
                  value={hash}
                  viewBox={`0 0 256 256`}
                />
              </div>
            </Box>
          )}

          {!hash && (
            <Typography gutterBottom>
              No se ha podido generar el código QR
            </Typography>
          )}

          <div>
            <Typography variant="h6" gutterBottom sx={{ textAlign: 'center' }}>
              {qrInfo.name}
            </Typography>
            <Typography variant="body1">Lugar: {qrInfo.venueName}</Typography>
            <Typography variant="body1">
              Ciudad: {qrInfo.city}, {qrInfo.state}
            </Typography>
            <Typography variant="body1">Fecha: {qrInfo.eventDate}</Typography>
            <Typography variant="body1">Hora: {qrInfo.eventTime}</Typography>
          </div>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Ok
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}
