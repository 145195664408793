import { z } from 'zod';

//const dateFormatRegex = /^\d{4}-\d{2}-\d{2}$/;
//const timeFormatRegex = /^([01]?[0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/;

export const datesSchema = z.object({
  eventDates: z.array(
    z.object({
      date: z.preprocess(
        (val) => (val === '' || !val ? undefined : new Date(val as Date)),
        z
          .date({
            required_error: 'Campo requerido',
          })
          .refine((date) => date instanceof Date, {
            message: 'Campo requerido',
          }),
      ),
      from: z.preprocess(
        (val) => (val === '' || !val ? undefined : new Date(val as Date)),
        z
          .date({
            required_error: 'Campo requerido',
          })
          .refine((date) => date instanceof Date, {
            message:
              'La hora de inicio no es válida, debe tener el formato adecuado (HH:MM:SS).',
          }),
      ),
      to: z.preprocess(
        (val) => (val === '' || !val ? undefined : new Date(val as Date)),
        z
          .date({
            required_error: 'Campo requerido',
          })
          .refine((date) => date instanceof Date, {
            message:
              'La hora de finalización no es válida, debe tener el formato adecuado (HH:MM:SS).',
          }),
      ),
    }),
  ),
});

export const postDatesSchema = datesSchema;

export type CreateDatesDto = z.infer<typeof postDatesSchema>;
export type DatesDto = z.infer<typeof datesSchema>;
