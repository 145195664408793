import { z } from 'zod';

export const createTableRowWithSeatsSchema = z.object({
  name: z.preprocess(
    (val) => (val === '' ? undefined : val),
    z.string({
      required_error: 'Por favor, ingrese un nombre para la mesa.',
    }),
  ),
  capacity: z
    .preprocess(
      (val) =>
        typeof val === 'string' && val !== '' ? Number(val) : undefined,
      z.number({
        required_error:
          'Por favor, indique la capacidad de asientos para esta mesa.',
      }),
    )
    .refine((val) => typeof val === 'number' && val > 0, {
      message: 'La capacidad debe ser un número mayor que cero.',
    }),
  price: z
    .preprocess(
      (val) =>
        typeof val === 'string' && val !== '' ? parseFloat(val) : undefined,
      z.number().optional().nullable(),
    )
    .refine((val) => {
      if (typeof val === 'number') {
        return val >= 0;
      }
      return true;
    }),
});

export type CreateTableRowWithSeatsDto = z.infer<
  typeof createTableRowWithSeatsSchema
>;
