import React from 'react';
import {
  Box,
  Button,
  Typography,
  Collapse,
  IconButton,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
} from '@mui/material';
import { SectionEntity } from 'libs/domain/src/lib/entities/Section';
import { RowEntity } from 'libs/domain/src/lib/entities/Row';
import DeleteIcon from '@mui/icons-material/Delete';
import { TableMenu } from './TableMenu';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import CachedIcon from '@mui/icons-material/Cached';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

type TypeTranslations = {
  [key: string]: string;
};

const typeTranslations: TypeTranslations = {
  ROW: 'Fila',
  TABLE: 'Mesa',
  GENERAL: 'Acceso general',
};

function RenderRowType(props: {
  section: SectionEntity;
  isOpen: boolean;
  onOpenAddRowsModal: (sectionId: string) => void;
  onOpenDeleteRowModal: (rowId: string) => void;
}) {
  const { section, isOpen, onOpenAddRowsModal, onOpenDeleteRowModal } = props;

  return (
    <Box sx={{ marginBottom: '24px', position: 'relative' }}>
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <Button
          variant="outlined"
          sx={{
            width: 200,
            maxHeight: 50,
            borderRadius: '10px',
            fontSize: {
              xs: '10px',
              md: '14px',
              xl: '16px',
            },
            fontWeight: 'bold',
            textTransform: 'initial',
            color: '#444E6A',
            border: '2px solid #444E6A',
            backgroundColor: '#FFFFFF',
            '&:hover': {
              backgroundColor: '#444E6A',
              border: '2px solid #444E6A',
              color: '#FFFFFF',
            },
            position: 'absolute',
            top: '0px',
            right: '10px',
            padding: '3px 8px',
          }}
          onClick={() => onOpenAddRowsModal(section.id)}
          startIcon={<AddIcon />}
        >
          Agregar fila
        </Button>
        <Box sx={{ margin: 1, position: 'relative' }}>
          <Table
            size="medium"
            aria-label="purchases"
            sx={{ marginTop: '30px' }}
          >
            <TableHead>
              <TableRow>
                <TableCell>Fila</TableCell>
                <TableCell>Nº de asientos </TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {section.rows?.map((row: RowEntity) => (
                <TableRow key={row.id}>
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell>{row.capacity}</TableCell>
                  <TableCell align="right">
                    <IconButton
                      aria-label="delete"
                      title="Eliminar fila"
                      size="small"
                      onClick={() => onOpenDeleteRowModal(row.id)}
                    >
                      <DeleteIcon sx={{ width: '20px' }} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
              {section.rows?.length === 0 && (
                <TableRow>
                  <TableCell colSpan={2} align="center">
                    No hay filas registradas aún
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Box>
      </Collapse>
    </Box>
  );
}

function RenderTableType(props: {
  section: SectionEntity;
  isOpen: boolean;
  onOpenAddTableRowsModal: (sectionId: string) => void;
  onOpenDeleteRowModal: (rowId: string) => void;
}) {
  const { section, isOpen, onOpenAddTableRowsModal, onOpenDeleteRowModal } =
    props;

  return (
    <Box sx={{ marginBottom: '24px', position: 'relative' }}>
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <Button
          variant="outlined"
          sx={{
            width: 200,
            maxHeight: 50,
            borderRadius: '10px',
            fontSize: {
              xs: '10px',
              md: '14px',
              xl: '16px',
            },
            fontWeight: 'bold',
            textTransform: 'initial',
            color: '#444E6A',
            border: '2px solid #444E6A',
            backgroundColor: '#FFFFFF',
            '&:hover': {
              backgroundColor: '#444E6A',
              border: '2px solid #444E6A',
              color: '#FFFFFF',
            },
            position: 'absolute',
            top: '0px',
            right: '10px',
            padding: '3px 8px',
          }}
          onClick={() => onOpenAddTableRowsModal(section.id)}
          startIcon={<AddIcon />}
        >
          Agregar mesa
        </Button>

        {/* Table Content */}
        <Box sx={{ margin: 1, position: 'relative' }}>
          <Table
            size="medium"
            aria-label="purchases"
            sx={{ marginTop: '30px' }}
          >
            <TableHead>
              <TableRow>
                <TableCell>Nombre</TableCell>
                <TableCell>
                  Nº de lugares por mesa{' '}
                  <small style={{ color: 'gray' }}>(Capacidad)</small>
                </TableCell>
                <TableCell>Precio</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody
              sx={{
                overflow: 'auto',
              }}
            >
              {section.rows &&
                section.rows.length > 0 &&
                section.rows.map((row: RowEntity) => (
                  <TableRow key={row.id}>
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell>{row.capacity}</TableCell>
                    <TableCell>{row.price ? `$${row.price}` : ''}</TableCell>
                    <TableCell align="right">
                      <IconButton
                        aria-label="delete"
                        title="Eliminar mesa"
                        size="small"
                        onClick={() => onOpenDeleteRowModal(row.id)}
                      >
                        <DeleteIcon sx={{ width: '20px' }} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              {section.rows?.length === 0 && (
                <TableRow>
                  <TableCell colSpan={4} align="center">
                    No hay mesas registradas aún
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Box>
      </Collapse>
    </Box>
  );
}

export function Section(props: {
  section: SectionEntity;
  isOpen: boolean;
  onToggle: () => void;
  onOpenAddRowsModal: (sectionId: string) => void;
  onOpenAddTableRowsModal: (sectionId: string) => void;
  onOpenDeleteRowModal: (rowId: string) => void;
  onOpenDeleteSectionModal: (sectionId: string) => void;
  onUpdateSectionModal: (section: SectionEntity) => void;
}) {
  const {
    section,
    isOpen,
    onToggle,
    onOpenAddRowsModal,
    onOpenAddTableRowsModal,
    onOpenDeleteRowModal,
    onOpenDeleteSectionModal,
    onUpdateSectionModal,
  } = props;
  const [isTableOpen, setIsTableOpen] = React.useState(false);
  const getTicketLabel = (type: string, capacity: number) => {
    const asientoTypes = ['ROW', 'TABLE'];
    return `${capacity} ${asientoTypes.includes(type) ? 'Lugares' : 'Accesos'}`;
  };

  React.useEffect(() => {
    if (!isOpen) {
      setIsTableOpen(false);
    }
  }, [isOpen]);

  const handleTableToggle = () => {
    setIsTableOpen(!isTableOpen);
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          borderRadius: '10px',
          border: '1px solid #C4C4C4',
          overflow: 'hidden',
          width: '100%',
          marginBottom: '24px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            backgroundColor: '#444E6A',
            padding: '8px 16px',
            color: '#FFF',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton onClick={onToggle} sx={{ color: '#FFF' }}>
              {isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
            <Box
              sx={{
                width: '16px',
                height: '16px',
                backgroundColor: `${section.color}`,
                marginRight: '8px',
                borderRadius: '4px',
              }}
            />
            <Typography variant="h6" sx={{ fontWeight: 700 }}>
              {section.name}
            </Typography>
          </Box>
          <TableMenu
            options={[
              {
                id: '0',
                label: ' Editar',
                icon: <CachedIcon sx={{ color: 'white' }} />,
                onClick: () => onUpdateSectionModal(section),
              },
              {
                id: '1',
                label: ' Eliminar',
                icon: <ClearIcon sx={{ color: 'white' }} />,
                onClick: () => onOpenDeleteSectionModal(section.id),
              },
            ]}
          />
        </Box>
        {isOpen && (
          <Box
            sx={{
              padding: '16px',
              backgroundColor: '#D9D9D9',
            }}
          >
            <Box sx={{ flex: 1, paddingLeft: '8px' }}>
              <Typography variant="body1">
                <strong style={{ fontWeight: 700 }}>Tipo de sección:</strong>{' '}
                {typeTranslations[section.type]} (
                {getTicketLabel(section.type, section.capacity)})
              </Typography>
              <Typography variant="body1">
                <strong style={{ fontWeight: 700 }}>Capacidad total:</strong>{' '}
                {section.capacity}
              </Typography>
              <Typography variant="body1">
                <strong style={{ fontWeight: 700 }}>Precio:</strong> $
                {section.price}
              </Typography>
            </Box>
            <Box
              sx={{
                width: '100%',
                justifyContent: 'end',
                display: 'flex',
              }}
            >
              {section.type !== 'GENERAL' && (
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={handleTableToggle}
                  sx={{ color: '#444E6A' }}
                >
                  {isTableOpen ? <HighlightOffIcon /> : <UnfoldMoreIcon />}
                </IconButton>
              )}
            </Box>
          </Box>
        )}
      </Box>

      {section.type === 'ROW' && (
        <RenderRowType
          onOpenDeleteRowModal={onOpenDeleteRowModal}
          section={section}
          isOpen={isTableOpen}
          onOpenAddRowsModal={onOpenAddRowsModal}
        />
      )}
      {section.type === 'TABLE' && (
        <RenderTableType
          onOpenDeleteRowModal={onOpenDeleteRowModal}
          section={section}
          isOpen={isTableOpen}
          onOpenAddTableRowsModal={onOpenAddTableRowsModal}
        />
      )}
    </React.Fragment>
  );
}
