import { ReactNode, useState } from 'react';
import Footer from './Footer';
import LogInModal from './LogInModal';
import { SignUpModal } from './SignUpModal';
import { Box } from '@mui/material';
import NavBar from './client/NavBar';
import { useCart } from '../hooks/useCart';
import { useAuth } from '../../providers/AuthProvider';
import ForgotPasswordModal from './ForgotPasswordModal';

interface LayoutProps {
  children: ReactNode;
}
export default function Layout({ children }: LayoutProps) {
  const { isAuth } = useAuth();
  const { state: cartState } = useCart();
  const [openLoginModal, setOpenLoginModal] = useState(false);
  const [openRegisterModal, setOpenRegisterModal] = useState(false);
  const [openForgotPasswordModal, setOpenForgotPasswordModal] = useState(false);

  const handleOpenLoginModal = () => {
    setOpenLoginModal(true);
  };

  const handleCloseLoginModal = () => {
    setOpenLoginModal(false);
  };

  const handleOpenRegisterModal = () => {
    setOpenRegisterModal(true);
  };

  const handleCloseRegisterModal = () => {
    setOpenRegisterModal(false);
  };

  const handleOpenForgotPasswordModal = () => {
    setOpenForgotPasswordModal(true);
  };

  const handleCloseForgotPasswordModal = () => {
    setOpenForgotPasswordModal(false);
  };

  return (
    <Box>
      <Box
        sx={{
          width: '100%',
        }}
      />
      <NavBar
        cartState={cartState}
        isAuth={isAuth}
        onOpenLoginModal={handleOpenLoginModal}
        backGroundColor="#C33186"
      />
      {/* </Stack> */}
      <LogInModal
        isOpen={openLoginModal}
        onCloseModal={handleCloseLoginModal}
        onOpenRegisterModal={handleOpenRegisterModal}
        onOpenForgotPasswordModal={handleOpenForgotPasswordModal}
      />
      <ForgotPasswordModal
        isOpen={openForgotPasswordModal}
        onCloseModal={handleCloseForgotPasswordModal}
        onOpenRegisterModal={handleOpenRegisterModal}
        onOpenLoginModal={handleOpenLoginModal}
      />
      <SignUpModal
        isOpen={openRegisterModal}
        onOpenLoginModal={handleOpenLoginModal}
        onCloseModal={handleCloseRegisterModal}
      />
      {children}
      <Footer />
    </Box>
  );
}
