import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, Divider, IconButton } from '@mui/material';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Close } from '@mui/icons-material';
import { Form } from 'react-router-dom';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { CreateDatesDto } from 'libs/domain/src/lib/dto';
import { datesSchema } from 'libs/domain/src/lib/dto';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';

interface AddEventDatesProps {
  isOpen: boolean;
  onClose: () => void;
  onCreateEventDates: (data: CreateDatesDto) => void;
  eventId: string | null;
}

interface CreationalEventDates extends Omit<CreateDatesDto, 'eventDates'> {
  eventDates: {
    date: Date | null;
    from: Date | null;
    to: Date | null;
  }[];
}

const initialValues: CreationalEventDates = {
  eventDates: [
    {
      date: null,
      from: null,
      to: null,
    },
  ],
};

export default function AddEventDate({
  isOpen,
  onClose,
  onCreateEventDates,
  eventId,
}: AddEventDatesProps) {
  const [open, setOpen] = React.useState(isOpen);
  const [loading, setLoading] = React.useState(false);
  const [additionalRows, setAdditionalRows] = React.useState<Array<{}>>([]);

  const {
    register,
    control,
    reset,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<CreationalEventDates>({
    defaultValues: initialValues,
    resolver: zodResolver(datesSchema),
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'eventDates',
  });

  const handleDisplayRow = () => {
    append({
      date: null,
      from: null,
      to: null,
    });
  };

  const handleBackdropClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    reset();
  };

  const handleClose = () => {
    onClose();
    setOpen(false);
    reset();
    setAdditionalRows([]);
  };

  const onSubmit = async (data: CreationalEventDates) => {
    if (!eventId) {
      return;
    }
    const eventDates: CreateDatesDto['eventDates'] = [];

    data.eventDates.forEach((eventDate) => {
      if (eventDate.from && eventDate.to && eventDate.date) {
        eventDates.push({
          date: eventDate.date,
          from: eventDate.from,
          to: eventDate.to,
        });
      }
    });
    onCreateEventDates({
      eventDates: eventDates,
    });
    handleClose();
  };

  React.useEffect(() => {
    setOpen(isOpen);
    setLoading(false);
  }, [isOpen]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        slotProps={{ backdrop: { onClick: handleBackdropClick } }}
        disableEscapeKeyDown
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{ position: 'absolute', top: 5, right: 1 }}
        >
          <Close />
        </IconButton>
        <Form onSubmit={handleSubmit(onSubmit)} method="post" action="">
          <DialogTitle>
            <b>Agregar fechas para el evento</b>
          </DialogTitle>
          <DialogContent>
            <DialogContentText sx={{ mt: 2, mb: 1 }}>
              <b>Fechas del evento</b>
              <Divider />
            </DialogContentText>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              {fields.map((eventDate, index) => (
                <DemoContainer
                  key={index}
                  components={['DatePicker', 'TimePicker']}
                >
                  <DemoItem label="Fecha del evento">
                    <Controller
                      name={`eventDates.${index}.date`}
                      control={control}
                      render={({
                        field: { value, onChange },
                        fieldState: { error },
                      }) => (
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                          <DatePicker
                            value={value}
                            onChange={(value) => onChange(value)}
                            disablePast
                            views={['year', 'month', 'day']}
                            format="yyyy-MM-dd"
                          />
                          {errors?.eventDates?.[index]?.date?.message && (
                            <Typography variant="caption" color="error">
                              {errors?.eventDates?.[index]?.date?.message}
                            </Typography>
                          )}
                        </Box>
                      )}
                    />
                  </DemoItem>
                  <DemoItem label="Hora de inicio">
                    <Controller
                      name={`eventDates.${index}.from`}
                      control={control}
                      render={({
                        field: { value, onChange },
                        fieldState: { error },
                      }) => (
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                          <TimePicker
                            value={value}
                            onChange={(value) => onChange(value)}
                            views={['hours', 'minutes']}
                            format="hh:mm a"
                            ampm={false}
                          />
                          {errors?.eventDates?.[index]?.from?.message && (
                            <Typography variant="caption" color="error">
                              {errors?.eventDates?.[index]?.from?.message}
                            </Typography>
                          )}
                        </Box>
                      )}
                    />
                  </DemoItem>
                  <DemoItem label="Hora de cierre">
                    <Controller
                      name={`eventDates.${index}.to`}
                      control={control}
                      render={({
                        field: { value, onChange },
                        fieldState: { error },
                      }) => (
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                          <TimePicker
                            value={value}
                            onChange={(value) => onChange(value)}
                            views={['hours', 'minutes']}
                            format="hh:mm a"
                            ampm={false}
                          />
                          {errors?.eventDates?.[index]?.to?.message && (
                            <Typography variant="caption" color="error">
                              {errors?.eventDates?.[index]?.to?.message}
                            </Typography>
                          )}
                        </Box>
                      )}
                    />
                  </DemoItem>
                </DemoContainer>
              ))}
            </LocalizationProvider>
            <Button
              variant="contained"
              sx={{
                float: 'center',
                width: '100%',
                height: '43px',
                margin: '20px 0 25px 0',
                backgroundColor: '#feffff',
                color: '#63708e',
                '&:hover': {
                  backgroundColor: '#757474',
                  color: '#fefeff',
                },
                borderRadius: '1px',
                padding: 1,
              }}
              aria-label="display-row"
              onClick={handleDisplayRow}
            >
              {'Agregar fecha'}
              &nbsp;
              <AddIcon />
            </Button>
          </DialogContent>
          <DialogActions>
            <Button size="small" color="inherit" onClick={handleClose}>
              Cancelar
            </Button>
            <Button
              disabled={isSubmitting}
              color="primary"
              type="submit"
              size="medium"
              onClick={handleSubmit(onSubmit)}
            >
              Crear
            </Button>
          </DialogActions>
        </Form>
      </Dialog>
    </div>
  );
}
