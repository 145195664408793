import { z } from 'zod';

export const addUserSchema = z
  .object({
    firstName: z.preprocess(
      (val) => (val === '' ? undefined : val),
      z
        .string({
          required_error: 'Por favor, ingrese sus nombres.',
        })
        .regex(
          /^[a-zA-ZáéíóúÁÉÍÓÚüÜñÑ]+(?: [a-zA-ZáéíóúÁÉÍÓÚüÜñÑ]+)*$/,
          'Los nombres deben contener solo letras.',
        ),
    ),
    lastName: z.preprocess(
      (val) => (val === '' ? undefined : val),
      z
        .string({
          required_error: 'Por favor, ingrese sus apellidos.',
        })
        .regex(
          /^[a-zA-ZáéíóúÁÉÍÓÚüÜñÑ]+(?: [a-zA-ZáéíóúÁÉÍÓÚüÜñÑ]+)*$/,
          'Los apellidos deben contener solo letras.',
        ),
    ),
    email: z
      .string({
        required_error: 'Por favor, ingrese su correo electrónico.',
      })
      .email('Por favor, ingrese un email válido.'),
    password: z.preprocess(
      (val) => (val === '' ? undefined : val),
      z
        .string({
          required_error: 'Por favor, ingrese su contraseña.',
        })
        .min(8, 'La contraseña debe tener al menos 8 caracteres.'),
    ),
    confirmPassword: z.preprocess(
      (val) => (val === '' ? undefined : val),
      z
        .string({
          required_error: 'Por favor, confirme su contraseña.',
        })
        .min(8, 'La contraseña, debe tener al menos 8 caracteres.'),
    ),
    role: z.preprocess(
      (val) => (val === '' ? undefined : val),
      z.string({
        required_error: 'Por favor, confirme el tipo de rol del usuario.',
      }),
    ),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: 'Las contraseñas no coinciden.',
    path: ['confirmPassword'],
  });

export type AddUserDto = z.infer<typeof addUserSchema>;
