import { Optional } from '../../types/common';
import { RowEntity } from '../Row';

export type TypeSection = 'ROW' | 'TABLE' | 'GENERAL';

export const TYPE_SECTION: TypeSection[] = ['ROW', 'TABLE', 'GENERAL'];

export enum TypeSectionEnum {
  ROW = 'ROW',
  TABLE = 'TABLE',
  GENERAL = 'GENERAL',
}

export interface SectionEntity {
  id: string;
  name: string;
  color: string;
  type: TypeSection;
  capacity: number;
  price: number;
  layout: string;
  eventId: string;
  createdAt: Date;
  updatedAt: Date | null;
  sort_order: number | null;

  // Relations
  rows?: RowEntity[];
}
export type SectionCreateInput = Optional<
  Omit<SectionEntity, 'id'>,
  'createdAt' | 'updatedAt'
>;

export const getSectionType = (sectionType: TypeSection) => {
  const sectionTypeDic = {
    ROW: 'Fila',
    TABLE: 'Mesa',
    GENERAL: 'General',
  };

  return sectionTypeDic[sectionType];
};
