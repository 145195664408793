import {
  Avatar,
  Box,
  Button,
  TableBody,
  TableCell,
  Table,
  TableHead,
  TableRow,
  Typography,
  TableContainer,
  Divider,
  IconButton,
} from '@mui/material';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { useCart } from '../../hooks/useCart';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { toCurrency } from '../../utils/currency';
import { useState } from 'react';
import DefaultDialog from '../DefaultDialog';
import { ClearCart, DeleteCartItem } from '../../../requests/carts';
import AlertMessage from '../AlertMessage';
import { ItemSummary } from '../../../../../../libs/domain/src/lib/dto/Cart/getCartWithItemsResponse';
import { ClearCartDialog } from '../../routes/checkout/ClearCartDialog';
import TransactionModal from '../TransactionModal';
import CartTimer from 'apps/client/src/features/cart/components/CartTimer';

const transactionBox = {
  display: 'flex',
  justifyContent: 'center',
  padding: '20px 40px',
  mb: '10px',
  width: { xs: '70%', sm: '80%', md: '85%', xl: '90%' },
  flexDirection: 'column',
  alignItems: 'center',
  backgroundColor: '#FFFFFF',
  borderRadius: '15px',
};

export const TransactionGenerator = () => {
  const [cartId, setCartId] = useState<string | null>(null);
  const [cartItemId, setCartItemId] = useState<string | null>(null);
  const [openDeleteItemDialog, setOpenDeleteItemDialog] = useState(false);
  const [openClearCartDialog, setOpenClearCartDialog] = useState(false);
  const [alertProps, setAlertProps] = useState<{
    isOpen: boolean;
    severity: 'success' | 'error' | 'info' | 'warning';
    message: string;
  }>({
    isOpen: false,
    severity: 'success',
    message: '',
  });
  const [openTransactionModal, setOpenTransactionModal] = useState(false);

  const handleOpenTransactionModal = () => {
    setOpenTransactionModal(true);
  };

  const handleCloseTransactionModal = () => {
    setOpenTransactionModal(false);
  };

  const {
    state: { cart, minutes, seconds },
    refreshCart,
  } = useCart();

  const chargeForService = cart ? cart.totalPrice * 0.13 : 0;

  const handleOpenDeleteItemDialog = (cartItemId: string) => {
    setCartItemId(cartItemId);
    setOpenDeleteItemDialog(true);
  };

  const handleCloseDeleteItemDialog = () => {
    setCartItemId(null);
    setOpenDeleteItemDialog(false);
  };

  const handleOpenClearCartDialog = (cartId: string) => {
    setCartId(cartId);
    setOpenClearCartDialog(true);
  };

  const handleCloseClearCartDialog = () => {
    setCartId(null);
    setOpenClearCartDialog(false);
  };

  const deleteItemFromCart = async () => {
    if (!cartItemId) {
      return;
    }

    const { response, status, error } = await DeleteCartItem(cartItemId);
    if (status === 'OK') {
      const messageDelete =
        response.data.message || '¡Boleto eliminado correctamente!';
      setAlertProps({
        isOpen: true,
        severity: 'success',
        message: messageDelete,
      });
      setOpenDeleteItemDialog(false);
      refreshCart();
    }
    if (status === 'ERROR') {
      const messageError =
        error.response?.data?.message || 'Ha ocurrido un error.';
      setAlertProps({
        isOpen: true,
        severity: 'error',
        message: messageError,
      });
      setOpenDeleteItemDialog(false);
    }
  };

  const handleCloseAlert = () => {
    setAlertProps({
      isOpen: false,
      severity: 'success',
      message: '',
    });
  };

  const clearCart = async () => {
    if (!cartId) {
      return;
    }
    const { response, status, error } = await ClearCart();
    if (status === 'OK') {
      const errorMessage =
        response.data.message || '¡El carrito se vacío correctamente!';
      setAlertProps({
        isOpen: true,
        severity: 'success',
        message: errorMessage,
      });
      setOpenClearCartDialog(false);
      refreshCart();
    }

    if (status === 'ERROR') {
      const errorMessage =
        error.response?.data?.message || 'Ha ocurrido un error.';
      setAlertProps({
        isOpen: true,
        severity: 'error',
        message: errorMessage,
      });
      setOpenClearCartDialog(false);
    }
  };

  const renderSeatCapacity = (item: ItemSummary) => {
    switch (item.section.type) {
      case 'ROW':
        return item.seat;
      case 'TABLE':
        return item.capacity;
      default:
        return 'N/A';
    }
  };

  return (
    <Box sx={transactionBox}>
      {cart && cart.items.length > 0 && (
        <CartTimer minutes={minutes} seconds={seconds} />
      )}
      <TableContainer sx={{ padding: 3 }}>
        <Table>
          <TableHead
            sx={{
              backgroundColor: (theme) => theme.palette.secondary.main,
              borderRadius: '20px',
              '& .MuiTableRow-root': {
                borderRadius: '20px',
              },
            }}
          >
            <TableRow>
              <TableCell
                align="left"
                sx={{
                  color: (theme) => theme.palette.common.white,
                  fontWeight: '600',
                  fontSize: '14px',
                }}
              >
                Evento
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  color: (theme) => theme.palette.common.white,
                  fontWeight: '600',
                  fontSize: '14px',
                }}
              >
                Sección
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  color: (theme) => theme.palette.common.white,
                  fontWeight: '600',
                  fontSize: '14px',
                }}
              >
                Fila/Mesa
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  color: (theme) => theme.palette.common.white,
                  fontWeight: '600',
                  fontSize: '14px',
                }}
              >
                Asiento/Cupo
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  color: (theme) => theme.palette.common.white,
                  fontWeight: '600',
                  fontSize: '14px',
                }}
              >
                Precio
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  color: (theme) => theme.palette.common.white,
                  fontWeight: '600',
                  fontSize: '14px',
                }}
              />
            </TableRow>
          </TableHead>
          <TableBody
            sx={{
              background: '#EBEBEB',
              borderRadius: '20px',
              '& .MuiTableRow-root': {
                borderRadius: '20px',
              },
            }}
          >
            {cart ? (
              cart.items.map((cartItem) => (
                <TableRow>
                  <TableCell
                    align="center"
                    sx={{
                      display: 'flex',
                      justifyContent: 'left',
                      alignItems: 'center',
                    }}
                  >
                    <Avatar
                      src={cart.event.cardImage}
                      sx={{
                        width: '125px',
                        height: '125px',
                        '&:hover': {
                          cursor: 'pointer',
                        },
                      }}
                      variant="rounded"
                    />
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        textAlign: 'justify',
                        marginInlineStart: '15px',
                      }}
                    >
                      <Typography
                        sx={{
                          color: (theme) => theme.palette.primary.main,
                          fontWeight: '800',
                          fontSize: '16px',
                          textTransform: 'uppercase',
                        }}
                      >
                        {cart.event.name}
                      </Typography>
                      <Typography
                        sx={{
                          color: (theme) => theme.palette.primary.main,
                          fontWeight: '300',
                          fontSize: '16px',
                        }}
                      >
                        {cart.event.eventDate
                          ? format(
                              new Date(cart.event.eventDate.date),
                              "dd 'de' MMMM yyyy",
                              {
                                locale: es,
                              },
                            )
                          : null}
                      </Typography>
                      <Typography
                        sx={{
                          color: '#000',
                          fontWeight: '300',
                          fontSize: '16px',
                        }}
                      >
                        {cart.event.venueName}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell align="center">
                    <Typography
                      sx={{
                        color: '#000',
                        fontWeight: '300',
                        fontSize: '16px',
                      }}
                    >
                      {' '}
                      {cartItem.section.name}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    {' '}
                    <Typography
                      sx={{
                        color: '#000',
                        fontWeight: '300',
                        fontSize: '16px',
                      }}
                    >
                      {' '}
                      {cartItem.row ? cartItem.row : 'N/A'}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography
                      sx={{
                        color: '#000',
                        fontWeight: '300',
                        fontSize: '16px',
                      }}
                    >
                      {renderSeatCapacity(cartItem)}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography
                      sx={{
                        color: '#000',
                        fontWeight: '800',
                        fontSize: '16px',
                      }}
                    >
                      {toCurrency(cartItem.section.price)}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <IconButton
                      sx={{
                        height: '20px',
                        borderRadius: '5px',
                        color: (theme) => theme.palette.error.main,
                      }}
                      onClick={() => handleOpenDeleteItemDialog(cartItem.id)}
                    >
                      <DeleteOutlineIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <>
                <TableRow>
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell />
                </TableRow>
                <TableRow>
                  <TableCell />
                  <TableCell />
                  <TableCell align="center">
                    <Typography
                      sx={{
                        color: '#000',
                        fontWeight: '300',
                        fontSize: '16px',
                      }}
                    >
                      Carrito Vacío
                    </Typography>
                  </TableCell>
                  <TableCell />
                  <TableCell />
                  <TableCell />
                </TableRow>
                <TableRow>
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell />
                </TableRow>
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Box
        sx={{
          width: '30%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignSelf: { xs: 'center', md: 'end' },
        }}
      >
        <Box
          sx={{
            justifyContent: 'space-between',
            display: 'inline-block',
            flexDirection: 'row',
            marginBottom: '5px',
          }}
        >
          {cart && (
            <Box
              sx={{
                width: '96%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <Typography
                  sx={{
                    fontSize: '18px',
                    fontWeight: '900',
                    fontFamily: 'Poppins',
                    color: (theme) => theme.palette.primary.main,
                  }}
                >
                  Cargos
                </Typography>
                <Typography
                  sx={{
                    fontSize: '18x',
                    fontWeight: '900',
                    fontFamily: 'Poppins',
                    color: (theme) => theme.palette.primary.main,
                  }}
                >
                  {`MX ${toCurrency(chargeForService)}`}
                </Typography>
              </Box>
              <Typography
                sx={{
                  alignSelf: 'start',
                  fontSize: '15px',
                  fontWeight: '500',
                  fontFamily: 'Poppins',
                  color: '#303030',
                }}
              >
                Cargo por servicio
              </Typography>
            </Box>
          )}
          {cart && cart.totalPrice > 0 && (
            <Box
              sx={{
                width: '96%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: '30px',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <Typography
                  sx={{
                    fontSize: '18px',
                    fontWeight: '900',
                    fontFamily: 'Poppins',
                    color: (theme) => theme.palette.common.black,
                  }}
                >
                  TOTAL
                </Typography>
                <Typography
                  sx={{
                    fontSize: '18x',
                    fontWeight: '900',
                    fontFamily: 'Poppins',
                    color: (theme) => theme.palette.primary.main,
                  }}
                >
                  MX {toCurrency(cart.totalPrice + chargeForService)}
                </Typography>
              </Box>
              <Divider />
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  width: '100%',
                  marginTop: '5px',
                }}
              >
                <Button
                  variant="outlined"
                  sx={{
                    borderRadius: '8px',
                    color: (theme) => theme.palette.primary.main,
                    background: (theme) => theme.palette.common.white,
                    fontFamily: 'Poppins',
                    fontWeight: 'bold',
                    textTransform: 'initial',
                    '&:hover': {
                      background: (theme) => theme.palette.primary.main,
                      color: (theme) => theme.palette.common.white,
                    },
                    width: '45%',
                  }}
                  onClick={() => handleOpenClearCartDialog(cart?.cartId)}
                >
                  Cancelar
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    borderRadius: '8px',
                    color: (theme) => theme.palette.common.white,
                    background: (theme) => theme.palette.primary.main,
                    fontFamily: 'Poppins',
                    fontWeight: 'bold',
                    textTransform: 'initial',
                    '&:hover': {
                      background: (theme) => theme.palette.secondary.main,
                      color: (theme) => theme.palette.common.white,
                    },
                    width: '53%',
                  }}
                  onClick={handleOpenTransactionModal}
                >
                  Generar transacción
                </Button>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
      <DefaultDialog
        title="Eliminar boleto"
        text="¿Estás seguro que deseas eliminar tu boleto?"
        isOpen={openDeleteItemDialog}
        onCancel={handleCloseDeleteItemDialog}
        onConfirm={deleteItemFromCart}
        textConfirmButton="Eliminar"
        textCancelButton="Cancelar"
      />
      <AlertMessage
        isOpen={alertProps.isOpen}
        severity={alertProps.severity}
        message={alertProps.message}
        onClose={handleCloseAlert}
      />
      <ClearCartDialog
        title="Vaciar carrito"
        text="Estás a punto de vaciar el carrito. Esta acción no se puede deshacer ¿Desea continuar?"
        isOpen={openClearCartDialog}
        textCancelButton="Cancelar"
        textConfirmButton="Confirmar"
        onCancel={handleCloseClearCartDialog}
        onConfirm={clearCart}
      />
      <TransactionModal
        isOpen={openTransactionModal}
        onCloseModal={handleCloseTransactionModal}
      />
    </Box>
  );
};
