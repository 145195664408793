import { CreateDatesDto } from 'libs/domain/src/lib/dto';
import { axiosRequest } from '../api-client';

export const AddEvent = (data: FormData) => {
  return axiosRequest({
    method: 'POST',
    url: '/events/',
    data,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const RemoveEvent = (eventId: string) => {
  return axiosRequest({
    method: 'DELETE',
    url: `/events/${eventId}`,
  });
};

export const UpdateEvent = (eventId: string, data: FormData) => {
  return axiosRequest({
    method: 'PUT',
    url: `/events/${eventId}`,
    data,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const UpdateStatusEvent = (eventId: string, data: object) => {
  return axiosRequest({
    method: 'PUT',
    url: `/events/${eventId}/status`,
    data,
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export const getEventById = (eventId: string) => {
  return axiosRequest({
    method: 'GET',
    url: `/events/${eventId}`,
  });
};

export const GetAllPublishedEvents = () => {
  return axiosRequest({
    method: 'GET',
    url: `/events/published`,
  });
};

export const getSectionsByEventId = (eventId: string) => {
  return axiosRequest({
    method: 'GET',
    url: `/events/${eventId}/sections`,
  });
};

export const getDatesByEventId = (eventId: string) => {
  return axiosRequest({
    method: 'GET',
    url: `/events/${eventId}/dates`,
  });
};

export const toggleHighlightedFLag = (eventId: string) => {
  return axiosRequest({
    method: 'PUT',
    url: `/events/${eventId}/highlight`,
  });
};

export const AddNewEventDate = (eventId: string, data: CreateDatesDto) => {
  return axiosRequest({
    method: 'POST',
    url: `/events/${eventId}/dates`,
    data,
  });
};

export const PublishEvent = (eventId: string) => {
  return axiosRequest({
    method: 'PUT',
    url: `/events/${eventId}/publish`,
    headers: {
      'Content-Type': 'application/json',
    },
  });
};
