export const toCurrency = (prop: number) => {
  return new Intl.NumberFormat('es-MX', {
    style: 'currency',
    currency: 'MXN',
  }).format(prop);
};

export const toNumber = (prop: string) => {
  return Number(prop.replace(/,/g, '').replace('$', '').replace(',', ''));
};
