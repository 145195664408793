import { Box, CircularProgress } from '@mui/material';
import { useEffect, useState } from 'react';
import DefaultDialog from '../../components/DefaultDialog';
import AlertMessage from '../../components/AlertMessage';
import { ClearCartDialog } from './ClearCartDialog';
import { useAuth } from '../../../providers/AuthProvider';
import { SignUpModal } from '../../components/SignUpModal';
import LogInModal from '../../components/LogInModal';
import { useScrollTop } from '../../hooks/useScrollTop';
import ModalCheckoutDialog from './ModalCheckout';
import { useCart } from '../../hooks/useCart';
import { ResumentItem } from '../../../../../../libs/domain/src/lib/dto/Cart/getCartWithItemsResponse';
import ResetPasswordModal from '../../components/ResetPasswordModal';
import { Navigate } from 'react-router-dom';
import CartItemsList from 'apps/client/src/features/cart/components/CartItemsList';
import CartHeader from 'apps/client/src/features/cart/components/CartHeader';
import EventInfoCard from 'apps/client/src/features/cart/components/EventInfoCard';
import CartTimer from 'apps/client/src/features/cart/components/CartTimer';
import CartReviewSection from 'apps/client/src/features/cart/components/CartReviewSection';
import { BoxMainContainer } from 'apps/client/src/features/cart/components/BoxMainContainer';
import { useCartActions } from 'apps/client/src/features/cart/hooks/useCartActions';

export const NewCartPage = () => {
  const { isAuth, isBuyer, user } = useAuth();

  if (isAuth && !isBuyer) {
    return <Navigate to={'/carrito'} replace />;
  }

  const {
    state: { cart, minutes, seconds },
    refreshCart,
    loading: cartLoading,
  } = useCart();

  const [cartItemId, setCartItemId] = useState<string | null>(null);
  const [cartId, setCartId] = useState<string | null>(null);
  const [openDeleteItemDialog, setOpenDeleteItemDialog] = useState(false);
  const [openClearCartDialog, setOpenClearCartDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openLoginModal, setOpenLoginModal] = useState(false);
  const [openRegisterModal, setOpenRegisterModal] = useState(false);
  const [openForgotPasswordModal, setOpenForgotPasswordModal] = useState(false);
  const [openResetPasswordModal, setOpenResetPasswordModal] = useState(false);
  const [checkoutRequestId, setCheckoutRequestId] = useState<string | null>(
    null,
  );
  const [rowSectionsItems, setRowSectionsItems] = useState<
    ResumentItem[] | undefined
  >([]);
  const [alertProps, setAlertProps] = useState<{
    isOpen: boolean;
    severity: 'success' | 'error' | 'info' | 'warning';
    message: string;
  }>({
    isOpen: false,
    severity: 'success',
    message: '',
  });
  const [openCheckoutModal, setOpenCheckoutModal] = useState(false);

  useEffect(() => {
    refreshCart();
  }, []);

  useEffect(() => {
    setRowSectionsItems([]);

    const items = cart?.items.reduce((acc, item) => {
      const sectionName = item.section.name;
      let existingSection = acc.find(
        (section) =>
          section.section === sectionName &&
          section.price === item.section.price,
      );

      existingSection = existingSection ?? {
        section: sectionName,
        price: item.section.price,
        quantity: 0,
      };

      if (existingSection.quantity === 0) {
        acc.push(existingSection);
      }

      existingSection.quantity += 1;

      return acc;
    }, [] as ResumentItem[]);

    setRowSectionsItems(items);
  }, [cart]);

  useScrollTop();

  const handleOpenLoginModal = () => {
    setOpenLoginModal(true);
  };

  const handleCloseLoginModal = () => {
    setOpenLoginModal(false);
  };

  const handleOpenRegisterModal = () => {
    setOpenRegisterModal(true);
  };

  const handleCloseRegisterModal = () => {
    setOpenRegisterModal(false);
  };

  const handleOpenDeleteItemDialog = (cartItemId: string) => {
    setCartItemId(cartItemId);
    setOpenDeleteItemDialog(true);
  };

  const handleCloseDeleteItemDialog = () => {
    setCartItemId(null);
    setOpenDeleteItemDialog(false);
  };

  const handleOpenClearCartDialog = (cartId: string) => {
    setCartId(cartId);
    setOpenClearCartDialog(true);
  };

  const handleCloseClearCartDialog = () => {
    setCartId(null);
    setOpenClearCartDialog(false);
  };

  const handleCloseCheckoutModal = () => {
    setOpenCheckoutModal(false);
  };

  const handleOpenForgotPasswordModal = () => {
    setOpenForgotPasswordModal(true);
  };

  const handleCloseForgotPasswordModal = () => {
    setOpenForgotPasswordModal(false);
  };

  const handleCloseResetPasswordModal = () => {
    setOpenResetPasswordModal(false);
  };

  const handleCloseAlert = () => {
    setAlertProps({
      isOpen: false,
      severity: 'success',
      message: '',
    });
  };

  const { clearCartAction, deleteItemAction, createOrder } = useCartActions(
    refreshCart,
    setAlertProps,
    setLoading,
    handleCloseDeleteItemDialog,
    handleCloseClearCartDialog,
    setCheckoutRequestId,
    setOpenCheckoutModal,
  );

  return (
    <BoxMainContainer>
      {cartLoading && !cart && <CircularProgress />}
      {/* Cabecera del carrito */}
      <CartHeader cart={cart} />

      <Box
        sx={{
          width: '85%',
          height: 'auto',
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          justifyContent: 'space-between',
          gap: 2,
          mt: 2,
        }}
      >
        {/* Columna Izquierda: Datos del Evento + Boletos */}
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
          }}
        >
          {/* Tarjeta con info del evento */}
          {cart && cart.event && <EventInfoCard event={cart.event} />}

          {/* Lista de boletos (items) */}
          {cart && cart.items.length > 0 && (
            <CartItemsList
              items={cart.items}
              onDeleteItem={(itemId) => handleOpenDeleteItemDialog(itemId)}
            />
          )}
        </Box>

        {/* Columna Derecha: Tiempo, Resumen y Acciones */}
        <Box
          sx={{
            width: { xs: '100%', md: '39%' },
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
          }}
        >
          {cart && <CartTimer minutes={minutes} seconds={seconds} />}

          {cart && (
            <CartReviewSection
              rowSectionsItems={rowSectionsItems}
              totalPrice={cart.totalPrice}
              createOrder={() => createOrder(user, handleOpenLoginModal)}
              onOpenClearCartDialog={handleOpenClearCartDialog}
              cartId={cart.cartId}
              openLogin={handleOpenLoginModal}
              loading={loading}
            />
          )}
        </Box>
      </Box>
      {cartItemId && (
        <DefaultDialog
          title="Eliminar boleto"
          text="¿Estás seguro que deseas eliminar tu boleto?"
          isOpen={openDeleteItemDialog}
          onCancel={handleCloseDeleteItemDialog}
          onConfirm={() => deleteItemAction(cartItemId)}
          textConfirmButton="Eliminar"
          textCancelButton="Cancelar"
        />
      )}
      <AlertMessage
        isOpen={alertProps.isOpen}
        severity={alertProps.severity}
        message={alertProps.message}
        onClose={handleCloseAlert}
      />
      {cartId && (
        <ClearCartDialog
          title="Vaciar carrito"
          text="Estás a punto de vaciar el carrito. Esta acción no se puede deshacer ¿Desea continuar?"
          isOpen={openClearCartDialog}
          textCancelButton="Cancelar"
          textConfirmButton="Confirmar"
          onCancel={handleCloseClearCartDialog}
          onConfirm={() => clearCartAction(cartId)}
        />
      )}
      <LogInModal
        isOpen={openLoginModal}
        onCloseModal={handleCloseLoginModal}
        onOpenRegisterModal={handleOpenRegisterModal}
        onOpenForgotPasswordModal={handleOpenForgotPasswordModal}
      />
      <ResetPasswordModal
        isOpen={openResetPasswordModal}
        onCloseModal={handleCloseResetPasswordModal}
      />

      <SignUpModal
        isOpen={openRegisterModal}
        onOpenLoginModal={handleOpenLoginModal}
        onCloseModal={handleCloseRegisterModal}
      />

      {checkoutRequestId && (
        <ModalCheckoutDialog
          isOpen={openCheckoutModal}
          onClose={handleCloseCheckoutModal}
          checkoutId={checkoutRequestId}
          cardUpdatedAt={cart?.updatedAt || ''}
        />
      )}
    </BoxMainContainer>
  );
};
