import { Box, Typography } from '@mui/material';

const boxContainer = {
  display: 'flex',
  flexDirection: 'column',
  height: 'auto',
  justifyContent: 'center',
  alignItems: 'center',
  pt: 10,
};

const scrollbarStyles = {
  borderRadius: '20px',
  padding: '65px 45px',
  mb: 5,
  backgroundColor: '#FFFFFF',
  width: { xs: '65%', sm: '70%', md: '80%', xl: '85%' },
  overflowY: 'scroll',
  scrollBehavior: 'smooth',
  maxHeight: '100vh',
  outline: 'none',
  '::-webkit-scrollbar': {
    width: '16px',
    margin: '0 5px 0 0',
  },
  '::-webkit-scrollbar-track': {
    background: '#EBEBEB',
    borderRadius: '10px',
    // border: 'solid 2px #EBEBEB',
  },

  /* thumb */
  '::-webkit-scrollbar-thumb': {
    background: '#C33186',
    backgroundClip: 'padding-box',
    border: '4px solid transparent',
    borderRadius: '8px',
  },
  '::-webkit-scrollbar-thumb:hover': {
    background: '#C33186',
    border: '4px solid transparent',
    borderRadius: '8px',
  },
  '::-webkit-scrollbar-thumb:active': {
    background: '#C33186',
  },
  '::-webkit-scrollbar-thumb:vertical': {
    borderTop: 'solid 10px #EBEBEB',
    borderBottom: 'solid 10px #EBEBEB',
  },
  '::-webkit-scrollbar-thumb:horizontal': {
    borderLeft: 'solid 10px #EBEBEB',
    borderRight: 'solid 10px #EBEBEB',
  },

  /* buttons */
  '::-webkit-scrollbar-button': {
    width: '14px',
    height: '512px',
    borderRadius: '10px',
  },

  /* up */
  '::-webkit-scrollbar-button:vertical:decrement': {
    borderWidth: '16px 9px 16px 9px',
    borderColor: 'transparent transparent #404040 transparent',
  },
  '::-webkit-scrollbar-button:vertical:decrement:hover': {
    borderColor: 'transparent transparent #505050 transparent',
  },

  /* down */
  '::-webkit-scrollbar-button:vertical:increment': {
    borderWidth: '16px 9px 16px 9px',
    borderColor: '#C33186 transparent transparent transparent',
  },
  '::-webkit-scrollbar-button:vertical:increment:hover': {
    borderColor: '#C33186 transparent transparent transparent',
  },

  /* left */
  '::-webkit-scrollbar-button:horizontal:decrement': {
    borderWidth: '7px 14px 7px 0',
    borderColor: 'transparent #404040 transparent transparent',
  },
  '::-webkit-scrollbar-button:horizontal:decrement:hover': {
    borderColor: 'transparent #505050 transparent transparent',
  },

  /* right */
  '::-webkit-scrollbar-button:horizontal:increment': {
    borderWidth: '7px 0 7px 14px',
    borderColor: 'transparent transparent transparent #404040',
  },
  '::-webkit-scrollbar-button:horizontal:increment:hover': {
    borderColor: 'transparent transparent transparent #505050',
  },
};

export const TermsConditions = () => {
  return (
    <Box sx={boxContainer}>
      <Typography
        sx={{
          textTransform: 'uppercase',
          fontSize: { xs: '24px', sm: '30px', md: '35px' },
          fontWeight: 900,
          color: '#48002A',
          lineHeight: 1.3,
          marginTop: '20px',
          paddingLeft: { xs: '16px', sm: '32px', md: '100px' },
          paddingRight: { xs: '22px', md: '75px'},
          paddingBottom: '10px',
          alignSelf: 'self-start',
        }}
      >
        Términos y condiciones de uso de portal de internet y compraventa
        electrónica de boletos
      </Typography>
      <Box sx={scrollbarStyles}>
        <Typography
          sx={{
            fontSize: '15px',
            fontWeight: '300',
          }}
        >
          CONTRATO DE ADHESIÓN RESPECTO DE LA PÁGINA DE INTERNET
          WWW.TICKETTOGO.COM.MX (EN ADELANTE “EL PORTAL”) QUE CELEBRAN POR UNA
          PARTE LA SOCIEDAD CUATRO CUARENTA ENTRETENIMIENTO, S.A. DE C.V., EN
          ADELANTE DENOMINADA COMO “TICKETTOGO” Y, POR LA OTRA PARTE, EL USUARIO
          AL QUE EN LO SUCESIVO SE LE DENOMINARÁ COMO “EL USUARIO”, SUJETÁNDOSE
          AMBAS PARTES, A LO ESTABLECIDO EN EL TEXTO DEL PRESENTE CONTRATO.
          <br />
          <br />
          1. Al ingresar y utilizar este portal de internet, cuyo nombre de
          dominio es: www.tickettogo.com.mx, usted (EL USUARIO) está aceptando
          los términos y las condiciones contenidos en este contrato así como
          los avisos, información publicada, condiciones de uso general y
          particular e instrucciones publicadas en EL PORTAL por TICKETOGO,
          siendo que éstas forman parte de este Contrato y de igual manera, EL
          USUARIO declara expresamente su aceptación utilizando para tal efecto
          medios electrónicos, en términos de lo dispuesto por el artículo 1803
          y 1834 bis del Código Civil Federal, 80, 81, 89 y demás relativos y
          aplicables del Código de Comercio y demás relativos y aplicables de la
          legislación aplicable.
          <br />
          <br />
          2. Si EL USUARIO no acepta en forma absoluta y completa los términos y
          condiciones de este contrato, deberá abstenerse de acceder, usar y ver
          EL PORTAL; para el caso que el usuario continúe en el uso de EL PORTAL
          dicha acción se considerará como su absoluta y expresa aceptación a
          los términos y condiciones aquí estipulados.
          <br />
          <br />
          3. El sólo uso de EL PORTAL por EL USUARIO, le implica la aceptación,
          plena e incondicional, de todas y cada una de las condiciones
          generales y particulares incluidas en estos términos y condiciones de
          uso en la versión publicada y administrada por TICKETTOGO. Cualquier
          modificación a los presentes términos y condiciones será realizada
          cuando TICKETTOGO, lo considere apropiado, siendo exclusiva
          responsabilidad del usuario asegurarse de tomar conocimiento de tales
          modificaciones.
          <br />
          <br />
          4. EL USUARIO acepta ser una persona física, cuyos datos generales han
          sido registrados previamente en EL PORTAL y que dicha información es
          cierta, la cual señala desde este momento para obtener todo tipo de
          notificaciones o comunicados derivados de la aplicación de este
          Contrato.
          <br />
          <br />
          5. Que al no existir error, dolo, mala fe o cualquier otro vicio de la
          voluntad que pudiera nulificar la validez del presente Contrato, ambas
          partes acuerdan en sujetarse al tenor de lo estipulado en las
          siguientes:
          <br />
        </Typography>
        <Typography
          sx={{
            fontSize: '15px',
            fontWeight: '750',
            lineHeight: 5.55,
          }}
        >
          Clausulas:
        </Typography>
        <Typography
          sx={{
            fontSize: '15px',
            fontWeight: '750',
            lineHeight: 5.55,
          }}
        >
          PRIMERA.- DEFINICIONES
        </Typography>
        <Typography sx={{ fontSize: '15px', fontWeight: '300' }}>
          Ya sea establecidas como singular o plural, masculino o femenino, o en
          las conjugaciones de sus verbos, los siguientes conceptos tendrán el
          significado referido a continuación:
        </Typography>
        <br />
        <br />
        <Typography sx={{ fontSize: '15px', fontWeight: '300', pl: 2.5 }}>
          1) Boletos: Significan los cupones impresos que otorgan a su portador
          el acceso a el evento que se establece en dicho documento. <br />
          <br />
          2) Cargo por Servicio: Significa la cantidad señalada en EL PORTAL que
          será pagada por EL USUARIO a TICKETTOGO por la prestación de los
          Servicios, incluyendo el correspondiente Impuesto al Valor Agregado.
          <br />
          <br />
          3) Cookie: Es un archivo que es almacenado en el disco duro de la
          computadora de EL USUARIO; contiene caracteres particulares de EL
          USUARIO de que se trate y el uso que éste da al sitio. El contenido
          puede o no tener información que identifica personalmente a EL
          USUARIO. <br />
          <br />
          4) Complejo: Establecimiento de TICKETTOGO elegido por EL USUARIO para
          recoger los boletos adquiridos a través de EL PORTAL. <br />
          <br />
          5) El portal: www. tickettogo.com.mx <br />
          <br />
          6) El usuario: Persona física registrada en EL PORTAL, que ha cumplido
          los requisitos y provisto información requerida en EL PORTAL por
          TICKETTOGO y habilitada para utilizar los Servicios, requisitando
          asimismo la solicitud de compra o formatos adicionales que establece
          EL PORTAL para cada caso. <br />
          <br />
          7) Información Personal: Aquella que individualmente identifica a EL
          USUARIO, como: nombre, dirección o correo electrónico, entre otras.
          <br />
          <br />
          8) Mal Uso: Se entenderá hacer uso sin autorización del titular de los
          derechos de propiedad intelectual, de las marcas, imágenes, obras y
          productos que aparezcan en la página de EL PORTAL; alterar o modificar
          los sistemas de seguridad EL PORTAL; manipular la información,
          términos, condiciones o lineamientos que se mencionen en EL PORTAL;
          incurrir en actos que se consideren como ingeniería en reversa y/o
          inversa; utilizar sin autorización de su titular una cuenta de EL
          USUARIO para solicitar los servicios; utilizar sin autorización de su
          titular una tarjeta de crédito para solicitar los servicios. <br />
          <br />
          9) Partes: TICKETTOGO y EL USUARIO. <br />
          <br />
          10) Precio Unitario: Es la cantidad señalada en EL PORTAL para cada
          boleto, más el correspondiente Impuesto al Valor Agregado. El Precio
          Unitario se cobrará en Pesos (moneda del curso legal de la República
          Mexicana). <br />
          <br />
          11) Servicios: compraventa electrónica de boletos. <br />
          <br />
          12) Sitios Enlazados: A través de éstos, TICKETTOGO pone a disposición
          de EL USUARIO, dispositivos técnicos de enlace, tales como,
          hipervínculos (links), anuncios (banners), botones, directorios y/o
          herramientas de búsqueda, entre otros, que les permiten acceder a
          páginas de Internet pertenecientes a terceros. <br />
          <br />
          13) TICKETTOGO: 440 ENTRETENIMIENTO, S.A. DE C.V., sus subsidiarias
          y/o afiliadas.
        </Typography>
        <Typography
          sx={{
            fontSize: '15px',
            fontWeight: '750',
            lineHeight: 5.55,
          }}
        >
          SEGUNDA.- USO PERMITIDO DEL PORTAL
        </Typography>
        <Typography
          sx={{
            fontSize: '15px',
            fontWeight: '300',
            pl: 2.5,
          }}
        >
          1) En este acto EL USUARIO manifiesta plenamente que conoce que
          únicamente está autorizado a visitar, ver y consultar las páginas de
          EL PORTAL para su propio uso, y que no deberá duplicar, descargar,
          publicar, modificar o distribuir de cualquier otra manera alterar el
          contenido de EL PORTAL, o del material contenido en EL PORTAL para
          ningún propósito distinto que no sea revisar eventos e información de
          publicidad, para uso personal, o para comprar boletos a menos de que
          haya sido autorizado específicamente por TICKETTOGO. El contenido y
          software de EL PORTAL es propiedad de TICKETTOGO y/o sus proveedores y
          está protegido bajo las leyes internacionales y nacionales de derecho
          de autor. TICKETTOGO ha publicado un aviso legal y varios títulos de
          crédito en las páginas de EL PORTAL, estos no deberán ser removidos,
          por lo que EL USUARIO no eliminará este aviso o estos créditos, o
          cualquier información adicional que venga incluida con los avisos y
          créditos. <br />
          <br />
          2) Los Servicios, son exclusivamente para uso personal y de ninguna
          forma podrán ser para uso comercial o de cualquier otro tipo. No se
          pueden utilizar los Servicios para vender o promocionar o
          comercializar un producto o servicio propio o de terceros, o para
          incrementar el tráfico a cualquier otro sitio o página de Internet
          para fines comerciales o cualquier distinto a personal y privado,
          salvo que exista un acuerdo expreso por anticipado con TICKETTOGO.
          <br />
          <br />
          3) TICKETTOGO se reserva el derecho de modificar, actualizar o dar por
          terminados, total o parcialmente, en cualquier tiempo, los Términos
          y/o los Servicios, por cualquier causa y sin responsabilidad alguna
          para TICKETTOGO. <br />
          <br />
          4) Las Partes aceptan y reconocen que TICKETTOGO se encuentra
          debidamente autorizado para hacer uso de la información que EL USUARIO
          registre en EL PORTAL, siempre y cuando el uso de dicha información se
          limite a facilitar el envío a EL USUARIO de información sobre
          promociones, productos o servicios, que TICKETTOGO comercialice, de
          tiempo en tiempo. TICKETTOGO utilizará la información en concordancia
          con los Términos y Condiciones, así como con la Política de Privacidad
          publicada en EL PORTAL. <br />
          <br />
          5) TICKETTOGO se reserva el derecho de diferir, suspender o denegar la
          prestación de los Servicios, en tanto EL USUARIO no cumpla con las
          condiciones y requisitos exigidos por TICKETTOGO en EL PORTAL. <br />
          <br />
          6) TICKETTOGO se reserva el derecho de suspender, modificar o terminar
          en cualquier momento, total o parcialmente, los Servicios, sin
          responsabilidad alguna y sin mayor formalidad que las que las
          disposiciones vigentes así pudieran establecer. <br />
          <br />
          7) TICKETTOGO se reserva el derecho de hacer cambios a la página de
          Internet de EL PORTAL, así como a realizar exclusiones o
          modificaciones a los Términos y Condiciones, en cualquier tiempo.
          <br />
          <br />
          8) TICKETTOGO podrá suspender los Servicios en cualquier momento, por
          disposición legal, en caso de que EL USUARIO haga Mal Uso de EL PORTAL
          o en caso de detectar cualquier irregularidad con EL USUARIO. Todo lo
          anterior, no obstante las acciones, vías y/o derechos que TICKETTOGO
          y/o las personas físicas o morales tengan que hacer valer en contra
          del responsable del Mal Uso o irregularidad referida. <br />
          <br />
          9) TICKETTOGO se reserva el derecho a modificar en cualquier momento
          los Términos y/o los Servicios y a notificarle dicho acto a EL USUARIO
          a través de la exposición de una versión actualizada los mismos en
          este EL PORTAL. <br />
          <br />
          10) Es responsabilidad de EL USUARIO revisar periódicamente estos
          Términos, a fin de estar enterado de los Términos vigentes en cada
          momento. <br />
          <br />
          11) El uso del Servicio posterior a las modificaciones a los Términos
          y/o Servicios, implica el consentimiento de EL USUARIO a dichos
          cambios. <br />
          <br />
          12) Los materiales e información presentados en EL PORTAL, son
          propiedad de TICKETTOGO y/o de sus autores y/o de terceros que
          contratan con TICKETTOGO la colocación de determinado material en EL
          PORTAL de TICKETTOGO, y son utilizados para la promoción de sus
          productos y/o servicios o de los productos y/o servicios de dichos
          terceros. <br />
          <br />
          13) TICKETTOGO es propietario de todos los derechos de propiedad
          intelectual, industrial y/o derecho de autor, relativos al diseño,
          funciones y/u operaciones que integran EL PORTAL, por lo que el uso
          del mismo no constituye una licencia a EL USUARIO para utilizar el
          nombre, diseños, signos distintivos, marcas y/o cualquier otro
          aplicable, propiedad de TICKETTOGO. <br />
          <br />
          14) EL USUARIO acepta que ni los Términos, ni el Contrato ni el uso
          que realice del Servicio, crean entre TICKETTOGO y dicho USUARIO una
          asociación, sociedad, relación laboral, agencia, comisión o cualquier
          similar. Asimismo, EL USUARIO acepta que el presente documento no
          constituye una licencia, cesión o transmisión de ningún tipo de
          derecho.
        </Typography>
        <Typography
          sx={{
            fontSize: '15px',
            fontWeight: '750',
            lineHeight: 5.55,
          }}
        >
          TERCERA: COMPRAVENTA ELECTRÓNICA DE BOLETOS.
        </Typography>
        <Typography
          sx={{
            fontSize: '15px',
            fontWeight: '300',
            pl: 2.5,
          }}
        >
          1) La venta de boletos a eventos de entretenimiento está regulada a
          nivel estatal, municipal o en su caso federal. TICKETTOGO podrá pedir
          a EL USUARIO que proporcione cierta información cuando se involucre en
          ciertos procesos de EL PORTAL. En este acto EL USUARIO manifiesta y
          garantiza que toda la información que proporciona es verdadera,
          completa y correcta, y que actualizarás toda la información a medida
          que cambie. EL USUARIO también reconoce que cumplirá en todo momento
          con los términos, condiciones y leyes que rigen el presente servicio,
          y manifiesta estar de ACUERDO EN LIBERAR A TICKETTOGO DE CUALQUIER
          RESPONSABILIDAD, DERIVADAS DE LA FALTAS COMETIDAS POR EL USUARIO EN
          CONTRA DE CUALQUIER LEY, TICKETTOGO CUMPLIRÁ CON LAS AUTORIDADES QUE
          ASÍ LO REQUIERAN Y LES PODRÁ PROPORCIONAR TODA LA INFORMACIÓN QUE EL
          USUARIO HAYA REMITIDO PARA AYUDAR EN CUALQUIER INVESTIGACIÓN O PROCESO
          QUE PUEDAN LLEVAR A CABO. Si TICKETTOGO no puede verificar o comprobar
          la autenticidad de cualquier información o boletos que nos
          proporciones durante cualquier proceso de inscripción, pedido, compra,
          publicación de boletos, venta, verificación de autenticidad, entrega,
          pago o proceso de remesa, o cualquier otro proceso no mencionado aquí,
          o si no puede verificar o autorizar tu tarjeta de crédito o
          información de cuenta bancaria, entonces se prohibirá a EL USUARIO el
          uso de EL PORTAL. <br />
          <br />
          2) Una vez que EL USUARIO hace uso de EL PORTAL, en caso que decida
          realizar la compraventa electrónica de boletos para eventos que así
          estén disponibles en EL PORTAL, TICKETTOGO acepta vender y EL USUARIO
          acepta comprar, la cantidad de boletos y/o productos seleccionados por
          EL USUARIO en EL PORTAL, acorde con el cumplimiento de requisitos que
          se establezcan en cada caso. A partir de ese momento, los presentes
          Términos se establecen en un contrato válido y vigente entre
          TICKETTOGO y EL USUARIO. <br />
          <br />
          3) TICKETTOGO únicamente distribuye boletos de eventos ofrecidos, por
          lo que no es responsable del contenido ni de los horarios de los
          mismos. <br />
          <br />
          4) EL USUARIO se obliga a requisar la información solicitada en EL
          PORTAL, a efecto de poder realizar cualquier tipo de compra o
          solicitud de información a través de EL PORTAL. <br />
          <br />
          5) TICKETTOGO, a través de EL PORTAL, proporcionará a EL USUARIO los
          lugares disponibles en el evento al momento de realizar la compra, de
          acuerdo al precio solicitado, a menos que EL USUARIO haya optado por
          elegir directamente los lugares a través de la funcionalidad
          disponible para tal efecto a través de EL PORTAL. <br />
          <br />
          6) El número de boletos adquiribles por EL USUARIO está limitado. Si
          en virtud de una o más operaciones de compra se excede el límite de
          boletos adquiribles por EL USUARIO, las solicitudes de compra serán
          canceladas sin previo aviso y sin responsabilidad para TICKETTOGO. En
          el caso que se hubiere hecho algún cargo a la tarjeta de crédito o
          débito del Cliente por las compras que se cancelen por los supuestos
          previstos en este párrafo, se reembolsará EL USUARIO la totalidad de
          los cargos realizados.
          <br />
          <br />
          7) La compra de boletos a través de EL PORTAL de TICKETTOGO generará
          un cargo por servicio y un cargo por entrega de boletos, el cual en
          este último caso puede variar dependiendo del tipo de entrega
          seleccionado por EL USUARIO y autorizado por TICKETTOGO, en adición al
          precio unitario de cada boleto. Los cargos por servicio y por entrega
          de boletos aplicarán para cada boleto adquirido a través de
          TICKETTOGO. <br />
          <br />
          8) Una vez que EL USUARIO: (i) se haya registrado como usuario de EL
          PORTAL, (ii) haya cumplido con las condiciones y requisitos exigidos;
          y (iii) después de haber seleccionado el evento y el número de
          boletos, TICKETTOGO a través de EL PORTAL le presentará en pantalla a
          EL USUARIO un mensaje con confirmación y/o algún similar, que
          documentará la aceptación de TICKETTOGO a la solicitud de compra de EL
          USUARIO. Dicho mensaje con confirmación, contendrá una clave única y
          establecerá los términos y condiciones bajo los cuales se hará la
          entrega de los boletos. <br />
          <br />
          9) Una vez requisitada y aceptada la solicitud electrónica de compra
          de los Servicios por TICKETTOGO a través del mensaje con confirmación,
          EL USUARIO se compromete a cumplir con su obligación de pagar a
          TICKETTOGO el precio unitario de cada uno de los boleto más el cargo
          por servicios correspondiente, independientemente de que haga uso o no
          de los mismos. <br />
          <br />
          10) El pago a que hace referencia en el subinciso inmediato anterior,
          se efectuará con cargo directo a la tarjeta de crédito de EL USUARIO,
          o a aquel método de pago que TICKETTOGO pueda tener vigente en dicho
          momento, razón por la cual, como parte de su solicitud de compra
          electrónica, deberá proporcionar el número de tarjeta de crédito
          correspondiente, así como los datos que sobre la misma le sean
          requeridos o aquellos otros aplicables. <br />
          <br />
          11) El perfeccionamiento, finalización o realización de la compra de
          boletos a través del sistema de TICKETTOGO está sujeta a la
          comprobación de los datos personales y de la tarjeta proporcionados
          por EL USUARIO y a la autorización por parte del banco emisor de la
          tarjeta de crédito o débito cuyos datos ha proporcionado EL USUARIO
          para el pago de los boletos solicitados o por parte del banco
          aceptante. Si los datos personales o de la tarjeta de crédito
          proporcionados por EL USUARIO no coinciden con los datos a disposición
          del banco emisor de la tarjeta de crédito o débito o, aun coincidiendo
          los datos en cuestión, el banco emisor o el banco aceptante no
          autorizan el cargo solicitado por EL USUARIO, la compra no será
          procesada ni finalizada y los boletos serán ofrecidos para venta al
          público sin responsabilidad alguna para TICKETTOGO. <br />
          <br />
          12) Sin perjuicio de lo anterior, EL USUARIO expresamente reconoce y
          acepta que la solicitud de compra de los boletos que realiza a través
          de EL PORTAL de TICKETTOGO es una oferta vinculante y que una vez
          recibida la autorización del banco emisor de la tarjeta de crédito o
          débito correspondiente o del banco aceptante, la operación de compra
          será definitiva y no estará sujeta a cambios, reembolsos, devoluciones
          o cancelaciones. <br />
          <br />
          13) EL USUARIO libera de responsabilidad y se compromete a sacar en
          paz y a salvo a TICKETTOGO por cualquier demanda, reclamo, daño o
          perjuicio sufrido, derivado del uso de la tarjeta de crédito que EL
          USUARIO utilice para solicitar los Servicios. <br />
          <br />
          14) Una vez confirmada la compra, TICKETTOGO, a través de EL PORTAL,
          proporcionará a EL USUARIO las opciones de (a) entrega física de
          boletos ó (b) entrega electrónica e impresión directa de boleto. En
          ambos casos, TICKETTOGO se enviará a EL USUARIO un mensaje a su correo
          electrónico con los términos y condiciones de la transacción. En caso
          que EL USUARIO haya optado por elegir opción (a), EL USUARIO deberá
          presentarse en el complejo o lugar que le sea indicado por TICKETTOGO
          a recoger los boletos que hubiera adquirido a través de EL PORTAL,
          sujeto, entre otros, a los términos y condiciones señaladas al efecto
          en su mensaje de correo electrónico de confirmación. <br />
          <br />
          15) TICKETTOGO no se encuentra obligado a entregar boleto alguno, en
          tanto no exista certeza de que la contraprestación por el pago de los
          Servicios haya tenido verificativo. <br />
          <br />
          16) Para la entrega física de boletos, TICKETTOGO requerirá, entre
          otros, que EL USUARIO presente la tarjeta de pago, identificación
          oficial y mensaje de correo electrónico de confirmación para recoger
          los boletos comprados. <br />
          <br />
          17) TICKETTOGO no realiza reposición de boletos si éstos han sido
          perdidos, robados o dañados, quedando éstos bajo la responsabilidad de
          EL USUARIO. <br />
          <br />
          18) EL USUARIO expresamente reconoce y acepta que no tendrá derecho a
          cambios, reembolsos, devoluciones o cancelaciones aún en el supuesto
          de que no recoja o imprima los boletos adquiridos o no haga uso de
          ellos. <br />
          <br />
          19) EL USUARIO expresamente reconoce y acepta que sólo tendrá derecho
          al reembolso del costo de los boletos en caso que se cancele el evento
          para el cual adquirió los boletos, en el entendido que en ese supuesto
          EL USUARIO NO tendrá derecho a que se le reembolsen los cargos por
          servicios y envío de TICKETTOGO.
        </Typography>
        <Typography
          sx={{
            fontSize: '15px',
            fontWeight: '750',
            lineHeight: 5.55,
          }}
        >
          CUARTA.- EXENCIÓN DE RESPONSABILIDADES
        </Typography>
        <Typography
          sx={{
            fontSize: '15px',
            fontWeight: '300',
            pl: 2.5,
          }}
        >
          1) TICKETTOGO NO SE OBLIGA A QUE EL SITIO ESTE LIBRE DE ERRORES, QUE
          NO TENDRÁ INTERRUPCIONES, O QUE PROPORCIONE RESULTADOS ESPECÍFICOS POR
          EL USO DE EL PORTAL O POR CUALQUIER CONTENIDO, BÚSQUEDA O ENCLACE EN
          EL PORTAL Y SUS CONTENIDOS SON ENTREGADOS "COMO ESTÁ" Y "COMO ESTÉ
          DISPONIBLE". TICKETTOGO NO PUEDE ASEGURAR QUE LOS ARCHIVOS QUE
          DESCARGE DEL SITIO ESTARÁN LIBRES DE VIRUS O DE CONTAMINACIÓN O DE
          CARACTERÍSTICAS DESTRUCTIVAS. TICKETTOGO SE LIBERA DE TODA
          RESPONSABILIDAD DE GARANTÍAS, TICKETTOGO NO SERÁ RESPONSABLE POR
          NINGUN DAÑO DE CUALQUIER TIPO QUE SURJA DEL USO DE ESTE SITIO,
          INCLUYENDO SIN LIMITACIÓN, DAÑOS DIRECTOS, INDIRECTOS, INCIDENTALES,
          PUNITIVOS Y CONSECUENCIALES. TICKETTOGO NO GARANTIZA NINGÚ RESULTADO
          ESPECÍFICO DEL USO DE ESTE PORTAL O EL USO DE LOS SERVICIOS DE
          TICKETTOGO. <br />
          <br />
          2) TICKETTOGO SE LIBERA DE CUALQUIER Y TODA RESPONSABILIDAD POR
          CUALQUIER HECHO, OMISIÓN Y CONDUCTA POR PARTE DE CUALQUIER USUARIO DE
          TERCEROS, USUARIO DE TICKETTOGO, PUBLICISTAS Y/O PATROCINADORES DE EL
          PORTAL, EN CONEXIÓN CON EL SERVICIO DE TICKETTOGO O DE CUALQUIER OTRA
          FORMA RELACIONADA CON SU USO DEL SITIO Y/O DEL SERVICIO DE TICKETTOGO.
          TICKETTOGO NO ES RESPONSABLE POR LOS PRODUCTOS, SERVICIOS, ACCIONES O
          FALTA DE ACCIÓN DE CUALQUIER LUGAR, ARTISTA, PROMOTOR O CUALQUIER
          TERCERO EN CONEXIÓN CON EL PORTAL O AL QUE SE HA HECHO REFERENCIA EN
          EL PORTAL. <br />
          <br />
          3) Sin prejuicio de lo anteriormente mencionado, EL USUARIO puede
          reportar la mala conducta de usuarios y/o publicistas de terceros,
          proveedores de servicios y/o productos a los que se hace referencia o
          que están incluidos en EL PORTAL de TICKETTOGO. TICKETTOGO puede
          investigar el reclamo y tomar la acción adecuada, a su plena
          discreción.
        </Typography>
        <Typography
          sx={{
            fontSize: '15px',
            fontWeight: '750',
            lineHeight: 5.55,
          }}
        >
          QUINTA.- LÍMITES DE RESPONSABILIDAD
        </Typography>
        <Typography
          sx={{
            fontSize: '15px',
            fontWeight: '300',
            pl: 2.5,
          }}
        >
          1) BAJO NINGUNA CIRCUNSTANCIA TICKETTOGO SERÁ RESPONSABLE DE CUALQUIER
          DAÑO INDIRECTO, CONSECUENCIAL, EJEMPLAR, INCIDENTAL, ESPECIAL O
          PUNITIVO, O POR LA PÉRDIDA DE GANANCIAS, INGRESOS U OPORTUNIDADES DE
          NEGOCIOS, AUN CUANDO SE LE HAYA AVISADO A TICKETTOGO DE LAS
          POSIBILIDADES DE TALES DAÑOS.
        </Typography>
        <Typography
          sx={{
            fontSize: '15px',
            fontWeight: '750',
            textTransform: 'uppercase',
            lineHeight: 5.55,
          }}
        >
          SEXTA.- PROPIEDAD INTELECTUAL
        </Typography>
        <Typography
          sx={{
            fontSize: '15px',
            fontWeight: '300',
            pl: 2.5,
          }}
        >
          1) Mediante el uso de EL PORTAL y los Servicios, EL USUARIO reconoce y
          acepta que TICKETTOGO queda excluida de cualquier responsabilidad que
          pudiera ser causada por el uso no autorizado de las marcas u otros
          derechos de propiedad intelectual, industrial y/o derechos de autor de
          terceros o contenidos en los Sitios Enlazados. <br />
          <br />
          2) Las eventuales referencias que se hagan en EL PORTAL a cualquier
          producto, servicio, proceso, Sitio Enlazado, hipertexto, marca, nombre
          comercial o cualquier otra información en la que se utilicen marcas,
          signos distintivos y/o dominios, el nombre comercial o el nombre del
          fabricante, suministrador, etc., que sean titularidad de terceros, en
          ningún momento constituirán, ni implicarán respaldo o recomendación
          alguna por parte de TICKETTOGO, y en ningún caso TICKETTOGO se asigna
          propiedad ni responsabilidad sobre los mismos, salvo estipulación
          expresa en contrario.
        </Typography>
        <Typography
          sx={{
            fontSize: '15px',
            fontWeight: '750',
            textTransform: 'uppercase',
            lineHeight: 5.55,
          }}
        >
          SÉPTIMA.- SITIOS ENLAZADOS
        </Typography>
        <Typography
          sx={{
            fontSize: '15px',
            fontWeight: '300',
            pl: 2.5,
          }}
        >
          1) El uso de EL PORTAL es bajo riesgo y responsabilidad de EL USUARIO,
          la responsabilidad de TICKETTOGO se limita en todo momento a los
          presentes términos vigentes en cada momento, así como al cumplimiento
          de las disposiciones aplicables en aquello no establecido en los
          términos. <br />
          <br />
          2) Los Sitios Enlazados pueden permitirle a EL USUARIO abandonar EL
          PORTAL propiedad de TICKETTOGO. Dichos Sitios Enlazados no son
          controlados por TICKETTOGO, no siendo éste responsable del contenido
          de los mismos ni de cualquier aspecto que con ellos se vincule directa
          o indirectamente. <br />
          <br />
          3) Los Sitios Enlazados en EL PORTAL, se limita a facilitar a EL
          USUARIO la búsqueda de y/o acceso en internet, a la información
          disponible de dichos Sitios Enlazados; no presupone, ni se establece
          explícitamente, la existencia de alguna clase de vínculo, comisión,
          agencia, distribución, comercialización, responsabilidad, obligación o
          asociación entre TICKETTOGO y los operadores, sociedades, individuos
          y/o cualesquier tercero, de los Sitios Enlazados y/o los terceros
          propietarios de dichos Sitios Enlazados. <br />
          <br />
          4) TICKETTOGO no controla, aprueba ni hace propios los servicios,
          información, datos, archivos, productos y/o cualquier clase de
          material existente en los Sitios Enlazados, incluyendo ofertas,
          información, datos, concursos y/o promociones. EL USUARIO, por lo
          tanto, debe extremar la prudencia en la valoración y utilización de
          cualquier clase de material existente en los Sitios Enlazados. <br />
          <br />
          5) TICKETTOGO no garantiza ni asume responsabilidad alguna por los
          daños y/o perjuicios de toda clase que puedan causarse por:
        </Typography>
        <br />
        <Typography
          sx={{
            fontSize: '15px',
            fontWeight: '300',
            pl: 4.5,
          }}
        >
          • El funcionamiento, disponibilidad, accesibilidad o continuidad de
          los Sitios Enlazados. <br /> • El mantenimiento de los servicios,
          información, datos, archivos, productos y/o cualquier clase de
          material existente en los Sitios Enlazados. <br /> • Las obligaciones,
          ofertas, concursos y/o promociones existentes en los Sitios Enlazados.{' '}
          <br /> • Utilidad, veracidad, parcialidad, objetividad, entre otros,
          de la información contenida en los Sitios Enlazados. <br /> • Virus
          generados o trasladados por cualquiera de los Sitios Enlazados.
        </Typography>
        <br />
        <Typography
          sx={{
            fontSize: '15px',
            fontWeight: '300',
            pl: 2.5,
          }}
        >
          6) Mediante la utilización de los Servicios, EL USUARIO reconoce y
          acepta que TICKETTOGO no garantiza de forma alguna, los Sitios
          Enlazados o la información proporcionada por terceros; de igual forma,
          no garantiza que los Sitios Enlazados funcionen correctamente, sean
          útiles para la realización de actividad alguna en particular o para
          cualquier otro fin, estén libres de información dañina, entre otros.
          <br />
          <br />
          7) Mediante el uso de los Servicios, EL USUARIO reconoce y acepta que
          TICKETTOGO queda excluida de cualquier responsabilidad por los daños y
          perjuicios que pudieran haber sido causados por la veracidad de la
          información o calidad de servicios contenidos u ofrecidos por terceros
          o que se encuentre en los Sitios Enlazados, o los que surjan con
          relación a EL PORTAL.
          <br />
          <br />
          8) Tanto el acceso a EL PROTAL o a los Sitios Enlazados, como el uso
          que pueda hacerse de la información contenida en los mismos, son
          exclusiva responsabilidad de EL USUARIO y no de TICKETTOGO.
          <br />
          <br />
          9) TICKETTOGO no es responsable de la información recibida de
          cualquier Sitio Enlazado. TICKETTOGO facilita a EL USUARIO estos
          vínculos como parte de los Servicios, en tal virtud, la inclusión de
          cualquier Sitio Enlazado no implica la aprobación de TICKETTOGO
          respecto de la información que ahí se contenga, asimismo, no implica
          relación alguna entre TICKETTOGO y el operador o propietario del
          mismo.
        </Typography>
        <Typography
          sx={{
            fontSize: '15px',
            fontWeight: '750',
            textTransform: 'uppercase',
            lineHeight: 5.55,
          }}
        >
          OCTAVA.- PARTICIPACIÓN EN PROMOCIONES DE LOS ANUNCIANTES
        </Typography>
        <Typography
          sx={{
            fontSize: '15px',
            fontWeight: '300',
            pl: 2.5,
          }}
        >
          1) Cualquier transacción con los anunciantes de EL PORTAL,
          participación en promociones, incluidos la entrega y el pago de bienes
          y servicios y cualesquiera otros términos o condiciones, garantías o
          declaraciones relativas a estas transacciones o promociones, se
          realizan única y exclusivamente entre EL USUARIO y el anunciante o
          cualquier otro tercero.
          <br />
          <br />
          2) TICKETTOGO no será de forma alguna, responsable de dichas
          transacciones o promociones.
        </Typography>
        <Typography
          sx={{
            fontSize: '15px',
            fontWeight: '750',
            textTransform: 'uppercase',
            lineHeight: 5.55,
          }}
        >
          NOVENA.- POLÍTICA DE PRIVACIDAD
        </Typography>
        <Typography
          sx={{
            fontSize: '15px',
            fontWeight: '300',
            pl: 2.5,
          }}
        >
          1) TICKETTOGO reconoce la importancia de la privacidad de EL USUARIO,
          por lo que los Servicios están diseñados considerando la protección de
          la información que es proveída a TICKETTOGO. <br />
          <br />
          2) La Política de Privacidad de TICKETTOGO describe los tipos de
          Información Personal que recolectamos de EL USUARIO cuando utilizan
          nuestros Servicios, así como el destino y salvaguarda de la misma.
          <br />
          <br />
          3) Nuestra Política de Privacidad esta basada en lo siguiente:
        </Typography>
        <br />
        <Typography
          sx={{
            fontSize: '15px',
            fontWeight: '300',
            pl: 4,
          }}
        >
          a. Recolección de Información.
          <br />
          i. Aquellos Servicios que no requieren autentificar a EL USUARIO,
          recolectan información general y no Información Personal, relativa al
          navegador que se utiliza, el tipo de conexión a Internet, el sistema
          operativo y otros elementos de configuración destinados a mejorar
          nuestros Servicios. Esta información puede ser guardada en una Cookie
          en la computadora de EL USUARIO, para futura referencia al navegar en
          EL PORTAL.
          <br />
          ii. Algunos de nuestros Servicios requieren explícitamente que EL
          USUARIO abra una cuenta en EL PROTAL y/o proporcione Información
          Personal, en virtud de la funcionalidad de EL PORTAL o por disposición
          legal aplicable. En este caso, EL PORTAL solicita algunos datos para
          la cuenta como nombre, dirección, edad, y sexo, entre otros,
          incluyendo Información Personal. Esta Información Personal puede
          solicitarse a EL USUARIO al entrar a EL PORTAL o cuando solicita un
          Servicio.
          <br />
          iii. Para algunos Servicios, como la compra de boletos en línea,
          requerimos información de tarjeta de crédito u otras formas de pago,
          entre otros, la cual es contenida en forma cifrada en servidores y
          equipo seguros, en cumplimiento de las disposiciones legales
          aplicables.
          <br />
          iv. Al momento de registrarse en EL PORTAL, EL USUARIO tiene la opción
          de solicitar no recibir correos electrónicos por parte de TICKETTOGO
          ni de terceros.
          <br />
          <br />
        </Typography>

        <Typography
          sx={{
            fontSize: '15px',
            fontWeight: '300',
            pl: 2.5,
          }}
        >
          <br />
          b. Cookies. i. Son utilizadas para mejorar la calidad del Servicio y
          para entender mejor la forma en que EL USUARIO interactúa con
          TICKETTOGO. Esto se hace almacenando preferencias y otros datos de EL
          USUARIO en las Cookies. La mayoría de los navegadores están
          configurados para aceptar Cookies. EL USUARIO puede deshabilitar esta
          aceptación automática en la configuración de su navegador, con la
          advertencia de que algunos de los Servicios ofrecidos pueden no
          funcionar adecuadamente sin dichas Cookies.
          <br />
          ii. EL USUARIO reconoce y acepta que TICKETTOGO puede colocar Cookies
          en su computadora, así como permitir que terceros que se anuncian en
          EL PORTAL, de igual forma lo hagan.
          <br />
        </Typography>

        <Typography
          sx={{
            fontSize: '15px',
            fontWeight: '300',
            pl: 2.5,
          }}
        >
          <br />
          c. Información compartida. i. TICKETTOGO no vende ni renta la
          Información Personal a otras personas físicas o morales. En todo caso,
          TICKETTOGO podrá compartir la información personal identificable de EL
          USUARIO, en cualquiera de los siguientes casos:
          <br />
          1. Contamos con el consentimiento de EL USUARIO.
          <br />
          2. Proveemos dicha información, Información Personal o no, con
          personas morales o físicas de confianza, para el sólo hecho de
          procesar la información por encargo de TICKETTOGO. Cuando esto sucede,
          se está bajo acuerdos que obligan a tales personas morales o
          individuos a procesar dicha información solamente bajo instrucciones
          de TICKETTOGO y en concordancia con esta Política de Privacidad,
          Términos y medidas apropiadas de confidencialidad y seguridad.
          <br />
          3. Si TICKETTOGO es requerido por Ley o, estamos en el entendido en
          buena fe, que el acceso, preservación o divulgación de dicha
          información, incluso Información Personal, es razonablemente necesario
          para proteger los derechos de TICKETTOGO, sus Usuarios o el público en
          general.
          <br />
          4. Cuando se trate de compartir información estadística de EL PORTAL y
          nuestros Usuarios. Esta información no identifica a EL USUARIO, pero
          sí a las tendencias y comportamientos del mismo. <br />
        </Typography>

        <Typography
          sx={{
            fontSize: '15px',
            fontWeight: '300',
            pl: 2.5,
          }}
        >
          <br />
          d. Seguridad de la información. i. TICKETTOGO toma las medidas
          conducentes para proteger la Información Personal y sus bases de
          datos, contra acceso o alteración no autorizados, divulgación o
          destrucción de información.
          <br />
          ii. TICKETTOGO restringe el acceso a la Información Personal,
          solamente a aquellos empleados que requieren conocerla con objeto de
          operar, desarrollar o mejorar nuestros Servicios.
          <br />
        </Typography>
        <Typography
          sx={{
            fontSize: '15px',
            fontWeight: '300',
            pl: 2.5,
          }}
        >
          <br />
          e. Actualización de la Información
          <br />
          i. TICKETTOGO provee a EL USUARIO, mecanismos de actualización o
          corrección de la Información Personal previamente proveída, la cual es
          utilizada en nuestros Servicios.
        </Typography>

        <Typography
          sx={{
            fontSize: '15px',
            fontWeight: '750',
            textTransform: 'uppercase',
            lineHeight: 5.55,
          }}
        >
          DÉCIMA.- CAMBIOS A LA POLÍTICA DE PRIVACIDAD
        </Typography>
        <Typography
          sx={{
            fontSize: '15px',
            fontWeight: '300',
            pl: 2.5,
          }}
        >
          1) La Política de Privacidad, así como los Términos, pueden cambiar en
          cualquier momento por cualquier causa. Normalmente los cambios serán
          menores, sin embargo, en algunas ocasiones habrá cambios
          significativos. Conforme realicemos dichos cambios, significativos o
          no, los publicaremos en EL PORTAL de que se trate; si los cambios son
          significativos, podremos dar a nuestros Usuarios algún otro tipo de
          aviso o notificación, sin que ocurra en todos los casos.
        </Typography>
        <Typography
          sx={{
            fontSize: '15px',
            fontWeight: '750',
            textTransform: 'uppercase',
            lineHeight: 5.55,
          }}
        >
          DÉCIMO PRIMERA.- SUSPENSIÓN DE LOS SERVICIOS
        </Typography>
        <Typography
          sx={{
            fontSize: '15px',
            fontWeight: '300',
            pl: 2.5,
          }}
        >
          1) TICKETTOGO puede dar por terminado en cualquier momento, con o sin
          causa, el acceso de EL USUARIO a todo o parte del Servicio. <br />
          <br />
          2) TICKETTOGO puede interrumpir o suspender la cuenta de EL USUARIO
          por inactividad, que se define como falta de acceso a dicha cuenta por
          un período prolongado de tiempo, según dicho período sea determinado
          por TICKETTOGO. El período de tiempo que actualmente TICKETTOGO
          considera como período de tiempo "prologado" puede ser consultado
          directamente con TICKETTOGO a la información proporcionada en este
          documento. Con la interrupción del Servicio, el derecho a utilizar el
          Servicio cesa inmediatamente y la cuenta puede ser reasignada a otro
          Usuario. La información que haya estado almacenada en la cuenta de EL
          USUARIO será eliminada al momento de la interrupción por falta de uso,
          sin responsabilidad alguna para TICKETTOGO. <br />
          <br />
          3) Si EL USUARIO desea cancelar su cuenta o el uso de los Servicios,
          el Usuario solo deberá interrumpir el uso del mismo.
        </Typography>
        <Typography
          sx={{
            fontSize: '15px',
            fontWeight: '750',
            textTransform: 'uppercase',
            lineHeight: 5.55,
          }}
        >
          DÉCIMO SEGUNDA.- DISPUTAS
        </Typography>
        <Typography
          sx={{
            fontSize: '15px',
            fontWeight: '300',
            pl: 2.5,
          }}
        >
          1) Si cualquier estipulación del presente documento se declara nula o
          no ejecutable de acuerdo con la legislación aplicable, incluida, pero
          sin limitarse a, la renuncia a garantías, limitaciones de
          responsabilidad y pago de daños y perjuicios establecidas previamente,
          dicha estipulación se considerará reemplazada por aquella otra
          estipulación válida y ejecutable que más se asemeje a la intención de
          la estipulación original, continuando vigentes las restantes
          estipulaciones de este documento. <br />
          <br />
          2) Los títulos sólo se incorporan para la comodidad de las Partes y no
          tienen significado legal o contractual alguno. <br />
          <br />
          3) Estos Términos son y serán regidos por las Leyes de la República
          Mexicana y cualquier disputa será sometida a la jurisdicción y
          competencia de las leyes y los tribunales de la Ciudad de Saltillo,
          Coahuila, renunciando expresamente el Usuario, a cualquier otra
          jurisdicción que pudiere corresponderle por motivo de su domicilio o
          nacionalidad, presente o futuro, o por cualquier otra causa. <br />
          <br />
          4) Estos Términos constituyen, junto con los demás acuerdos publicados
          en los Sitios, el total de acuerdos entre TICKETTOGO y EL USUARIO y
          sustituyen cualquier acuerdo previo, escrito o verbal.
        </Typography>
      </Box>
    </Box>
  );
};
