import { useState, useRef, useEffect, useCallback } from 'react';
import { Box, Button } from '@mui/material';
import BannerImageSlider from './BannerImageSlider';
import TGDropzone from './TgDropzone';
import Cropper, { ReactCropperElement } from 'react-cropper';
import 'cropperjs/dist/cropper.min.css';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import SaveIcon from '@mui/icons-material/Save';

interface EventBannerPreviewProps {
  currentImage: string | null;
  onSave: (file: File) => void;
  onCancel: () => void;
}

export default function EventBannerPreview({
  currentImage,
  onSave,
  onCancel,
}: EventBannerPreviewProps) {
  const [images, setImages] = useState<string[]>([]);
  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const [showPreview, setShowPreview] = useState(false);
  const [croppedImage, setCroppedImage] = useState<string | null>(null);
  const [croppedImageFile, setCroppedImageFile] = useState<File | null>(null);
  const cropperRef = useRef<ReactCropperElement>(null);

  // Manejo de archivo subido
  const onReceiveFile = (file: File) => {
    const reader = new FileReader();
    reader.onload = () => {
      setImageSrc(reader.result as string);
      setShowPreview(false);
    };
    reader.readAsDataURL(file);
  };

  // Reiniciar todo
  const handleChangeImage = () => {
    setImages([]);
    setImageSrc(null);
    setCroppedImage(null);
    setCroppedImageFile(null);
    setShowPreview(false);
  };

  // Obtener recorte del cropper
  const getCroppedImage = useCallback(() => {
    if (cropperRef.current && cropperRef.current.cropper) {
      const cropper = cropperRef.current.cropper;
      const canvas = cropper.getCroppedCanvas();
      if (canvas) {
        const dataUrl = canvas.toDataURL('image/jpeg');
        setCroppedImage(dataUrl);
        setImages([dataUrl]);

        canvas.toBlob((blob) => {
          if (blob) {
            const file = new File([blob], 'cropped.jpg', {
              type: 'image/jpeg',
            });
            setCroppedImageFile(file);
          }
        }, 'image/jpeg');
        setShowPreview(true);
      }
    }
  }, []);

  // Guardar recorte
  const handleSave = async () => {
    if (croppedImageFile) {
      onSave(croppedImageFile);
    }
  };

  useEffect(() => {
    if (currentImage) {
      setImages([currentImage]);
      setShowPreview(true);
    }
  }, [currentImage]);

  return (
    <Box>
      <Box
        sx={{
          position: 'relative',
          width: '100%',
          height: {
            xs: 500,
            sm: 500,
            md: 470,
            lg: 500,
            xl: 590,
          },
          backgroundColor: !imageSrc ? 'rgba(0, 0, 0, 0.1)' : '#fff',
          mb: 2,
        }}
      >
        {!imageSrc && images.length === 0 && (
          <TGDropzone file={null} onReceiveFile={onReceiveFile} />
        )}

        {imageSrc && (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: {
                xs: 250,
                sm: 300,
                md: 400,
                lg: 500,
                xl: 700,
              },
            }}
            style={{
              opacity: showPreview ? 0 : 1,
              pointerEvents: showPreview ? 'none' : 'auto',
              zIndex: showPreview ? 1 : 2,
              transition: 'opacity 0.3s ease',
            }}
          >
            <Cropper
              src={imageSrc}
              height={480}
              width={800}
              autoCropArea={3 / 4}
              guides
              viewMode={1}
              background={false}
              responsive
              checkOrientation={false}
              dragMode="move"
              ref={cropperRef}
              onInitialized={(instance) => {
                setTimeout(() => {
                  try {
                    instance.zoomTo(0.3);
                  } catch (e) {
                    console.error('Zoom error:', e);
                  }
                }, 300);
              }}
            />
          </Box>
        )}

        {images.length > 0 && (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
            }}
            style={{
              opacity: showPreview ? 1 : 0,
              pointerEvents: showPreview ? 'auto' : 'none',
              zIndex: showPreview ? 2 : 1,
              transition: 'opacity 0.3s ease',
            }}
          >
            <BannerImageSlider events={[]} images={images} />
          </Box>
        )}
      </Box>

      <Box
        sx={{
          width: '400px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          p: 2,
          m: '0 auto',
        }}
      >
        {(imageSrc || images.length > 0) && (
          <Button
            sx={{
              width: '100%',
              minHeight: '35px',
              background: '#444E6A',
              borderRadius: '10px',
              color: '#FFFFFF',
              fontWeight: 600,
              letterSpacing: '1px',
              fontFamily: 'Poppins',
              textTransform: 'capitalize',
              fontSize: '1rem',
              '&:hover': {
                backgroundColor: '#FFFFFF',
                color: '#444E6A',
              },
              mb: 2,
            }}
            variant="contained"
            size="small"
            onClick={handleChangeImage}
            startIcon={<AddPhotoAlternateIcon />}
          >
            Cambiar imagen
          </Button>
        )}

        {imageSrc && (
          <Button
            sx={{
              width: '100%',
              minHeight: '35px',
              background: '#FFFFFF',
              borderRadius: '10px',
              color: '#444E6A',
              fontWeight: 600,
              letterSpacing: '1px',
              fontFamily: 'Poppins',
              textTransform: 'capitalize',
              fontSize: '1rem',
              '&:hover': {
                backgroundColor: '#444E6A',
                color: '#FFFFFF',
              },
              mb: 2,
            }}
            variant="contained"
            onClick={() => {
              if (showPreview) {
                setShowPreview(false);
              } else {
                getCroppedImage();
              }
            }}
            size="small"
            startIcon={showPreview ? <EditIcon /> : <VisibilityIcon />}
          >
            {showPreview ? 'Seguir editando' : 'Pre visualizar'}
          </Button>
        )}

        {croppedImage && (
          <Button
            sx={{
              width: '100%',
              minHeight: '35px',
              background: '#444E6A',
              borderRadius: '10px',
              color: '#FFFFFF',
              fontWeight: 600,
              letterSpacing: '1px',
              fontFamily: 'Poppins',
              textTransform: 'capitalize',
              fontSize: '1rem',
              '&:hover': {
                backgroundColor: '#FFFFFF',
                color: '#444E6A',
              },
              mb: 2,
            }}
            variant="contained"
            size="small"
            onClick={handleSave}
            startIcon={<SaveIcon />}
          >
            Guardar
          </Button>
        )}
      </Box>
    </Box>
  );
}
