import { Box, Typography } from '@mui/material';

export interface StepContainerProps {
  title?: string;
}

const WithStepContainer =
  <T extends StepContainerProps>(
    Component: React.ComponentType<T & StepContainerProps>,
  ) =>
  (props: T) => {
    return (
      <Box
        component="div"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          width: '85%',
          height: '100%',
          backgroundColor: '#FFFFFF',
          borderRadius: '10px',
          boxShadow: '3px 3px 0px 0px #878787',
          '& .MuiTextField-root': { width: '100%', m: 1 },
          p: 2,
        }}
      >
        <Typography
          gutterBottom
          sx={{
            fontFamily: 'Popins, sans-serif',
            fontWeight: 800,
            letterSpacing: '0.5px',
            ml: 1,
            color: '#444E6A',
            lineHeight: 1.5,
            fontSize: '1.5rem',
          }}
        >
          {props.title}
        </Typography>
        <Component {...props} />
      </Box>
    );
  };

export default WithStepContainer;
