import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Modal,
  Typography,
} from '@mui/material';
import { MouseEvent, ReactNode, useEffect, useState } from 'react';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

interface TransactionModalProps {
  isOpen: boolean;
  onCloseModal: () => void;
  title: string;
  subtitle?: string | null;
  children: Readonly<ReactNode>;
}

function CustomModal({
  isOpen,
  onCloseModal,
  title,
  subtitle,
  children,
}: TransactionModalProps) {
  const [open, setOpen] = useState(isOpen);

  const handleBackdropClick = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
  };

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      slotProps={{ backdrop: { onClick: handleBackdropClick } }}
      disableEscapeKeyDown
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '10%',
        }}
      >
        <Card
          sx={{
            borderRadius: '10px',
            width: '800px',
            display: 'flex',
            flexDirection: 'column',
            padding: '0px',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CardHeader
            sx={{
              backgroundColor: (theme) => theme.palette.primary.dark,
              width: '100%',
              height: '10%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <CheckCircleOutlineIcon
              sx={{ fontSize: '2em', marginLeft: '10px' }}
            />
          </CardHeader>
          <CardContent sx={{ width: '100%', height: '100%', padding: '0px' }}>
            <Box
              sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'space-evenly',
              }}
            >
              <Typography
                sx={{
                  color: (theme) => theme.palette.primary.dark,
                  fontSize: '2em',
                  fontWeight: 'bold',
                }}
              >
                {title}
              </Typography>
              <Typography sx={{ textAlign: 'center', width: '57%' }}>
                {subtitle}
              </Typography>
              <Box
                sx={{
                  width: '80%',
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'space-evenly',
                }}
              >
                {children}
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Modal>
  );
}

export default CustomModal;
