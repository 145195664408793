import React from 'react';
import { Box, Avatar, Typography, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { toCurrency } from 'apps/client/src/app/utils/currency';
import { ItemSummary } from 'libs/domain/src/lib/dto/Cart/getCartWithItemsResponse';
import ticketIcon from '../../../app/images/ticket-icon.png';
interface CartItemsListProps {
  items: ItemSummary[];
  onDeleteItem: (itemId: string) => void;
}

const CartItemsList: React.FC<CartItemsListProps> = ({
  items,
  onDeleteItem,
}) => {
  return (
    <>
      {items.map((item, index) => (
        <Box
          key={index}
          sx={{
            width: '90%',
            minHeight: '140px',
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            alignItems: 'center',
            gap: '20px',
            backgroundColor: (theme) => theme.palette.common.white,
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
            borderRadius: '10px',
            p: '14px',
            mb: '15px',
          }}
        >
          <Avatar
            sx={{
              width: 90,
              height: 90,
              borderRadius: 0,
              flexShrink: 0,
            }}
            src={ticketIcon}
          />
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
            }}
          >
            <Typography
              sx={{
                fontFamily: 'Roboto',
                fontSize: '18px',
                fontWeight: 900,
                color: (theme) => theme.palette.primary.main,
                display: 'flex',
                alignItems: 'center',
                mb: '4px',
              }}
            >
              <Box
                component="div"
                sx={{
                  width: '18px',
                  height: '18px',
                  backgroundColor: item.section.color,
                  borderRadius: 1,
                  mr: '8px',
                }}
              />
              {`Boleto ${item.section.name}`}
            </Typography>
            <Typography
              sx={{
                fontSize: '18px',
                fontWeight: 300,
                color: '#303030',
              }}
            >
              {item.section.type === 'GENERAL' &&
                `General: ${toCurrency(item.section.price)}`}
              {item.section.type === 'TABLE' &&
                `Mesa: ${toCurrency(item.section.price)}`}
              {item.section.type === 'ROW' &&
                `Fila: ${toCurrency(item.section.price)}`}
            </Typography>
            {item.section.type === 'ROW' && (
              <>
                <Typography
                  sx={{
                    fontSize: '18px',
                    fontWeight: 300,
                    color: '#303030',
                  }}
                >
                  {`Fila: ${item.row}`}
                </Typography>
                <Typography
                  sx={{
                    fontSize: '18px',
                    fontWeight: 900,
                    color: '#303030',
                  }}
                >
                  {`Asiento: ${item.seat}`}
                </Typography>
              </>
            )}
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
            }}
          >
            <Typography
              sx={{
                fontFamily: 'Roboto',
                fontSize: '18px',
                fontWeight: 900,
                color: (theme) => theme.palette.primary.main,
                textAlign: 'right',
              }}
            >
              {toCurrency(item.section.price)}
            </Typography>
            <IconButton
              sx={{
                backgroundColor: '#fff',
                borderRadius: '5px',
                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                '&:hover': { backgroundColor: '#f8f8f8' },
              }}
              onClick={() => onDeleteItem(item.id)}
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        </Box>
      ))}
    </>
  );
};

export default CartItemsList;
