import {
  ChangeEvent,
  Fragment,
  MouseEvent,
  ReactNode,
  useEffect,
  useState,
} from 'react';
import { EventsTable } from '../components/events/EventsTable';
import DefaultDialog from '../components/DefaultDialog';
import {
  AddEvent,
  UpdateEvent,
  RemoveEvent,
  toggleHighlightedFLag,
  UpdateStatusEvent,
} from '../../requests/events';
import { isAxiosError } from 'axios';
import AlertMessage from '../components/AlertMessage';
import { api } from '../../requests/api-client';
import { UpdateEventByAdminDto } from 'libs/domain/src/lib/dto';
import UpdateEventDialog from '../components/events/UpdateEventDialog';
import FullPageSpinner from '../components/FullPageSpinner';
import CreateEvent from '../components/events/CreateEvent';
import { EVENT_STATUS, EventEntity } from 'libs/domain/src/lib/entities/Event';
import {
  Box,
  Button,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SelectChangeEvent,
  Typography,
  useTheme,
} from '@mui/material';
import { debounce } from 'lodash';
import { useAuth } from '../../providers/AuthProvider';
import { useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import PeopleIcon from '@mui/icons-material/People';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import HomeIcon from '@mui/icons-material/Home';
import SellIcon from '@mui/icons-material/Sell';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import PersonPinIcon from '@mui/icons-material/PersonPin';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import CustomModal from '../components/client/Modal';
import {
  AdminPanelSettings,
  PointOfSale,
  QrCodeScanner,
} from '@mui/icons-material';

interface MenuOptions {
  label?: string;
  path: string;
  icon: ReactNode;
  onClick: () => void;
}

export const Events = () => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [titleModal, setTitleModal] = useState<string>('');
  const [subTitleModal, setSubTitleModal] = useState<string>('');
  const [events, setEvents] = useState<EventEntity[]>([]);
  const [openRegisterEventModal, setOpenRegisterEventModal] = useState(false);
  const [openDeleteEventDialog, setOpenDeleteEventDialog] = useState(false);
  const [openUpdateEventModal, setOpenUpdateEventModal] = useState(false);
  const [eventToUpdateStatus, setEventToUpdateStatus] = useState<{
    status: string;
    eventId: string;
  }>({ status: '', eventId: '' });
  const [eventDataToUpdate, setEventDataToUpdate] =
    useState<UpdateEventByAdminDto>({
      name: '',
      shortDescription: '',
      longDescription: '',
      status: '',
      lineAddress: '',
      category: '',
      state: '',
      city: '',
      venueName: '',
      eventDates: [
        {
          id: null,
          date: new Date(),
          from: new Date(),
          to: new Date(),
        },
      ],
    });
  const [alertProps, setAlertprops] = useState<{
    isOpen: boolean;
    severity: 'success' | 'error' | 'info' | 'warning';
    message: string;
  }>({
    isOpen: false,
    severity: 'success',
    message: '',
  });
  const [eventId, setEventId] = useState<string | null>(null);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageReady, setPageReady] = useState(false);
  const [isCreatingEvent, setIsCreatingEvent] = useState(false);
  const [searchEventQuery, setSearchEventQuery] = useState('');
  const [open, setOpen] = useState(true);
  const { user } = useAuth();
  const navigate = useNavigate();
  const theme = useTheme();

  const menuOptions: MenuOptions[] = [
    {
      label: 'Eventos',
      path: '/admin/eventos',
      icon: <EventAvailableIcon sx={{ color: 'white' }} />,
      onClick: () => navigate('/admin/eventos'),
    },
    {
      label: 'Administradores',
      path: '/admin/administradores',
      onClick: () => navigate('/admin/administradores'),
      icon: <AdminPanelSettings sx={{ color: 'white' }} />,
    },
    {
      label: 'Usuarios Ventas',
      path: '/admin/sales',
      onClick: () => navigate('/admin/sales'),
      icon: <PointOfSale sx={{ color: 'white' }} />,
    },
    {
      label: 'Usuarios Escaner',
      path: '/admin/scan',
      onClick: () => navigate('/admin/scan'),
      icon: <QrCodeScanner sx={{ color: 'white' }} />,
    },
    {
      label: 'Usuarios',
      path: '/admin/usuarios',
      onClick: () => navigate('/admin/usuarios'),
      icon: <PeopleIcon sx={{ color: 'white' }} />,
    },
    {
      label: 'Historial de ventas',
      path: '/admin/historial-de-ventas',
      icon: <SellIcon sx={{ color: 'white' }} />,
      onClick: () => navigate('/admin/historial-de-ventas'),
    },
    {
      label: 'Página principal',
      path: '/',
      icon: <HomeIcon sx={{ color: 'white' }} />,
      onClick: () => navigate('/'),
    },
  ];

  const handleChangePage = (
    event: MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOpenRegisterEventModal = () => {
    setOpenRegisterEventModal(true);
  };

  const handleCloseRegisterEventModal = () => {
    setOpenRegisterEventModal(false);
  };

  const handleOpenDeleteEventDialog = (eventId: string) => {
    setEventId(eventId);
    setOpenDeleteEventDialog(true);
  };

  const handleCloseDeleteEventDialog = () => {
    setEventId(null);
    setOpenDeleteEventDialog(false);
  };

  const handleOpenUpdateEventModal = (
    eventData: UpdateEventByAdminDto,
    id: string,
  ) => {
    setEventId(id);
    setEventDataToUpdate(eventData);
    setOpenUpdateEventModal(true);
  };

  const handleOpenDrawer = () => {
    setOpen(true);
  };

  const handleCloseDrawer = () => {
    setOpen(false);
  };

  const handleCloseUpdateEventModal = () => {
    setEventDataToUpdate({
      name: '',
      shortDescription: '',
      longDescription: '',
      status: '',
      lineAddress: '',
      category: '',
      state: '',
      city: '',
      venueName: '',
      eventDates: [
        {
          id: null,
          date: new Date(),
          from: new Date(),
          to: new Date(),
        },
      ],
    });
    setOpenUpdateEventModal(false);
  };

  const handleCloseAlert = () => {
    setAlertprops({
      isOpen: false,
      severity: alertProps.severity,
      message: '',
    });
  };

  /* API Functions */
  const fetchEvents = async () => {
    try {
      const offset = rowsPerPage * page;
      const response = await api.get('/events/', {
        params: {
          search: searchEventQuery,
          page: page,
          limit: rowsPerPage,
          offset,
          sort_by: 'highlighted',
          order_by: 'DESC',
        },
      });
      setEvents(response.data.data.events);
      setTotal(response.data.count);
    } catch (error) {
      console.error('Error fetching events:', error);
    }
  };

  const createNewEvent = async (data: FormData) => {
    setIsCreatingEvent(true);
    const { status, error } = await AddEvent(data);

    if (status === 'OK') {
      setAlertprops({
        isOpen: true,
        severity: 'success',
        message: '¡Evento registrado exitosamente!',
      });
      setIsCreatingEvent(false);
      fetchEvents();
      setOpenRegisterEventModal(false);
    }

    if (status === 'ERROR') {
      if (isAxiosError(error)) {
        const errorCreateEventMessage =
          error.response?.data.message || 'Error al registrar el evento.';
        setAlertprops({
          isOpen: true,
          severity: 'error',
          message: errorCreateEventMessage,
        });
      }
      setIsCreatingEvent(false);
    }
  };

  const deleteEvent = async () => {
    if (eventId === null) {
      return;
    }
    const { response, status, error } = await RemoveEvent(eventId);
    if (status === 'OK') {
      const messageDelete =
        response.data.message || '¡Evento eliminado correctamente!';
      setAlertprops({
        isOpen: true,
        severity: 'success',
        message: messageDelete,
      });
      setOpenDeleteEventDialog(false);
      fetchEvents();
    }
    if (status === 'ERROR') {
      const messageError =
        error.response?.data?.message || 'Ha ocurrido un error.';
      setAlertprops({
        isOpen: true,
        severity: 'error',
        message: messageError,
      });
      setOpenDeleteEventDialog(false);
    }
  };

  const updateEvent = async (data: FormData) => {
    if (!eventId) {
      return;
    }
    const { response, status, error } = await UpdateEvent(eventId, data);
    if (status === 'OK') {
      const successMessageUpdateEvent =
        response.data.event || '¡Evento actualizado correctamente!';
      setAlertprops({
        isOpen: true,
        severity: 'success',
        message: successMessageUpdateEvent,
      });
      fetchEvents();
      setOpenUpdateEventModal(false);
    }
    if (status === 'ERROR') {
      const errorMessageUpdateEvent =
        error.response.data.message || 'No se pudo actualizar el evento.';
      setAlertprops({
        isOpen: true,
        severity: 'error',
        message: errorMessageUpdateEvent,
      });
    }
  };

  const toggleHighlight = async (eventId: string) => {
    if (!eventId) {
      return;
    }
    const { response, status, error } = await toggleHighlightedFLag(eventId);
    if (status === 'OK') {
      const successMessageHighlightEvent = response.data.message;
      setAlertprops({
        isOpen: true,
        severity: 'success',
        message: successMessageHighlightEvent,
      });
      fetchEvents();
    }
    if (status === 'ERROR') {
      const errorMessageHighlightEvent =
        error.response.data.message || 'No se pudo destacar el evento.';
      setAlertprops({
        isOpen: true,
        severity: 'error',
        message: errorMessageHighlightEvent,
      });
    }
  };

  const onUpdateStatusEvent = async (
    event: SelectChangeEvent,
    eventId: string,
  ) => {
    if (!eventId) {
      return;
    }

    if (
      event.target.value === EVENT_STATUS[0] ||
      event.target.value === EVENT_STATUS[2] ||
      event.target.value === EVENT_STATUS[3]
    ) {
      setSubTitleModal(
        'Si actualizas el estatus, los usuarios no podrán ver este evento en la página principal.',
      );
    } else {
      setSubTitleModal(
        'Si actualizas el estatus, los usuarios podrán ver este evento en la página principal.',
      );
    }

    setOpenModal(true);
    setEventToUpdateStatus({
      status: event.target.value as string,
      eventId,
    });
  };

  const submitModal = async () => {
    const data = {
      status: eventToUpdateStatus.status,
    };

    const { response, status, error } = await UpdateStatusEvent(
      eventToUpdateStatus.eventId,
      data,
    );

    if (status === 'OK') {
      const successMessageUpdateEvent =
        response.data.event || '¡Evento actualizado correctamente!';
      setAlertprops({
        isOpen: true,
        severity: 'success',
        message: successMessageUpdateEvent,
      });
      fetchEvents();
      setOpenModal(false);
      setSubTitleModal('');
      setEventToUpdateStatus({ status: '', eventId: '' });
    }
    if (status === 'ERROR') {
      const errorMessageUpdateEvent =
        error.response.data.message || 'No se pudo actualizar el evento.';
      setAlertprops({
        isOpen: true,
        severity: 'error',
        message: errorMessageUpdateEvent,
      });
    }
  };

  const debouncedEventsQuery = debounce(fetchEvents, 500);

  useEffect(() => {
    const delay = setTimeout(() => {
      debouncedEventsQuery();
    }, 150);

    if (pageReady) {
      if (searchEventQuery === '') {
        debouncedEventsQuery.cancel();
        fetchEvents();
      } else {
        debouncedEventsQuery();
      }
    }

    return () => {
      clearTimeout(delay);
      debouncedEventsQuery.cancel();
    };
  }, [searchEventQuery]);

  useEffect(() => {
    (async () => {
      await fetchEvents();
      setPageReady(true);
    })();
  }, []);

  useEffect(() => {
    if (pageReady) {
      fetchEvents();
    }
  }, [page, rowsPerPage]);

  if (!pageReady) {
    return <FullPageSpinner />;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        height: 'auto',
      }}
    >
      {/* New menu here */}
      {open && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            padding: '17.5px 0 0 0',
            position: 'relative',
            borderRadius: '15px',
            width: { xs: '100%', md: '30%' },
            minHeight: '675px',
            backgroundColor: '#444E6A',
            m: '20px 0',
            right: '10px',
          }}
        >
          <Fragment>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                width: '100%',
                justifyContent: 'center',
              }}
            >
              <PersonPinIcon
                sx={{
                  mr: 1,
                  width: 48,
                  height: 48,
                  color: '#FFF',
                }}
              />
              <Typography
                variant="caption"
                sx={{
                  fontSize: 20,
                  fontWeight: 900,
                  color: '#FFF',
                  lineHeight: 1.2,
                  maxWidth: '60%',
                  whiteSpace: 'normal',
                  wordBreak: 'break-word',
                }}
              >
                Bienvenido, {user?.firstName}
              </Typography>
            </Box>
            <Divider
              sx={{
                borderColor: '#FFF',
                borderWidth: '0.5px',
                mt: 1,
              }}
            />
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <List sx={{ p: '10px 35px 0 0' }} className="customAdminList">
                {menuOptions.map((option, index) => (
                  <ListItem
                    key={option.label}
                    disablePadding
                    sx={{ display: 'block' }}
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? 'initial' : 'center',
                        px: 2.5,
                        color: '#FFF',
                        background: '#444E6A',
                      }}
                      onClick={option.onClick}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? '15px' : 'auto',
                          justifyContent: 'center',
                        }}
                      >
                        {option.icon}
                      </ListItemIcon>
                      <ListItemText
                        primary={option.label}
                        sx={{
                          opacity: open ? 1 : 0,
                          fontSize: 13,
                          fontWeight: 500,
                          textAlign: 'start',
                        }}
                      />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </Box>
          </Fragment>
        </Box>
      )}
      {/* Menu options */}
      {!open && (
        <IconButton
          aria-label="open drawer"
          onClick={handleOpenDrawer}
          edge="start"
          sx={{
            width: 48,
            height: 48,
            top: '20px',
            left: '17.5px',
          }}
        >
          <MenuIcon
            sx={{
              color: '#444E6A',
              width: 36,
              height: 36,
            }}
          />
        </IconButton>
      )}
      <CreateEvent
        isOpen={openRegisterEventModal}
        onClose={handleCloseRegisterEventModal}
        onRegister={createNewEvent}
        isSubmitting={isCreatingEvent}
      />
      <EventsTable
        onOpenRegisterModal={handleOpenRegisterEventModal}
        onOpenDeleteModal={handleOpenDeleteEventDialog}
        onOpenUpdateEventModal={handleOpenUpdateEventModal}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        toggleHighlight={toggleHighlight}
        events={events}
        onSearch={(search) => setSearchEventQuery(search)}
        currentPage={page}
        rowsPerPage={rowsPerPage}
        totalEvents={total}
        onUpdateStatusEvent={onUpdateStatusEvent}
      />
      <DefaultDialog
        title="Eliminar evento"
        text="¿Estás seguro de eliminar este evento?"
        isOpen={openDeleteEventDialog}
        textCancelButton="Cancelar"
        textConfirmButton="Confirmar"
        onCancel={handleCloseDeleteEventDialog}
        onConfirm={deleteEvent}
      />
      <UpdateEventDialog
        eventData={eventDataToUpdate}
        isOpen={openUpdateEventModal}
        handleCloseUpdate={handleCloseUpdateEventModal}
        onUpdateEvent={updateEvent}
      />
      <AlertMessage
        onClose={handleCloseAlert}
        isOpen={alertProps.isOpen}
        message={alertProps.message}
        severity={alertProps.severity}
        autoHideDuration={5000}
      />
      <CustomModal
        isOpen={openModal}
        title="¿Seguro de actualizar este evento?"
        subtitle={subTitleModal}
        onCloseModal={() => console.log('here')}
      >
        <Button
          sx={{
            width: '50%',
            background: (theme) => theme.palette.common.white,
            border: '2px solid #48002A',
            fontWeight: 'bold',
            margin: '10px',
            color: (theme) => theme.palette.primary.dark,
            '&:hover': {
              background: (theme) => theme.palette.primary.dark,
              color: (theme) => theme.palette.common.white,
              border: 'none',
            },
          }}
          onClick={() => setOpenModal(false)}
        >
          Cancelar
        </Button>
        <Button
          onClick={submitModal}
          sx={{
            width: '50%',
            fontWeight: 'bold',
            marginBottom: '10px',
            background: (theme) => theme.palette.primary.dark,
            color: (theme) => theme.palette.common.white,
            '&:hover': {
              background: '#303647',
              color: (theme) => theme.palette.common.white,
              border: 'none',
            },
          }}
        >
          Aceptar
        </Button>
      </CustomModal>
    </Box>
  );
};
