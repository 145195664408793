import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Box } from '@mui/material';
import { useAuth } from '../../providers/AuthProvider';
import { useNavigate } from 'react-router-dom';

interface LogoutDialogClientProps {
  isOpen: boolean;
  onCloseDialog: () => void;
}
const styleButtonSalir = {
  background: '#A90063',
  borderRadius: '10px',
  color: '#FFFFFF',
  '&:hover': {
    background: '#48002A',
    color: '#FFFFFF',
    border: '2px #FFFFFF',
  },
};
const styleButtonCancelar = {
  background: '#FFFFFF',
  borderRadius: '10px',
  color: '#A90063',
};

export default function LogoutDialogClient({
  isOpen,
  onCloseDialog,
}: LogoutDialogClientProps) {
  const { logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = async () => {
    await logout();
    navigate('/');
  };

  return (
    <Box>
      <Dialog
        open={isOpen}
        onClose={onCloseDialog}
        aria-labelledby="alert-dialog-logout"
        aria-describedby="alert-dialog-logout"
        PaperProps={{
          sx: {
            borderRadius: '10px',
            overflow: 'hidden',
          },
        }}
      >
        <DialogTitle
          id="alert-dialog-logout"
          sx={{
            color: '#A90063',
          }}
        >
          {'Salir'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-logout-description">
            ¿Estás seguro que quieres salir?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button sx={styleButtonCancelar} onClick={onCloseDialog}>
            Cancelar
          </Button>
          <Button sx={styleButtonSalir} onClick={handleLogout}>
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
