import React from 'react';
import { Box, Typography } from '@mui/material';
import { Scanner } from '@yudiel/react-qr-scanner';
import DialogAlert from '../components/DialogAlert';
import { validateQR } from '../../requests/scanner';

export const QRScanner = () => {
  const [isScanning, setIsScanning] = React.useState(true);
  const [dialogProps, setDialogProps] = React.useState<{
    isOpen: boolean;
    severity: 'success' | 'error' | 'warning' | 'info';
    message: string;
  }>({
    isOpen: false,
    severity: 'success',
    message: '',
  });

  const handleCloseDialog = () => {
    setDialogProps({ isOpen: false, severity: 'success', message: '' });
    setIsScanning(true);
  };

  const validateQr = async (QRData: any) => {
    const { rawValue } = QRData[0];
    setIsScanning(false);

    if (!rawValue || rawValue === '') {
      setDialogProps({
        isOpen: true,
        severity: 'error',
        message: 'Código QR no válido. Por favor, escanea nuevamente.',
      });
      return;
    }

    try {
      const data = { qrstring: rawValue.replace(/^"|"$/g, '') };
      const response = await validateQR(data);

      if (response.status === 'OK') {
        const message = response.response.message || 'Código QR válido.';
        setDialogProps({
          isOpen: true,
          severity: 'success',
          message: message,
        });
      } else if (response.status === 'ERROR') {
        const message =
          response.error.response.data.message ||
          'Error en el escáner. Intenta nuevamente.';
        setDialogProps({
          isOpen: true,
          severity: 'error',
          message: message,
        });
      }
    } catch (error: any) {
      setDialogProps({
        isOpen: true,
        severity: 'error',
        message: 'Error en el escáner. Intenta nuevamente.',
      });
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        width: '100%',
        minHeight: '90vh',
        mt: { xs: 2, lg: 4 },
      }}
    >
      <Typography
        sx={{
          fontFamily: 'Roboto',
          fontSize: '25px',
          fontWeight: 900,
          color: (theme) => theme.palette.primary.main,
          alignSelf: 'center',
          mb: 2,
        }}
      >
        Escanea tu código aquí
      </Typography>
      <Box
        sx={{
          width: { xs: '90%', sm: '400px', md: '500px' },
          height: 'auto',
          maxWidth: '90%',
          mt: 2.5,
        }}
      >
        {isScanning && (
          <Scanner
            onScan={(result: any) => validateQr(result)}
            onError={() =>
              setDialogProps({
                isOpen: true,
                severity: 'warning',
                message: 'No se pudo acceder a la cámara.',
              })
            }
            constraints={{ facingMode: 'environment' }}
          />
        )}
      </Box>
      <DialogAlert
        severity={dialogProps.severity}
        message={dialogProps.message}
        isOpen={dialogProps.isOpen}
        autoHideDuration={10000}
        onClose={handleCloseDialog}
      />
    </Box>
  );
};
