import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import {
  Box,
  Table,
  TablePagination,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Avatar,
  InputBase,
  MenuItem,
  SelectChangeEvent,
  TextField,
  Paper,
  Typography,
} from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import {
  KeyboardArrowLeft,
  KeyboardArrowRight,
  LastPage,
  FirstPage,
} from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import StarIcon from '@mui/icons-material/Star';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import { UpdateEventByAdminDto } from 'libs/domain/src/lib/dto';
import { useNavigate } from 'react-router-dom';
import { TableMenu } from './TableMenu';
import TTButton from '../shared/TTButton';
import { EventEntity } from 'libs/domain/src/lib/entities/Event';
import { fromTimeStrToDate } from '../../utils/dateUtils';
import SearchIcon from '@mui/icons-material/Search';

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    e: React.MouseEvent<HTMLButtonElement>,
    newPage: number,
  ) => void;
}

type EventCategoryTranslations = {
  [key: string]: string;
};

const eventCategoryTranslations: EventCategoryTranslations = {
  MUSICA: 'Música',
  DEPORTES: 'Deportes',
  ENTRETENIMIENTO: 'Entretenimiento',
};

const SearchIconWrapper = styled('div')<{ isTexting: boolean }>(
  ({ theme, isTexting }) => ({
    display: isTexting ? 'none' : 'flex',
    color: '#999',
    transition: theme.transitions.create('color', {
      duration: theme.transitions.duration.short,
    }),
  }),
);

function TablePaginationActions(props: TablePaginationActionsProps) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (
    e: React.MouseEvent<HTMLButtonElement>,
  ) => {
    onPageChange(e, 0);
  };

  const handleBackButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(e, page - 1);
  };

  const handleNextButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(e, page + 1);
  };

  const handleLastPageButtonClick = (
    e: React.MouseEvent<HTMLButtonElement>,
  ) => {
    onPageChange(e, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPage /> : <FirstPage />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPage /> : <LastPage />}
      </IconButton>
    </Box>
  );
}

interface EventsTableProps {
  events: EventEntity[];
  onOpenRegisterModal: () => void;
  onOpenDeleteModal: (eventId: string) => void;
  onOpenUpdateEventModal: (
    eventData: UpdateEventByAdminDto,
    id: string,
  ) => void;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => void;
  onRowsPerPageChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
  currentPage: number;
  rowsPerPage: number;
  totalEvents: number;
  toggleHighlight: (eventId: string) => void;
  onSearch: (search: string) => void;
  onUpdateStatusEvent: (event: SelectChangeEvent, eventId: string) => void;
}

export const EventsTable = ({
  events,
  currentPage,
  rowsPerPage,
  totalEvents,
  onOpenRegisterModal,
  onOpenDeleteModal,
  onOpenUpdateEventModal,
  onPageChange,
  onRowsPerPageChange,
  toggleHighlight,
  onSearch,
  onUpdateStatusEvent,
}: EventsTableProps) => {
  const navigate = useNavigate();
  const [searchEventQuery, setSearchEventQuery] = React.useState('');

  const handleOpenUpdateEventModal = (event: EventEntity) => {
    onOpenUpdateEventModal(
      {
        name: event.name,
        shortDescription: event.shortDescription,
        longDescription: event.longDescription,
        status: event.status,
        lineAddress: event.lineAddress,
        category: event.category,
        state: event.state,
        city: event.city,
        venueName: event.venueName,
        cardImage: event.cardImage,
        ticketImage: event.ticketImage,
        bannerImage: event.bannerImage,
        eventDates:
          event.eventDates?.map((date) => {
            const today = new Date();
            const year = today.getFullYear();
            const month = today.getMonth() + 1;
            const day = today.getDate();

            const formattedMonth = month < 10 ? `0${month}` : month;
            const formattedDay = day < 10 ? `0${day}` : day;

            return {
              id: date.id,
              date: new Date(date.date),

              from: new Date(
                `${year}-${formattedMonth}-${formattedDay}T${date.from}`,
              ),
              to: new Date(
                `${year}-${formattedMonth}-${formattedDay}T${date.to}`,
              ),
            };
          }) || [],
      },
      event.id,
    );
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#444E6A',
      color: theme.palette.common.white,
      fontWeight: 600,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 15,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    backgroundColor: '#EBEBEB',
    border: 0,
  }));

  return (
    <TableContainer sx={{ padding: 3 }}>
      {/* Search input */}
      <Paper
        component="form"
        sx={{
          p: '4px 4px',
          display: 'flex',
          alignItems: 'center',
          width: 'auto',
          maxWidth: 350,
          borderRadius: '10px',
          boxShadow: 1,
          border: '2px solid black',
        }}
        elevation={3}
      >
        <InputBase
          sx={{
            ml: 1,
            flex: 1,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
          placeholder="Buscar eventos…"
          value={searchEventQuery}
          onChange={(event) => {
            setSearchEventQuery(event.target.value);
            onSearch(event.target.value);
          }}
          inputProps={{ 'aria-label': 'search' }}
        />
        <SearchIconWrapper isTexting={!!searchEventQuery}>
          <SearchIcon />
        </SearchIconWrapper>
      </Paper>
      {/* Search input */}
      <TTButton
        color="secondary"
        variant="contained"
        sx={{
          float: 'right',
          marginTop: '10px',
          lineHeight: 2,
          minWidth: '200px',
        }}
        aria-label="Add event"
        onClick={onOpenRegisterModal}
        startIcon={<AddIcon />}
      >
        {'Crear Evento'}
        &nbsp;
      </TTButton>
      <Typography
        variant="h5"
        component="div"
        sx={{
          flexGrow: 1,
          fontWeight: 900,
          color: '#444E6A',
          fontSize: '1.75rem',
          m: '15px 0px',
        }}
      >
        {'Lista de Eventos'}
      </Typography>
      <Table
        aria-label="customized table"
        component={Paper}
        sx={{
          minWidth: 700,
          borderRadius: '10px',
          overflow: 'hidden',
        }}
      >
        <TableHead>
          <TableRow>
            <StyledTableCell align="center">&nbsp;</StyledTableCell>
            <StyledTableCell align="left">Evento&nbsp;</StyledTableCell>
            <StyledTableCell align="left">
              Estatus&nbsp;del Evento
            </StyledTableCell>
            <StyledTableCell align="left">Categoría&nbsp;</StyledTableCell>
            <StyledTableCell align="center">Opciones&nbsp;</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {events.length > 0 ? (
            events.map((event) => (
              <StyledTableRow key={event.id}>
                <StyledTableCell
                  align="center"
                  sx={{
                    display: 'flex',
                    justifyContent: 'left',
                    alignItems: 'center',
                  }}
                >
                  <IconButton
                    title={
                      event.highlighted ? 'Quitar destacado' : 'Destacar evento'
                    }
                    sx={{
                      marginRight: 2,
                      color: '#FFD700',
                    }}
                    onClick={() => toggleHighlight(event.id)}
                  >
                    {event.highlighted ? (
                      <StarIcon sx={{ color: '#FFD700' }} />
                    ) : (
                      <StarOutlineIcon />
                    )}
                  </IconButton>
                  <Avatar
                    sx={{
                      borderRadius: '10px',
                      width: 56,
                      height: 56,
                      '&:hover': {
                        cursor: 'pointer',
                      },
                    }}
                    title="Ver información del evento"
                    onClick={() => handleOpenUpdateEventModal(event)}
                    src={event?.cardImage || ''}
                    alt={event.name}
                    variant="rounded"
                  />
                </StyledTableCell>
                <StyledTableCell
                  align="left"
                  sx={{
                    fontWeight: 800,
                  }}
                >
                  {event.name}
                </StyledTableCell>
                <StyledTableCell align="left">
                  <TextField
                    select
                    fullWidth
                    defaultValue={event.status}
                    onChange={(
                      changedStatus: React.ChangeEvent<HTMLInputElement>,
                    ) => onUpdateStatusEvent(changedStatus, event.id)}
                    className="customTextField"
                    SelectProps={{
                      MenuProps: {
                        classes: {
                          paper: 'customTextPaper',
                        },
                      },
                    }}
                  >
                    {[
                      { value: 'DRAFT', label: 'Borrador' },
                      { value: 'PUBLISHED', label: 'Publicado' },
                      { value: 'FINISHED', label: 'Finalizado' },
                      { value: 'CANCELED', label: 'Cancelado' },
                    ].map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </StyledTableCell>
                <StyledTableCell
                  align="left"
                  sx={{
                    fontWeight: 400,
                  }}
                >
                  {eventCategoryTranslations[event.category] || event.category}
                </StyledTableCell>
                <StyledTableCell component="th" scope="row" align="center">
                  <TableMenu
                    color="#444E6A"
                    options={[
                      {
                        id: '0',
                        label: !event.highlighted
                          ? 'Destacar'
                          : 'Quitar destacado',
                        onClick: () => toggleHighlight(event.id),
                      },
                      {
                        id: '1',
                        label: 'Secciones',
                        onClick: () => {
                          navigate(`/admin/eventos/${event.id}/preview`);
                          window.scrollTo(0, 0);
                        },
                      },
                      {
                        id: '2',
                        label: 'Eliminar',
                        onClick: () => onOpenDeleteModal(event.id),
                      },
                    ]}
                  />
                </StyledTableCell>
              </StyledTableRow>
            ))
          ) : (
            <StyledTableRow>
              <StyledTableCell colSpan={5} align="center">
                {
                  'Aún no hay eventos registrados, puedes crear uno nuevo dando click en el botón "Crear Evento".'
                }
              </StyledTableCell>
            </StyledTableRow>
          )}
        </TableBody>
      </Table>
      {events.length > 0 && (
        <TablePagination
          sx={{
            float: 'right',
          }}
          labelRowsPerPage="Filas por página"
          rowsPerPageOptions={[1, 10, 25, { label: 'Todo', value: 100000000 }]}
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to} de ${count !== -1 ? count : `más de ${to}`}`
          }
          count={totalEvents}
          colSpan={5}
          rowsPerPage={rowsPerPage}
          page={currentPage}
          SelectProps={{
            inputProps: {
              'aria-label': 'Filas por página',
            },
          }}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
          ActionsComponent={TablePaginationActions}
        />
      )}
    </TableContainer>
  );
};
