import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Divider,
  FormControl,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
  Tooltip,
} from '@mui/material';
import { EventEntity } from 'libs/domain/src/lib/entities/Event';
import { ChangeEvent, ChangeEventHandler, useEffect, useState } from 'react';
import {
  getDatesByEventId,
  getSectionsByEventId,
} from '../../../requests/events';
import { SectionEntity } from '../../../../../../libs/domain/src/lib/entities/Section';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { EventDateEntity } from '../../../../../../libs/domain/src/lib/entities/EventDate';
import {
  GetMaxNumberOfSeatsByGeneralSectionId,
  GetSectionTypeRow,
  GetSectionTypeTable,
} from '../../../requests/sections';
import { RowEntity } from '../../../../../../libs/domain/src/lib/entities/Row';
import { SeatEntity } from '../../../../../../libs/domain/src/lib/entities/Seat';
import { GetSeatsByRowId } from '../../../requests/rows';
import { Add, HighlightOff, Remove } from '@mui/icons-material';
import { AddToCart } from '../../../requests/carts';
import { CreateCartWithItemsDto } from '../../../../../../libs/domain/src/lib/dto';
import { isAxiosError } from 'axios';
import AlertMessage from '../AlertMessage';
import { useCart } from '../../hooks/useCart';
import { toCurrency } from '../../utils/currency';

const addPaidBox = {
  display: 'flex',
  justifyContent: 'center',
  padding: '20px 40px',
  mb: '15px',
  width: { xs: '70%', sm: '80%', md: '85%', xl: '90%' },
  height: 'auto',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundColor: '#FFFFFF',
  borderRadius: '15px',
};

interface PaidSaleProps {
  events: EventEntity[];
}

export const AddPaidToSales = ({ events }: PaidSaleProps) => {
  const { refreshCart } = useCart();
  const [alertProps, setAlertprops] = useState<{
    isOpen: boolean;
    severity: 'success' | 'error' | 'info' | 'warning';
    message: string;
  }>({
    isOpen: false,
    severity: 'success',
    message: '',
  });

  const handleCloseAlert = () => {
    setAlertprops({
      isOpen: false,
      severity: 'success',
      message: '',
    });
  };

  const [selectedEventId, setSelectedEventId] = useState<string>('');
  const [selectedEventDateId, setSelectedEventDateId] = useState<string>('');
  const [selectedSectionId, setSelectedSectionId] = useState<string>('');
  const [selectedSection, setSelectedSection] = useState<SectionEntity | null>(
    null,
  );
  const [selectedRowId, setSelectedRowId] = useState<string>('');
  const [selectedSeats, setSelectedSeats] = useState<SeatEntity[]>([]);
  const [selectedTableSeats, setSelectedTableSeats] = useState<RowEntity[]>([]);
  const [selectedTables, setSelectedTables] = useState<RowEntity[]>([]);
  const [sections, setSections] = useState<SectionEntity[]>([]);
  const [eventDates, setEventDates] = useState<EventDateEntity[] | null>(null);
  const [sectionType, setSectionType] = useState<string>('ROW');
  const [rows, setRows] = useState<RowEntity[]>([]);
  const [seats, setSeats] = useState<SeatEntity[]>([]);
  const [maxNumberOfSeats, setMaxNumberOfSeats] = useState<number>(1);
  const [quantity, setQuantity] = useState<number>(1);
  const [disabledSubmit, setDisabledSubmit] = useState<boolean>(true);

  const resetForm = () => {
    setSelectedEventId('');
    setSelectedEventDateId('');
    setSelectedSectionId('');
    setSelectedSection(null);
    setSelectedRowId('');
    setSelectedSeats([]);
    setSelectedTableSeats([]);
    setSelectedTables([]);
    setSections([]);
    setEventDates(null);
    setSectionType('ROW');
    setRows([]);
    setSeats([]);
    setMaxNumberOfSeats(1);
    setQuantity(1);
  };

  const handleSelectedEvent: ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (event) => {
    setSelectedEventId(event.target.value);
  };

  const handleSelectedEventDate: ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (event) => {
    setSelectedEventDateId(event.target.value);
  };

  const handleSelectedSection: ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (event) => {
    setSelectedSectionId(event.target.value);

    const filteredSection = sections?.find(
      (section) => section.id === event.target.value,
    );

    setSectionType(filteredSection?.type || 'ROW');
  };

  const handleSelectedRow: ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (event) => {
    setSelectedRowId(event.target.value);
  };

  const handleSelectedSeat = (values: SeatEntity[]) => {
    setSelectedSeats(values);
  };

  const handleSelectedTable = (values: RowEntity[]) => {
    setSelectedTables(values);
    setSelectedTableSeats(values);
  };

  const getSeatsFromTable = (tables: RowEntity[]) => {
    const tableSeats: string[] = [];

    tables.forEach((table) => {
      if (table.seats && table.seats?.length > 0) {
        tableSeats.push(table.seats[0].id);
      }
    });

    return tableSeats;
  };

  const handleSubmit = async () => {
    if (quantity > maxNumberOfSeats) {
      setAlertprops({
        isOpen: true,
        severity: 'error',
        message: 'No hay suficientes asientos disponibles.',
      });
      return;
    }

    let seatIds = selectedSeats.map((seat) => seat.id);

    if (sectionType === 'TABLE') {
      seatIds = await getSeatsFromTable(selectedTables);
    }

    const ticketToCart = {
      eventDateId: selectedEventDateId,
      eventId: selectedEventId,
      quantity,
      seatIds,
      sectionId: selectedSectionId,
      sectionType,
      rowId: selectedRowId,
    };

    const { response, status, error } = await AddToCart(ticketToCart);

    if (status === 'OK') {
      const successMessage =
        response.message || '¡Ítems agregados al carrito exitosamente!';
      setAlertprops({
        isOpen: true,
        severity: 'success',
        message: successMessage,
      });

      await setSelectedSeats([]);
      await setSelectedTableSeats([]);
      await fetchSeat();
      await fetchRow();
      await fetchTables();
      await setQuantity(1);
      await refreshCart();
    }

    if (status === 'ERROR') {
      if (isAxiosError(error)) {
        const errorAddItemsMessage =
          error.response?.data.message || 'Error al registrar los ítems.';
        setAlertprops({
          isOpen: true,
          severity: 'error',
          message: errorAddItemsMessage,
        });
      }
    }
    resetForm();
  };

  const handleQuantityChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const value = event.target.value;

    if (value && isNaN(Number(value))) {
      return;
    }

    if (Number(value) > maxNumberOfSeats) {
      return;
    }
  };

  const handleIncrementQuantity = async () => {
    const currentQuantity = quantity;

    if (currentQuantity === maxNumberOfSeats) {
      return;
    }

    await setQuantity(quantity + 1);
  };

  const handleDecrementQuantity = async () => {
    const currentQuantity = quantity;

    if (currentQuantity === 1 || currentQuantity === 0) {
      return;
    }

    await setQuantity(quantity - 1);
  };

  const fetchEventDates = async () => {
    if (!selectedEventId) {
      return;
    }

    const { response, status } = await getDatesByEventId(selectedEventId);

    if (status === 'OK') {
      const datesData = response.data;

      if (datesData.length === 1) {
        await setSelectedEventDateId(datesData[0].id);
        await fetchSection();
      }

      await setEventDates(datesData);
    }
  };

  const fetchSection = async () => {
    if (!selectedEventId) {
      return;
    }

    const { response, status } = await getSectionsByEventId(selectedEventId);

    if (status === 'OK') {
      const sectionsData = response.data.sections;
      await setSections(sectionsData);
    }
  };

  const fetchMaxNumberOfSeats = async () => {
    if (!selectedSectionId || !selectedEventDateId) {
      return;
    }

    const { response, status } = await GetMaxNumberOfSeatsByGeneralSectionId(
      selectedSectionId,
      selectedEventDateId,
    );

    if (status === 'OK') {
      const maxNumberOfSeatsResponse = response.data.maxNumberOfSeats;

      await setMaxNumberOfSeats(maxNumberOfSeatsResponse);
    }
  };

  const fetchRow = async () => {
    if (!selectedSectionId || !selectedEventDateId) {
      return;
    }

    const { response, status } = await GetSectionTypeRow(
      selectedSectionId,
      selectedEventDateId,
    );

    if (status === 'OK') {
      const rowData = response.data.rows;
      const sectionData = response.data.section;

      if (sectionData.type === 'GENERAL') {
        await fetchMaxNumberOfSeats();
        await setDisabledSubmit(false);
      }

      await setSelectedSection(sectionData);
      await setSectionType(sectionData.type);
      await setRows(rowData);
    }
  };

  const fetchSeat = async () => {
    if (!selectedRowId || !selectedEventDateId) {
      return;
    }
    const { response, status } = await GetSeatsByRowId(
      selectedRowId,
      selectedEventDateId,
    );

    if (status === 'OK') {
      const seatsData = response.data.seats;
      await setSeats(seatsData);
    }
  };

  const fetchTables = async () => {
    if (!selectedSectionId || !selectedEventDateId) {
      return;
    }

    const { response, status } = await GetSectionTypeTable(
      selectedSectionId,
      selectedEventDateId,
    );

    if (status === 'OK') {
      const rowData = response.data.rows;
      const sectionData = response.data.section;

      await setSelectedSection(sectionData);
      await setRows(rowData);
    }
  };

  useEffect(() => {
    setSelectedEventDateId('');
    setEventDates(null);
    setSections([]);
    setRows([]);
    setSeats([]);
    setSelectedSeats([]);
    setSectionType('ROW');

    (async () => {
      await fetchEventDates();
    })();
  }, [selectedEventId]);

  useEffect(() => {
    setSections([]);
    setRows([]);
    setSeats([]);
    setSelectedSeats([]);
    setSectionType('ROW');

    (async () => {
      await fetchSection();
    })();
  }, [selectedEventDateId]);

  useEffect(() => {
    setRows([]);
    setSeats([]);
    setSelectedSeats([]);
    setQuantity(1);
    setSelectedTableSeats([]);

    (async () => {
      if (sectionType === 'TABLE' && selectedSectionId && selectedEventDateId) {
        await fetchTables();
      } else {
        await fetchRow();
      }
    })();
  }, [selectedSectionId]);

  useEffect(() => {
    setSeats([]);
    setSelectedSeats([]);

    (async () => {
      await fetchSeat();
    })();
  }, [selectedRowId]);

  useEffect(() => {
    if (selectedSeats.length > 0 || selectedTableSeats.length > 0) {
      setDisabledSubmit(false);
    } else {
      setDisabledSubmit(true);
    }
  }, [selectedSeats, selectedTableSeats]);

  return (
    <Box sx={addPaidBox}>
      {/* Evento */}
      <Box
        sx={{
          width: '100%',
        }}
      >
        <TextField
          fullWidth
          size="medium"
          id="select-event"
          select
          label="Nombre del Evento"
          defaultValue={''}
          onChange={handleSelectedEvent}
          value={selectedEventId}
          variant="outlined"
          color="secondary"
        >
          {events?.map((event) => (
            <MenuItem key={event.id} value={event.id}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                }}
              >
                <Box component="div" sx={{ borderRadius: 1 }} />
                <Box>{event.name}</Box>
              </Box>
            </MenuItem>
          ))}
        </TextField>
      </Box>
      {selectedEventId ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            justifyContent: 'space-between',
            width: '100%',
            padding: '10px 0',
          }}
        >
          {/* Fechas del evento */}
          <Box
            sx={{
              minWidth: { xs: '100%', md: '20%' },
            }}
          >
            {eventDates && eventDates?.length > 0 ? (
              <>
                {eventDates?.length === 1 ? (
                  <Typography
                    sx={{
                      alignSelf: 'center',
                      padding: '10px 10px 0px 0px',
                      textTransform: 'uppercase',
                      fontSize: '20px',
                      fontWeight: '900',
                      textAlign: 'center',
                      width: '100%',
                      color: (theme) => theme.palette.secondary.main,
                    }}
                  >
                    {format(new Date(eventDates[0].date), "dd 'de' MMMM yyyy", {
                      locale: es,
                    })}
                  </Typography>
                ) : (
                  <TextField
                    fullWidth
                    size="medium"
                    id="select-date"
                    select
                    label="Fecha"
                    onChange={handleSelectedEventDate}
                    value={selectedEventDateId}
                  >
                    {eventDates?.map((eventDate) => (
                      <MenuItem key={eventDate.id} value={eventDate.id}>
                        {format(new Date(eventDate.date), "dd 'de' MMMM yyyy", {
                          locale: es,
                        })}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              </>
            ) : (
              <Typography
                sx={{
                  alignSelf: 'center',
                  padding: '10px 10px 0px 0px',
                  textTransform: 'uppercase',
                  fontSize: '20px',
                  fontWeight: '900',
                  textAlign: 'center',
                  width: '100%',
                  color: (theme) => theme.palette.secondary.main,
                }}
              >
                Fechas No Disponibles
              </Typography>
            )}
          </Box>

          {/* Sección del evento */}
          <Box
            sx={{
              minWidth: { xs: '100%', md: '20%' },
            }}
          >
            <TextField
              fullWidth
              size="medium"
              id="select-section"
              select
              label="Sección"
              defaultValue={[]}
              onChange={handleSelectedSection}
              value={selectedSectionId}
              variant="outlined"
              color="secondary"
              disabled={sections.length <= 0}
            >
              {sections?.map((section) => (
                <MenuItem key={section.id} value={section.id}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'flex-start',
                    }}
                  >
                    {section.name}
                  </Box>
                </MenuItem>
              ))}
            </TextField>
          </Box>

          <>
            {/* Filas del evento*/}
            {sectionType === 'ROW' && (
              <>
                <Box
                  sx={{
                    minWidth: '20%',
                  }}
                >
                  <TextField
                    fullWidth
                    size="medium"
                    id="select-row"
                    select
                    label="Fila"
                    onChange={handleSelectedRow}
                    value={selectedRowId}
                    variant="outlined"
                    color="secondary"
                    disabled={rows.length <= 0}
                  >
                    {rows?.map((row) => (
                      <MenuItem key={row.id} value={row.id}>
                        {row.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Box>

                <Box
                  sx={{
                    minWidth: '20%',
                  }}
                >
                  <FormControl sx={{ width: '100%' }}>
                    <Autocomplete
                      id="select-seat"
                      multiple
                      value={selectedSeats}
                      limitTags={2}
                      disableCloseOnSelect
                      defaultValue={[]}
                      options={seats}
                      getOptionLabel={(option) => option.name}
                      onChange={(event, values) => {
                        handleSelectedSeat(values);
                      }}
                      inputValue="" // '' empty to block input
                      sx={{
                        '& .MuiAutocomplete-endAdornment': {
                          '& .MuiAutocomplete-popupIndicator': {
                            display: 'none',
                          },
                        },
                      }}
                      clearIcon={<HighlightOff />}
                      disabled={selectedRowId.length <= 0}
                      noOptionsText="No disponibles"
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            label={option.name}
                            {...getTagProps({ index })}
                            onDelete={undefined}
                            variant="filled"
                            sx={{
                              backgroundColor: (theme) =>
                                theme.palette.secondary.light,
                              color: (theme) => theme.palette.secondary.main,
                              borderRadius: '10px',
                            }}
                            key={option.id}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField {...params} label="Asientos" />
                      )}
                    />
                  </FormControl>
                </Box>
              </>
            )}

            {/* Mesas del evento */}
            {sectionType === 'TABLE' && (
              <Box
                sx={{
                  minWidth: '50%',
                }}
              >
                {rows ? (
                  <FormControl sx={{ width: '100%' }}>
                    <Autocomplete
                      id="select-table"
                      multiple
                      value={selectedTableSeats}
                      limitTags={2}
                      defaultValue={[]}
                      options={rows}
                      getOptionLabel={(option) => option.name}
                      onChange={(event, values) => handleSelectedTable(values)}
                      inputValue="" // '' empty to block input
                      sx={{
                        '& .MuiAutocomplete-endAdornment': {
                          '& .MuiAutocomplete-popupIndicator': {
                            display: 'none',
                          },
                        },
                      }}
                      clearIcon={<HighlightOff />}
                      noOptionsText="No Disponibles"
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            label={option.name}
                            {...getTagProps({ index })}
                            onDelete={undefined}
                            variant="filled"
                            sx={{
                              backgroundColor: (theme) =>
                                theme.palette.secondary.light,
                              color: (theme) => theme.palette.secondary.main,
                              borderRadius: '10px',
                            }}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField {...params} label="Mesa" />
                      )}
                    />
                  </FormControl>
                ) : null}
              </Box>
            )}

            {sectionType === 'GENERAL' && (
              <Box
                sx={{
                  minWidth: '50%',
                }}
              >
                <Tooltip
                  title={
                    maxNumberOfSeats <= 0
                      ? 'No hay boletos disponibles para esta sección'
                      : ''
                  }
                  arrow
                >
                  <span>
                    <TextField
                      sx={{
                        '& input': {
                          textAlign: 'center',
                        },
                      }}
                      fullWidth
                      size="medium"
                      id="quantity"
                      type="text"
                      inputMode="numeric"
                      label={`Cantidad de boletos (Disponibles: ${maxNumberOfSeats})`}
                      value={quantity}
                      onChange={handleQuantityChange}
                      inputProps={{ min: 1, max: maxNumberOfSeats }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              disabled={
                                quantity === maxNumberOfSeats ||
                                maxNumberOfSeats <= 0
                              }
                              aria-label="increment"
                              onClick={handleIncrementQuantity}
                            >
                              <Add />
                            </IconButton>
                          </InputAdornment>
                        ),
                        startAdornment: (
                          <InputAdornment position="start">
                            <IconButton
                              disabled={quantity === 1 || maxNumberOfSeats <= 0}
                              aria-label="decrement"
                              onClick={handleDecrementQuantity}
                            >
                              <Remove />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      disabled={maxNumberOfSeats <= 0}
                    />
                  </span>
                </Tooltip>
              </Box>
            )}
          </>
        </Box>
      ) : null}
      {selectedEventId ? (
        <Box
          sx={{
            width: { xs: '100%', lg: '30%' },
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignSelf: { xs: 'center', md: 'end' },
            gap: 2,
          }}
        >
          <Box
            sx={{
              justifyContent: 'space-between',
              display: 'flex',
              flexDirection: 'row',
              marginBottom: '5px',
            }}
          >
            <Typography
              sx={{
                fontSize: '16px',
                fontWeight: '700',
                color: (theme) => theme.palette.common.black,
                textTransform: 'uppercase',
              }}
            >
              Precio de Sección
            </Typography>
            <Typography
              sx={{
                fontSize: '16px',
                fontWeight: '700',
                color: (theme) => theme.palette.primary.main,
              }}
            >
              MX {toCurrency(selectedSection?.price || 0)}
            </Typography>
          </Box>
          <Divider />
          <Button
            variant="contained"
            sx={{
              borderRadius: '8px',
              color: (theme) => theme.palette.common.white,
              background: (theme) => theme.palette.primary.main,
              fontFamily: 'Poppins',
              fontWeight: 'bold',
              textTransform: 'initial',
              '&:hover': {
                background: (theme) => theme.palette.secondary.main,
                color: (theme) => theme.palette.common.white,
              },
              width: '100%',
              margin: '35px 0 10px 0',
            }}
            onClick={handleSubmit}
            disabled={disabledSubmit || maxNumberOfSeats <= 0}
          >
            Agregar para pagar
          </Button>
        </Box>
      ) : null}
      <AlertMessage
        onClose={handleCloseAlert}
        isOpen={alertProps.isOpen}
        message={alertProps.message}
        severity={alertProps.severity}
      />
    </Box>
  );
};
