import axios, { AxiosRequestConfig } from "axios";
// I'm not sure the env variables work here. Investigate and if not leave it with the default.
const apiUrl = import.meta.env.VITE_API_URL || `http://localhost:3333/api`;
const config: AxiosRequestConfig = {
  baseURL: apiUrl,
  withCredentials: true
};
export enum ResponseStatus {
  OK = 'OK',
  ERROR = 'ERROR'
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface AxiosParams<T = any> {
  url: string;
  method: 'put' | 'post' | 'get' | 'delete' | 'GET' | 'PUT' | 'POST' | 'DELETE';
  data?: T;
  headers?: T;
  responseType?: T;
  params?: T;
}
export type AxiosResponse =
  | {
      status: string;
      response: unknown;
      error?: undefined;
    }
  | {
      status: string;
      error: any;
      response?: undefined;
    };
const defaultHeaders = {
  'Access-Control-Allow-Credentials': true
};
export const multipartHeaders = {
  'Content-type': 'multipart/form-data',
};

export const api = axios.create(config);


export const axiosRequest = async (parameters: AxiosParams) => {
  const { url, method, data, headers, responseType, params } = parameters;
  try {
    const response = await api.request({
      url,
      method,
      data,
      headers: {
        ...defaultHeaders,
        ...headers || { 'Content-type': 'application/json' }
      },
      responseType,
      params,
    });
    return {
      status: ResponseStatus.OK,
      response: response.data
    };
  } catch (error: any) {
    return {
      status: ResponseStatus.ERROR,
      error: error
    };
  }
};