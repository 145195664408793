import React from 'react';
import { Box, Typography } from '@mui/material';
import { toCurrency } from 'apps/client/src/app/utils/currency';

export interface ResumentItem {
  section: string;
  price: number;
  quantity: number;
}

interface CartResumeItemsProps {
  items: ResumentItem[] | undefined;
}

const CartResumeItems: React.FC<CartResumeItemsProps> = ({ items }) => {
  if (!items || items.length === 0) {
    return null;
  }

  return (
    <Box>
      {items.map((item, idx) => (
        <Box key={idx} sx={{ width: '90%', mx: 'auto', mt: 3 }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <Typography
              sx={{
                fontSize: '1rem',
                fontWeight: 600,
                fontFamily: 'Poppins',
                color: 'primary.main',
              }}
            >
              x{item.quantity} {item.quantity > 1 ? 'Boletos' : 'Boleto'}{' '}
              {item.section}
            </Typography>
            <Typography
              sx={{
                fontSize: '1rem',
                fontWeight: 900,
                fontFamily: 'Poppins',
                color: 'primary.main',
              }}
            >
              MX {toCurrency(item.price * item.quantity)}
            </Typography>
          </Box>
          <Typography
            sx={{
              fontSize: '0.85rem',
              fontWeight: 500,
              fontFamily: 'Poppins',
              color: '#303030',
            }}
          >
            {`MX ${toCurrency(item.price)} x${item.quantity}`}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

export default CartResumeItems;
