import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { AppBar, Divider, IconButton, Slide, Toolbar } from '@mui/material';
import { useForm } from 'react-hook-form';
import {
  CreateRowWithSeatsDto,
  createRowWithSeatsSchema,
} from 'libs/domain/src/lib/dto';
import { zodResolver } from '@hookform/resolvers/zod';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Form } from 'react-router-dom';
import { TransitionProps } from '@mui/material/transitions';

interface AddRowsWithSeatsProps {
  isOpen: boolean;
  onClose: () => void;
  onCreateRowsWithSeats: (data: FormData) => void;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddRowsWithSeats({
  isOpen,
  onClose,
  onCreateRowsWithSeats,
}: AddRowsWithSeatsProps) {
  const [open, setOpen] = React.useState(isOpen);

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<CreateRowWithSeatsDto>({
    resolver: zodResolver(createRowWithSeatsSchema),
  });

  const handleBackdropClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    reset();
  };

  const handleClose = () => {
    onClose();
    setOpen(false);
    reset();
  };

  const onSubmit = async (data: CreateRowWithSeatsDto) => {
    const formData = new FormData();
    if (data.seatsInitNumber) {
      formData.append('seatsInitNumber', data.seatsInitNumber.toString());
    }
    if (data.seatsPrefix) {
      formData.append('seatsPrefix', data.seatsPrefix);
    }
    formData.append('name', data.name);
    formData.append('capacity', data.capacity.toString());
    onCreateRowsWithSeats(formData);
    reset();
    onClose();
  };

  React.useEffect(() => {
    setOpen(isOpen);
  }, [isOpen, onCreateRowsWithSeats]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        slotProps={{ backdrop: { onClick: handleBackdropClick } }}
        disableEscapeKeyDown
        TransitionComponent={Transition}
        PaperProps={{
          sx: {
            borderRadius: '10px',
            overflow: 'hidden',
          },
        }}
      >
        <AppBar
          sx={{
            position: 'relative',
            backgroundColor: '#890050',
          }}
        >
          <Toolbar sx={{ justifyContent: 'flex-end' }}>
            <IconButton edge="end" onClick={handleClose} aria-label="close">
              <HighlightOffIcon sx={{ color: '#FFF' }} />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Form onSubmit={handleSubmit(onSubmit)} method="post" action="">
          <DialogTitle
            sx={{
              color: '#444E6A',
              fontWeight: 700,
              fontSize: '2rem',
              fontStyle: 'bold',
            }}
          >
            <b>Agregar filas a la sección</b>
          </DialogTitle>
          <DialogContent>
          <DialogContentText
              sx={{
                mt: 2,
                mb: 1,
                fontWeight: 600,
                color: '#444E6A',
                fontSize: '1rem',
              }}
            >
              <b>Datos de la fila</b>
              <Divider />
            </DialogContentText>
            <TextField
              autoFocus
              {...register('name')}
              id="nombre-de-la-fila"
              label="Nombre de la fila"
              fullWidth
              margin="normal"
              size="small"
              error={!!errors.name}
              helperText={errors.name?.message}
              className="customTextField"
            />
            <TextField
              {...register('capacity')}
              id="numero-de-asientos"
              label="Número de asientos por fila"
              type="number"
              fullWidth
              margin="normal"
              size="small"
              error={!!errors.capacity}
              helperText={errors.capacity?.message}
              className="customTextField"
            />
            <TextField
              {...register('seatsInitNumber')}
              label="Número inicial de asientos (opcional)"
              type="number"
              fullWidth
              margin="normal"
              size="small"
              error={!!errors.seatsInitNumber}
              helperText={errors.seatsInitNumber?.message}
              className="customTextField"
            />
            <TextField
              {...register('seatsPrefix')}
              id="asientos-prefijos"
              label="Prefijo para los asientos (opcional)"
              fullWidth
              margin="normal"
              size="small"
              error={!!errors.seatsPrefix}
              helperText={errors.seatsPrefix?.message}
              className="customTextField"
            />
          </DialogContent>
          <DialogActions
            sx={{
              display: 'flex',
              justifyContent: 'space-evenly',
              alignItems: 'center',
              mb: 5,
            }}
          >
            <Button
              size="medium"
              sx={{
                width: '45%',
                maxHeight: 50,
                borderRadius: '10px',
                fontSize: {
                  xs: '10px',
                  sm: '10px',
                  md: '12px',
                  lg: '14px',
                  xl: '16px',
                },
                fontWeight: 'bold',
                textTransform: 'uppercase',
                color: '#890050',
                mt: 2,
                border: '2px solid #890050',
                backgroundColor: '#FFFFFF',
                '&:hover': {
                  backgroundColor: '#890050',
                  border: '2px solid #890050',
                  color: '#FFFFFF',
                },
              }}
              onClick={handleClose}
            >
              Cancelar
            </Button>
            <Button
              disabled={isSubmitting}
              sx={{
                width: '45%',
                maxHeight: 50,
                borderRadius: '10px',
                fontSize: {
                  xs: '10px',
                  sm: '10px',
                  md: '12px',
                  lg: '14px',
                  xl: '16px',
                },
                fontWeight: 'bold',
                textTransform: 'uppercase',
                color: '#FFF',
                mt: 2,
                border: '2px solid #890050',
                backgroundColor: '#890050',
                '&:hover': {
                  backgroundColor: '#FFF',
                  border: '2px solid #890050',
                  color: '#890050',
                },
              }}
              type="submit"
              size="medium"
              onClick={handleSubmit(onSubmit)}
            >
              Crear
            </Button>
          </DialogActions>
        </Form>
      </Dialog>
    </div>
  );
}
