import { Box, Typography } from '@mui/material';

interface EmptyProps {
  label: string;
}

export const NoTickets = ({ label }: EmptyProps) => {
  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        component="img"
        src="/no-ticket.png"
        alt="empty"
        sx={{
          width: 300,
          maxWidth: 600,
          height: 'auto',
          objectFit: 'contain',
          transform: 'rotate(18deg)',
          transformOrigin: 'center',
          transition: 'transform 0.3s ease',
          '@keyframes zoomInOnce': {
            '0%': {
              transform: 'scale(0.8)',
            },
            '80%': {
              transform: 'scale(1.05)',
            },
            '100%': {
              transform: 'scale(1)',
            },
          },
          animation: 'zoomInOnce 0.8s ease-in-out',
          animationFillMode: 'forwards',
          animationIterationCount: 1,
        }}
      />
      <Typography
        variant="body2"
        color="#C33186"
        align="center"
        sx={{
          fontWeight: 600,
          fontSize: '1.2rem',
          marginTop: 2,
        }}
      >
        {label}
      </Typography>
    </Box>
  );
};
