import {
  Box,
  Button,
  Typography,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import Carousel from 'react-material-ui-carousel';
import { EventEntity } from 'libs/domain/src/lib/entities/Event';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';

interface BannerImageSliderProps {
  events: EventEntity[];
  loading?: boolean;
  images?: string[];
}

const styleLandingPage = {
  width: 'auto',
  height: {
    xs: '250px',
    sm: '300px',
    md: '400px',
    lg: '500px',
    xl: '600px',
  },
};

export default function BannerImageSlider({
  events,
  images,
}: BannerImageSliderProps) {
  return (
    <Carousel
      navButtonsAlwaysVisible
      indicators={true}
      sx={{
        ...styleLandingPage,
      }}
      stopAutoPlayOnHover
      animation="slide"
      swipe
      fullHeightHover
      duration={1500}
      navButtonsProps={{
        style: {
          backgroundColor: 'white',
          color: 'gray',
          borderRadius: '50%',
          boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.5)',
        },
      }}
    >
      {events.length > 0 ? (
        events.map((event, index) => <EventImage event={event} key={index} />)
      ) : images && images.length > 0 ? (
        <EventImage image={images[0]} />
      ) : (
        <EventImage image="https://storage.googleapis.com/ticket-togo-public-files/bannerImage.jpg" />
      )}
    </Carousel>
  );
}

function EventImage({
  event,
  image,
}: {
  event?: EventEntity | undefined;
  image?: string;
}) {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('md'));

  let formattedDates = 'Fecha(s) del evento';
  let srcImage = image ? image : '';

  if (event) {
    formattedDates =
      event?.eventDates
        ?.map((eventDate) =>
          format(new Date(eventDate.date), "dd 'de' MMMM 'de' yyyy", {
            locale: es,
          }),
        )
        .slice(0, event?.eventDates.length - 1)
        .join(', ')
        .concat(
          `${event?.eventDates.length > 1 ? ' y ' : ''}`,
          (event?.eventDates.length > 0 &&
            format(
              new Date(event?.eventDates[event?.eventDates.length - 1].date),
              "dd 'de' MMMM 'de' yyyy",
              { locale: es },
            )) ||
            'No hay fechas disponibles',
        ) || 'No hay fechas disponibles';

    srcImage = event.bannerImage ?? '';
  }

  return (
    <Box
      sx={{
        position: 'relative',
        width: 'auto',
        height: {
          xs: '250px',
          sm: '300px',
          md: '400px',
          lg: '500px',
          xl: '600px',
        },
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        m: '0 auto',
      }}
    >
      {/* Shadow overlay */}
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          background: 'rgba(0, 0, 0, 0.65)',
          zIndex: 1,
        }}
      />
      <Box
        sx={{
          zIndex: 2,
          position: 'absolute',
          display: 'inline-block',
          left: isMobileScreen ? '50%' : '80px',
          transform: isMobileScreen ? 'translateX(-50%)' : 'none',
          textAlign: isMobileScreen ? 'center' : 'left',
        }}
      >
        <Typography
          sx={{
            fontSize: isMobileScreen ? '1.2rem' : '2.75rem',
            fontWeight: '700',
            fontFamily: 'Poppins',
            color: (theme) => theme.palette.common.white,
            textTransform: 'uppercase',
          }}
        >
          {event ? event.name : 'Nombre del Evento'}
        </Typography>
        <Typography
          sx={{
            fontSize: isMobileScreen ? '1rem' : '1.25rem',
            fontWeight: '700',
            fontFamily: 'Poppins',
            color: (theme) => theme.palette.common.white,
            fontStyle: 'italic',
            lineHeight: '2',
          }}
        >
          {formattedDates}
        </Typography>
        <Button
          variant="contained"
          sx={{
            textTransform: 'uppercase',
            width: { xs: 'auto', md: '200px' },
            height: { xs: 'auto', md: '40px' },
            borderRadius: '10px',
            color: (theme) => theme.palette.common.white,
            background: (theme) => theme.palette.primary.main,
            fontFamily: 'Poppins',
            fontWeight: 'bold',
            '&:hover': {
              background: (theme) => theme.palette.common.white,
              color: (theme) => theme.palette.primary.main,
            },
          }}
          onClick={() => event && navigate(`/eventos/${event?.id}/`)}
        >
          Ver evento
        </Button>
      </Box>
      <Box
        component={'img'}
        src={srcImage}
        alt="imagen"
        sx={{
          width: '100%',
          height: {
            xs: '250px',
            sm: '300px',
            md: '400px',
            lg: '500px',
            xl: '600px',
          },
          objectFit: 'cover',
        }}
      />
    </Box>
  );
}
