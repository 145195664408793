import React from 'react';
import { Box, CircularProgress } from '@mui/material';
import { styled } from '@mui/system';

const Page = styled(Box)({
    fontSize: '18px',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
});

export default function FullPageSpinner(): JSX.Element {
    return (
        <Page>
            <CircularProgress />
        </Page>
    );
}
