import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Box,
} from '@mui/material';
import React, { useState } from 'react';
import { useEffect } from 'react';
import WarningIcon from '@mui/icons-material/Warning';

interface DialogWarningProps {
  title: string;
  text: string;
  isOpen: boolean;
  confirmDisabledButton?: boolean;
  cancelDisabledButton?: boolean;
  onCancel: () => void;
  onConfirm: (payload?: any) => void;
  children?: React.ReactNode;
  showConfirmButton?: boolean;
  showCancelButton?: boolean;
  textConfirmButton?: string;
  textCancelButton?: string;
}

export const ClearCartDialog = ({
  title,
  text,
  isOpen,
  confirmDisabledButton = false,
  cancelDisabledButton = false,
  children,
  onCancel,
  onConfirm,
  showConfirmButton = true,
  showCancelButton = true,
  textConfirmButton = 'Confirmar',
  textCancelButton = 'Cancelar',
}: DialogWarningProps) => {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    onCancel();
    setOpen(false);
  };

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              border: '2px solid #baa216',
              padding: '8px',
            }}
          >
            <WarningIcon
              color="error"
              style={{ marginRight: '8px', color: '#baa216' }}
            />
            <span
              style={{
                color: '#baa216',
                fontWeight: 'bold',
                fontFamily: 'Arial',
              }}
            >
              {title}
            </span>
          </Box>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{text}</DialogContentText>
          {children}
        </DialogContent>
        <DialogActions>
          {showCancelButton && (
            <Button
              size="small"
              color="inherit"
              disabled={cancelDisabledButton}
              onClick={handleClose}
            >
              {textCancelButton}
            </Button>
          )}
          {showConfirmButton && (
            <Button
              size="small"
              color="primary"
              type="submit"
              disabled={confirmDisabledButton}
              onClick={onConfirm}
            >
              {textConfirmButton}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};
