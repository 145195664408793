import * as React from 'react';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import { TransitionProps } from '@mui/material/transitions';
import {
  Box,
  Card,
  DialogActions,
  DialogContent,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Slide,
} from '@mui/material';
import TTButton from '../shared/TTButton';
import CreateEventStepper from './CreateEventStepper';
import GeneralInformationStep from './GeneralInformationStep';
import EventImagesStep from './EventImagesStep';
import { useFieldArray, useForm } from 'react-hook-form';
import {
  // general info
  CreateEventGeneralInformationDto,
  createEventGeneralInformationSchema,
  // dates & place info
  eventDatesAndPlacesSchema,
  CreateEventDatesAndPlaceInfoDto,
  // images
  CreateEventImagesDto,
  createEventImagesSchema,
} from 'libs/domain/src/lib/dto';
import { zodResolver } from '@hookform/resolvers/zod';
import PlaceInformationStep from './PlaceInformationStep';
import TTLoadingButton from '../shared/TTLoadingButton';

const initialGeneralInfoFormValues: CreateEventGeneralInformationDto = {
  name: '',
  shortDescription: '',
  longDescription: '',
  category: '',
};

const initialEventDatesAndPlacesFormValues: CreateEventDatesAndPlaceInfoDto = {
  eventDates: [
    {
      date: new Date(),
      from: new Date(),
      to: new Date(),
    },
  ],
  lineAddress: '',
  state: '',
  city: '',
  venueName: '',
};

const initialEventImagesFormValues = {
  bannerImage: null,
  cardImage: null,
  ticketImage: null,
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface CreateEventProps {
  isOpen: boolean;
  onClose: () => void;
  onRegister: (data: FormData) => void;
  isSubmitting?: boolean;
}

export default function CreateEvent({
  isOpen,
  onClose,
  onRegister,
  isSubmitting,
}: CreateEventProps) {
  // States
  const [open, setOpen] = React.useState(isOpen);
  const [activeStep, setActiveStep] = React.useState<number>(0);
  const [scroll, setScroll] = React.useState<DialogProps['scroll']>('paper');

  // Hooks
  const generalInfoForm = useForm<CreateEventGeneralInformationDto>({
    // resolver: customResolver,
    resolver: zodResolver(createEventGeneralInformationSchema),
    defaultValues: initialGeneralInfoFormValues,
  });

  const eventDatesAndPlacesForm = useForm<CreateEventDatesAndPlaceInfoDto>({
    resolver: zodResolver(eventDatesAndPlacesSchema),
    defaultValues: initialEventDatesAndPlacesFormValues,
  });

  const imagesForm = useForm<CreateEventImagesDto>({
    // resolver: customResolver,
    resolver: zodResolver(createEventImagesSchema),
    defaultValues: initialEventImagesFormValues,
  });

  const { fields, append, remove } =
    useFieldArray<CreateEventDatesAndPlaceInfoDto>({
      control: eventDatesAndPlacesForm.control,
      name: 'eventDates',
    });

  // Handlers

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const onAppendEventDate = () => {
    append({ date: new Date(), from: new Date(), to: new Date() });
  };

  const handleRemoveEventDate = (index: number) => {
    remove(index);
  };

  const handleReset = () => {
    setActiveStep(0);
    generalInfoForm.reset(initialGeneralInfoFormValues);
    eventDatesAndPlacesForm.reset(initialEventDatesAndPlacesFormValues);
    imagesForm.reset(initialEventImagesFormValues);
  };

  const handleClose = () => {
    setOpen(false);
    handleReset();
    onClose();
  };

  const onSubmitGeneralInfo = async (
    data: CreateEventGeneralInformationDto,
  ) => {
    handleNext();
  };

  const onSubmitPlaceAndDatesInfo = async (
    data: CreateEventDatesAndPlaceInfoDto,
  ) => {
    handleNext();
  };

  const onSubmitImages = async (data: CreateEventImagesDto) => {
    const generalInfo = generalInfoForm.getValues();
    const placesAndDatesInfo = eventDatesAndPlacesForm.getValues();
    const images = data;
    const formData = new FormData();
    formData.append('name', generalInfo.name);
    formData.append('shortDescription', generalInfo.shortDescription);
    formData.append('longDescription', generalInfo.longDescription);
    formData.append('bannerImage', images.bannerImage);
    formData.append('cardImage', images.cardImage);
    formData.append('ticketImage', images.ticketImage);
    formData.append('lineAddress', placesAndDatesInfo.lineAddress);
    formData.append('category', generalInfo.category);
    formData.append('state', placesAndDatesInfo.state);
    formData.append('city', placesAndDatesInfo.city);
    formData.append('venueName', placesAndDatesInfo.venueName);
    const formattedEventDates = placesAndDatesInfo.eventDates.map(
      (eventDate) => ({
        ...eventDate,
        date: new Date(
          eventDate.date.getTime() - eventDate.date.getTimezoneOffset() * 60000,
        )
          .toISOString()
          .slice(0, -1),
        from: new Date(
          eventDate.from.getTime() - eventDate.from.getTimezoneOffset() * 60000,
        )
          .toISOString()
          .slice(0, -1),
        to: new Date(
          eventDate.to.getTime() - eventDate.to.getTimezoneOffset() * 60000,
        )
          .toISOString()
          .slice(0, -1),
      }),
    );

    formData.append('eventDates', JSON.stringify(formattedEventDates));
    onRegister(formData);
    handleReset();
    onClose();
  };

  // Effects

  React.useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  return (
    <React.Fragment>
      <Dialog
        fullScreen
        open={open}
        scroll={scroll}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar
          sx={{
            position: 'relative',
            backgroundColor: '#890050',
          }}
        >
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography
              sx={{
                ml: 2,
                flex: 1,
                fontFamily: 'Popins, sans-serif',
                fontWeight: 700,
                letterSpacing: '0.7px',
              }}
              variant="h6"
              component="div"
            >
              Nuevo evento
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent dividers>
          <Card
            sx={{
              width: '100%',
              height: '100%',
              backgroundColor: '#f3f3f3',
              p: 2,
              overflowY: 'scroll',
              scrollBehavior: 'smooth',
              borderRadius: '10px',
            }}
          >
            <Box
              component={'div'}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: 2,
              }}
            >
              <Box
                sx={{
                  width: '10%',
                  display: 'flex',
                  gap: 2,
                }}
              >
                <CreateEventStepper currentStep={activeStep} />
              </Box>
              <Box
                sx={{
                  width: '75%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: 2,
                }}
              >
                {activeStep === 0 && (
                  <GeneralInformationStep
                    register={generalInfoForm.register}
                    errors={generalInfoForm.formState.errors}
                    resetField={generalInfoForm.resetField}
                    title="Datos generales del evento"
                  />
                )}

                {activeStep === 1 && (
                  <PlaceInformationStep
                    register={eventDatesAndPlacesForm.register}
                    control={eventDatesAndPlacesForm.control}
                    errors={eventDatesAndPlacesForm.formState.errors}
                    resetField={eventDatesAndPlacesForm.resetField}
                    setValue={eventDatesAndPlacesForm.setValue}
                    fields={fields}
                    onAppend={onAppendEventDate}
                    onRemove={handleRemoveEventDate}
                    title="Datos específicos del evento"
                  />
                )}

                {activeStep === 2 && (
                  <EventImagesStep
                    setValue={imagesForm.setValue}
                    errors={imagesForm.formState.errors}
                    title="Imágenes del evento"
                    eventName={
                      generalInfoForm.getValues().name || 'Nombre del Evento'
                    }
                    venueName={
                      eventDatesAndPlacesForm.getValues().venueName ||
                      'Lugar del Evento'
                    }
                  />
                )}
              </Box>
            </Box>
          </Card>
        </DialogContent>
        <DialogActions>
          <TTButton variant="contained" color="inherit" onClick={handleClose}>
            Cancelar
          </TTButton>

          {activeStep !== 0 && (
            <TTButton
              onClick={handleBack}
              color="secondary"
              variant="contained"
              startIcon={<ArrowBackIcon />}
            >
              Atrás
            </TTButton>
          )}

          {!isSubmitting && (
            <TTButton
              onClick={
                activeStep === 0
                  ? generalInfoForm.handleSubmit(onSubmitGeneralInfo)
                  : activeStep === 1
                    ? eventDatesAndPlacesForm.handleSubmit(
                        onSubmitPlaceAndDatesInfo,
                      )
                    : imagesForm.handleSubmit(onSubmitImages)
              }
              color="secondary"
              variant="contained"
              endIcon={activeStep !== 2 ? <ArrowForwardIcon /> : null}
              startIcon={activeStep === 2 ? <SaveIcon /> : null}
            >
              {activeStep === 2 ? 'Confirmar' : 'Siguiente'}
            </TTButton>
          )}

          {isSubmitting && (
            <TTLoadingButton
              color="primary"
              variant="contained"
              startIcon={<SaveIcon />}
              disabled
            >
              Guardando...
            </TTLoadingButton>
          )}
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
