import { ReactNode, useEffect, useState } from 'react';
import {
  UpdateEventByAdminDto,
  updateEventByAdminSchema,
} from 'libs/domain/src/lib/dto';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Box,
  Button,
  Divider,
  IconButton,
  SxProps,
  Typography,
  MenuItem,
  AppBar,
  Toolbar,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  CardMedia,
  CssBaseline,
} from '@mui/material';
import FullScreenDialog from '../FullScreenDialog';
import EventCardPreview from '../EventCardPreview';
import { Close } from '@mui/icons-material';
import EventBannerPreview from '../EventBannerPreview';
import EventImagesList from '../EventImagesList';
import TTButton from '../shared/TTButton';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import EventTicketPreview from '../EventTicketPreview';
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import t1 from '../../images/t1.png';
import LogoutIcon from '@mui/icons-material/Logout';
import LogoutDialogAdmin from '../LogoutDialogAdmin';

interface EventFormProps {
  onUpdateEvent: (data: FormData) => void;
  eventData: UpdateEventByAdminDto;
  isOpen: boolean;
  handleCloseUpdate: () => void;
}

const BoxContainerStyled = ({
  children,
  sx,
}: {
  children: ReactNode;
  sx?: SxProps;
}) => (
  <Box
    sx={{
      borderRadius: '10px',
      backgroundColor: '#fff',
      p: 4,
      m: 2,
      '& .MuiOutlinedInput-root': {
        backgroundColor: 'white !important',
      },
      ...sx,
    }}
  >
    {children}
  </Box>
);

export default function UpdateEventDialog({
  isOpen,
  eventData,
  onUpdateEvent,
  handleCloseUpdate,
}: EventFormProps) {
  const [open, setOpen] = useState(false);
  const [openUploadCardImageModal, setOpenUploadCardImageModal] =
    useState(false);
  const [openUploadBannerImageModal, setOpenUploadBannerImageModal] =
    useState(false);
  const [openUploadTicketImageModal, setOpenUploadTicketImageModal] =
    useState(false);
  const [, setBannerImage] = useState<File | null>(null);
  const [bannerImageStr, setBannerImageStr] = useState<string | null>('');
  const [cardImage, setCardImage] = useState<File | null>(null);
  const [cardImageStr, setCardImageStr] = useState<string | null>('');
  const [, setTicketImage] = useState<File | null>(null);
  const [ticketImageStr, setTicketImageStr] = useState<string | null>('');
  const [openLogoutDialog, setOpenLogoutDialog] = useState(false);

  const handleOpenLogoutDialog = () => {
    setOpenLogoutDialog(true);
  };

  const handleCloseLogoutDialog = () => {
    setOpenLogoutDialog(false);
  };

  const handleClose = () => {
    reset();
    handleCloseUpdate();
    setOpen(false);
  };

  const {
    setValue,
    register,
    reset,
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
    getValues,
  } = useForm<UpdateEventByAdminDto>({
    resolver: zodResolver(updateEventByAdminSchema),
  });

  const values = getValues();

  const onSubmit = async (data: UpdateEventByAdminDto) => {
    const formData = new FormData();
    formData.append('name', data.name);
    formData.append('shortDescription', data.shortDescription);
    formData.append('longDescription', data.longDescription);

    if (data.bannerImage) {
      formData.append('bannerImage', data.bannerImage);
    }

    if (data.cardImage) {
      formData.append('cardImage', data.cardImage);
    }

    if (data.ticketImage) {
      formData.append('ticketImage', data.ticketImage);
    }

    formData.append('status', data.status);
    formData.append('lineAddress', data.lineAddress);
    formData.append('category', data.category);
    formData.append('state', data.state);
    formData.append('city', data.city);
    formData.append('venueName', data.venueName);

    const formattedEventDates = data.eventDates.map((eventDate) => ({
      ...eventDate,
      date: new Date(
        eventDate.date.getTime() - eventDate.date.getTimezoneOffset() * 60000,
      )
        .toISOString()
        .slice(0, -1),
      from: new Date(
        eventDate.from.getTime() - eventDate.from.getTimezoneOffset() * 60000,
      )
        .toISOString()
        .slice(0, -1),
      to: new Date(
        eventDate.to.getTime() - eventDate.to.getTimezoneOffset() * 60000,
      )
        .toISOString()
        .slice(0, -1),
    }));

    formData.append('eventDates', JSON.stringify(formattedEventDates));

    formData.append(
      'eventDatesToDelete',
      JSON.stringify(data.eventDatesToDelete),
    );

    onUpdateEvent(formData);
    setBannerImage(null);
    setCardImage(null);
    setTicketImage(null);
  };

  const closeUploadCardImageModal = () => {
    setOpenUploadCardImageModal(false);
  };

  const closeUploadBannerImageModal = () => {
    setOpenUploadBannerImageModal(false);
  };

  const closeUploadTicketImageModal = () => {
    setOpenUploadTicketImageModal(false);
  };

  const handleAddNewEventDate = () => {
    append({
      id: null,
      date: null,
      from: null,
      to: null,
    });
  };

  const { fields, append, remove } = useFieldArray<UpdateEventByAdminDto>({
    control: control,
    name: 'eventDates',
  });

  const handleRemoveEventDate = (index: number) => {
    const eventDate = eventData.eventDates[index];
    const eventDatesToDelete = values.eventDatesToDelete?.filter(
      (id: string) => id !== eventDate.id,
    ) as string[] | [];
    remove(index);
    if (eventDate.id) {
      setValue('eventDatesToDelete', [...eventDatesToDelete, eventDate.id]);
    }
  };

  const handleBannerImageSave = (file: File) => {
    closeUploadBannerImageModal();
    setBannerImage(file);
    setValue('bannerImage', file);
    setBannerImageStr(URL.createObjectURL(file));
  };

  const handleBannerImageRemove = () => {
    setBannerImageStr('');
    closeUploadBannerImageModal();
  };

  const handleCardImageSave = (file: File) => {
    closeUploadCardImageModal();
    setCardImage(file);
    setValue('cardImage', file);
    setCardImageStr(URL.createObjectURL(file));
  };

  const handleCardImageRemove = () => {
    setCardImageStr('');
    closeUploadCardImageModal();
  };

  const handleTicketImageSave = (file: File) => {
    closeUploadTicketImageModal();
    setTicketImage(file);
    setValue('ticketImage', file);
    setTicketImageStr(URL.createObjectURL(file));
  };

  const handleTicketImageRemove = () => {
    setTicketImageStr('');
    closeUploadTicketImageModal();
  };

  useEffect(() => {
    setOpen(isOpen);
    reset();
  }, [isOpen]);

  useEffect(() => {
    reset({
      name: eventData?.name || '',
      shortDescription: eventData?.shortDescription || '',
      longDescription: eventData?.longDescription || '',
      category: eventData?.category || '',
      city: eventData?.city || '',
      state: eventData?.state || '',
      lineAddress: eventData?.lineAddress || '',
      venueName: eventData?.venueName || '',
      status: eventData?.status || '',
      eventDates: eventData?.eventDates || [
        {
          id: null,
          date: null,
          from: null,
          to: null,
        },
      ],
      eventDatesToDelete: [],
    });

    if (eventData?.cardImage) {
      setCardImageStr(eventData.cardImage);
    }

    if (eventData?.bannerImage) {
      setBannerImageStr(eventData.bannerImage);
    }

    if (eventData?.ticketImage) {
      setTicketImageStr(eventData.ticketImage);
    }
  }, [eventData]);

  return (
    <Box>
      <LogoutDialogAdmin
        isOpen={openLogoutDialog}
        onCloseDialog={handleCloseLogoutDialog}
        title="Cerrar sesión"
        subtitle="¿Estás seguro que quieres cerrar la sesión?"
      />
      <CssBaseline />
      <Dialog open={open} onClose={handleClose} fullScreen>
        <AppBar
          sx={{
            position: 'relative',
            backgroundColor: '#890050',
          }}
        >
          <Toolbar
            sx={{
              justifyContent: 'space-between',
              position: 'relative',
            }}
          >
            <Box
              sx={{
                justifyContent: 'space-evenly',
                display: 'flex',
              }}
            >
              <CardMedia
                component="img"
                alt="Imagen"
                height="35"
                image={t1}
                sx={{
                  boxShadow: 'none',
                  border: 'none',
                }}
              />
            </Box>
            <Button
              sx={{
                display: 'flex',
                border: 0,
                boxShadow: 0,
                fontSize: '15px',
                fontWeight: 750,
                textTransform: 'initial',
                backgroundColor: '#890050',
                '&:hover': {
                  background: '#890050',
                  color: (theme) => theme.palette.common.white,
                  border: 'none',
                },
              }}
              variant="contained"
              onClick={handleOpenLogoutDialog}
              startIcon={
                <LogoutIcon sx={{ color: 'white', position: 'start' }} />
              }
            >
              Salir
            </Button>
          </Toolbar>
        </AppBar>
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          method="post"
          action=""
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            width: '100%',
            padding: '20px',
            background: '#D9D9D9',
          }}
        >
          <DialogContent>
            {/* General info */}
            <Box
              sx={{
                ml: '2px',
                width: '50%',
                display: 'flex',
                flexDirection: 'row',
                position: 'relative',
                alignSelf: 'self-start',
              }}
            >
              <IconButton
                onClick={handleClose}
                aria-label="close"
                sx={{
                  width: 48,
                  height: 48,
                }}
              >
                <ArrowBackIcon
                  sx={{
                    color: '#444E6A',
                    width: 40,
                    height: 40,
                  }}
                />
              </IconButton>
              <Typography
                gutterBottom
                sx={{
                  letterSpacing: '0.5px',
                  ml: 2.25,
                  color: '#444E6A',
                  lineHeight: 1.3,
                  fontSize: { xs: '24px', sm: '30px', md: '35px' },
                  fontWeight: 900,
                  alignSelf: 'self-start',
                }}
              >
                Actualizar datos del evento{' '}
              </Typography>
            </Box>
            <BoxContainerStyled>
              <DialogContentText sx={{ mb: 1 }} component="span">
                <Typography
                  gutterBottom
                  sx={{
                    fontFamily: 'Popins, sans-serif',
                    fontWeight: 800,
                    letterSpacing: '0.5px',
                    mt: 1,
                    ml: 1,
                    color: '#444E6A',
                    lineHeight: 1.5,
                    fontSize: '1.5rem',
                  }}
                >
                  Datos generales del evento{' '}
                </Typography>
              </DialogContentText>
              <TextField
                {...register('name')}
                autoFocus
                id="outlined-required"
                label="Nombre del evento."
                fullWidth
                margin="normal"
                size="small"
                className="customTextField"
                defaultValue={eventData?.name || ''}
                error={!!errors.name}
                helperText={errors.name?.message}
              />
              <TextField
                {...register('shortDescription')}
                id="short-description"
                label="Agrega una breve descripción"
                fullWidth
                margin="normal"
                size="small"
                className="customTextField"
                defaultValue={eventData?.shortDescription || ''}
                error={!!errors.shortDescription}
                helperText={errors.shortDescription?.message}
              />
              <TextField
                {...register('longDescription')}
                multiline
                rows={4}
                id="long-description"
                label="Agrega una descripción del evento más detallada"
                fullWidth
                margin="normal"
                size="small"
                className="customTextField"
                defaultValue={eventData?.longDescription || ''}
                error={!!errors.longDescription}
                helperText={errors.longDescription?.message}
              />

              <Box sx={{ marginTop: '10px' }}>
                <TextField
                  {...register('category')}
                  fullWidth
                  size="small"
                  id="select-category"
                  select
                  label="Categoría del evento"
                  defaultValue={eventData.category}
                  className="customTextField"
                  SelectProps={{
                    MenuProps: {
                      classes: {
                        paper: 'customTextPaper',
                      },
                    },
                  }}
                >
                  {[
                    { value: 'MUSICA', label: 'Musica' },
                    { value: 'DEPORTES', label: 'Deportes' },
                    { value: 'ENTRETENIMIENTO', label: 'Entretenimiento' },
                  ].map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
            </BoxContainerStyled>
            {/* General info */}

            {/* Event Dates */}
            <BoxContainerStyled sx={{ position: 'relative' }}>
              <DialogContentText sx={{ mt: 2, mb: 1 }} component="span">
                <Button
                  className="customOutlinedButton"
                  aria-label="display-row"
                  onClick={handleAddNewEventDate}
                  disabled={fields.length >= 3}
                >
                  {'Agregar fecha'}
                  &nbsp;
                  <AddIcon />
                </Button>
                <Typography
                  gutterBottom
                  sx={{
                    fontFamily: 'Popins, sans-serif',
                    fontWeight: 800,
                    letterSpacing: '0.5px',
                    mt: 1,
                    ml: 1,
                    color: '#444E6A',
                    lineHeight: 1.5,
                    fontSize: '1.5rem',
                  }}
                >
                  Fechas y horarios - Máximo 3 fechas
                </Typography>
              </DialogContentText>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                {fields.map((eventDate, index) => (
                  <Box key={`${eventDate.id}`}>
                    <DemoContainer
                      key={eventDate.id}
                      components={['DatePicker', 'TimePicker']}
                    >
                      <DemoItem label={'Fecha del evento'}>
                        <Controller
                          name={`eventDates.${index}.date`}
                          control={control}
                          render={({
                            field: { value, onChange },
                            fieldState: { error },
                          }) => (
                            <Box
                              sx={{ display: 'flex', flexDirection: 'column' }}
                            >
                              <DatePicker
                                value={value}
                                onChange={(value) => onChange(value)}
                                views={['year', 'month', 'day']}
                                format="yyyy-MM-dd"
                                slotProps={{ textField: { size: 'small' } }}
                                className="customTextField"
                              />
                              {errors?.eventDates?.[index]?.date?.message && (
                                <Typography variant="caption" color="error">
                                  {errors?.eventDates?.[index]?.date?.message}
                                </Typography>
                              )}
                            </Box>
                          )}
                        />
                      </DemoItem>
                      <DemoItem label={'Hora de inicio'}>
                        <Controller
                          name={`eventDates.${index}.from`}
                          control={control}
                          render={({
                            field: { value, onChange },
                            fieldState: { error },
                          }) => (
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                width: 150,
                              }}
                            >
                              <TimePicker
                                value={value}
                                onChange={(value) => onChange(value)}
                                views={['hours', 'minutes']}
                                format="HH:mm"
                                ampm={false}
                                slotProps={{ textField: { size: 'small' } }}
                                className="customTextField"
                              />
                              {errors?.eventDates?.[index]?.from?.message && (
                                <Typography variant="caption" color="error">
                                  {errors?.eventDates?.[index]?.from?.message}
                                </Typography>
                              )}
                            </Box>
                          )}
                        />
                      </DemoItem>
                      <DemoItem label={'Hora de cierre'}>
                        <Controller
                          name={`eventDates.${index}.to`}
                          control={control}
                          render={({
                            field: { value, onChange },
                            fieldState: { error },
                          }) => (
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                width: 150,
                              }}
                            >
                              <TimePicker
                                value={value}
                                onChange={(value) => onChange(value)}
                                views={['hours', 'minutes']}
                                format="HH:mm"
                                ampm={false}
                                slotProps={{ textField: { size: 'small' } }}
                                className="customTextField"
                              />
                              {errors?.eventDates?.[index]?.to?.message && (
                                <Typography variant="caption" color="error">
                                  {errors?.eventDates?.[index]?.to?.message}
                                </Typography>
                              )}
                            </Box>
                          )}
                        />
                      </DemoItem>
                      <DemoItem>
                        <IconButton
                          title={'Eliminar fecha'}
                          disabled={fields.length === 1}
                          sx={{ mt: 3.2 }}
                          onClick={() => handleRemoveEventDate(index)}
                          aria-label="close"
                        >
                          <Close />
                        </IconButton>
                      </DemoItem>
                    </DemoContainer>
                    {index !== fields.length - 1 && (
                      <Divider
                        sx={{
                          mt: 1,
                          mb: 1,
                        }}
                      />
                    )}
                  </Box>
                ))}
              </LocalizationProvider>
            </BoxContainerStyled>
            {/* Event Dates */}

            {/* Place informacion */}
            <BoxContainerStyled>
              <DialogContentText sx={{ mt: 2, mb: 1 }} component="span">
                <Typography
                  gutterBottom
                  sx={{
                    fontFamily: 'Popins, sans-serif',
                    fontWeight: 800,
                    letterSpacing: '0.5px',
                    ml: 1,
                    color: '#444E6A',
                    lineHeight: 1.5,
                    fontSize: '1.5rem',
                  }}
                >
                  Datos específicos del evento{' '}
                </Typography>
              </DialogContentText>
              <TextField
                {...register('lineAddress')}
                id="outlined-required"
                label="Dirección donde se llevará a cabo el evento"
                fullWidth
                margin="normal"
                size="small"
                defaultValue={eventData?.lineAddress || ''}
                error={!!errors.lineAddress}
                helperText={errors.lineAddress?.message}
                className="customTextField"
              />
              <TextField
                {...register('state')}
                id="outlined-required"
                label="Estado donde se llevará a cabo el evento"
                fullWidth
                margin="normal"
                defaultValue={''}
                size="small"
                error={!!errors.state}
                helperText={errors.state?.message}
                className="customTextField"
              />
              <TextField
                {...register('city')}
                id="outlined-required"
                label="Ciudad donde se llevará a cabo el evento"
                fullWidth
                margin="normal"
                defaultValue={''}
                size="small"
                error={!!errors.city}
                helperText={errors.city?.message}
                className="customTextField"
              />
              <TextField
                {...register('venueName')}
                id="outlined-required"
                label="Escenario donde se llevará a cabo el evento"
                fullWidth
                margin="normal"
                defaultValue={''}
                size="small"
                error={!!errors.venueName}
                helperText={errors.venueName?.message}
                className="customTextField"
              />
            </BoxContainerStyled>
            {/* Place informacion */}

            {/* Images */}
            <BoxContainerStyled>
              <DialogContentText sx={{ mt: 2, mb: 1 }} component="span">
                <Typography
                  gutterBottom
                  sx={{
                    fontFamily: 'Popins, sans-serif',
                    fontWeight: 800,
                    letterSpacing: '0.5px',
                    ml: 1,
                    color: '#444E6A',
                    lineHeight: 1.5,
                    fontSize: '1.5rem',
                  }}
                >
                  Imágenes del evento
                </Typography>
              </DialogContentText>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-around',
                  flexDirection: 'row',
                  alignItems: 'center',
                  padding: '25px',
                }}
              >
                <EventImagesList
                  bannerImage={{
                    image: bannerImageStr,
                    title: 'Imagen del banner',
                    subtitle:
                      'Se mostrará en la parte superior de la página del evento.',
                    onChangeImage: () => setOpenUploadBannerImageModal(true),
                    onRemoveImage: handleBannerImageRemove,
                    error: errors.bannerImage?.message as string,
                  }}
                  cardImage={{
                    image: cardImageStr,
                    title: 'Imagen de la tarjeta',
                    subtitle: 'Se mostrará esta imagen en la página principal.',
                    onChangeImage: () => setOpenUploadCardImageModal(true),
                    onRemoveImage: handleCardImageRemove,
                    error: errors.cardImage?.message as string,
                  }}
                  ticketImage={{
                    image: ticketImageStr,
                    title: 'Imagen del boleto',
                    subtitle:
                      'Esta imagen se mostrará en los boletos del evento.',
                    onChangeImage: () => setOpenUploadTicketImageModal(true),
                    onRemoveImage: handleTicketImageRemove,
                    error: errors.ticketImage?.message as string,
                  }}
                />
              </Box>
            </BoxContainerStyled>
            {/* Images */}
          </DialogContent>
          <DialogActions>
            <TTButton variant="contained" color="inherit" onClick={handleClose}>
              Cancelar
            </TTButton>
            <TTButton
              variant="contained"
              color="secondary"
              type="submit"
              disabled={isSubmitting}
              onClick={handleSubmit(onSubmit)}
            >
              Guardar
            </TTButton>
          </DialogActions>
          <FullScreenDialog
            title="Agregar imagen del banner para el evento"
            open={openUploadBannerImageModal}
            handleClose={closeUploadBannerImageModal}
          >
            <EventBannerPreview
              currentImage={bannerImageStr}
              onSave={handleBannerImageSave}
              onCancel={closeUploadBannerImageModal}
            />
          </FullScreenDialog>
          <FullScreenDialog
            title="Agregar imagen de la tarjeta para el evento"
            open={openUploadCardImageModal}
            handleClose={closeUploadCardImageModal}
          >
            <EventCardPreview
              name={values.name}
              venueName={values.venueName}
              image={cardImage}
              currentImage={cardImageStr}
              onSave={handleCardImageSave}
              onCancel={handleCardImageRemove}
            />
          </FullScreenDialog>
          <FullScreenDialog
            title="Agregar imagen del boleto para el evento"
            open={openUploadTicketImageModal}
            handleClose={closeUploadTicketImageModal}
          >
            <EventTicketPreview
              currentImage={ticketImageStr}
              onSave={handleTicketImageSave}
              onCancel={handleTicketImageRemove}
            />
          </FullScreenDialog>
        </Box>
      </Dialog>
    </Box>
  );
}
