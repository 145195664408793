import React from 'react';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Modal,
  Typography,
  Button,
} from '@mui/material';
import { useAuth } from '../../providers/AuthProvider';
import { useNavigate } from 'react-router-dom';

interface LogoutDialogAdminProps {
  isOpen: boolean;
  onCloseDialog: () => void;
  title: string;
  subtitle?: string | null;
}

export default function LogoutDialogAdmin({
  isOpen,
  onCloseDialog,
  title,
  subtitle,
}: LogoutDialogAdminProps) {
  const { logout } = useAuth();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(isOpen);

  const handleBackdropClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
  };

  React.useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  const handleLogout = async () => {
    await logout();
    navigate('/');
  };

  return (
    <Modal
      open={open}
      onClose={onCloseDialog}
      aria-labelledby="modal-modal-title"
      slotProps={{ backdrop: { onClick: handleBackdropClick } }}
      disableEscapeKeyDown
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '10%',
        }}
      >
        <Card
          sx={{
            borderRadius: '10px',
            width: '800px',
            display: 'flex',
            flexDirection: 'column',
            padding: '0px',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CardHeader
            sx={{
              backgroundColor: '#890050',
              width: '100%',
              height: '10%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              borderTopLeftRadius: '10px', // Optional: Matches the card's border radius
              borderTopRightRadius: '10px',
            }}
          ></CardHeader>
          <CardContent
            sx={{
              width: '100%',
              height: '100%',
              padding: '0px',
              borderBottomLeftRadius: '10px', // Optional: Matches the card's border radius
              borderBottomRightRadius: '10px',
            }}
          >
            <Box
              sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'space-evenly',
              }}
            >
              <Typography
                sx={{
                  color: (theme) => theme.palette.primary.main,
                  fontSize: '2em',
                  fontWeight: 'bold',
                }}
              >
                {title}
              </Typography>
              <Typography sx={{ textAlign: 'center', width: '57%' }}>
                {subtitle}
              </Typography>
              <Box
                sx={{
                  width: '80%',
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'space-evenly',
                }}
              >
                <Button
                  sx={{
                    width: '50%',
                    background: (theme) => theme.palette.common.white,
                    border: '2px solid #48002A',
                    fontWeight: 'bold',
                    margin: '10px',
                    color: '#890050',
                    '&:hover': {
                      background: '#890050',
                      color: (theme) => theme.palette.common.white,
                      border: 'none',
                    },
                  }}
                  onClick={onCloseDialog}
                >
                  Cancelar
                </Button>
                <Button
                  sx={{
                    width: '50%',
                    fontWeight: 'bold',
                    marginBottom: '10px',
                    background: '#890050',
                    color: (theme) => theme.palette.common.white,
                    '&:hover': {
                      background: (theme) => theme.palette.primary.main,
                      color: (theme) => theme.palette.common.white,
                      border: 'none',
                    },
                  }}
                  onClick={handleLogout}
                >
                  Aceptar
                </Button>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Modal>
  );
}
