import { z } from 'zod';

export const dateISORegex =
  /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})\.\d{3}Z$/;

export const registerSchema = z
  .object({
    firstName: z.preprocess(
      (val) => (val === '' ? undefined : val),
      z
        .string({
          required_error: 'Por favor, ingrese sus nombres.',
        })
        .regex(
          /^[a-zA-ZáéíóúÁÉÍÓÚüÜñÑ]+(?: [a-zA-ZáéíóúÁÉÍÓÚüÜñÑ]+)*$/,
          'Los nombres deben contener solo letras.',
        ),
    ),
    lastName: z.preprocess(
      (val) => (val === '' ? undefined : val),
      z
        .string({
          required_error: 'Por favor, ingrese sus apellidos.',
        })
        .regex(
          /^[a-zA-ZáéíóúÁÉÍÓÚüÜñÑ]+(?: [a-zA-ZáéíóúÁÉÍÓÚüÜñÑ]+)*$/,
          'Los apellidos deben contener solo letras.',
        ),
    ),
    email: z
      .string({
        required_error: 'Por favor, ingrese su correo electrónico.',
      })
      .email('Por favor, ingrese un email válido.'),
    password: z.preprocess(
      (val) => (val === '' ? undefined : val),
      z
        .string({
          required_error: 'Por favor, ingrese su contraseña.',
        })
        .min(8, 'La contraseña debe tener al menos 8 caracteres.'),
    ),
    confirmPassword: z.preprocess(
      (val) => (val === '' ? undefined : val),
      z
        .string({
          required_error: 'Por favor, confirme su contraseña.',
        })
        .min(8, 'La contraseña debe tener al menos 8 caracteres.'),
    ),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: 'Las contraseñas no coinciden.',
    path: ['confirmPassword'],
  });

export type RegisterDto = z.infer<typeof registerSchema>;
