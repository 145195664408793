import { Box } from '@mui/material';
import { useCallback, useState } from 'react';
import SeatsSelector from './SeatsSelector';
import { AddToCart } from '../../requests/carts';
import { isAxiosError } from 'axios';
import AlertMessage from '../components/AlertMessage';
import { CreateCartWithItemsDto } from 'libs/domain/src/lib/dto/Cart/index';
import ChangeImage from './ChangeImageComponent';
import { EventEntity } from 'libs/domain/src/lib/entities/Event';
import { SectionEntity } from 'libs/domain/src/lib/entities/Section';
import { useCart } from '../hooks/useCart';

interface EventSeatsSelector {
  event: EventEntity | null;
}

export default function EventSeatsSelector({ event }: EventSeatsSelector) {
  const { refreshCart } = useCart();
  const [section, setSection] = useState<SectionEntity | null | undefined>(
    null,
  );
  const [alertProps, setAlertprops] = useState<{
    isOpen: boolean;
    severity: 'success' | 'error' | 'info' | 'warning';
    message: string;
  }>({
    isOpen: false,
    severity: 'success',
    message: '',
  });

  const handleCloseAlert = () => {
    setAlertprops({
      isOpen: false,
      severity: 'success',
      message: '',
    });
  };

  const handleSection = (section: SectionEntity | undefined) => {
    setSection(section);
  };

  const submit = useCallback(
    async (data: CreateCartWithItemsDto) => {
      const { response, status, error } = await AddToCart(data);

      if (status === 'OK') {
        const successMessage =
          response.message || '¡Ítems agregados al carrito exitosamente!';
        setAlertprops({
          isOpen: true,
          severity: 'success',
          message: successMessage,
        });
        refreshCart();
      }

      if (status === 'ERROR') {
        if (isAxiosError(error)) {
          const errorAddItemsMessage =
            error.response?.data.message || 'Error al registrar los ítems.';
          setAlertprops({
            isOpen: true,
            severity: 'error',
            message: errorAddItemsMessage,
          });
        }
      }
    },
    [refreshCart],
  );

  return (
    <Box
      id="event-seats-selector"
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', lg: 'row' },
        justifyContent: 'center',
        padding: '55px 0',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          width: {
            xs: '700px',
            sm: '900px',
            md: '1050px',
            lg: '1200px',
            xl: '1200px',
          },
          height: 'auto',
          display: 'flex',
          alignItems: 'center',
          flexDirection: { xs: 'column', md: 'row' },
          justifyContent: 'space-around',
          borderRadius: '20px',
        }}
      >
        <ChangeImage section={section} />
        <SeatsSelector
          event={event}
          sectionSelected={handleSection}
          addDataToCart={submit}
        />
      </Box>
      <AlertMessage
        onClose={handleCloseAlert}
        isOpen={alertProps.isOpen}
        message={alertProps.message}
        severity={alertProps.severity}
      />
    </Box>
  );
}
