import * as React from 'react';
import LoadingButton, { LoadingButtonProps } from '@mui/lab/LoadingButton';
import { SxProps } from '@mui/material';

interface TTLoadingButtonProps extends LoadingButtonProps {
  children: React.ReactNode;
  onClick?: () => void;
  variant?: 'text' | 'outlined' | 'contained';
  color?:
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'error'
    | 'info'
    | 'warning';
  size?: 'small' | 'medium' | 'large';
  disabled?: boolean;
  type?: 'button' | 'submit' | 'reset';
  sx?: SxProps;
}

const getPropColor = (color: string | undefined) => {
  switch (color) {
    case 'primary':
      return '#3f51b5';
    case 'secondary':
      return '#f50057';
    case 'success':
      return '#4caf50';
    case 'error':
      return '#f44336';
    case 'info':
      return '#2196f3';
    case 'warning':
      return '#ff9800';
    case 'inherit':
      return '#cfcfcf';
    default:
      return 'white';
  }
};

export default function TTLoadingButton({
  children,
  ...props
}: TTLoadingButtonProps) {
  return (
    <LoadingButton
      loading
      {...props}
      sx={{
        ...props.sx,
        textTransform: 'none',
        fontSize: '1rem',
        fontFamily: 'Popins, sans-serif',
        fontWeight: 600,
        letterSpacing: '0.7px',
        borderRadius: '5px',
        padding: '5px 15px',
        backgroundColor: props.color ? getPropColor(props.color) : 'white',
        boxShadow: 'none',
        '&:hover': {
          // with shadow
          boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.2)',
        },
      }}
    >
      {children}
    </LoadingButton>
  );
}
