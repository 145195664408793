import React from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import {
  AddNewEventDate,
  PublishEvent,
  getEventById,
} from '../../requests/events';
import {
  AddSection,
  CreateRowsWithSeats,
  DeleteSection,
  UpdateSection,
} from '../../requests/sections';
import AddNewSection from '../components/sections/AddSection';
import { isAxiosError } from 'axios';
import AlertMessage from '../components/AlertMessage';
import AddRowsWithSeats from '../components/rows/AddRowsWithSeats';
import AddEventDate from '../components/eventDates/AddEventDateModal';
import { CreateDatesDto } from 'libs/domain/src/lib/dto';
import { EventEntity } from 'libs/domain/src/lib/entities/Event';
import { NewEventCardPreview } from '../components/NewEventCardPreview';
import SectionAndRows from '../components/events/SectionsAndRows';
import AddTableRowModal from '../components/rows/AddTableRowModal';
import DefaultDialog from '../components/DefaultDialog';
import { DeleteRowById } from '../../requests/rows';
import UpdateSectionDialog from '../components/sections/UpdateSectionsDialog';
import { SectionEntity } from 'libs/domain/src/lib/entities/Section';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export const PreviewRoute = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [event, setEvent] = React.useState<EventEntity | null>(null);
  const [loading, setLoading] = React.useState(true);
  const [section, setSection] = React.useState<SectionEntity | null>(null);
  const [sectionId, setSectionId] = React.useState<string | null>(null);
  const [rowId, setRowId] = React.useState<string | null>(null);
  const [eventId, setEventId] = React.useState<string | null>(null);
  const [openRegisterSectionModal, setOpenRegisterSectionModal] =
    React.useState(false);
  const [openAddRowsModal, setOpenAddRowsModal] = React.useState(false);
  const [openAddTableRowsModal, setOpenAddTableRowsModal] =
    React.useState(false);
  const [openDeleteRowModal, setOpenDeleteRowModal] = React.useState(false);
  const [openRegisterEventDates, setOpenRegisterEventDates] =
    React.useState(false);
  const [openDeleteSectionModal, setOpenDeleteSectionModal] =
    React.useState(false);
  const [openUpdateSectionModal, setOpenUpdateSectionModal] =
    React.useState(false);

  const [alertProps, setAlertprops] = React.useState<{
    isOpen: boolean;
    severity: 'success' | 'error' | 'info' | 'warning';
    message: string;
  }>({
    isOpen: false,
    severity: 'success',
    message: '',
  });
  /* Handle functions*/

  const handleCloseAlert = () => {
    setAlertprops({
      isOpen: false,
      severity: 'success',
      message: '',
    });
  };

  const handleOpenRegisterSectionModal = () => {
    setOpenRegisterSectionModal(true);
  };

  const handleCloseRegisterSectionModal = () => {
    setOpenRegisterSectionModal(false);
  };

  const handleOpenAddRowsModal = (sectionId: string) => {
    setSectionId(sectionId);
    setOpenAddRowsModal(true);
  };

  const handleOpenAddTableRowsModal = (sectionId: string) => {
    setSectionId(sectionId);
    setOpenAddTableRowsModal(true);
  };

  const handleCloseAddTableRowsModal = () => {
    setOpenAddTableRowsModal(false);
  };

  const handleCloseAddRowsModal = () => {
    setOpenAddRowsModal(false);
  };

  const handleCloseAddEventDateModal = () => {
    setOpenRegisterEventDates(false);
  };

  const handleOpenDelteRowModal = (rowId: string) => {
    setRowId(rowId);
    setOpenDeleteRowModal(true);
  };

  const handleCloseDeleteRowModal = () => {
    setRowId(null);
    setOpenDeleteRowModal(false);
  };

  const handleOpenDeleteSectionModal = (sectionId: string) => {
    setSectionId(sectionId);
    setOpenDeleteSectionModal(true);
  };

  const handleCloseDeleteSectionModal = () => {
    setSectionId(null);
    setOpenDeleteSectionModal(false);
  };

  const handleOpenUpdateSectionModal = (section: SectionEntity) => {
    setSectionId(section.id);
    setSection(section);
    setOpenUpdateSectionModal(true);
  };

  const handleCloseUpdateSectionModal = () => {
    setSectionId(null);
    setSection(null);
    setOpenUpdateSectionModal(false);
  };

  /* Fetch Events */
  const fetchEvent = async () => {
    if (!id) {
      return;
    }
    const { response, status } = await getEventById(id);
    if (status === 'OK') {
      const eventData = response.data.event;
      setEvent(eventData);
    }
  };

  /* Create, update and delete functions */
  const createNewSection = async (data: FormData) => {
    const { status, error } = await AddSection(data);

    if (status === 'OK') {
      setAlertprops({
        isOpen: true,
        severity: 'success',
        message: '¡Sección registrada exitosamente!',
      });
      setOpenRegisterSectionModal(false);
      fetchEvent();
    }

    if (status === 'ERROR') {
      if (isAxiosError(error)) {
        const errorCreateSectionMessage =
          error.response?.data.message || 'Error al registrar la sección.';
        setAlertprops({
          isOpen: true,
          severity: 'error',
          message: errorCreateSectionMessage,
        });
      }
    }
  };

  /* Create Row With Seats */
  const createRowWithSeats = async (data: FormData) => {
    if (!sectionId) {
      return;
    }
    const { response, status, error } = await CreateRowsWithSeats(
      sectionId,
      data,
    );
    if (status === 'OK') {
      const successMessageCreateRow =
        response.message || '¡Nueva fila creada correctamente!';
      setAlertprops({
        isOpen: true,
        severity: 'success',
        message: successMessageCreateRow,
      });
      setOpenAddTableRowsModal(false);
      fetchEvent();
    }
    if (status === 'ERROR') {
      const errorMessageCreateRow =
        error.response.data.error || 'No se ha podido crear la fila.';
      setAlertprops({
        isOpen: true,
        severity: 'error',
        message: errorMessageCreateRow,
      });
      setOpenAddTableRowsModal(false);
    }
  };

  /* Create Event Dates */
  const createEventDates = async (data: CreateDatesDto) => {
    if (!eventId) {
      return;
    }
    const { response, status, error } = await AddNewEventDate(eventId, data);
    if (status === 'OK') {
      const successMessageCreateEventDate =
        response.message || '¡Nuevas fechas creadas correctamente!';
      setAlertprops({
        isOpen: true,
        severity: 'success',
        message: successMessageCreateEventDate,
      });
      setOpenRegisterEventDates(false);
    }

    if (status === 'ERROR') {
      if (isAxiosError(error)) {
        const errorCreateEventDateMessage =
          error.response?.data.message || 'Error al registrar las fechas.';
        setAlertprops({
          isOpen: true,
          severity: 'error',
          message: errorCreateEventDateMessage,
        });
      }
    }
  };

  /* Update Section */
  const updateSection = async (data: FormData) => {
    if (!sectionId) {
      return;
    }
    const { response, status, error } = await UpdateSection(sectionId, data);
    if (status === 'OK') {
      const successMessageUpdateSection =
        response.data.message || '¡Sección actualizada correctamente!';
      setAlertprops({
        isOpen: true,
        severity: 'success',
        message: successMessageUpdateSection,
      });
      setOpenUpdateSectionModal(false);
      fetchEvent();
    }
    if (status === 'ERROR') {
      const errorMessageUpdateSection =
        error.response.error || 'No se ha podido actualizar la sección.';
      setAlertprops({
        isOpen: true,
        severity: 'error',
        message: errorMessageUpdateSection,
      });
      setOpenUpdateSectionModal(false);
    }
  };

  /* Delete Section */
  const deleteSection = async () => {
    if (!sectionId) {
      return;
    }
    const { response, status, error } = await DeleteSection(sectionId);
    if (status === 'OK') {
      const successMessageDeleteSection =
        response.data.message || '¡Sección eliminada correctamente!';
      setAlertprops({
        isOpen: true,
        severity: 'success',
        message: successMessageDeleteSection,
      });
      setOpenDeleteSectionModal(false);
      fetchEvent();
    }
    if (status === 'ERROR') {
      console.log(error);
      const errorMessageDeleteSection =
        error.response.error ||
        error.response.data.message ||
        'No se ha podido eliminar la sección.';
      setAlertprops({
        isOpen: true,
        severity: 'error',
        message: errorMessageDeleteSection,
      });
      setOpenDeleteSectionModal(false);
    }
  };

  /* Delete Row */
  const deleteRow = async () => {
    if (!rowId) {
      return;
    }
    const { response, status, error } = await DeleteRowById(rowId);
    if (status === 'OK') {
      const successMessageDeleteRow =
        response.data.message || '¡Fila eliminada correctamente!';
      setAlertprops({
        isOpen: true,
        severity: 'success',
        message: successMessageDeleteRow,
      });
      setOpenDeleteRowModal(false);
      fetchEvent();
    }
    if (status === 'ERROR') {
      const errorMessageDeleteRow =
        error.response.error || 'No se ha podido eliminar la fila.';
      setAlertprops({
        isOpen: true,
        severity: 'error',
        message: errorMessageDeleteRow,
      });
      setOpenDeleteRowModal(false);
    }
  };

  /* Publish Event By Id */
  const PublishEventById = async () => {
    if (!id) {
      return;
    }
    const { status, error, response } = await PublishEvent(id);
    if (status === 'OK') {
      const successMessagePublishEvent =
        response.data.message || '¡Evento publicado correctamente!';
      setAlertprops({
        isOpen: true,
        severity: 'success',
        message: successMessagePublishEvent,
      });
      fetchEvent();
    }

    if (status === 'ERROR') {
      if (isAxiosError(error)) {
        const errorPublishEventMessage =
          error.response?.data.message || 'Error al publicar el evento.';
        setAlertprops({
          isOpen: true,
          severity: 'error',
          message: errorPublishEventMessage,
        });
      }
    }
  };

  React.useEffect(() => {
    (async () => {
      await fetchEvent();
      setLoading(false);
    })();
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: 'auto',
        minHeight: '100vh',
        margin: 0,
        pt: 5,
        backgroundColor: '#D9D9D9',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          ml: { xs: '8px', sm: '16px', md: '64px' },
          pb: '10px',
          width: '275px',
          justifyContent: 'space-between',
          display: 'flex',
          flexDirection: 'row',
          position: 'relative',
          alignSelf: 'self-start',
        }}
      >
        <IconButton
          aria-label="back to events table"
          onClick={() => navigate(`/admin/eventos`)}
          sx={{
            width: 48,
            height: 48,
          }}
        >
          <ArrowBackIcon
            sx={{
              color: '#444E6A',
              width: 40,
              height: 40,
            }}
          />
        </IconButton>
        <Typography
          sx={{
            fontSize: { xs: '24px', sm: '30px', md: '35px' },
            fontWeight: 900,
            color: '#444E6A',
            lineHeight: 1.3,
            alignSelf: 'self-start',
          }}
        >
          Secciones
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignSelf: 'center',
          width: '90%',
          justifyContent: 'space-around',
          padding: 2.5,
        }}
      >
        <NewEventCardPreview
          event={event!}
          loading={loading}
          onPublishEvent={PublishEventById}
        />
      </Box>
      <AddNewSection
        event={event}
        onClose={handleCloseRegisterSectionModal}
        isOpen={openRegisterSectionModal}
        onRegisterSection={createNewSection}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignSelf: 'center',
          width: '90%',
          justifyContent: 'space-around',
          padding: 2.5,
          mb: 1,
        }}
      >
        <SectionAndRows
          onOpenDeleteRowModal={handleOpenDelteRowModal}
          onOpenAddTableRowsModal={handleOpenAddTableRowsModal}
          onOpenAddRowsModal={handleOpenAddRowsModal}
          onOpenRegisterSectionModal={handleOpenRegisterSectionModal}
          sections={event?.sections || []}
          onOpenDeleteSectionModal={handleOpenDeleteSectionModal}
          onOpenUpdateSectionModal={handleOpenUpdateSectionModal}
        />
      </Box>
      <AddEventDate
        isOpen={openRegisterEventDates}
        onClose={handleCloseAddEventDateModal}
        onCreateEventDates={createEventDates}
        eventId={eventId}
      />
      {/* Section modals */}
      <DefaultDialog
        title="Eliminar sección"
        text="¿Estás seguro de que deseas eliminar esta sección?"
        textCancelButton="Cancelar"
        textConfirmButton="Confirmar"
        isOpen={openDeleteSectionModal}
        onCancel={handleCloseDeleteSectionModal}
        onConfirm={deleteSection}
      />

      <UpdateSectionDialog
        isOpen={openUpdateSectionModal}
        onClose={handleCloseUpdateSectionModal}
        section={section}
        onUpdateSection={updateSection}
      />
      {/* modal rows */}
      <AddRowsWithSeats
        onCreateRowsWithSeats={createRowWithSeats}
        isOpen={openAddRowsModal}
        onClose={handleCloseAddRowsModal}
      />
      <AddTableRowModal
        isOpen={openAddTableRowsModal}
        onClose={handleCloseAddTableRowsModal}
        onCreateRowsWithSeats={createRowWithSeats}
      />
      <DefaultDialog
        isOpen={openDeleteRowModal}
        title="Eliminar fila/mesa"
        text="¿Estás seguro de que deseas eliminar esta sección?"
        textCancelButton="Cancelar"
        textConfirmButton="Confirmar"
        onCancel={handleCloseDeleteRowModal}
        onConfirm={deleteRow}
      />
      {/* modal rows */}
      <AlertMessage
        onClose={handleCloseAlert}
        isOpen={alertProps.isOpen}
        message={alertProps.message}
        severity={alertProps.severity}
      />
    </Box>
  );
};
