import { z } from 'zod';
import { TypeSection, TYPE_SECTION } from '../../entities/Section';

const MAX_FILE_SIZE = 50000000;
const ACCEPTED_IMAGE_TYPES = ['image/jpeg', 'image/jpg', 'image/png'];

export const createSectionSchema = z.object({
  name: z.preprocess(
    (val) => (val === '' ? undefined : val),
    z.string({
      required_error: 'Por favor, ingrese un nombre para la sección.',
    }),
  ),
  color: z.preprocess(
    (val) => (val === '' ? undefined : val),
    z.string({
      required_error: 'Por favor, ingrese el color de la sección.',
    }),
  ),
  type: z
    .preprocess(
      (val) => (val === '' ? undefined : val),
      z.string({
        required_error: 'Por favor, ingrese el tipo de sección.',
      }),
    )
    .refine(
      (type) => TYPE_SECTION.includes(type as TypeSection),
      'El tipo de sección no es válido.',
    ),
  capacity: z
    .preprocess(
      (val) =>
        typeof val === 'string' && val !== '' ? Number(val) : undefined,
      z.number({
        required_error: 'Por favor, indique la capacidad para esta sección.',
      }),
    )
    .refine((val) => typeof val === 'number' && val > 0, {
      message: 'La capacidad debe ser un número mayor que cero.',
    }),
  price: z
    .preprocess(
      (val) => (typeof val === 'string' ? Number(val) : undefined),
      z.number({
        required_error: 'Por favor, indique un precio válido.',
      }),
    )
    .refine((val) => typeof val === 'number' && val > 0, {
      message: 'El precio debe ser un número mayor que cero.',
    }),
  layout:
    typeof window === 'undefined'
      ? z.any()
      : z
          .custom<File>((file) => file instanceof File, {
            message:
              'Por favor, ingrese una imagen para la maqueta de la sección.',
          })
          .refine((file) => ACCEPTED_IMAGE_TYPES.includes(file.type), {
            message:
              'Formato de imagen no válido, por favor, ingrese una imagen válida.',
          })
          .refine((file) => file.size <= MAX_FILE_SIZE, {
            message: 'Por favor, ingrese una imagen que pese menos de 500 kB.',
          }),
});

export type CreateSectionDto = z.infer<typeof createSectionSchema>;
