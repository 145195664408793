import React from 'react';
import {
  AppBar,
  Badge,
  Box,
  Button,
  CardMedia,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemButton,
  ListItemText,
  Toolbar,
  Tooltip,
  useTheme,
  useMediaQuery,
  Divider,
} from '@mui/material';
import { ShoppingCartOutlined } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import t1 from '../../images/t1.png';
import BuyerMenu from '../BuyerMenu';
import { RootState } from '../../store';
import SortIcon from '@mui/icons-material/Sort';
import LoginIcon from '@mui/icons-material/Login';
import Avatar from '@mui/material/Avatar';
import Logout from '@mui/icons-material/Logout';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import ReceiptIcon from '@mui/icons-material/Receipt';
import AdminPanelSettingsRoundedIcon from '@mui/icons-material/AdminPanelSettingsRounded';
import { useAuth } from '../../../providers/AuthProvider';
import CloseIcon from '@mui/icons-material/Close';
import LogoutDialogClient from '../LogoutDialogClient';

interface MenuOptions {
  label: string;
  icon: JSX.Element;
  onClick: () => void;
}

interface NavBarProps {
  cartState: RootState['cart'];
  isAuth: boolean;
  onOpenLoginModal: () => void;
  backGroundColor: string;
}

const NavBar = ({
  cartState,
  isAuth,
  onOpenLoginModal,
  backGroundColor,
}: NavBarProps) => {
  const { user, isAdmin, isBuyer, isSales, isScanner } = useAuth();
  const { count, minutes, seconds } = cartState;
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [tooltipTitle, setTooltipTitle] = React.useState('Carrito Vacío');
  const [navBarColor, setNavBarColor] = React.useState(backGroundColor);
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [openLogoutDialog, setOpenLogoutDialog] = React.useState(false);

  const handleOpenLogoutDialog = () => {
    setOpenLogoutDialog(true);
  };

  const handleCloseLogoutDialog = () => {
    setOpenLogoutDialog(false);
  };

  const handleClose = () => {
    if (anchorEl) {
      anchorEl.focus();
    }
    setAnchorEl(null);
  };

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      setDrawerOpen(open);
    };

  const changeNavBarColor = async () => {
    if (window.scrollY >= 20) {
      setNavBarColor('#C33186');
    } else {
      setNavBarColor(backGroundColor);
    }
  };

  React.useEffect(() => {
    if (count > 0) {
      setTooltipTitle(
        `Tiempo restante para completar la compra: ${minutes}:${seconds}`,
      );
    } else {
      setTooltipTitle('Carrito Vacío');
    }
  }, [cartState]);

  window.addEventListener('scroll', changeNavBarColor);

  const menuOptions: MenuOptions[] = [
    {
      label: 'Carrito de compra',
      icon: <ShoppingCartOutlined sx={{ color: '#FFF' }} />,
      onClick: () => navigate('/carrito'),
    },
    isBuyer
      ? {
          label: 'Mis boletos',
          icon: <ReceiptIcon fontSize="small" />,
          onClick: () => navigate('/mis-eventos'),
        }
      : null,
    isAdmin
      ? {
          label: 'Ir al panel de administrador',
          icon: <AdminPanelSettingsRoundedIcon fontSize="small" />,
          onClick: () => navigate('/admin'),
        }
      : null,
    isSales
      ? {
          label: 'Ir al panel de ventas',
          icon: <PointOfSaleIcon fontSize="small" />,
          onClick: () => navigate('/admin/punto-de-venta'),
        }
      : null,
    isScanner
      ? {
          label: 'Ir al panel de escaneo',
          icon: <PointOfSaleIcon fontSize="small" />,
          onClick: () => navigate('/admin/scanner'),
        }
      : null,
  ].filter((option): option is MenuOptions => option !== null);

  const TTGDrawerList = (
    <Box
      sx={{ width: 250, backgroundColor: '#C33186', height: '100%' }}
      role="presentation"
    >
      {/* Close Icon at the top corner */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          padding: '8px',
          color: '#FFF',
        }}
      >
        <IconButton onClick={toggleDrawer(false)} sx={{ color: '#FFF' }}>
          <CloseIcon />
        </IconButton>
      </Box>

      {/* Menu options list */}
      <List>
        {menuOptions.map((option, index) => (
          <ListItem
            key={option.label}
            disablePadding
            className="customClientPaper"
          >
            <ListItemButton onClick={option.onClick}>
              <ListItemIcon>{option.icon}</ListItemIcon>
              <ListItemText primary={option.label} sx={{ color: '#FFF' }} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>

      <Divider sx={{ backgroundColor: '#FFF' }} />

      {/* Auth and Admin/Buyer specific options */}
      <List>
        {isAuth ? (
          <>
            {/* Display User info */}
            <ListItem disablePadding className="customClientPaper">
              <ListItemButton>
                <ListItemIcon>
                  <Avatar />
                </ListItemIcon>
                <ListItemText
                  primary={`${user?.firstName} ${user?.lastName}`}
                  sx={{ color: '#FFF' }}
                />
              </ListItemButton>
            </ListItem>

            {/* Logout Option */}
            <ListItem disablePadding className="customClientPaper">
              <ListItemButton onClick={handleOpenLogoutDialog}>
                <ListItemIcon sx={{ color: '#FFF' }}>
                  <Logout fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Salir" sx={{ color: '#FFF' }} />
              </ListItemButton>
            </ListItem>
          </>
        ) : (
          /* Login Option if not Authenticated */
          <ListItem disablePadding className="customClientPaper">
            <ListItemButton onClick={onOpenLoginModal}>
              <ListItemIcon sx={{ color: '#FFF' }}>
                <LoginIcon />
              </ListItemIcon>
              <ListItemText primary="Iniciar Sesión" sx={{ color: '#FFF' }} />
            </ListItemButton>
          </ListItem>
        )}
      </List>
    </Box>
  );

  return (
    <AppBar
      sx={{
        backgroundColor: `${navBarColor}`,
        boxShadow: 'none',
      }}
    >
      <Toolbar
        sx={{
          justifyContent: 'space-between',
          display: 'flex',
        }}
      >
        <Box>
          <CardMedia
            onClick={() => navigate('/')}
            component="img"
            alt="Imagen"
            image={t1}
            sx={{
              boxShadow: 'none',
              border: 'none',
              cursor: 'pointer',
              width: isSmallScreen ? 150 : 200,
            }}
          />
        </Box>
        <LogoutDialogClient
          isOpen={openLogoutDialog}
          onCloseDialog={handleCloseLogoutDialog}
        />
        {isSmallScreen ? (
          <>
            <IconButton
              size="medium"
              aria-label="menu"
              sx={{
                position: 'relative',
                boxShadow: 'none',
                borderRadius: '5px',
                height: 30,
                p: '5px',
                color: (theme) => theme.palette.secondary.main,
                background: (theme) => theme.palette.common.white,
                '&:hover': {
                  background: (theme) => theme.palette.primary.main,
                  color: (theme) => theme.palette.common.white,
                },
              }}
              onClick={toggleDrawer(true)}
            >
              <SortIcon />
            </IconButton>

            {/* Drawer for mobile */}
            <Drawer anchor="left" open={drawerOpen} onClose={handleClose}>
              {TTGDrawerList}
            </Drawer>
          </>
        ) : (
          <>
            <Box
              sx={{ display: 'flex', marginLeft: 'auto', marginRight: '0px' }}
            >
              {!isAuth || isBuyer ? (
                <Button
                  startIcon={<ShoppingCartOutlined />}
                  sx={{
                    borderRadius: '10px',
                    color: (theme) => theme.palette.common.white,
                    fontFamily: 'Poppins',
                    fontWeight: 'bold',
                    textTransform: 'initial',
                    marginRight: '15px',
                    '&:hover': {
                      background: (theme) => theme.palette.secondary.main,
                    },
                  }}
                  onClick={() => navigate('/carrito')}
                >
                  <Tooltip title={tooltipTitle}>
                    <Badge badgeContent={count} color="secondary">
                      Carrito de compra
                    </Badge>
                  </Tooltip>
                </Button>
              ) : null}

              {isAuth ? (
                <BuyerMenu />
              ) : (
                <Button
                  variant="contained"
                  sx={{
                    borderRadius: '10px',
                    color: (theme) => theme.palette.secondary.main,
                    background: (theme) => theme.palette.common.white,
                    fontFamily: 'Poppins',
                    fontWeight: 'bold',
                    textTransform: 'uppercase',
                    '&:hover': {
                      background: (theme) => theme.palette.primary.main,
                      color: (theme) => theme.palette.common.white,
                    },
                  }}
                  onClick={onOpenLoginModal}
                >
                  Iniciar Sesión
                </Button>
              )}
            </Box>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default NavBar;
