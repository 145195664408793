import { Box, Card, CardMedia, Grid, Typography } from '@mui/material';
import PlaceIcon from '@mui/icons-material/Place';
import EventIcon from '@mui/icons-material/Event';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { EventEntity } from 'libs/domain/src/lib/entities/Event';

interface PreviewProps {
  event: EventEntity;
  loading: boolean;
  onPublishEvent: (eventId: string) => void;
}

export const NewEventCardPreview = ({
  event,
  loading,
  onPublishEvent,
}: PreviewProps) => {
  const formattedDates =
    event?.eventDates
      ?.map((eventDate) =>
        format(new Date(eventDate.date), "dd 'de' MMMM yyyy", { locale: es }),
      )
      .slice(0, event?.eventDates.length - 1)
      .join(', ')
      .concat(
        `${event?.eventDates.length > 1 ? ' y ' : ''}`,
        (event?.eventDates.length > 0 &&
          format(
            new Date(event?.eventDates[event?.eventDates.length - 1].date),
            "dd 'de' MMMM yyyy",
            { locale: es },
          )) ||
          'No hay fechas disponibles',
      ) || 'No hay fechas disponibles';

  return (
    <Card
      sx={{
        display: 'flex',
        p: 5,
        borderRadius: '10px',
        boxShadow: 3,
        width: '95%',
        height: 'auto',
        alignSelf: 'center',
        background: '#FFF',
        alignItems: 'center',
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <Box
            sx={{
              zIndex: 1,
              display: 'flex',
              flexDirection: 'column',
              padding: '15px 7.5px',
            }}
          >
            <Typography
              variant="h4"
              sx={{
                color: '#890050',
                textTransform: 'uppercase',
                fontWeight: 800,
                lineHeight: 1.3,
              }}
            >
              {event?.name}
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{
                display: 'flex',
                alignItems: 'center',
                color: '#000',
                mt: 1,
              }}
            >
              <EventIcon sx={{ color: '#000', fontSize: 25, mr: 1 }} />
              {formattedDates}
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{
                display: 'flex',
                alignItems: 'center',
                color: '#000',
                mt: 0.75,
              }}
            >
              <PlaceIcon sx={{ color: '#000', fontSize: 25, mr: 1 }} />
              {event?.venueName}
            </Typography>
            <Box mt={2}>
              <Typography
                variant="body1"
                sx={{
                  color: '#000',
                  textAlign: 'justify',
                  lineHeight: 1.5,
                  wordBreak: 'break-word',
                }}
              >
                {event?.longDescription || 'No descripción disponible.'}
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <CardMedia
            component="img"
            title={event?.name}
            image={event?.cardImage || ''}
            alt="Event"
            sx={{ borderRadius: '10px', height: '100%', objectFit: 'cover' }}
          />
        </Grid>
      </Grid>
    </Card>
  );
};
