import Stack from "@mui/material/Stack";
import { Box, FormControl, FormHelperText } from "@mui/material";
import { MuiColorInput } from "mui-color-input";

interface ColorUploadProps {
  value: string;
  onChange: (value: string) => void;
  error?: string;
}

export const ColorInput = ({ value, onChange, error }: ColorUploadProps) => {
  return (
    <Stack direction="row" sx={{ mt: 1, alignItems: "center" }}>
      <Box sx={{ flexGrow: 1 }}>
        <FormControl error={!!error} fullWidth>
          <MuiColorInput
            value={value || "#000000"}
            onChange={onChange}
            format="hex"
            size="small"
            className="customTextField"
            sx={{
              width: "100%",
              "& .MuiInputBase-root": {
                width: "100%",
              },
            }}
          />
          {error && (
            <FormHelperText>{error}</FormHelperText>
          )}
        </FormControl>
      </Box>
    </Stack>
  );
};
