import { TransactionDto } from '../../../../../libs/domain/src/lib/dto';
import { axiosRequest } from '../api-client';
import { CreateCartWithItemsDto } from 'libs/domain/src/lib/dto/Cart/index';

/**
 * @route apps/api/src/routes/cart/index.ts:14
 *
 * @param data
 * @returns
 */
export const AddToCart = (data: CreateCartWithItemsDto) => {
  return axiosRequest({
    method: 'POST',
    url: '/cart/',
    data,
  });
};

/**
 * @route apps/api/src/routes/cart/index.ts:20
 *
 * @returns
 */
export const GetCart = () => {
  return axiosRequest({
    method: 'GET',
    url: '/cart/',
  });
};

/**
 * @route apps/api/src/routes/cart/index.ts:17
 *
 * @returns
 */
export const PostCheckout = () => {
  return axiosRequest({
    method: 'POST',
    url: '/cart/checkout',
  });
};

/**
 * @route apps/api/src/routes/cart/transaction.ts:17
 *
 * @returns
 */
export const PostTransaction = (data: TransactionDto) => {
  return axiosRequest({
    method: 'POST',
    url: '/cart/transaction',
    data,
  });
};

/**
 * @route apps/api/src/routes/cart/index.ts:23
 *
 * @returns
 */
export const ClearCart = () => {
  return axiosRequest({
    method: 'DELETE',
    url: '/cart/',
  });
};

/**
 * @route apps/api/src/routes/cart/index.ts:26
 *
 * @param id
 * @returns
 */
export const DeleteCartItem = (id: string) => {
  return axiosRequest({
    method: 'DELETE',
    url: `/cart/${id}/cartItem`,
  });
};

/**
 * @route apps/api/src/routes/cart/index.ts:37
 *
 * @param orderId
 * @returns
 */
export const ConfirmOrderPaid = (order: any, orderId: string) => {
  return axiosRequest({
    method: 'POST',
    url: `/cart/confirm-payment`,
    data: {
      order,
      orderId,
    },
  });
};

/**
 * @route apps/api/src/routes/cart/index.ts:38
 *
 * @returns
 */
export const GetCartTimeRemaining = () => {
  return axiosRequest({
    method: 'GET',
    url: '/cart/timer',
  });
};
