import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

export const SuccessPayment = () => {
  const navigate = useNavigate();

  const handleGoHome = () => {
    navigate('/mis-eventos');
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '10%',
        boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.75)',
        minHeight: '40vh',
        height: 'auto',
      }}
    >
      <Card
        sx={{
          height: '300px',
          borderRadius: '10px',
          width: '600px',
          display: 'flex',
          flexDirection: 'column',
          padding: '0px',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CardHeader
          sx={{
            backgroundColor: (theme) => theme.palette.secondary.main,
            width: '100%',
            height: '10%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <CheckCircleOutlineIcon
            sx={{ fontSize: '2em', marginLeft: '10px' }}
          />
        </CardHeader>
        <CardContent sx={{ width: '100%', height: '100%', padding: '0px' }}>
          <Box
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'space-evenly',
            }}
          >
            <Typography
              sx={{
                color: (theme) => theme.palette.success.main,
                fontSize: '2em',
                fontWeight: 'bold',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              ¡Gracias por tu compra!{' '}
              <CheckCircleOutlineIcon
                sx={{ fontSize: '0.9em', marginLeft: '8px' }}
              />
            </Typography>
            <Button
              sx={{
                width: '50%',
                fontWeight: 'bold',
                background: (theme) => theme.palette.secondary.main,
                color: (theme) => theme.palette.common.white,
                '&:hover': {
                  background: (theme) => theme.palette.secondary.main,
                  color: (theme) => theme.palette.common.white,
                  border: 'none',
                },
              }}
              onClick={handleGoHome}
            >
              Ver mis boletos
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};
