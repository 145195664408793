import { z }from 'zod'

const emailSchema = z
  .string({
    required_error: 'Por favor, ingrese una dirección de correo electrónico.',
  })
  .email({ message: 'Por favor, ingrese un email válido.' });


export const transactionSchema = z.object({
  name: z.string({
    required_error: 'Por favor, ingrese un nombre.',
  }).min(3, 'Por favor, ingrese un nombre mayor a 3 caracteres.'),
  email: emailSchema,
  confirmEmail: emailSchema
}).refine(data => data.email === data.confirmEmail, {
  message: 'Los correos no coinciden.',
  path: ['confirmEmail'],
});;

export type TransactionDto = z.infer<typeof transactionSchema>;
