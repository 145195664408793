import { MoreVert } from '@mui/icons-material';
import { Button, IconButton, Menu, MenuItem } from '@mui/material';
import React from 'react';

interface MenuOption {
  id: string;
  label: string;
  icon?: React.ReactNode;
  onClick: () => void;
}

interface TTGMenuProps {
  options: MenuOption[];
  color?: string;
}

export const TableMenu = ({ options, color = '#FFF' }: TTGMenuProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        disableRipple
        sx={{ color }}
      >
        <MoreVert />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        PaperProps={{
          elevation: 0,
          className: 'customTextPaper',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {options.map((option, index) => (
          <MenuItem
            key={new Date().getTime() + index}
            onClick={(e) => {
              option.onClick();
              handleClose();
              e.stopPropagation();
            }}
          >
            <Button
              endIcon={option.icon}
              sx={{
                justifyContent: 'space-between',
                width: '100%',
                color: '#FFFFFF',
                textTransform: 'none',
                fontSize: '1rem',
                fontFamily: 'Poppins, sans-serif',
              }}
            >
              {option.label}
            </Button>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};
