import { ReactNode, useState } from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import LogoutIcon from '@mui/icons-material/Logout';
import AppBar from './AppBar';
import { Button, CardMedia } from '@mui/material';
import t1 from '../images/t1.png';
import LogoutDialogAdmin from './LogoutDialogAdmin';

const styleAppBarWithSession = {
  zIndex: '2',
  boxSizing: 'border-box',
  position: 'relative',
  width: '100%',
  height: '61px',
  left: '0px',
  top: '0px',
  backgroundColor: '#890050',
};

interface MenuOptions {
  label?: string;
  path: string;
  icon: ReactNode;
  onClick: () => void;
}

interface LayoutProps {
  children: ReactNode;
}

export default function Layout({ children }: LayoutProps) {
  const [open] = useState(true);
  const [openLogoutDialog, setOpenLogoutDialog] = useState(false);

  const handleOpenLogoutDialog = () => {
    setOpenLogoutDialog(true);
  };

  const handleCloseLogoutDialog = () => {
    setOpenLogoutDialog(false);
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        position: 'relative',
        top: 0,
        left: 0,
      }}
    >
      <LogoutDialogAdmin
        isOpen={openLogoutDialog}
        onCloseDialog={handleCloseLogoutDialog}
        title="Cerrar sesión"
        subtitle="¿Estás seguro que quieres cerrar la sesión?"
      />
      <CssBaseline />
      <AppBar position="fixed" open={open} sx={styleAppBarWithSession}>
        <Toolbar
          sx={{
            justifyContent: 'space-between',
            position: 'relative',
          }}
        >
          <Box
            sx={{
              justifyContent: 'space-evenly',
              display: 'flex',
            }}
          >
            <CardMedia
              component="img"
              alt="Imagen"
              height="35"
              image={t1}
              sx={{
                boxShadow: 'none',
                border: 'none',
              }}
            />
          </Box>
          <Button
            sx={{
              display: 'flex',
              border: 0,
              boxShadow: 0,
              fontSize: '15px',
              fontWeight: 750,
              textTransform: 'initial',
              backgroundColor: '#890050',
              '&:hover': {
                background: '#890050',
                color: (theme) => theme.palette.common.white,
                border: 'none',
              },
            }}
            variant="contained"
            onClick={handleOpenLogoutDialog}
            startIcon={
              <LogoutIcon sx={{ color: 'white', position: 'start' }} />
            }
          >
            Salir
          </Button>
        </Toolbar>
      </AppBar>
      <Box component="main" sx={{ flexGrow: 1 }}>
        <Box>{children}</Box>
      </Box>
    </Box>
  );
}
