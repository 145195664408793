import React from 'react';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import ttgoLogo from '../images/ttgologo.png';
import LoadingButton from '@mui/lab/LoadingButton';
import Stack from '@mui/material/Stack';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Alert from '@mui/material/Alert';
import { CardMedia, Divider, Card } from '@mui/material';
import { Form, Navigate, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useAuth } from '../../providers/AuthProvider';
import './styles.css';

const style = {
  width: 400,
  minHeight: 400,
  bgcolor: 'background.paper',
  boxShadow: 14,
  p: 4,
  paddingTop: '2px',
};
const initialFormData = {
  email: '',
  password: '',
};

const LoginAdmin = () => {
  const { login, redirectAdminUserToMainPage, isAuth } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(false);
  const [isEmptyError, setIsEmptyError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false || null);
  const [form, setFormData] = useState({ ...initialFormData });

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };

  const handleFormChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const submit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!form.email || !form.password) {
      setIsEmptyError(true);
      return;
    }
    setIsEmptyError(false);
    setLoading(true);
    try {
      const user = await login(form.email, form.password);
      if (user && ['ADMIN', 'SALES', 'SCANNER'].includes(user.role)) {
        setLoading(false);
        navigate('/admin/eventos');
      } else {
        setLoading(false);
        navigate('/');
      }
    } catch (error: any) {
      setErrorMessage(error.message || '');
      setError(true);
      setLoading(false);
    }
  };

  if (isAuth) {
    const mainPage = redirectAdminUserToMainPage();
    return <Navigate to={mainPage} replace />;
  }

  return (
    <div className="admin-login-bg dark-ocean-bg">
      <Card sx={style}>
        <CardContent>
          <Box sx={{ mt: 5 }}>
            <CardMedia
              component="img"
              alt="Imagen"
              height="80"
              image={ttgoLogo}
              sx={{ boxShadow: 'none', border: 'none' }}
            />
          </Box>
          <Form onSubmit={submit} method="post" action="">
            <TextField
              fullWidth
              required
              type="email"
              name="email"
              value={form.email}
              onChange={handleFormChange}
              id="outlined-required"
              label="Correo electrónico"
              margin="normal"
              size="small"
            />
            <br />
            <TextField
              fullWidth
              required
              id="outlined-password-input"
              label="Contraseña"
              name="password"
              value={form.password}
              type={showPassword ? 'text' : 'password'}
              onChange={handleFormChange}
              autoComplete="current-password"
              margin="normal"
              size="small"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <CardActions sx={{ padding: 0 }}>
              <LoadingButton
                loading={loading}
                variant="contained"
                fullWidth
                sx={{ mt: 2 }}
                type="submit"
              >
                Entrar
              </LoadingButton>
            </CardActions>
          </Form>
          <Divider sx={{ my: 2 }} />
          <Stack sx={{ width: '100%' }} spacing={2}>
            {isEmptyError && (
              <Alert severity="error">
                Por favor, completa todos los campos.
              </Alert>
            )}
            {error && <Alert severity="error">{errorMessage}</Alert>}
          </Stack>
        </CardContent>
      </Card>
    </div>
  );
};

export default LoginAdmin;
