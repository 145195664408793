import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './app/store';
import App from './app/app';
import './styles.css';
import { LoadScript } from '@react-google-maps/api';
import * as Sentry from '@sentry/react';

const GOOGLE_MAPS_API_KEY = import.meta.env.VITE_GOOGLE_MAPS_API_KEY ?? '';

if (import.meta.env.SENTRY_DSN_FE) {
  Sentry.init({
    dsn: import.meta.env.SENTRY_DSN_FE,
    environment: import.meta.env.MODE,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: [
      import.meta.env.APP_DOMAIN,
      import.meta.env.VITE_API_URL,
    ],
    replaysOnErrorSampleRate: 1.0,
  });
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <StrictMode>
    <Provider store={store}>
      <>
        <App />
        <LoadScript
          googleMapsApiKey={GOOGLE_MAPS_API_KEY}
          libraries={['places']}
          onLoad={() => {}}
          loadingElement={<div style={{ height: '100%' }}>Loading...</div>}
        />
      </>
    </Provider>
  </StrictMode>,
);
