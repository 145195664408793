import { Box, Card, CardContent, CardMedia, Button } from '@mui/material';
import Typography from '@mui/material/Typography';
import EventImagesMenu from './EventImagesMenu';
import AttachFileIcon from '@mui/icons-material/AttachFile';

interface ImageData {
  image: string | null;
  title: string | null;
  subtitle?: string;
  onChangeImage: () => void;
  onRemoveImage: () => void;
  error?: string | null;
}

interface EventImagesListListProps {
  cardImage: ImageData;
  bannerImage: ImageData;
  ticketImage: ImageData;
}

export default function EventImagesList({
  bannerImage,
  cardImage,
  ticketImage,
}: EventImagesListListProps) {
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'space-between',
        flexDirection: 'column',
      }}
    >
      {/* New buttons for images */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-evenly',
          width: '100%',
          height: 'auto',
          p: 2,
        }}
      >
        {bannerImage.image ? (
          <Card
            sx={{
              display: 'flex',
              flexDirection: 'column',
              borderRadius: '5px',
              background: '#D9D9D9',
              width: '100%',
              maxWidth: '30%',
              margin: '0 auto',
              overflow: 'hidden',
            }}
          >
            <CardMedia
              component="img"
              sx={{
                objectFit: 'cover',
                width: '100%',
                height: '200px',
              }}
              image={bannerImage.image}
              alt="Imagen del banner"
            />
            <Box
              sx={{ display: 'flex', flexDirection: 'column', padding: '16px' }}
            >
              <CardContent>
                <Typography
                  component="div"
                  sx={{
                    fontWeight: 600,
                    fontSize: '1.25rem',
                    color: '#444E6A',
                    marginBottom: '8px',
                  }}
                >
                  {bannerImage.title}
                </Typography>
                <Typography
                  component="div"
                  sx={{
                    color: '#000',
                    fontSize: '0.9rem',
                  }}
                >
                  {bannerImage.subtitle}
                </Typography>
              </CardContent>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  paddingTop: '8px',
                }}
              >
                <EventImagesMenu
                  onEdit={bannerImage.onChangeImage}
                  onDelete={bannerImage.onRemoveImage}
                />
              </Box>
            </Box>
          </Card>
        ) : (
          <Box
            sx={{
              display: 'flex',
              width: '25%',
              flexDirection: 'column',
            }}
          >
            <Typography
              variant="h5"
              sx={{
                display: '-webkit-box',
                WebkitLineClamp: 2,
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                width: '100%',
                fontSize: '0.9rem',
                fontFamily: 'Poppins',
                color: '#444E6A',
                fontWeight: '300',
                lineHeight: 2,
              }}
            >
              Imagen del banner
            </Typography>
            <Button
              variant="outlined"
              sx={{
                backgroundColor: (theme) => theme.palette.common.white,
                color: '#444E6A',
                width: '100%',
                maxHeight: '45px',
                border: '2px solid #444E6A',
                borderRadius: '10px',
                fontWeight: 700,
                textAlign: 'center',
                '&:hover': {
                  backgroundColor: '#444E6A',
                  color: (theme) => theme.palette.common.white,
                  border: '2px solid #444E6A',
                },
              }}
              endIcon={<AttachFileIcon />}
              onClick={bannerImage.onChangeImage}
            >
              Subir
            </Button>
            {bannerImage.error && (
              <Typography variant="caption" color="red">
                {bannerImage.error}
              </Typography>
            )}
          </Box>
        )}

        {cardImage.image ? (
          <Card
            sx={{
              display: 'flex',
              flexDirection: 'column',
              borderRadius: '5px',
              background: '#D9D9D9',
              width: '100%',
              maxWidth: '30%',
              margin: '0 auto',
              overflow: 'hidden',
            }}
          >
            <CardMedia
              component="img"
              sx={{
                objectFit: 'cover',
                width: '100%',
                height: '200px',
              }}
              image={cardImage.image}
              alt="Imagen de la card"
            />
            <Box
              sx={{ display: 'flex', flexDirection: 'column', padding: '16px' }}
            >
              <CardContent>
                <Typography
                  component="div"
                  sx={{
                    fontWeight: 600,
                    fontSize: '1.25rem',
                    color: '#444E6A',
                    marginBottom: '8px',
                  }}
                >
                  {cardImage.title}
                </Typography>
                <Typography
                  component="div"
                  sx={{
                    color: '#000',
                    fontSize: '0.9rem',
                  }}
                >
                  {cardImage.subtitle}
                </Typography>
              </CardContent>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  paddingTop: '8px',
                }}
              >
                <EventImagesMenu
                  onEdit={cardImage.onChangeImage}
                  onDelete={cardImage.onRemoveImage}
                />
              </Box>
            </Box>
          </Card>
        ) : (
          <Box
            sx={{
              display: 'flex',
              width: '25%',
              flexDirection: 'column',
            }}
          >
            <Typography
              variant="h5"
              sx={{
                display: '-webkit-box',
                WebkitLineClamp: 2,
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                width: '100%',
                fontSize: '0.9rem',
                fontFamily: 'Poppins',
                color: '#444E6A',
                fontWeight: '300',
                lineHeight: 2,
              }}
            >
              Imagen de la tarjeta
            </Typography>
            <Button
              variant="outlined"
              sx={{
                backgroundColor: (theme) => theme.palette.common.white,
                color: '#444E6A',
                width: '100%',
                maxHeight: '45px',
                border: '2px solid #444E6A',
                borderRadius: '10px',
                fontWeight: 700,
                textAlign: 'center',
                '&:hover': {
                  backgroundColor: '#444E6A',
                  color: (theme) => theme.palette.common.white,
                  border: `2px solid #444E6A`,
                },
              }}
              endIcon={<AttachFileIcon />}
              onClick={cardImage.onChangeImage}
            >
              Subir
            </Button>
            {cardImage.error && (
              <Typography variant="caption" color="red">
                {cardImage.error}
              </Typography>
            )}
          </Box>
        )}

        {ticketImage.image ? (
          <Card
            sx={{
              display: 'flex',
              flexDirection: 'column',
              borderRadius: '5px',
              background: '#D9D9D9',
              width: '100%',
              maxWidth: '30%',
              margin: '0 auto',
              overflow: 'hidden',
            }}
          >
            <CardMedia
              component="img"
              sx={{
                objectFit: 'cover',
                width: '100%',
                height: '200px',
              }}
              image={ticketImage.image}
              alt="Imagen de la card"
            />
            <Box
              sx={{ display: 'flex', flexDirection: 'column', padding: '16px' }}
            >
              <CardContent>
                <Typography
                  component="div"
                  sx={{
                    fontWeight: 600,
                    fontSize: '1.25rem',
                    color: '#444E6A',
                    marginBottom: '8px',
                  }}
                >
                  {ticketImage.title}
                </Typography>
                <Typography
                  component="div"
                  sx={{
                    color: '#000',
                    fontSize: '0.9rem',
                  }}
                >
                  {ticketImage.subtitle}
                </Typography>
              </CardContent>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  paddingTop: '8px',
                }}
              >
                <EventImagesMenu
                  onEdit={ticketImage.onChangeImage}
                  onDelete={ticketImage.onRemoveImage}
                />
              </Box>
            </Box>
          </Card>
        ) : (
          <Box
            sx={{
              display: 'flex',
              width: '25%',
              flexDirection: 'column',
            }}
          >
            <Typography
              variant="h5"
              sx={{
                display: '-webkit-box',
                WebkitLineClamp: 2,
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                width: '100%',
                fontSize: '0.9rem',
                fontFamily: 'Poppins',
                color: '#444E6A',
                fontWeight: '300',
                lineHeight: 2,
              }}
            >
              Imagen del boleto
            </Typography>
            <Button
              variant="outlined"
              sx={{
                backgroundColor: (theme) => theme.palette.common.white,
                color: '#444E6A',
                width: '100%',
                maxHeight: '45px',
                border: '2px solid #444E6A',
                borderRadius: '10px',
                fontWeight: 700,
                textAlign: 'center',
                '&:hover': {
                  backgroundColor: '#444E6A',
                  color: (theme) => theme.palette.common.white,
                  border: `2px solid #444E6A`,
                },
              }}
              endIcon={<AttachFileIcon />}
              onClick={ticketImage.onChangeImage}
            >
              Subir
            </Button>
            {ticketImage.error && (
              <Typography variant="caption" color="red">
                {ticketImage.error}
              </Typography>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
}
