import { Optional } from '../../types/common';

export interface UserEntity {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  phoneNumber: string;
  birthDate: Date;
  role: UserRole;
  createdAt: Date;
  updatedAt: Date | null;
  status: UserStatus;
  islegacy: boolean;
  deletedAt: Date;
  customerId: string;
}

export type UserRole = 'ADMIN' | 'USER' | 'SALES' | 'SCANNER';
export const USER_ROLE: UserRole[] = ['ADMIN', 'USER', 'SALES', 'SCANNER'];
export enum UserRoleEnum {
  ADMIN = 'ADMIN',
  USER = 'USER',
  SALES = 'SALES',
  SCANNER = 'SCANNER',
}

export type UserStatus = 'INVITED' | 'ACTIVE' | 'DISABLED';
export const USER_STATUS: UserStatus[] = ['INVITED', 'ACTIVE', 'DISABLED'];
export enum UserStatusEnum {
  INVITED = 'INVITED',
  ACTIVE = 'ACTIVE',
  DISABLED = 'DISABLED',
}

export type UserCreateInput = Optional<
  Omit<UserEntity, 'id'>,
  'createdAt' | 'updatedAt' | 'deletedAt'
>;

export type SerializableUser = Pick<
  UserEntity,
  'id' | 'firstName' | 'lastName' | 'email' | 'role'
>;

export type UserJwtPayload = {
  email: string;
  type: string;
};
