import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { Button, Box } from '@mui/material';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import { CartItemEntity } from 'libs/domain/src/lib/entities/CartItem';
import { formatTZDate } from '../utils/dateUtils';

interface TicketListProps {
  tickets: CartItemEntity[];
  openQRTicketDialog: (ticket: CartItemEntity) => void;
}

export default function TicketList({
  tickets,
  openQRTicketDialog,
}: TicketListProps) {
  const groupedTickets = tickets.reduce(
    (acc, ticket) => {
      const dateKey = ticket.eventDate?.date
        ? formatTZDate(ticket.eventDate.date)
        : 'Sin fecha';
      if (!acc[dateKey]) {
        acc[dateKey] = [];
      }
      acc[dateKey].push(ticket);
      return acc;
    },
    {} as { [key: string]: CartItemEntity[] },
  );

  const isEventDateTodayOrPast = (eventDate: Date) => {
    const today = new Date();
    const eventDay = new Date(eventDate);
    return today >= eventDay;
  };

  return (
    <List sx={{ width: '100%', maxWidth: 700, bgcolor: 'background.paper' }}>
      {Object.entries(groupedTickets).map(([date, ticketsByDate]) => (
        <React.Fragment key={date}>
          <Box sx={{ my: 2, textAlign: 'center' }}>
            <Divider />
            <Typography
              variant="h6"
              sx={{
                mt: -1.5,
                px: 2,
                bgcolor: 'background.paper',
                display: 'inline-block',
                fontWeight: 'bold',
                color: '#890050',
              }}
            >
              {date}
            </Typography>
            <Divider />
          </Box>

          {ticketsByDate.map((ticket) => (
            <ListItem key={ticket.id} alignItems="flex-start">
              <ListItemAvatar
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  alignSelf: 'center',
                  m: 1,
                  p: 1,
                }}
              >
                <Avatar
                  variant="rounded"
                  sx={{
                    width: { xs: 60, sm: 100 },
                    height: { xs: 60, sm: 100 },
                  }}
                  alt={ticket.event?.name || ''}
                  src={ticket.event?.cardImage || ''}
                />
              </ListItemAvatar>

              <ListItemText
                primary={`Sección ${ticket.section?.name}`}
                secondary={
                  <React.Fragment>
                    {ticket.section?.type === 'GENERAL' && (
                      <Typography
                        component="span"
                        variant="body1"
                        sx={{
                          color: 'text.primary',
                          fontWeight: '700',
                          fontSize: { xs: '1rem', sm: '1.5rem' },
                        }}
                      >
                        Acceso General
                      </Typography>
                    )}
                    {ticket.section?.type === 'ROW' && (
                      <Typography
                        component="span"
                        variant="body1"
                        sx={{
                          color: 'text.primary',
                          fontWeight: '700',
                          fontSize: { xs: '1rem', sm: '1.5rem' },
                        }}
                      >
                        Fila {ticket.row?.name}, Asiento {ticket.seat?.name}
                      </Typography>
                    )}
                    {ticket.section?.type === 'TABLE' && (
                      <>
                        <Typography
                          component="span"
                          variant="body1"
                          sx={{
                            color: 'text.primary',
                            fontWeight: '700',
                            fontSize: { xs: '1rem', sm: '1.5rem' },
                          }}
                        >
                          Mesa {ticket.row?.name} - Lugares{' '}
                          {ticket.row?.capacity}
                        </Typography>
                        <br />
                        <Typography
                          component="span"
                          variant="body2"
                          sx={{ color: 'text.primary' }}
                        >
                          {ticket.scanned_count} escaneados - restantes:{' '}
                          {ticket.number_of_scanns - ticket.scanned_count}
                        </Typography>
                      </>
                    )}
                    <br />
                    <Typography
                      component="span"
                      variant="body1"
                      sx={{ color: 'text.primary' }}
                    >
                      {ticket.eventDate?.from} hrs
                    </Typography>
                    <br />
                    <Typography
                      component="span"
                      variant="body2"
                      sx={{ color: 'text.secondary' }}
                    >
                      Lugar: {ticket.event?.venueName}, {ticket.event?.city},{' '}
                      {ticket.event?.state}
                    </Typography>
                  </React.Fragment>
                }
                sx={{
                  '& .MuiListItemText-primary': {
                    fontSize: { xs: '1rem', sm: '1.5rem' },
                    fontWeight: '700',
                    color: '#890050',
                  },
                  '& .MuiListItemText-secondary': {
                    fontSize: '1rem',
                    fontWeight: '300',
                    color: '#656B7E',
                  },
                }}
              />

              {isEventDateTodayOrPast(ticket.eventDate?.date || new Date()) &&
                ticket.number_of_scanns - ticket.scanned_count > 0 && (
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      backgroundColor: '#C33186',
                      '&:hover': { backgroundColor: '#890050' },
                      alignSelf: 'center',
                    }}
                    title="Ver QR Code"
                    onClick={() => openQRTicketDialog(ticket)}
                  >
                    <QrCodeScannerIcon />
                  </Button>
                )}
            </ListItem>
          ))}
        </React.Fragment>
      ))}
    </List>
  );
}
