import { Box, Button, Typography } from '@mui/material';
import React, { useCallback, useEffect } from 'react';
import { DropEvent, FileRejection, useDropzone } from 'react-dropzone';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { EventEntity } from 'libs/domain/src/lib/entities/Event';
import EventCardPreview from './EventCardPreview';
import DefaultImage from '../images/defaultImage.png';

interface MyDropzoneProps {
  maxFiles?: number;
  file?: File | null;
  onReceiveFile: (file: File) => void;
  containerHeight?: number;
}

interface DragAndDropBoxProps {
  isDragActive?: boolean;
  containerHeight?: number;
}

const DragAndDropBox = ({
  isDragActive,
  containerHeight,
}: DragAndDropBoxProps) => {
  return (
    <Box
      sx={{
        backgroundImage: `url(${DefaultImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        width: '100%',
        height: containerHeight
          ? containerHeight
          : {
              xs: 250,
              sm: 300,
              md: 450,
              lg: 500,
              xl: 590,
            },
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        border: '1px dashed #63708e',
        borderRadius: '4px',
        backgroundColor: 'white',
        color: '#63708e',
        outline: 'none',
        transition: 'border .24s ease-in-out',
        cursor: 'pointer',
      }}
    >
      <Box sx={{ m: 2 }}>
        <FileUploadIcon fontSize="large" />
      </Box>
      <Box>
        <Typography
          variant="h6"
          gutterBottom
          sx={{
            fontFamily: 'Poppins',
            color: '#63708e',
            fontWeight: 500,
            fontSize: '1rem',
          }}
        >
          {isDragActive
            ? 'Suelta los archivos aquí ...'
            : 'Arrastra y suelta los archivos aquí, o haz clic para seleccionar archivos'}
        </Typography>
      </Box>
    </Box>
  );
};

function TGDropzone({
  maxFiles = 1,
  onReceiveFile,
  containerHeight,
}: MyDropzoneProps) {
  const [error, setError] = React.useState<string | null>(null);

  const onDrop = useCallback((acceptedFiles: File[]) => {
    setError(null);

    if (acceptedFiles.length > 0 && maxFiles === 1) {
      onReceiveFile(acceptedFiles[0]);
    }
  }, []);

  const onDropRejected = (
    fileRejections: FileRejection[],
    event: DropEvent,
  ) => {
    if (fileRejections.length > 0) {
      const errorMessage = fileRejections[0].errors[0].message;
      const errorCode = fileRejections[0].errors[0].code;
      if (errorCode === 'too-many-files') {
        const error =
          maxFiles > 1
            ? `Solo puedes subir ${maxFiles} archivos`
            : 'Solo puedes subir un archivo';
        setError(error);
      }
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    onDropRejected,
    onError: (err: Error) => {
      console.log(err);
    },
    maxFiles: 1,
  });

  return (
    <Box
      sx={{
        height: {
          xs: '250px',
          sm: '300px',
          md: '400px',
          lg: '500px',
          xl: '600px',
        },
      }}
    >
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <DragAndDropBox
          isDragActive={isDragActive}
          containerHeight={containerHeight}
        />

        {error && (
          <Typography
            variant="body1"
            gutterBottom
            color="error"
            sx={{
              textAlign: 'center',
              marginTop: '10px',
              fontSize: '0.75rem',
              fontWeight: 400,
              fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
            }}
          >
            {error}
          </Typography>
        )}
      </div>
    </Box>
  );
}

export default TGDropzone;
