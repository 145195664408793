import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { motion } from 'framer-motion';

export const ErrorPayment = () => {
  const navigate = useNavigate();
  const handleGoHome = () => {
    navigate('/');
  };
  return (
    <Box
      sx={{
        height: '90vh',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '20px',
        boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.75)',
      }}
    >
      <Card
        sx={{
          height: '70%',
          width: '600px',
          display: 'flex',
          flexDirection: 'column',
          padding: '0px',
          justifyContent: 'start',
          alignItems: 'center',
        }}
      >
        <CardContent
          sx={{
            width: '100%',
            height: '85%',
            padding: '0px',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: 'space-evenly',
          }}
        >
          <Typography
            sx={{
              color: 'white',
              backgroundColor: 'red',
              fontSize: '2em',
              width: '100%',
              height: '40%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              fontFamily: 'Roboto',
            }}
          >
            Lo sentimos, parece que no pudimos realizar el cobro por tus
            boletos.
            <ErrorOutlineIcon sx={{ fontSize: '2em' }} />
          </Typography>
          <motion.div
            style={{ width: '90%', height: '55%' }}
            animate={{
              x: '570px',
              opacity: 1,
              transition: {
                duration: 0.5,
                ease: 'easeInOut',
                delay: 0.2,
              },
            }}
          >
            <Avatar
              src="https://midu.dev/images/this-is-fine-404.gif"
              sx={{
                width: '100%',
                height: '100%',
                marginLeft: '-570px',
              }}
              alt="this is fine"
              variant="rounded"
            />
          </motion.div>
        </CardContent>
        <CardActions
          sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}
        >
          <Button
            sx={{
              height: '100%',
              width: '50%',
              background: '#CC1580',
              color: 'white',
              '&:hover': {
                background: '#890050',
                color: '#FFFFFF',
                border: 'none',
              },
            }}
            onClick={handleGoHome}
          >
            Volver al inicio
          </Button>
        </CardActions>
      </Card>
    </Box>
  );
};
