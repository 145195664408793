import { z } from 'zod';
import { EventCategory, EVENT_CATEGORY } from '../../entities/Event';

const MAX_FILE_SIZE = 50000000;
const ACCEPTED_IMAGE_TYPES = [
  'image/jpeg',
  'image/jpg',
  'image/png',
  'image/webp',
  'webp',
];

export const createEventGeneralInformationSchema = z.object({
  name: z.preprocess(
    (val) => (val === '' ? undefined : val),
    z.string({
      required_error: 'Por favor, ingrese un nombre para el evento.',
    }),
  ),
  shortDescription: z.preprocess(
    (val) => (val === '' ? undefined : val),
    z
      .string({
        required_error:
          'Por favor, ingrese una descripción corta para el evento.',
      })
      .min(3, 'La descripción corta debe tener al menos 3 caracteres.'),
  ),
  longDescription: z.preprocess(
    (val) => (val === '' ? undefined : val),
    z
      .string({
        required_error:
          'Por favor, ingrese una descripción larga para el evento.',
      })
      .min(10, 'La descripción larga debe tener al menos 10 caracteres.'),
  ),
  category: z
    .preprocess(
      (val) => (val === '' ? undefined : val),
      z.string({
        required_error: 'Por favor, ingrese una categoría para el evento.',
      }),
    )
    .refine(
      (category) => EVENT_CATEGORY.includes(category as EventCategory),
      'La categoría ingresada no es válida.',
    ),
});

export const createEventDatesSchema = z.object({
  eventDates: z.array(
    z.object({
      date: z.preprocess(
        (val) => (val === '' || !val ? undefined : new Date(val as Date)),
        z
          .date({
            required_error: 'Campo requerido.',
          })
          .nullable()
          .refine((date) => date instanceof Date, {
            message: 'Campo requerido.',
          }),
      ),
      from: z.preprocess(
        (val) => (val === '' || !val ? undefined : new Date(val as Date)),
        z
          .date({
            required_error: 'Campo requerido.',
          })
          .nullable()
          .refine((date) => date instanceof Date, {
            message:
              'La hora de inicio no es válida, debe tener el formato adecuado (HH:MM:SS).',
          }),
      ),
      to: z.preprocess(
        (val) => (val === '' || !val ? undefined : new Date(val as Date)),
        z
          .date({
            required_error: 'Campo requerido.',
          })
          .nullable()
          .refine((date) => date instanceof Date, {
            message:
              'La hora de finalización no es válida, debe tener el formato adecuado (HH:MM:SS).',
          }),
      ),
    }),
  ),
});

export const createEventPlaceInformationSchema = z.object({
  lineAddress: z.preprocess(
    (val) => (val === '' ? undefined : val),
    z.string({
      required_error: 'Por favor, ingrese una dirección para el evento.',
    }),
  ),
  state: z.preprocess(
    (val) => (val === '' ? undefined : val),
    z.string({
      required_error: 'Por favor, ingrese un estado para el evento.',
    }),
  ),
  city: z.preprocess(
    (val) => (val === '' ? undefined : val),
    z.string({
      required_error: 'Por favor, ingrese una ciudad para el evento.',
    }),
  ),
  venueName: z.preprocess(
    (val) => (val === '' ? undefined : val),
    z.string({
      required_error: 'Por favor, ingrese un escenario para el evento.',
    }),
  ),
});

export const createEventImagesSchema = z.object({
  bannerImage:
    typeof window === 'undefined'
      ? z.any()
      : z
          .custom<File>((file) => file instanceof File, {
            message: 'Por favor, ingrese una imagen para el banner del evento.',
          })
          .refine(
            (file) => {
              return ACCEPTED_IMAGE_TYPES.includes(file.type);
            },
            {
              message:
                'Formato de imagen no válido, por favor, ingrese una imagen válida.',
            },
          )
          .refine((file) => file.size <= MAX_FILE_SIZE, {
            message: 'Por favor, ingrese una imagen que pese menos de 500 kB.',
          }),
  ticketImage:
    typeof window === 'undefined'
      ? z.any()
      : z
          .custom<File>((file) => file instanceof File, {
            message: 'Por favor, ingrese una imagen para el boleto del evento.',
          })
          .refine(
            (file) => {
              return ACCEPTED_IMAGE_TYPES.includes(file.type);
            },
            {
              message:
                'Formato de imagen no válido, por favor, ingrese una imagen válida.',
            },
          )
          .refine((file) => file.size <= MAX_FILE_SIZE, {
            message: 'Por favor, ingrese una imagen que pese menos de 500 kB.',
          }),
  cardImage:
    typeof window === 'undefined'
      ? z.any()
      : z
          .custom<File>((file) => file instanceof File, {
            message:
              'Por favor, ingrese una imagen para la tarjeta del evento.',
          })
          .refine((file) => ACCEPTED_IMAGE_TYPES.includes(file.type), {
            message:
              'Formato de imagen no válido, por favor, ingrese una imagen válida.',
          })
          .refine((file) => file.size <= MAX_FILE_SIZE, {
            message: 'Por favor, ingrese una imagen que pese menos de 500 kB.',
          }),
});

export const createEventSchema = z
  .object({
    name: z.preprocess(
      (val) => (val === '' ? undefined : val),
      z.string({
        required_error: 'Por favor, ingrese un nombre para el evento.',
      }),
    ),
    shortDescription: z.preprocess(
      (val) => (val === '' ? undefined : val),
      z.string({
        required_error:
          'Por favor, ingrese una descripción corta para el evento.',
      }),
    ),
    longDescription: z.preprocess(
      (val) => (val === '' ? undefined : val),
      z.string({
        required_error:
          'Por favor, ingrese una descripción larga para el evento.',
      }),
    ),
    bannerImage:
      typeof window === 'undefined'
        ? z.any()
        : z
            .custom<File>((file) => file instanceof File, {
              message:
                'Por favor, ingrese una imagen para el banner del evento.',
            })
            .refine(
              (file) => {
                console.log(file.type);
                return ACCEPTED_IMAGE_TYPES.includes(file.type);
              },
              {
                message:
                  'Formato de imagen no válido, por favor, ingrese una imagen válida',
              },
            )
            .refine((file) => file.size <= MAX_FILE_SIZE, {
              message:
                'Por favor, ingrese una imagen que pese menos de 500 kB.',
            }),
    cardImage:
      typeof window === 'undefined'
        ? z.any()
        : z
            .custom<File>((file) => file instanceof File, {
              message:
                'Por favor, ingrese una imagen para la tarjeta del evento.',
            })
            .refine(
              (file) => {
                console.log(file.type);
                return ACCEPTED_IMAGE_TYPES.includes(file.type);
              },
              {
                message:
                  'Formato de imagen no válido, por favor, ingrese una imagen válida',
              },
            )
            .refine((file) => file.size <= MAX_FILE_SIZE, {
              message:
                'Por favor, ingrese una imagen que pese menos de 500 kB.',
            }),
    ticketImage:
      typeof window === 'undefined'
        ? z.any()
        : z
            .custom<File>((file) => file instanceof File, {
              message:
                'Por favor, ingrese una imagen para el ticket del evento.',
            })
            .refine(
              (file) => {
                console.log(file.type);
                return ACCEPTED_IMAGE_TYPES.includes(file.type);
              },
              {
                message:
                  'Formato de imagen no válido, por favor, ingrese una imagen válida',
              },
            )
            .refine((file) => file.size <= MAX_FILE_SIZE, {
              message:
                'Por favor, ingrese una imagen que pese menos de 500 kB.',
            }),
    lineAddress: z.preprocess(
      (val) => (val === '' ? undefined : val),
      z.string({
        required_error: 'Por favor, ingrese una dirección para el evento.',
      }),
    ),
    category: z
      .preprocess(
        (val) => (val === '' ? undefined : val),
        z.string({
          required_error: 'Por favor, ingrese una categoría para el evento.',
        }),
      )
      .refine(
        (category) => EVENT_CATEGORY.includes(category as EventCategory),
        'La categoría ingresada no es válida.',
      ),
    state: z.preprocess(
      (val) => (val === '' ? undefined : val),
      z.string({
        required_error: 'Por favor, ingrese un estado para el evento.',
      }),
    ),
    city: z.preprocess(
      (val) => (val === '' ? undefined : val),
      z.string({
        required_error: 'Por favor, ingrese una ciudad para el evento.',
      }),
    ),
    venueName: z.preprocess(
      (val) => (val === '' ? undefined : val),
      z.string({
        required_error: 'Por favor, ingrese un escenario para el evento.',
      }),
    ),
    eventDates: z.preprocess(
      (val) => (typeof val === 'string' ? JSON.parse(val) : val),
      z.array(
        z.object({
          date: z.preprocess(
            (val) => (val === '' || !val ? undefined : new Date(val as Date)),
            z
              .date({
                required_error: 'Campo requerido.',
              })
              .nullable()
              .refine((date) => date instanceof Date, {
                message: 'Campo requerido.',
              }),
          ),
          from: z.preprocess(
            (val) => (val === '' || !val ? undefined : new Date(val as Date)),
            z
              .date({
                required_error: 'Campo requerido.',
              })
              .nullable()
              .refine((date) => date instanceof Date, {
                message:
                  'La hora de inicio no es válida, debe tener el formato adecuado (HH:MM:SS).',
              }),
          ),
          to: z.preprocess(
            (val) => (val === '' || !val ? undefined : new Date(val as Date)),
            z
              .date({
                required_error: 'Campo requerido.',
              })
              .nullable()
              .refine((date) => date instanceof Date, {
                message:
                  'La hora de finalización no es válida, debe tener el formato adecuado (HH:MM:SS).',
              }),
          ),
        }),
      ),
    ),
  })
  .transform((data) => {
    const { eventDates, ...rest } = data;
    return {
      ...rest,
      eventDates: eventDates.map((eventDate) => {
        const { date, from, to } = eventDate;
        return {
          ...date,
          date: new Date(date!),
          from: new Date(from!),
          to: new Date(to!),
        };
      }),
    };
  });

const updateEventSchema = z
  .object({
    name: z.preprocess(
      (val) => (val === '' ? undefined : val),
      z.string({
        required_error: 'Por favor, ingrese un nombre para el evento.',
      }),
    ),
    shortDescription: z.preprocess(
      (val) => (val === '' ? undefined : val),
      z.string({
        required_error:
          'Por favor, ingrese una descripción corta para el evento.',
      }),
    ),
    longDescription: z.preprocess(
      (val) => (val === '' ? undefined : val),
      z.string({
        required_error:
          'Por favor, ingrese una descripción larga para el evento.',
      }),
    ),
    bannerImage:
      typeof window === 'undefined'
        ? z.any()
        : z
            .custom<File>((file) => file instanceof File, {
              message:
                'Por favor, ingrese una imagen para el banner del evento.',
            })
            .refine(
              (file) => {
                console.log(file.type);
                return ACCEPTED_IMAGE_TYPES.includes(file.type);
              },
              {
                message:
                  'Formato de imagen no válido, por favor, ingrese una imagen válida.',
              },
            )
            .refine((file) => file.size <= MAX_FILE_SIZE, {
              message:
                'Por favor, ingrese una imagen que pese menos de 500 kB.',
            }),
    cardImage:
      typeof window === 'undefined'
        ? z.any()
        : z
            .custom<File>((file) => file instanceof File, {
              message:
                'Por favor, ingrese una imagen para la tarjeta del evento.',
            })
            .refine(
              (file) => {
                console.log(file.type);
                return ACCEPTED_IMAGE_TYPES.includes(file.type);
              },
              {
                message:
                  'Formato de imagen no válido, por favor, ingrese una imagen válida.',
              },
            )
            .refine((file) => file.size <= MAX_FILE_SIZE, {
              message:
                'Por favor, ingrese una imagen que pese menos de 500 kB.',
            }),
    ticketImage:
      typeof window === 'undefined'
        ? z.any()
        : z
            .custom<File>((file) => file instanceof File, {
              message:
                'Por favor, ingrese una imagen para el ticket del evento.',
            })
            .refine(
              (file) => {
                console.log(file.type);
                return ACCEPTED_IMAGE_TYPES.includes(file.type);
              },
              {
                message:
                  'Formato de imagen no válido, por favor, ingrese una imagen válida.',
              },
            )
            .refine((file) => file.size <= MAX_FILE_SIZE, {
              message:
                'Por favor, ingrese una imagen que pese menos de 500 kB.',
            }),
    lineAddress: z.preprocess(
      (val) => (val === '' ? undefined : val),
      z.string({
        required_error: 'Por favor, ingrese una dirección para el evento.',
      }),
    ),
    category: z
      .preprocess(
        (val) => (val === '' ? undefined : val),
        z.string({
          required_error: 'Por favor, ingrese una categoría para el evento.',
        }),
      )
      .refine(
        (category) => EVENT_CATEGORY.includes(category as EventCategory),
        'La categoría ingresada no es válida.',
      ),
    state: z.preprocess(
      (val) => (val === '' ? undefined : val),
      z.string({
        required_error: 'Por favor, ingrese un estado para el evento.',
      }),
    ),
    city: z.preprocess(
      (val) => (val === '' ? undefined : val),
      z.string({
        required_error: 'Por favor, ingrese una ciudad para el evento.',
      }),
    ),
    venueName: z.preprocess(
      (val) => (val === '' ? undefined : val),
      z.string({
        required_error: 'Por favor, ingrese un escenario para el evento.',
      }),
    ),
    eventDates: z.preprocess(
      (val) => (typeof val === 'string' ? JSON.parse(val) : val),
      z.array(
        z.object({
          date: z.preprocess(
            (val) => (val === '' || !val ? undefined : new Date(val as Date)),
            z
              .date({
                required_error: 'Campo requerido.',
              })
              .nullable()
              .refine((date) => date instanceof Date, {
                message: 'Campo requerido.',
              }),
          ),
          id: z.string().nullable().optional(),
          from: z.preprocess(
            (val) => (val === '' || !val ? undefined : new Date(val as Date)),
            z
              .date({
                required_error: 'Campo requerido.',
              })
              .nullable()
              .refine((date) => date instanceof Date, {
                message:
                  'La hora de inicio no es válida, debe tener el formato adecuado (HH:MM:SS).',
              }),
          ),
          to: z.preprocess(
            (val) => (val === '' || !val ? undefined : new Date(val as Date)),
            z
              .date({
                required_error: 'Campo requerido.',
              })
              .nullable()
              .refine((date) => date instanceof Date, {
                message:
                  'La hora de finalización no es válida, debe tener el formato adecuado (HH:MM:SS).',
              }),
          ),
        }),
      ),
    ),
  })
  .transform((data) => {
    const { eventDates, ...rest } = data;
    return {
      ...rest,
      eventDates: eventDates.map((eventDate) => {
        const { date, from, to } = eventDate;
        return {
          ...date,
          date: new Date(date!),
          from: new Date(from!),
          to: new Date(to!),
        };
      }),
    };
  });

export const eventDatesAndPlacesSchema = createEventDatesSchema.merge(
  createEventPlaceInformationSchema,
);

export type CreateEventDto = z.infer<typeof createEventSchema>;

export type UpdateEventDto = z.infer<typeof updateEventSchema>;

export type CreateEventGeneralInformationDto = z.infer<
  typeof createEventGeneralInformationSchema
>;

export type CreateEventDatesAndPlaceInfoDto = z.infer<
  typeof eventDatesAndPlacesSchema
>;

// none in use
export type CreateEventDatesDto = z.infer<typeof createEventDatesSchema>;

export type CreateEventPlaceInformationDto = z.infer<
  typeof createEventPlaceInformationSchema
>;
//

export type CreateEventImagesDto = z.infer<typeof createEventImagesSchema>;

export type CreateEventDatesAndPlacesDto = z.infer<
  typeof eventDatesAndPlacesSchema
>;
