import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
} from '@mui/material';
import { useEffect, useState, useRef, useCallback } from 'react';
import DefaultImage from '../images/defaultImage.png';

import Cropper, { ReactCropperElement } from 'react-cropper';
import TGDropzone from './TgDropzone';

import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import SaveIcon from '@mui/icons-material/Save';

const classes = {
  cropContainer: {
    position: 'relative',
    height: 200,
    background: '#333',
  },
};

const styleCard = {
  width: 350,
  height: 400,
  maxHeight: 450,
  borderRadius: '10px',
  backgroundColor: '#d0d1d6',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  m: 2,
};

const styleButton = {
  width: '100%',
  minHeight: '40px',
  background: '#CC1580',
  borderRadius: '10px',
  color: '#FFFFFF',
  fontWeight: 600,
  letterSpacing: '1px',
  fontFamily: 'Poppins',
  textTransform: 'capitalize',
  fontSize: '1rem',
  '&:hover': {
    backgroundColor: '#890050',
    color: '#FFFFFF',
  },
  mb: 2,
};

interface EventCardPreviewProps {
  name: string | null;
  venueName: string | null;
  image: File | null;
  onCancel: () => void;
  onSave: (file: File) => void;
  currentImage?: string | null;
}

export default function EventCardPreview({
  name,
  venueName,
  image,
  currentImage,
  // functions
  onCancel,
  onSave,
}: EventCardPreviewProps) {
  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const [showPreview, setShowPreview] = useState(false);

  const [croppedImage, setCroppedImage] = useState<string | null>(null);

  const [croppedImageFile, setCroppedImageFile] = useState<File | null>(null);
  const [cardImage, setCardImage] = useState<string | null>(null);
  const cropperRef = useRef<ReactCropperElement>(null);

  // Manejo de archivo subido
  const onReceiveFile = (file: File) => {
    const reader = new FileReader();
    reader.onload = () => {
      setImageSrc(reader.result as string);
      setShowPreview(false);
    };
    reader.readAsDataURL(file);
  };

  const handleChangeImage = () => {
    setCardImage(null);
    setImageSrc(null);
    setCroppedImage(null);
    setShowPreview(false);
  };

  // Obtener recorte del cropper
  const getCroppedImage = useCallback(() => {
    if (cropperRef.current && cropperRef.current.cropper) {
      const cropper = cropperRef.current.cropper;
      const canvas = cropper.getCroppedCanvas();
      if (canvas) {
        const dataUrl = canvas.toDataURL('image/jpeg');
        setCroppedImage(dataUrl);
        setImageSrc(dataUrl);
        setCardImage(dataUrl);

        canvas.toBlob((blob) => {
          if (blob) {
            const file = new File([blob], 'cropped.jpg', {
              type: 'image/jpeg',
            });
            setCroppedImageFile(file);
          }
        }, 'image/jpeg');
        setShowPreview(true);
      }
    }
  }, []);

  // Guardar recorte
  const handleSave = async () => {
    if (croppedImageFile) {
      onSave(croppedImageFile);
    }
  };

  useEffect(() => {
    if (currentImage) {
      setCardImage(currentImage);
      setShowPreview(true);
    }
  }, [currentImage]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        alignItems: 'center',
      }}
    >
      <Box>
        <Typography
          variant="h5"
          sx={{
            color: 'grey',
            fontSize: '1rem',
            mb: 2,
            textAlign: 'center',
          }}
        >
          Asi se lucirá tu evento en la página principal
        </Typography>

        <Card sx={styleCard}>
          {showPreview && (
            <CardMedia
              sx={{
                borderRadius: '10px',
                cursor: 'pointer',
                height: 200,
                width: 320,
                marginTop: '15px',
                alignSelf: 'center',
              }}
              image={cardImage || croppedImage || DefaultImage}
              title={name || ''}
            />
          )}

          {!showPreview && imageSrc && (
            <Box sx={classes.cropContainer}>
              <Cropper
                src={imageSrc}
                height={200}
                width={320}
                autoCropArea={3 / 4}
                guides
                viewMode={1}
                background={false}
                responsive
                checkOrientation={false}
                dragMode="move"
                ref={cropperRef}
                onInitialized={(instance) => {
                  setTimeout(() => {
                    try {
                      instance.zoomTo(0.1);
                    } catch (e) {
                      console.error('Zoom error:', e);
                    }
                  }, 300);
                }}
              />
            </Box>
          )}

          {!croppedImage && !imageSrc && !cardImage && (
            <Box
              sx={{
                width: '100%',
                height: '200px',
              }}
            >
              <TGDropzone
                file={image}
                onReceiveFile={onReceiveFile}
                containerHeight={200}
              />
            </Box>
          )}

          <CardContent>
            <Typography
              gutterBottom
              variant="h5"
              sx={{
                display: '-webkit-box',
                WebkitLineClamp: 2,
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                width: '100%',
                height: 'auto',
                fontSize: '1.4rem',
                fontFamily: 'Poppins',
                color: '#890050',
                fontWeight: '750',
              }}
            >
              {name || 'Nombre del evento'}
            </Typography>

            <Typography
              variant="body1"
              sx={{
                display: '-webkit-box',
                WebkitLineClamp: 2,
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                width: '100%',
                fontSize: '0.9rem',
                fontFamily: 'Poppins',
                color: '#000',
                fontWeight: '300',
              }}
            >
              {venueName || 'Nombre del lugar'}
            </Typography>
          </CardContent>
          <CardActions
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Button size="small" sx={styleButton} onClick={() => null}>
              Ver detalles
            </Button>
          </CardActions>
        </Card>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            m: 2,
          }}
        >
          {(croppedImage || cardImage || imageSrc) && (
            <Button
              sx={{
                width: '100%',
                minHeight: '35px',
                background: '#444E6A',
                borderRadius: '10px',
                color: '#FFFFFF',
                fontWeight: 600,
                letterSpacing: '1px',
                fontFamily: 'Poppins',
                textTransform: 'capitalize',
                fontSize: '1rem',
                '&:hover': {
                  backgroundColor: '#FFFFFF',
                  color: '#444E6A',
                },
                mb: 2,
              }}
              variant="contained"
              size="small"
              onClick={handleChangeImage}
              startIcon={<AddPhotoAlternateIcon />}
            >
              Cambiar imagen
            </Button>
          )}

          {imageSrc && (
            <Button
              sx={{
                width: '100%',
                minHeight: '35px',
                background: '#FFFFFF',
                borderRadius: '10px',
                color: '#444E6A',
                fontWeight: 600,
                letterSpacing: '1px',
                fontFamily: 'Poppins',
                textTransform: 'capitalize',
                fontSize: '1rem',
                '&:hover': {
                  backgroundColor: '#444E6A',
                  color: '#FFFFFF',
                },
                mb: 2,
              }}
              variant="contained"
              onClick={() => {
                if (showPreview) {
                  setShowPreview(false);
                } else {
                  getCroppedImage();
                }
              }}
              size="small"
              startIcon={showPreview ? <EditIcon /> : <VisibilityIcon />}
            >
              {showPreview ? 'Seguir editando' : 'Pre visualizar'}
            </Button>
          )}

          {croppedImage && (
            <Button
              sx={{
                width: '100%',
                minHeight: '35px',
                background: '#444E6A',
                borderRadius: '10px',
                color: '#FFFFFF',
                fontWeight: 600,
                letterSpacing: '1px',
                fontFamily: 'Poppins',
                textTransform: 'capitalize',
                fontSize: '1rem',
                '&:hover': {
                  backgroundColor: '#FFFFFF',
                  color: '#444E6A',
                },
                mb: 2,
              }}
              variant="contained"
              size="small"
              onClick={handleSave}
              startIcon={<SaveIcon />}
            >
              Guardar
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
}
