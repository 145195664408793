import { Box, Typography } from '@mui/material';
import { EventEntity } from 'libs/domain/src/lib/entities/Event';
import { AddPaidToSales } from '../components/sales/AddPaidToEvent';
import { TransactionGenerator } from '../components/sales/TransactionsGenerator';
import { useEffect, useState } from 'react';
import { api } from '../../requests/api-client';

const boxContainer = {
  m: '60px 0',
  display: 'flex',
  flexDirection: 'column',
  padding: '17.5px 0 0 0',
  position: 'relative',
  borderRadius: '15px',
  minHeight: '575px',
  height: 'auto',
  alignItems: 'center',
};

export const SalesPoint = () => {
  const [events, setEvents] = useState<EventEntity[]>([]);

  const fetchEvents = async () => {
    if (events.length === 0) {
      const response = await api.get('/events/published');

      setEvents(response.data.data.events);
    }
  };

  useEffect(() => {
    (async () => {
      await fetchEvents();
    })();
  }, []);

  return (
    <Box sx={boxContainer}>
      <Typography
        sx={{
          textTransform: 'uppercase',
          fontSize: '35px',
          fontWeight: '900',
          color: (theme) => theme.palette.secondary.dark,
          lineHeight: 2.55,
          alignSelf: 'self-start',
          position: 'relative',
          top: '15px',
          left: '70px',
        }}
      >
        Perfil de ventas
      </Typography>
      <AddPaidToSales events={events} />
      <TransactionGenerator />
    </Box>
  );
};
