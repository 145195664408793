import { z } from 'zod';
import {
  EventCategory,
  EventStatus,
  EVENT_CATEGORY,
  EVENT_STATUS,
} from '../../entities/Event';

const MAX_FILE_SIZE = 50000000;
const ACCEPTED_IMAGE_TYPES = ['image/jpeg', 'image/jpg', 'image/png'];

export const updateEventByAdminSchema = z.object({
  name: z.preprocess(
    (val) => (val === '' ? undefined : val),
    z
      .string({
        required_error: 'Por favor, ingrese un nombre para el evento.',
      })
      .max(50, 'El nombre debe tener máximo 50 caracteres'),
  ),
  shortDescription: z.preprocess(
    (val) => (val === '' ? undefined : val),
    z
      .string({
        required_error:
          'Por favor, ingrese una breve descripción acerca del evento.',
      })
      .max(255, 'La descripción corta debe tener máximo 255 caracteres'),
  ),
  longDescription: z.preprocess(
    (val) => (val === '' ? undefined : val),
    z
      .string({
        required_error:
          'Por favor, ingrese una descripción más detallada sobre el evento.',
      })
      .max(255, 'La descripción larga debe tener máximo 255 caracteres'),
  ),
  bannerImage:
    typeof window === 'undefined'
      ? z.any()
      : z
          .custom<File>((file) => file instanceof File, {
            message: 'Por favor, ingrese una imagen para el banner del evento.',
          })
          .refine(
            (file) => {
              return ACCEPTED_IMAGE_TYPES.includes(file.type);
            },
            {
              message:
                'Formato de imagen no válido, por favor, ingrese una imagen válida.',
            },
          )
          .refine((file) => file.size <= MAX_FILE_SIZE, {
            message: 'Por favor, ingrese una imagen que pese menos de 500 kB.',
          })
          .optional(),
  cardImage:
    typeof window === 'undefined'
      ? z.any()
      : z
          .custom<File>((file) => file instanceof File, {
            message:
              'Por favor, ingrese una imagen para la tarjeta del evento.',
          })
          .refine((file) => ACCEPTED_IMAGE_TYPES.includes(file.type), {
            message:
              'Formato de imagen no válido, por favor, ingrese una imagen válida.',
          })
          .refine((file) => file.size <= MAX_FILE_SIZE, {
            message: 'Por favor, ingrese una imagen que pese menos de 500 kB.',
          })
          .optional(),
  ticketImage:
    typeof window === 'undefined'
      ? z.any()
      : z
          .custom<File>((file) => file instanceof File, {
            message: 'Por favor, ingrese una imagen para el boleto del evento.',
          })
          .refine(
            (file) => {
              return ACCEPTED_IMAGE_TYPES.includes(file.type);
            },
            {
              message:
                'Formato de imagen no válido, por favor, ingrese una imagen válida.',
            },
          )
          .refine((file) => file.size <= MAX_FILE_SIZE, {
            message: 'Por favor, ingrese una imagen que pese menos de 500 kB.',
          })
          .optional(),
  status: z
    .preprocess(
      (val) => (val === '' ? undefined : val),
      z.string({
        required_error: 'Por favor, ingrese un estatus para el evento.',
      }),
    )
    .refine(
      (status) => EVENT_STATUS.includes(status as EventStatus),
      'Por favor, ingrese un estatus válido.',
    ),
  lineAddress: z.preprocess(
    (val) => (val === '' ? undefined : val),
    z.string({
      required_error:
        'Por favor, ingrese la dirección donde se llevará a cabo el evento.',
    }),
  ),
  category: z
    .preprocess(
      (val) => (val === '' ? undefined : val),
      z.string({
        required_error: 'Por favor, selecciona la categoría del evento.',
      }),
    )
    .refine(
      (category) => EVENT_CATEGORY.includes(category as EventCategory),
      'La categoría ingresada no es válida.',
    ),
  state: z.preprocess(
    (val) => (val === '' ? undefined : val),
    z.string({
      required_error:
        'Por favor, ingrese un estado donde se llevará a cabo el evento.',
    }),
  ),
  city: z.preprocess(
    (val) => (val === '' ? undefined : val),
    z.string({
      required_error:
        'Por favor, ingrese una ciudad donde se llevará a cabo el evento.',
    }),
  ),
  venueName: z.preprocess(
    (val) => (val === '' ? undefined : val),
    z
      .string({
        required_error:
          'Por favor, ingrese un establecimiento donde se llevará a cabo el evento.',
      })
      .max(
        255,
        'El nombre del establecimiento debe tener máximo 255 caracteres',
      ),
  ),
  eventDates: z.preprocess(
    (val) => (typeof val === 'string' ? JSON.parse(val) : val),
    z.array(
      z.object({
        date: z.preprocess(
          (val) => (val === '' || !val ? undefined : new Date(val as Date)),
          z
            .date({
              required_error: 'Campo requerido.',
            })
            .nullable()
            .refine((date) => date instanceof Date, {
              message: 'Campo requerido.',
            }),
        ),
        id: z.string().optional().nullable(),
        from: z.preprocess(
          (val) => (val === '' || !val ? undefined : new Date(val as Date)),
          z
            .date({
              required_error: 'Campo requerido.',
            })
            .nullable()
            .refine((date) => date instanceof Date, {
              message:
                'La hora de inicio no es válida, debe tener el formato adecuado (HH:MM:SS).',
            }),
        ),
        to: z.preprocess(
          (val) => (val === '' || !val ? undefined : new Date(val as Date)),
          z
            .date({
              required_error: 'Campo requerido.',
            })
            .nullable()
            .refine((date) => date instanceof Date, {
              message:
                'La hora de finalización no es válida, debe tener el formato adecuado (HH:MM:SS).',
            }),
        ),
      }),
    ),
  ),
  eventDatesToDelete: z.preprocess(
    (val) => (typeof val === 'string' ? JSON.parse(val) : val),
    z.array(z.string()).optional(),
  ),
});

export type UpdateEventByAdminDto = z.infer<typeof updateEventByAdminSchema>;
