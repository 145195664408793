import React from 'react';
import { Paper, Typography, Box } from '@mui/material';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';

interface CartTimerProps {
  minutes: number;
  seconds: number;
}

const CartTimer: React.FC<CartTimerProps> = ({ minutes, seconds }) => {
  return (
    <Paper
      elevation={2}
      sx={{
        p: 1,
        borderRadius: '10px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 1,
        backgroundColor: '#890050',
        color: 'white',
      }}
    >
      <WarningAmberRoundedIcon />
      <Typography
        sx={{
          fontWeight: 600,
          fontFamily: 'Poppins',
          fontSize: '0.95rem',
        }}
      >
        Tiempo restante de tu selección:&nbsp;
        <Box component="span" sx={{ fontWeight: 700 }}>
          {minutes}:{seconds.toString().padStart(2, '0')}
        </Box>
        &nbsp;{minutes === 0 ? 's' : 'min'}
      </Typography>
    </Paper>
  );
};

export default CartTimer;
