import { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { ConektaCheckout } from './ConektaCheckout';
import { RemainingTime } from './RemainingTime';
import { Box } from '@mui/material';
import { SuccessRedirect } from './SuccessRedirect';
import { useCart } from '../../hooks/useCart';

interface ModalCheckoutDialogProps {
  isOpen: boolean;
  onClose: () => void;
  checkoutId: string;
  cardUpdatedAt?: string;
}

export default function ModalCheckoutDialog({
  isOpen,
  onClose,
  checkoutId,
  cardUpdatedAt,
}: ModalCheckoutDialogProps) {
  const [hasPaymentSucceeded, setHasPaymentSucceeded] = useState(false);
  const { refreshCart } = useCart();

  const handleSuccess = () => {
    refreshCart();
    setHasPaymentSucceeded(true);
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullScreen
    >
      <DialogTitle id="alert-dialog-title">Checkout</DialogTitle>
      <DialogContent>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            p: 2,
          }}
        >
          {!hasPaymentSucceeded && <RemainingTime />}
          {hasPaymentSucceeded ? <SuccessRedirect /> : null}
        </Box>
        {isOpen && (
          <ConektaCheckout
            checkoutRequestId={checkoutId}
            onPaymentSuccess={handleSuccess}
          />
        )}
      </DialogContent>
    </Dialog>
  );
}
