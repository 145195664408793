import { useEffect, useState } from 'react';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import { useAuth } from '../../providers/AuthProvider';

export const ConfirmAccount = () => {
  const navigate = useNavigate();
  const [mainPage, setMainPage] = useState('');
  const [searchParams] = useSearchParams();
  const { redirectAdminUserToMainPage, confirmNewUser } = useAuth();
  useEffect(() => {
    (async () => {
      const token = searchParams.get('token');

      if (token) {
        const { status } = await confirmNewUser(token);

        if (status) {
          const mainPage = redirectAdminUserToMainPage();
          setMainPage(mainPage);
        }
      }
    })();
  }, [searchParams]);

  return <Navigate to={mainPage} replace />;
};
