import React from 'react';
import { Box, Typography } from '@mui/material';
import { toCurrency } from 'apps/client/src/app/utils/currency';
import { useCart } from 'apps/client/src/app/hooks/useCart';

interface CartSummaryProps {
  totalPrice: number;
}

const CartSummary: React.FC<CartSummaryProps> = ({ totalPrice }) => {
  const {
    state: { cart },
  } = useCart();

  const chargeForService = cart ? cart.totalPrice * 0.13 : 0;

  return (
    <Box sx={{ width: '90%', mx: 'auto', mt: 3 }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <Typography
          sx={{
            fontSize: '1rem',
            fontWeight: 900,
            fontFamily: 'Poppins',
            color: 'primary.main',
          }}
        >
          Cargos
        </Typography>
        <Typography
          sx={{
            fontSize: '1rem',
            fontWeight: 900,
            fontFamily: 'Poppins',
            color: 'primary.main',
          }}
        >
          {`MX ${toCurrency(chargeForService)}`}
        </Typography>
      </Box>
      <Typography
        sx={{
          alignSelf: 'start',
          fontSize: '0.85rem',
          fontWeight: 500,
          fontFamily: 'Poppins',
          color: '#303030',
          textAlign: 'justify',
          lineHeight: 1.5,
          wordBreak: 'break-word',
        }}
      >
        Cargo por servicio
      </Typography>
      {totalPrice > 0 && (
        <Box
          sx={{
            mt: 10,
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Typography
            sx={{
              fontSize: '20px',
              fontWeight: 900,
              fontFamily: 'Poppins',
              color: 'common.black',
            }}
            gutterBottom
          >
            Total
          </Typography>
          <Typography
            sx={{
              fontSize: '20px',
              fontWeight: 900,
              fontFamily: 'Poppins',
              color: 'primary.main',
            }}
            gutterBottom
          >
            {`MX ${toCurrency(totalPrice + chargeForService)}`}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default CartSummary;
