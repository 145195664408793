import React from 'react';
import AlertMessage from '../components/AlertMessage';
import { isAxiosError } from 'axios';
import { api } from '../../requests/api-client';
import FullPageSpinner from '../components/FullPageSpinner';
import { debounce } from 'lodash';
import { UsersTable } from '../components/users/UsersTable';
import { useAuth } from '../../providers/AuthProvider';
import { useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import PeopleIcon from '@mui/icons-material/People';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import HomeIcon from '@mui/icons-material/Home';
import SellIcon from '@mui/icons-material/Sell';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import PersonPinIcon from '@mui/icons-material/PersonPin';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import {
  AdminPanelSettings,
  PointOfSale,
  QrCodeScanner,
} from '@mui/icons-material';
import {
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme,
} from '@mui/material';

interface MenuOptions {
  label?: string;
  path: string;
  icon: React.ReactNode;
  onClick: () => void;
}

export interface User {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  role: string;
  status: string;
}

export const Users = () => {
  const [users, setUsers] = React.useState<User[]>([]);
  const [alertProps, setAlertprops] = React.useState<{
    isOpen: boolean;
    severity: 'success' | 'error' | 'info' | 'warning';
    message: string;
  }>({
    isOpen: false,
    severity: 'success' as 'success',
    message: '',
  });
  const [page, setPage] = React.useState(0);
  const [total, setTotal] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [pageReady, setPageReady] = React.useState(false);
  const [searchUserQuery, setSearchUserQuery] = React.useState('');
  const [open, setOpen] = React.useState(true);
  const { user } = useAuth();
  const navigate = useNavigate();
  const theme = useTheme();

  const menuOptions: MenuOptions[] = [
    {
      label: 'Eventos',
      path: '/admin/eventos',
      icon: <EventAvailableIcon sx={{ color: 'white' }} />,
      onClick: () => navigate('/admin/eventos'),
    },
    {
      label: 'Administradores',
      path: '/admin/administradores',
      onClick: () => navigate('/admin/administradores'),
      icon: <AdminPanelSettings sx={{ color: 'white' }} />,
    },
    {
      label: 'Usuarios Ventas',
      path: '/admin/sales',
      onClick: () => navigate('/admin/sales'),
      icon: <PointOfSale sx={{ color: 'white' }} />,
    },
    {
      label: 'Usuarios Escaner',
      path: '/admin/scan',
      onClick: () => navigate('/admin/scan'),
      icon: <QrCodeScanner sx={{ color: 'white' }} />,
    },
    {
      label: 'Usuarios',
      path: '/admin/usuarios',
      onClick: () => navigate('/admin/usuarios'),
      icon: <PeopleIcon sx={{ color: 'white' }} />,
    },
    {
      label: 'Historial de ventas',
      path: '/admin/historial-de-ventas',
      icon: <SellIcon sx={{ color: 'white' }} />,
      onClick: () => navigate('/admin/historial-de-ventas'),
    },
    {
      label: 'Página principal',
      path: '/',
      icon: <HomeIcon sx={{ color: 'white' }} />,
      onClick: () => navigate('/'),
    },
  ];

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOpenDrawer = () => {
    setOpen(true);
  };

  const handleCloseDrawer = () => {
    setOpen(false);
  };

  /* API Functions */
  const fetchUsers = async () => {
    try {
      const offset = rowsPerPage * page;
      const response = await api.get('/admin/users', {
        params: {
          search: searchUserQuery,
          page: page,
          limit: rowsPerPage,
          offset,
        },
      });
      setUsers(response.data.data.users);
      setTotal(response.data.count);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  /* API Functions end */
  const debouncedUsersQuery = debounce(fetchUsers, 500);

  React.useEffect(() => {
    const delay = setTimeout(() => {
      debouncedUsersQuery();
    }, 150);

    console.log(debouncedUsersQuery);

    if (pageReady) {
      if (searchUserQuery === '') {
        debouncedUsersQuery.cancel();
        fetchUsers();
      } else {
        debouncedUsersQuery();
      }
    }

    return () => {
      clearTimeout(delay);
      debouncedUsersQuery.cancel();
    };
  }, [searchUserQuery]);

  React.useEffect(() => {
    (async () => {
      await fetchUsers();
      setPageReady(true);
    })();
  }, []);

  React.useEffect(() => {
    if (pageReady) {
      fetchUsers();
    }
  }, [page, rowsPerPage]);

  if (!pageReady) {
    return <FullPageSpinner />;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        height: 'auto',
      }}
    >
      {/* New menu here */}
      {open && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            padding: '17.5px 0 0 0',
            position: 'relative',
            borderRadius: '15px',
            width: { xs: '100%', md: '30%' },
            minHeight: '675px',
            backgroundColor: '#444E6A',
            m: '20px 0',
            right: '10px',
          }}
        >
          <React.Fragment>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                width: '100%',
                justifyContent: 'center',
              }}
            >
              <PersonPinIcon
                sx={{
                  mr: 1,
                  width: 48,
                  height: 48,
                  color: '#FFF',
                }}
              />
              <Typography
                variant="caption"
                sx={{
                  fontSize: 20,
                  fontWeight: 900,
                  color: '#FFF',
                  lineHeight: 1.2,
                  maxWidth: '60%',
                  whiteSpace: 'normal',
                  wordBreak: 'break-word',
                }}
              >
                Bienvenido, {user?.firstName}
              </Typography>
            </Box>
            <Divider
              sx={{
                borderColor: '#FFF',
                borderWidth: '0.5px',
                mt: 1,
              }}
            />
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <List sx={{ p: '10px 35px 0 0' }} className="customAdminList">
                {menuOptions.map((option, index) => (
                  <ListItem
                    key={option.label}
                    disablePadding
                    sx={{ display: 'block' }}
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? 'initial' : 'center',
                        px: 2.5,
                        color: '#FFF',
                        background: '#444E6A',
                      }}
                      onClick={option.onClick}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? '15px' : 'auto',
                          justifyContent: 'center',
                        }}
                      >
                        {option.icon}
                      </ListItemIcon>
                      <ListItemText
                        primary={option.label}
                        sx={{
                          opacity: open ? 1 : 0,
                          fontSize: 13,
                          fontWeight: 500,
                          textAlign: 'start',
                        }}
                      />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </Box>
          </React.Fragment>
        </Box>
      )}

      {/* Menu options */}
      {!open && (
        <IconButton
          aria-label="open drawer"
          onClick={handleOpenDrawer}
          edge="start"
          sx={{
            width: 48,
            height: 48,
            top: '20px',
            left: '17.5px',
          }}
        >
          <MenuIcon
            sx={{
              color: '#444E6A',
              width: 36,
              height: 36,
            }}
          />
        </IconButton>
      )}
      <UsersTable
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        users={users}
        currentPage={page}
        rowsPerPage={rowsPerPage}
        totalUsers={total}
        onSearch={(search) => setSearchUserQuery(search)}
      />
      {/*
      <AlertMessage
        onClose={handleCloseAlert}
        isOpen={alertProps.isOpen}
        message={alertProps.message}
        severity={alertProps.severity}
      /> */}
    </Box>
  );
};
