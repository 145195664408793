import * as React from 'react';
import {
  Box,
  TextField,
  MenuItem,
  InputAdornment,
  IconButton,
} from '@mui/material';
import {
  FieldErrors,
  UseFormRegister,
  UseFormResetField,
} from 'react-hook-form';
import { CreateEventGeneralInformationDto } from 'libs/domain/src/lib/dto';
import WithStepContainer, { StepContainerProps } from './StepContainer';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

interface GeneralInformationStepProps extends StepContainerProps {
  register: UseFormRegister<CreateEventGeneralInformationDto>;
  errors: FieldErrors<CreateEventGeneralInformationDto>;
  resetField: UseFormResetField<CreateEventGeneralInformationDto>;
}

const GeneralInformationStep = ({
  register,
  errors,
  resetField,
}: GeneralInformationStepProps) => {
  return (
    <React.Fragment>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#FFFFFF',
          width: '100%',
          padding: '10px 12px',
        }}
      >
        <TextField
          id="outlined-basic"
          label="Nombre del evento"
          defaultValue=""
          size="small"
          {...register('name')}
          error={!!errors.name}
          helperText={errors.name?.message}
          className="customTextField"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => resetField('name')} edge="end">
                  <HighlightOffIcon
                    sx={{
                      color: '#49454F',
                    }}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          {...register('shortDescription')}
          id="outlined-basic"
          label="Agrega una breve descripción"
          defaultValue=""
          size="small"
          error={!!errors.shortDescription}
          helperText={errors.shortDescription?.message}
          className="customTextField"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => resetField('shortDescription')}
                  edge="end"
                >
                  <HighlightOffIcon
                    sx={{
                      color: '#49454F',
                    }}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          {...register('longDescription')}
          rows={4}
          multiline
          id="outlined-password-input"
          label="Agrega una descripción más detallada"
          defaultValue=""
          size="small"
          error={!!errors.longDescription}
          helperText={errors.longDescription?.message}
          className="customTextField"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => resetField('longDescription')}
                  edge="end"
                >
                  <HighlightOffIcon
                    sx={{
                      color: '#49454F',
                    }}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          {...register('category')}
          fullWidth
          size="small"
          id="outlined-select-currency"
          select
          label="Categoría"
          defaultValue={''}
          error={!!errors.category}
          helperText={errors.category?.message}
          className="customTextField"
          SelectProps={{
            MenuProps: {
              classes: {
                paper: 'customTextPaper',
              },
            },
          }}
        >
          {[
            { value: 'MUSICA', label: 'Música' },
            { value: 'DEPORTES', label: 'Deportes' },
            { value: 'ENTRETENIMIENTO', label: 'Entretenimiento' },
          ].map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Box>
    </React.Fragment>
  );
};

export default WithStepContainer(GeneralInformationStep);
