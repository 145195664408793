import { Button, SxProps, ButtonProps, Theme } from '@mui/material';

interface TTButtonProps extends ButtonProps {
  children: React.ReactNode;
  onClick?: () => void;
  variant?: 'text' | 'outlined' | 'contained';
  color?:
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'error'
    | 'info'
    | 'warning';
  size?: 'small' | 'medium' | 'large';
  disabled?: boolean;
  type?: 'button' | 'submit' | 'reset';
  sx?: SxProps;
}

const getPropColor = (color: string | undefined, theme: Theme) => {
  switch (color) {
    case 'primary':
      return theme.palette.primary.main;
    case 'secondary':
      return theme.palette.secondary.main;
    case 'success':
      return theme.palette.success.main;
    case 'error':
      return theme.palette.error.main;
    case 'info':
      return theme.palette.info.main;
    case 'warning':
      return theme.palette.warning.main;
    case 'inherit':
      return '#f8f8f8';
    default:
      return theme.palette.common.white;
  }
};

export default function TTButton({ children, ...props }: TTButtonProps) {
  return (
    <Button
      {...props}
      variant={props.variant || 'contained'}
      sx={{
        textTransform: 'none',
        fontSize: '0.9rem',
        fontFamily: 'Popins, sans-serif',
        fontWeight: 600,
        letterSpacing: '0.7px',
        borderRadius: '10px',
        padding: '5px 15px',
        backgroundColor: (theme) => getPropColor(props.color, theme),
        boxShadow: 'none',
        '&:hover': {
          // with shadow
          boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.2)',
        },
        ...props.sx,
      }}
    >
      {children}
    </Button>
  );
}
