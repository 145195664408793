import { Box, Typography } from '@mui/material';

const boxContainer = {
  display: 'flex',
  flexDirection: 'column',
  height: 'auto',
  justifyContent: 'center',
  alignItems: 'center',
  pt: 10,
};

const scrollbarStyles = {
  borderRadius: '20px',
  padding: '65px 45px',
  mb: 5,
  backgroundColor: '#FFFFFF',
  width: { xs: '70%', sm: '75%', md: '80%', xl: '85%' },
  overflowY: 'scroll',
  scrollBehavior: 'smooth',
  maxHeight: '100vh',
  outline: 'none',
  '::-webkit-scrollbar': {
    width: '16px',
    margin: '0 5px 0 0',
  },
  '::-webkit-scrollbar-track': {
    background: '#EBEBEB',
    borderRadius: '10px',
    // border: 'solid 2px #EBEBEB',
  },

  /* thumb */
  '::-webkit-scrollbar-thumb': {
    background: '#C33186',
    backgroundClip: 'padding-box',
    border: '4px solid transparent',
    borderRadius: '8px',
  },
  '::-webkit-scrollbar-thumb:hover': {
    background: '#C33186',
    border: '4px solid transparent',
    borderRadius: '8px',
  },
  '::-webkit-scrollbar-thumb:active': {
    background: '#C33186',
  },
  '::-webkit-scrollbar-thumb:vertical': {
    borderTop: 'solid 10px #EBEBEB',
    borderBottom: 'solid 10px #EBEBEB',
  },
  '::-webkit-scrollbar-thumb:horizontal': {
    borderLeft: 'solid 10px #EBEBEB',
    borderRight: 'solid 10px #EBEBEB',
  },

  /* buttons */
  '::-webkit-scrollbar-button': {
    width: '14px',
    height: '512px',
    borderRadius: '10px',
  },

  /* up */
  '::-webkit-scrollbar-button:vertical:decrement': {
    borderWidth: '16px 9px 16px 9px',
    borderColor: 'transparent transparent #404040 transparent',
  },
  '::-webkit-scrollbar-button:vertical:decrement:hover': {
    borderColor: 'transparent transparent #505050 transparent',
  },

  /* down */
  '::-webkit-scrollbar-button:vertical:increment': {
    borderWidth: '16px 9px 16px 9px',
    borderColor: '#C33186 transparent transparent transparent',
  },
  '::-webkit-scrollbar-button:vertical:increment:hover': {
    borderColor: '#C33186 transparent transparent transparent',
  },

  /* left */
  '::-webkit-scrollbar-button:horizontal:decrement': {
    borderWidth: '7px 14px 7px 0',
    borderColor: 'transparent #404040 transparent transparent',
  },
  '::-webkit-scrollbar-button:horizontal:decrement:hover': {
    borderColor: 'transparent #505050 transparent transparent',
  },

  /* right */
  '::-webkit-scrollbar-button:horizontal:increment': {
    borderWidth: '7px 0 7px 14px',
    borderColor: 'transparent transparent transparent #404040',
  },
  '::-webkit-scrollbar-button:horizontal:increment:hover': {
    borderColor: 'transparent transparent transparent #505050',
  },
};

export const PrivacyNotice = () => {
  return (
    <Box sx={boxContainer}>
      <Typography
        sx={{
          textTransform: 'uppercase',
          fontSize: { xs: '24px', sm: '30px', md: '35px' },
          fontWeight: '900',
          color: '#48002A',
          lineHeight: 1.3,
          marginTop: '20px',
          paddingLeft: { xs: '16px', sm: '32px', md: '100px' },
          paddingRight: { xs: '22px', md: '100px'},
          paddingBottom: '10px',
          alignSelf: 'self-start',
        }}
      >
        Aviso de privacidad
      </Typography>
      <Box sx={scrollbarStyles}>
        <Typography
          sx={{
            fontSize: '15px',
            fontWeight: '300',
            textAlign: 'justify',
          }}
        >
          CUATRO CUARENTA ENTRETENIMIENTO, S.A. DE C.V. (en adelante,
          "TICKETTOGO"), con domicilio Venustiano Carranza # 2931, Colonia La
          Salle, C.P. 25240 en Saltillo, Coahuila, México, en cumplimiento a lo
          establecido en la Ley Federal de Datos Personales en Posesión de los
          Particulares, tiene entre sus objetivos la protección de la
          información personal proporcionada por cada persona (la
          "Información"). Por lo anterior, TICKETTOGO ha establecido los
          siguientes lineamientos para proteger dicha información.
        </Typography>
        <Typography
          sx={{
            fontSize: '15px',
            fontWeight: '750',
            lineHeight: 5.55,
          }}
        >
          1. INFORMACIÓN PERSONAL SOLICITADA
        </Typography>
        <Typography
          sx={{ fontSize: '15px', fontWeight: '300', textAlign: 'justify' }}
        >
          TICKETTOGO solicitará información de carácter personal a EL USUARIO de
          los servicios, titulares de información personal, con el objeto de
          poder brindar la mejor experiencia personalizada en el uso de los
          servicios que TICKETTOGO proporciona, para a la medida de sus
          posibilidades hacerte llegar a discreción de TICKETTOGO mensajes que
          puedan ser de tu interés, ya sean propios o bien de nuestros
          anunciantes y publicidad de terceros con quién TICKETTOGO tenga
          celebrados convenios. El tipo de información que se solicita a los
          usuarios de los servicios incluye: nombre, dirección de correo
          electrónico (e-mail), domicilio, código postal, teléfonos de contacto,
          fecha de nacimiento, intereses personales, e información de tarjetas
          de crédito; no toda la Información que se solicite al momento de
          registrarse a los servicios de TICKETTOGO es obligatoria de
          proporcionarse, salvo aquella que TICKETTOGO considere conveniente y
          que así se le haga saber al titular al momento del registro.
          <br />
          <br />
          TICKETTOGO solicitará dicha Información para poder realizar la venta
          de boletos al titular o cuando considere sea oportuno o necesario como
          por ejemplo, cuando el titular quiera participar en concursos o
          promociones patrocinadas por TICKETTOGO o por terceros, en las
          encuestas puestas en práctica para evaluar y/o proporcionar de manera
          más satisfactoria los servicios, cuando los usuarios quieran hacer una
          compra, etc. La información personal que TICKETTOGO recolecta podrá
          ser utilizada para promocionar eventos, análisis de datos y mejorar
          nuestros servicios, publicidad de terceros con quién TICKETTOGO tenga
          celebrados convenios y contenidos. Si desea ser excluido de una lista
          de distribución de correo, puede actualizar sus preferencias aquí. El
          titular de los datos al registrarse otorga su pleno consentimiento
          libre y voluntariamente de proporcionar los datos personales que le
          sean requeridos, en el entendido de que si el usuario decidiera no
          proporcionar información obligatoria, este no podrá acceder a los
          servicios que efectivamente requiere dicha información, sin embargo,
          podrá tener acceso a todos los demás servicios que no requieran dicha
          Información. TICKETTOGO en ningún caso y bajo ninguna circunstancia
          almacena información de datos personales sensibles como origen racial
          o étnico, estado de salud actual, información genética, creencias
          religiosas, filosóficas y morales, afiliación sindical, opiniones
          políticas, preferencia sexual, etc.
        </Typography>
        <Typography
          sx={{
            fontSize: '15px',
            fontWeight: '750',
            lineHeight: 5.55,
          }}
        >
          2. PERSONA RESPONSABLE DE RECABAR DATOS PERSONALES
        </Typography>
        <Typography
          sx={{
            fontSize: '15px',
            fontWeight: '300',
            textAlign: 'justify',
          }}
        >
          La persona moral responsable de recabar tus Datos Personales (en este
          acto, así como en actos futuros relativos a la operación de
          TICKETTOGO), es la persona moral denominada CUATRO CUARENTA
          ENTRETENIMIENTO, S.A. DE C.V. <br />
          <br />
          Para efectos de lo establecido en la fracción I del artículo 16 de la
          Ley, se señala como domicilio el ubicado en Venustiano Carranza #
          2931, Colonia La Salle, C.P. 25240 en Saltillo, Coahuila, México.
        </Typography>
        <Typography
          sx={{
            fontSize: '15px',
            fontWeight: '750',
            lineHeight: 5.55,
          }}
        >
          3. PRIVACIDAD Y PROTECCIÓN DE DATOS PERSONALES
        </Typography>
        <Typography
          sx={{
            fontSize: '15px',
            fontWeight: '300',
            textAlign: 'justify',
          }}
        >
          TICKETTOGO utiliza a su alcance la tecnología más avanzada y los
          procesos para la protección de la información proporcionada por los
          titulares de los datos personales. Esta tecnología cifra, codifica y
          previene la intercepción de la información suministrada por el
          Internet, incluyendo tarjetas de crédito y direcciones de correo
          electrónico. <br />
          <br />
          TICKETTOGO establece y mantiene medidas de seguridad administrativas,
          técnicas y físicas que permiten proteger los datos personales contra:
          daños, pérdida o alteración, destrucción, o el uso, acceso o
          tratamiento no autorizado. TICKETTOGO contiene hipervínculos o
          hipertextos "links", banners, botones y herramientas de búsqueda en la
          World Wide Web que al ser oprimidos o utilizados por los usuarios
          conllevan a otros portales o sitios de Internet propiedad de terceros.
          No obstante que en algunos casos estos sitios o portales de terceros
          se encuentran enmarcados con la barra de navegación de TICKETTOGO, la
          Información que el titular llegase a proporcionar a través de esos
          sitios o portales no se encuentra cubierta o contemplada por este
          aviso de privacidad y su manejo o utilización no es responsabilidad de
          TICKETTOGO, por lo que recomendamos a nuestros usuarios verificar los
          avisos y políticas de privacidad desplegadas o aplicables a estos
          sitios o portales de terceros. <br />
          <br />
          TICKETTOGO implementa medidas precautorias administrativas, técnicas y
          físicas para resguardar la información personal del titular de
          pérdidas, robos, o uso indebidos así como del acceso, la divulgación,
          modificación o destrucción no autorizados. <br />
          <br />
          Los servicios online emplean cifrado SSL (Secure Sockets Layer) en
          todas las páginas web en las que se solicita información personal a
          fin de proteger la confidencialidad de la información personal durante
          la transmisión a través de Internet. <br />
          <br />
          Al utilizar algunos productos, servicios o aplicaciones online de
          TICKETTOGO, propias o de terceros como son foros, blogs o redes
          sociales, la información personal que comparte el titular es visible
          para otros usuarios, que pueden leerla, recopilarla o utilizarla. El
          titular será responsable por la información personal que decida
          proporcionar en estos casos. <br />
          <span style={{ fontWeight: '700', lineHeight: 4.75 }}>
            Responsabilidad sobre el uso de la Información Financiera.
          </span>
          <br />
          TICKETTOGO podrá utilizar su información financiera para verificar que
          cumpla con los requisitos que contemplan las instituciones financieras
          del país, para realizar ventas y otras transacciones en EL PORTAL de
          manera eficiente, para entregar los bienes y servicios que ha
          solicitado, para inscribirlo en programas de descuento, reembolso y
          otros programas en los cuales usted elige participar y para llevar a
          cabo aseguramiento de calidad. En algunos casos podemos utilizar, y
          consecuentemente podemos proporcionarles a proveedores de servicios,
          su información financiera para ayudarnos a realizar algunas de estas
          tareas. En tales casos, nosotros requerimos a los proveedores de
          servicios que establezcan acuerdos con nosotros en cuanto al manejo
          confidencial de su información que deberá de ser igual cuando memos al
          tratamiento que le da TICKETTOGO a la información. También, podemos
          proporcionar su información financiera a emisores de tarjetas de
          crédito, instituciones financieras o procesadores de tarjetas de
          crédito para que procesen transacciones y para otros de sus
          propósitos. También podemos proporcionarle su información financiera a
          los organizadores de eventos, principalmente con el propósito de
          facilitar la entrega de los boletos, resolver disputas y servicio al
          cliente en el lugar del evento y en la fecha del evento, y podemos
          proporcionar su información financiera a aquellos comerciantes que le
          están proporcionando bienes, servicios o reservaciones que usted
          compró u ordenó, o bien que están administrando las ofertas de los
          programas a los que ingresó en EL PORTAL (o que ingresó por medio de
          otro sitio después de hacer clic en un enlace de este Sitio que
          promovía tal oferta o programa). <br />
          <br />
          Podríamos revelar información financiera específica cuando
          determinemos que tal revelación puede ser útil para cumplir con la
          ley, para cooperar con o buscar asistencia por parte de autoridades de
          seguridad pública o para proteger los intereses o seguridad de
          TICKETTOGO o de los otros visitantes al Sitio o usuarios de servicios
          o productos proporcionados por TICKETTOGO o para participar
          conjuntamente con otras entidades en actividades contra el fraude.
          También, su información financiera puede ser suministrada a terceros
          en el caso de una transferencia de propiedad o de bienes, o en un
          proceso de quiebra de TICKETTOGO. TICKETTOGO no es responsable o de
          cualquier otra divulgación de su información financiera por parte de
          un tercero con quien TICKETTOGO tenga permitido divulgar su
          información financiera bajo este aviso de privacidad. <br />
          <br />
          TICKETTOGO no es responsable o de cualquier otra manera responsable
          legalmente de cualquier uso o divulgación de su información financiera
          por parte de un tercero con quien TICKETTOGO tenga permitido divulgar
          su información financiera bajo este aviso de privacidad. <br />
          <br />
        </Typography>
        <Typography
          sx={{
            fontSize: '15px',
            fontWeight: '750',
            lineHeight: 5.55,
          }}
        >
          4. USO DE "COOKIES"
        </Typography>
        <Typography
          sx={{
            fontSize: '15px',
            fontWeight: '300',
            textAlign: 'justify',
          }}
        >
          TICKETTOGO hace uso de herramientas que permiten obtener información
          de forma pasiva de EL USUARIO como cookies y como una herramienta de
          uso común para registrar el comportamiento de navegación en línea
          (tracking). <br />
          <br />
          Las cookies son archivos de texto que son descargados automáticamente
          y almacenados en el navegador y/o disco duro del equipo de cómputo de
          EL USUARIO al navegar en un sitio de internet y que permiten al sitio
          recordar algunos datos del usuario. TICKETTOGO utiliza cookies para
          recordar tu usuario y permitir recordar al navegador los datos que
          registraste y la ciudad o zona geográfica que seleccionas al consultar
          los eventos, tus datos de pago, historial de compras, preferencias,
          así como los datos para poder llevar a cabo una compra en línea en
          www.tickettogo.com.mx. También para que la siguiente ocasión en que
          ingreses a EL PORTAL y tengas una nueva sesión a través del mismo
          navegador, ya esté precargado con tu nombre. <br />
          <br />
          TICKETTOGO o sus anunciantes dentro de EL PORTAL y aplicaciones
          móviles de TICKETTOGO pueden utilizar cookies o tecnologías similares
          como herramientas de seguimiento de comportamiento. Se utilizan estas
          herramientas:
          <br />
        </Typography>
        <Typography
          sx={{
            fontSize: '15px',
            fontWeight: '300',
            textAlign: 'justify',
            pl: 2.5,
          }}
        >
          • Para reconocer a clientes nuevos o pasados. <br /> • Para almacenar
          tu contraseña con la que estas registrado en nuestros sitios. <br />
          • Para mejora de la experiencia de uso en nuestros sitios. <br /> •
          Para mostrar contenido publicitario que pudiera ser de tu interés.{' '}
          <br /> Para lograrlo, podemos observar tu comportamiento de navegación
          en nuestro sitio y otros. También podemos recabar información sobre tu
          historial de navegación. <br /> • Para entender de mejor manera los
          intereses de los consumidores y los visitantes de los sitios en línea
          de TICKETTOGO. <br /> • Para medir el flujo de tráfico al sitio.
        </Typography>
        <br />
        <br />
        <Typography
          sx={{
            fontSize: '15px',
            fontWeight: '300',
            textAlign: 'justify',
          }}
        >
          Cierta publicidad en EL PORTAL puede ser basada en comportamiento de
          navegación, en sitios de TICKETTOGO o externos. <br />
          <span style={{ fontWeight: '700', lineHeight: 5.55 }}>
            Forma de controlar el uso de cookies.
          </span>
          <br />
          Tu navegador puede darte la opción de controlar el uso de cookies.
          Como hacerlo depende del tipo de cookies. Algunos navegadores pueden
          ser configurados para rechazar el uso de cookies.
          <br />
          <br />
          Si decides bloquear las cookies en tu navegador, características y
          funcionalidades del sitio en línea pueden no funcionar, como la compra
          de boletos.
        </Typography>

        <Typography
          sx={{
            fontSize: '15px',
            fontWeight: '750',
            lineHeight: 5.55,
          }}
        >
          5. REDES SOCIALES
        </Typography>
        <Typography
          sx={{
            fontSize: '15px',
            fontWeight: '300',
            textAlign: 'justify',
          }}
        >
          Las redes sociales (tales como Facebook®, Twitter®, YouTube®,
          Linkedin® y/o las demás redes sociales que de tiempo en tiempo estén
          disponibles en el mercado) constituyen una plataforma de comunicación
          y de interconexión entre plataformas digitales de los distintos
          usuarios, son ajenas a TICKETTOGO y, por lo tanto, no se encuentran
          bajo su responsabilidad.
          <br />
          <br />
          La información que proporciones dentro de redes sociales en las que
          TICKETTOGO participa como usuario, no constituye ni forma parte de los
          Datos Personales sujetos a la protección de este Aviso de Privacidad,
          siendo responsabilidad de la empresa prestadora de esa plataforma y de
          quien lo publica.
          <br />
          <br />
          En caso de que TICKETTOGO llegare a requerirte algún dato personal de
          los referidos por medio y/o a través de alguna red social, tales datos
          serán tratados conforme a las Finalidades del Tratamiento descritas a
          continuación, ello según correspondan al tipo de datos de que se
          trate. En este supuesto, los datos personales recaerán bajo la
          responsabilidad de TICKETTOGO. Sin embargo, por ser recolectados a
          través de las redes sociales, éstas últimas también podrán tener
          acceso o propiedad sobre tal información, ello dependiendo de las
          políticas de uso, confidencialidad y tratamiento de la información de
          cada una de las redes sociales.
        </Typography>
        <Typography
          sx={{
            fontSize: '15px',
            fontWeight: '750',
            textTransform: 'uppercase',
            lineHeight: 5.55,
          }}
        >
          6. Limitación del uso o divulgación de tus Datos Personales
        </Typography>
        <Typography
          sx={{
            fontSize: '15px',
            fontWeight: '300',
            textAlign: 'justify',
            pl: 1.5,
          }}
        >
          6.1. Acciones para prevenir el uso o divulgación no autorizada Con el
          fin de garantizar la protección de tus Datos Personales y limitar el
          uso o divulgación no autorizada de los mismos, TICKETTOGO
          continuamente realiza y realizará las siguientes acciones:
        </Typography>
        <br />
        <br />
        <Typography
          sx={{
            fontSize: '15px',
            fontWeight: '300',
            textAlign: 'justify',
            pl: 3,
          }}
        >
          a) Confidencialidad de la información. TICKETTOGO guardará
          confidencialidad respecto de tus Datos Personales recabados, misma que
          subsistirá aun después de finalizar sus relaciones comerciales o de
          otra naturaleza con el cliente o titular de dichos Datos Personales.
          <br />
          <br />
          b) Notificación de confidencialidad. En caso que, por algún motivo,
          TICKETTOGO se vea en la necesidad de proporcionar tus Datos Personales
          a terceros (en los términos previstos en la Ley o en el presente Aviso
          de Privacidad), notificará a dichos terceros la obligación de cumplir
          con las disposiciones de la Ley y la confidencialidad de tus Datos
          Personales.
          <br />
          <br />
          c) Administración de bases de datos. Los Datos Personales son
          administrados y resguardados mediante el uso de bases de datos (“Base
          de Datos”), las cuales son administradas únicamente por las personas
          designadas por TICKETTOGO para tal efecto, sin que se permita su uso,
          consulta, manejo o acceso a personas no autorizadas.
          <br />
          <br />
          d) Sistemas de cómputo e informáticos. Nuestras Bases de Datos están
          protegidas por firewalls y sistemas de cómputo y/o informáticos
          enfocados a prevenir y evitar el que personas ajenas a TICKETTOGO o no
          autorizadas puedan acceder a tus Datos Personales.
        </Typography>
        <br />
        <br />
        <Typography
          sx={{
            fontSize: '15px',
            fontWeight: '300',
            textAlign: 'justify',
            pl: 1.5,
          }}
        >
          6.2. Solicitudes para limitar el uso o divulgación En caso que desees
          limitar el uso o divulgación de tus Datos Personales, en relación con
          una o varias de las Finalidades del Tratamiento de Datos Personales,
          podrás enviar la solicitud respectiva conforme al procedimiento
          establecido en el apartado 8 del presente Aviso de Privacidad, o
          mediante el acceso a los vínculos que se cargan o llegaren a cargar en
          las páginas de internet de TICKETTOGO o en la información promocional
          que te hacemos llegar.
        </Typography>
        <Typography
          sx={{
            fontSize: '15px',
            fontWeight: '750',
            textTransform: 'uppercase',
            lineHeight: 5.55,
          }}
        >
          7. CAMBIOS AL AVISO DE PRIVACIDAD.
        </Typography>
        <Typography
          sx={{
            fontSize: '15px',
            fontWeight: '300',
            textAlign: 'justify',
          }}
        >
          TICKETTOGO se reserva el derecho de efectuar, en cualquier momento,
          modificaciones o actualizaciones al presente Aviso de Privacidad, para
          la atención de novedades legislativas o jurisprudenciales, políticas
          internas, nuevos requerimientos para la prestación u ofrecimiento de
          sus productos y servicios, o por prácticas comerciales.
          <br />
          <br />
          Por lo anterior, en caso que TICKETTOGO llegare a modificar el
          contenido del presente Aviso de Privacidad, lo hará de tu conocimiento
          mediante publicación en EL PORTAL www.tickettogo.com.mx
          <br />
          <br />
          El presente Aviso de Privacidad fue actualizado por última ocasión el
          10 de septiembre del 2016.
        </Typography>
        <Typography
          sx={{
            fontSize: '15px',
            fontWeight: '750',
            textTransform: 'uppercase',
            lineHeight: 5.55,
          }}
        >
          8. DERECHOS DE ACCESO, RECTIFICACIÓN, CANCELACIÓN U OPOSOCIÓN (ARCO).
        </Typography>
        <Typography
          sx={{
            fontSize: '15px',
            fontWeight: '300',
            textAlign: 'justify',
          }}
        >
          A fin de brindar al titular el medio para ejercer los derechos ARCO,
          TICKETTOGO pone a disposición de los titulares la dirección de correo
          electrónico arco@tickettogo.com.mx a través de la cual se dará trámite
          y seguimiento a las solicitudes de acceso, rectificación, cancelación
          u oposición.
          <br />
          <br />
          El titular podrá enviar a través de este medio una solicitud para el
          acceso, rectificación, cancelación u oposición, respecto de sus datos
          personales. <br />
          <br />
          Dicha solicitud deberá contener:
        </Typography>
        <br />
        <Typography
          sx={{
            fontSize: '15px',
            fontWeight: '300',
            textAlign: 'justify',
            pl: 2.5,
          }}
        >
          • El nombre completo del titular y todos los datos que le fueron
          solicitados para registrarse así como un correo electrónico para
          comunicarle la respuesta a su solicitud. <br /> • Copia electrónica de
          los documentos que acrediten la identidad del titular de los datos
          personales (RFC, INE). <br /> • Descripción clara y precisa de los
          datos personales respecto de los que se busca ejercer alguno de los
          derechos antes mencionados. <br /> • Cualquier otro elemento o
          documento que facilite la localización de los datos personales. <br />{' '}
          • Las modificaciones a realizarse y/o las limitaciones al uso de los
          datos personales y aportar la documentación que sustente su petición.{' '}
        </Typography>
        <br />
        <br />
        <Typography
          sx={{
            fontSize: '15px',
            fontWeight: '300',
            textAlign: 'justify',
          }}
        >
          TICKETTOGO comunicará al titular de los datos personales la
          determinación adoptada, en un plazo no mayor a 20 días hábiles
          contados desde la fecha en que se recibió la solicitud. Este plazo
          podrá ser ampliado por TICKETTOGO en una sola ocasión por un periodo
          igual, siempre y cuando así lo justifiquen las circunstancias del
          caso.
          <br />
          <br />
          TICKETTOGO informará al titular de los datos personales el sentido y
          motivación de la resolución por correo electrónico y acompañará dicha
          resolución de las pruebas pertinentes, en su caso.
          <br />
          <br />
          TICKETTOGO podrá negar el acceso total o parcial a los datos
          personales o a la realización de la rectificación, cancelación u
          oposición al tratamiento de los mismos, en los siguientes supuestos:
        </Typography>
        <br />
        <Typography
          sx={{
            fontSize: '15px',
            fontWeight: '300',
            textAlign: 'justify',
            pl: 2.5,
          }}
        >
          • Cuando el solicitante no sea el titular o el representante legal no
          esté acreditado para ello. <br /> • Cuando en la base de datos de
          TICKETTOGO no se encuentren los datos personales del solicitante.{' '}
          <br /> • Cuando se lesionen los derechos de un tercero. <br /> •
          Cuando exista impedimento legal o resolución de una autoridad. <br />{' '}
          • Cuando la rectificación, cancelación u oposición haya sido
          previamete realizada, de manera que la solicitud carezca de materia.
        </Typography>
        <br />
        <br />
        <Typography
          sx={{
            fontSize: '15px',
            fontWeight: '300',
            textAlign: 'justify',
          }}
        >
          Hecho lo anterior, TICKETTOGO podrá conservar los datos personales
          exclusivamente para efectos de las responsabilidades derivadas del
          tratamiento a que se refiere el Aviso de Privacidad.
          <br />
          <br />
          TICKETTOGO no estará obligado a cancelar los datos personales cuando
          se trate de los supuestos establecidos en el artículo vigésimo sexto
          de la Ley Federal para la Protección de Datos en Posesión de los
          Particulares.
          <br />
          <br />
          Asimismo, cuando la información recabada en los datos personales deje
          de ser necesaria para el cumplimiento de las funcionalidades previstas
          en este Aviso de Privacidad y en las disposiciones jurídicas
          aplicables, tus datos personales serán cancelados de la base de datos
          de TICKETTOGO.
        </Typography>

        <Typography
          sx={{
            fontSize: '15px',
            fontWeight: '750',
            textTransform: 'uppercase',
            lineHeight: 5.55,
          }}
        >
          9. TRANSFERENCIA DE DATOS PERSONALES
        </Typography>
        <Typography
          sx={{
            fontSize: '15px',
            fontWeight: '300',
            textAlign: 'justify',
          }}
        >
          En caso de que TICKETTOGO llegare a requerir realizar alguna
          transferencia a alguno de sus proveedores, socios comerciales o
          terceros involucrados en alguna de sus operaciones en la
          comercialización de los productos o servicios de TICKETTOGO y/o para
          poder llevar a cabo las Finalidades del Tratamiento establecidas en el
          presente Aviso de Privacidad, lo hará previó consentimiento de tú
          parte, para lo cual, previo a la recopilación de tus datos personales
          y/o previo a la transferencia de los mismos, te dará a conocer la
          posible transferencia y en donde recopilará tu consentimiento para
          ello. <br />
          <br />A su vez, en caso de que lo anterior llegue a ocurrir, la
          transferencia se realizará previa celebración de convenios de
          confidencialidad y, siempre y cuando (i) el proveedor o persona a
          quien se le transmitan acepte someter el tratamiento de los Datos
          Personales al presente Aviso de Privacidad, y (ii) no se trate de
          alguno de los supuestos establecidos en el artículo 37 de la Ley.
        </Typography>
        <br />
        <Typography
          sx={{ fontSize: '15px', fontWeight: '750', lineHeight: 5.5 }}
        >
          SI TIENE ALGUNA DUDA O PREGUNTA RESPECTO AL AVISO DE PRIVACIDAD, POR
          FAVOR CONTACTENOS EN LA SIGUIENTE DIRECCIÓN:
        </Typography>
        <Typography sx={{ fontSize: '15px', fontWeight: '300', pl: 2.5 }}>
          • avisodeprivacidad@tickettogo.com.mx
        </Typography>
      </Box>
    </Box>
  );
};
