// Define the handleChange to use the GET req from the backend
import { Fragment, useEffect, useState } from 'react';
import {
  Box,
  IconButton,
  TextField,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  InputAdornment,
  AppBar,
  Toolbar,
  Divider,
  Button,
} from '@mui/material';
import { VisibilityOff, Visibility } from '@mui/icons-material';
import { AddUserDto, addUserSchema } from 'libs/domain/src/lib/dto';
import { useForm, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import AutocompleteInput from '../Autocomplete';
import TTButton from '../shared/TTButton';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

const initialFormValues: AddUserDto = {
  firstName: '',
  lastName: '',
  email: '',
  role: '',
  password: '',
  confirmPassword: '',
};

const ROLE_OPTIONS = [
  { label: 'Administrador', id: 'ADMIN' },
  { label: 'Escaner de boletos', id: 'SCANNER' },
  { label: 'Punto de venta', id: 'SALES' },
];

interface AddUsersModalProps {
  isOpen: boolean;
  onRegisterUser: (data: AddUserDto) => void;
  onClose: () => void;
}

const styleBox = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export const AddUsersModal = ({
  isOpen,
  onRegisterUser,
  onClose,
}: AddUsersModalProps) => {
  const [open, setOpen] = useState(isOpen);
  const [showPassword, setShowPassword] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors, isSubmitting },
  } = useForm<AddUserDto>({
    resolver: zodResolver(addUserSchema),
  });

  const handleClose = () => {
    onClose();
    reset(initialFormValues);
    setOpen(false);
  };

  const onSubmit = async (data: AddUserDto) => {
    onRegisterUser(data);
    handleClose();
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  return (
    <Fragment>
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <Dialog
          open={open}
          onClose={handleClose}
          fullWidth
          PaperProps={{
            sx: {
              borderRadius: '10px',
              overflow: 'hidden',
            },
          }}
        >
          <AppBar
            sx={{
              position: 'relative',
              backgroundColor: '#890050',
            }}
          >
            <Toolbar sx={{ justifyContent: 'flex-end' }}>
              <IconButton edge="end" onClick={handleClose} aria-label="close">
                <HighlightOffIcon sx={{ color: '#FFF' }} />
              </IconButton>
            </Toolbar>
          </AppBar>
          <DialogTitle
            sx={{
              color: '#444E6A',
              fontWeight: 700,
              fontSize: '2rem',
              fontStyle: 'bold',
            }}
          >
            <b>Nuevo usuario</b>
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              sx={{
                mt: 2,
                mb: 1,
                fontWeight: 600,
                color: '#444E6A',
                fontSize: '1rem',
              }}
            >
              <b>Ingrese los datos del nuevo usuario</b>
              <Divider />
            </DialogContentText>
            <Stack sx={{ width: '100%' }} spacing={2}>
              <TextField
                {...register('firstName')}
                defaultValue={''}
                fullWidth
                id="outlined-basic"
                label="Nombre(s)"
                variant="outlined"
                error={!!errors.firstName}
                helperText={errors.firstName?.message}
                size="small"
                className="customTextField"
              />
              <TextField
                {...register('lastName')}
                defaultValue={''}
                fullWidth
                id="outlined-basic"
                label="Apellidos"
                variant="outlined"
                error={!!errors.lastName}
                helperText={errors.lastName?.message}
                size="small"
                className="customTextField"
              />
              <TextField
                {...register('email')}
                defaultValue={''}
                error={!!errors.email}
                helperText={errors.email?.message}
                fullWidth
                id="outlined-basic"
                label="Correo"
                variant="outlined"
                name="email"
                margin="normal"
                size="small"
                className="customTextField"
              />

              <Controller
                name="role"
                control={control}
                render={({ field: roleController, fieldState: { error } }) => (
                  <AutocompleteInput
                    options={ROLE_OPTIONS}
                    onSelectOption={(option) => {
                      roleController.onChange(option ? (option as string) : '');
                    }}
                    selectedOption={roleController.value}
                    error={error ? error.message : null}
                    placeholder="Rol"
                  />
                )}
              />
              <TextField
                {...register('password')}
                fullWidth
                id="password-input"
                label="Contraseña"
                type={showPassword ? 'text' : 'password'}
                autoComplete="current-password"
                margin="normal"
                size="small"
                className="customTextField"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                error={!!errors.password}
                helperText={errors.password?.message}
              />
              <TextField
                {...register('confirmPassword')}
                fullWidth
                id="confirm-password-input"
                label="Confirmar Contraseña"
                type={showPassword ? 'text' : 'password'}
                autoComplete="current-password"
                margin="normal"
                size="small"
                className="customTextField"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                error={!!errors.confirmPassword}
                helperText={errors.confirmPassword?.message}
              />
            </Stack>
          </DialogContent>
          <DialogActions>
            <TTButton variant="contained" onClick={handleClose} color="inherit">
              Cancelar
            </TTButton>
            <Button
              type="submit"
              disabled={isSubmitting}
              onClick={handleSubmit(onSubmit)}
              sx={{
                textTransform: 'none',
                color: '#fff',
                backgroundColor: '#C33186',
                fontSize: '0.9rem',
                fontFamily: 'Popins, sans-serif',
                fontWeight: 600,
                letterSpacing: '0.7px',
                borderRadius: '10px',
                padding: '5px 15px',
                boxShadow: 'none',
                '&:hover': {
                  backgroundColor: '#890050',
                  boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.2)',
                },
              }}
            >
              Registrar
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Fragment>
  );
};
