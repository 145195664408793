import { Box, Typography } from '@mui/material';
import React from 'react';
import { Table } from './createSectionModal';
import { Tooltip } from '@mui/material';
import { toCurrency } from '../../utils/currency';

interface TableBoxProps {
  tableName: string;
  tableData: Table;
  index: number;
  color?: string;
}

export const TableBox: React.FC<TableBoxProps> = ({
  tableName,
  tableData,
  index,
  color = '#76f015',
}) => {
  return (
    <Tooltip title={<TooltipTitle tableData={tableData} />} placement="top">
      <Box
        key={index}
        sx={{
          minWidth: '35px',
          minHeight: '35px',
          m: 1,
          backgroundColor: color,
          color: 'white',
          fontSize: 14,
          fontWeight: 'bold',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          p: 1,
        }}
      >
        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
          {tableName}
        </Typography>
      </Box>
    </Tooltip>
  );
};

interface TooltipTitleProps {
  tableData: Table;
}

const TooltipTitle: React.FC<TooltipTitleProps> = ({ tableData }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        p: 1,
        borderRadius: 1,
      }}
    >
      <Typography
        variant="h5"
        sx={{
          fontWeight: 'bold',
        }}
      >
        Precio:{' '}
        {isNaN(
          Number(
            tableData.price.replace(/,/g, '').replace('$', '').replace(',', ''),
          ),
        )
          ? '0.00'
          : toCurrency(
              Number(
                tableData.price
                  .replace(/,/g, '')
                  .replace('$', '')
                  .replace(',', ''),
              ),
            )}
      </Typography>
      <Typography
        variant="h6"
        sx={{
          fontWeight: 'bold',
        }}
      >
        Cupo de la mesa: {tableData.seats}
      </Typography>
    </Box>
  );
};
