import { forwardRef, useEffect, useState } from 'react';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps, AlertColor } from '@mui/material/Alert';

export interface BaseAlertMessageProps {
  severity: AlertColor;
  message: string;
  isOpen: boolean;
  autoHideDuration?: number;
}

interface AlertMessageProps extends BaseAlertMessageProps {
  onClose: () => void;
}

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function AlertMessage({
  severity,
  message,
  isOpen = false,
  onClose,
  autoHideDuration = 2500,
}: AlertMessageProps) {
  const [open, setOpen] = useState(isOpen);

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    onClose();
    setOpen(false);
  };

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  return (
    <Stack spacing={2}>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={open}
        autoHideDuration={autoHideDuration}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={severity}
          sx={{ width: '100%', fontWeight: 600, color: 'white' }}
        >
          {message}
        </Alert>
      </Snackbar>
    </Stack>
  );
}
