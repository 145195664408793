import { Optional } from '../../types/common';
import { CartItemEntity } from '../CartItem';
import { EventDateEntity } from '../EventDate';
import { SectionEntity } from '../Section';

export type EventStatus = 'DRAFT' | 'PUBLISHED' | 'FINISHED' | 'CANCELED';
export type EventCategory = 'MUSICA' | 'DEPORTES' | 'ENTRETENIMIENTO';
export type EventImageType = 'bannerImage' | 'ticketImage' | 'cardImage';

export const EVENT_STATUS: EventStatus[] = [
  'DRAFT',
  'PUBLISHED',
  'FINISHED',
  'CANCELED',
];
export const EVENT_CATEGORY: EventCategory[] = [
  'MUSICA',
  'DEPORTES',
  'ENTRETENIMIENTO',
];

export interface EventEntity {
  id: string;
  name: string;
  shortDescription: string;
  longDescription: string;
  bannerImage: string | null;
  ticketImage: string | null;
  cardImage: string | null;
  status: EventStatus;
  lineAddress: string;
  category: EventCategory;
  createdAt: Date;
  updatedAt: Date | null;
  highlighted: boolean;
  state: string;
  city: string;
  venueName: string;

  // Relations
  sections?: SectionEntity[];
  eventDates?: EventDateEntity[];
  cart_items?: CartItemEntity[];
}

export type EventCreateInput = Optional<
  Omit<EventEntity, 'id'>,
  'createdAt' | 'updatedAt'
>;
