import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import EventImagesMenu from '../EventImagesMenu';
import { Button, Card, CardContent, CardMedia } from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';

interface ImageData {
  image: string | null;
  title: string | null;
  subtitle?: string;
  onChangeImage: () => void;
  onRemoveImage: () => void;
  error?: string | null;
}

interface UploadSectionImageProps {
  sectionImage: ImageData;
}

export default function UploadSectionImage({
  sectionImage,
}: UploadSectionImageProps) {
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'space-between',
        flexDirection: 'column',
      }}
    >
      {sectionImage.image ? (
        <Card
          sx={{
            display: 'flex',
            borderRadius: '5px',
            background: '#D9D9D9',
            flexDirection: 'row',
            width: '100%',
            maxHeight: 'auto',
            alignSelf: 'center',
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <CardContent sx={{ flex: '1 0 auto' }}>
              <Typography
                component="div"
                sx={{
                  fontWeight: 600,
                  fontSize: '1.25rem',
                  color: '#444E6A',
                }}
              >
                {sectionImage.title}
              </Typography>
              <Typography
                component="div"
                sx={{
                  color: '#000',
                  fontSize: '0.9rem',
                }}
              >
                {sectionImage.subtitle}
              </Typography>
            </CardContent>
            <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}>
              <EventImagesMenu
                onEdit={sectionImage.onChangeImage}
                onDelete={sectionImage.onRemoveImage}
              />
              {sectionImage.error && (
                <Typography variant="caption" color="red">
                  {sectionImage.error}
                </Typography>
              )}
            </Box>
          </Box>
          <CardMedia
            component="img"
            sx={{
              objectFill: 'cover',
              maxWidth: '190px',
              alignSelf: 'self-end',
            }}
            image={sectionImage.image}
            alt="Imagen del banner"
          />
        </Card>
      ) : (
        <Box
          sx={{
            display: 'flex',
            width: '25%',
            flexDirection: 'column',
          }}
        >
          <Button
            variant="outlined"
            sx={{
              backgroundColor: '#FFFFFF',
              color: '#444E6A',
              width: '100%',
              maxHeight: '45px',
              border: '2px solid #444E6A',
              borderRadius: '10px',
              fontWeight: 700,
              textAlign: 'center',
              '&:hover': {
                backgroundColor: '#444E6A',
                color: '#FFFFFF',
                border: '2px solid #444E6A',
              },
            }}
            endIcon={<AttachFileIcon />}
            onClick={sectionImage.onChangeImage}
          >
            Subir
          </Button>
          {sectionImage.error && (
            <Typography variant="caption" color="red">
              {sectionImage.error}
            </Typography>
          )}
        </Box>
      )}
    </Box>
  );
}
