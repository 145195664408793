import React from 'react';
import { Dialog, Typography, Button, Box } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

export interface DialogAlertProps {
  severity: 'success' | 'error' | 'warning' | 'info';
  message: string;
  isOpen: boolean;
  autoHideDuration?: number;
  onClose: () => void;
}

const DialogAlert: React.FC<DialogAlertProps> = ({
  severity,
  message,
  isOpen = false,
  autoHideDuration,
  onClose,
}) => {
  const getDialogStyles = () => {
    switch (severity) {
      case 'success':
        return {
          bgColor: '#4CAF50',
          Icon: (
            <CheckCircleOutlineIcon sx={{ fontSize: '100px', color: '#fff' }} />
          ),
        };
      case 'error':
        return {
          bgColor: '#F44336',
          Icon: <HighlightOffIcon sx={{ fontSize: '100px', color: '#fff' }} />,
        };
      case 'warning':
        return {
          bgColor: '#FF9800',
          Icon: <WarningAmberIcon sx={{ fontSize: '100px', color: '#fff' }} />,
        };
      case 'info':
        return {
          bgColor: '#2196F3',
          Icon: (
            <HourglassEmptyIcon sx={{ fontSize: '100px', color: '#fff' }} />
          ),
        };
      default:
        return {
          bgColor: '#000',
          Icon: null,
        };
    }
  };

  const { bgColor, Icon } = getDialogStyles();

  // Auto-hide logic
  React.useEffect(() => {
    if (isOpen && autoHideDuration) {
      const timer = setTimeout(() => {
        onClose();
      }, autoHideDuration);
      return () => clearTimeout(timer);
    }
  }, [isOpen, autoHideDuration, onClose]);

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      fullScreen
      sx={{
        '& .MuiDialog-paper': {
          margin: 0,
        },
      }}
    >
      <Box
        sx={{
          backgroundColor: bgColor,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
        }}
      >
        {Icon}
        <Typography
          variant="h4"
          sx={{
            color: '#fff',
            fontWeight: 700,
            mt: 2,
            fontSize: { xs: '24px', md: '32px', lg: '40px' },
          }}
        >
          {severity === 'success' && 'Aprobado'}
          {severity === 'error' && 'Rechazado'}
          {severity === 'warning' && 'Advertencia'}
          {severity === 'info' && 'Información'}
        </Typography>
        <Typography
          variant="body1"
          sx={{
            color: '#fff',
            mt: 1,
            px: 2,
            textAlign: 'center',
            fontSize: { xs: '16px', md: '18px', lg: '20px' },
          }}
        >
          {message}
        </Typography>
        <Button
          onClick={onClose}
          variant="contained"
          sx={{
            mt: 4,
            backgroundColor: '#fff',
            color: bgColor,
            fontWeight: 700,
            ':hover': { backgroundColor: '#e0e0e0' },
          }}
        >
          Cerrar
        </Button>
      </Box>
    </Dialog>
  );
};

export default DialogAlert;
