import { z }from 'zod'

export const loginSchema = z.object({
  email: z.string({
    required_error: 'Por favor, ingrese una dirección de correo electrónico.',
  }).email('Por favor, ingrese un email válido.'),
  password: z.string({
    required_error: 'Por favor, ingrese una contraseña.',
  }).min(8, 'La contraseña debe tener al menos 8 caracteres.'),
});

export type LoginDto = z.infer<typeof loginSchema>;