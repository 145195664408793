import {
  Box,
  Stepper,
  Step,
  StepLabel,
  Typography,
  styled,
} from '@mui/material';
import { StepIconProps } from '@mui/material/StepIcon';
import Check from '@mui/icons-material/Check';

const steps = [
  {
    label: 'Datos generales del evento',
    sublabel: 'Nombre, descripción y categoría',
  },
  {
    label: 'Datos específicos del evento',
    sublabel: 'Fechas, lugar y dirección',
  },
  {
    label: 'Imágenes del evento',
    sublabel: 'Imagen principal y banner',
  },
];

interface CustomStepIconProps extends StepIconProps {}

const CustomStepIcon: React.FC<CustomStepIconProps> = (props) => {
  const { active, completed, icon } = props;
  const style = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 24,
    height: 24,
    borderRadius: '50%',
    backgroundColor: active || completed ? '#444E6A' : '#e0e0e0',
    color: '#fff',
  };

  return (
    <div style={style}>
      {completed ? <Check style={{ color: '#fff', fontSize: '18px' }} /> : icon}
    </div>
  );
};

interface CreateEventStepperProps {
  currentStep: number;
}

export default function CreateEventStepper({
  currentStep,
}: CreateEventStepperProps) {
  return (
    <Box sx={{ width: '100%' }}>
      <Typography sx={{ mt: 2, mb: 1, fontWeight: 600, fontSize: '1.2rem' }}>
        Paso {currentStep + 1} de {steps.length}
      </Typography>
      <Stepper activeStep={currentStep} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel
              sx={{
                fontWeight: 550,
              }}
              StepIconComponent={(props) => <CustomStepIcon {...props} />}
              optional={
                currentStep === index ? (
                  <Typography variant="caption">{step.sublabel}</Typography>
                ) : null
              }
            >
              {step.label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}
