import { Box, Card, Paper, Typography } from '@mui/material';
import SeatSelectorDefault from '../images/SeatSeletorDefault.png';
import { SectionEntity } from 'libs/domain/src/lib/entities/Section';
import { useCart } from '../hooks/useCart';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import InnerImageZoom from 'react-inner-image-zoom';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.min.css';

interface ChangeImageProps {
  section: SectionEntity | null | undefined;
}

export default function ChangeImage({ section }: ChangeImageProps) {
  const {
    state: { cart, minutes, seconds },
  } = useCart();

  return (
    <Card
      sx={{
        width: '45%',
        borderRadius: '10px',
        boxShadow:
          '5px 2px 5px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)',
        padding: 1,
        backgroundColor: (theme) => theme.palette.common.white,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      {cart ? (
        <>
          <Paper
            elevation={2}
            sx={{
              width: '85%',
              p: 1,
              borderRadius: '10px',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              gap: 1,
              backgroundColor: '#890050',
              color: 'white',
            }}
          >
            <WarningAmberRoundedIcon />
            <Typography
              sx={{
                fontWeight: 600,
                fontFamily: 'Poppins',
                fontSize: '0.95rem',
              }}
            >
              Tiempo restante de tu selección:&nbsp;
              <Box component="span" sx={{ fontWeight: 700 }}>
                {minutes}:{seconds.toString().padStart(2, '0')}
              </Box>
              &nbsp;{minutes === 0 ? 's' : 'min'}
            </Typography>
          </Paper>
        </>
      ) : null}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignSelf: 'center',
          width: '85%',
          height: '95%',
          border: 'none',
          objectFit: 'fill',
          borderRadius: '12px',
          overflow: 'hidden',
        }}
      >
        <InnerImageZoom
          src={section ? section.layout : SeatSelectorDefault}
          zoomSrc={section ? section.layout : SeatSelectorDefault}
          hasSpacer={true}
        />
      </Box>
    </Card>
  );
}
