import { z } from 'zod';

const dateFormatRegex = /^\d{4}-\d{2}-\d{2}$/;
const phoneNumberRegex = /^\+?\d{10}$/;

export const updateUserInfoSchema = z.object({
  email: z
    .string({
      required_error: 'Por favor, ingrese su correo electrónico.',
    })
    .email('Por favor, ingrese un email válido.')
    .optional(),
    firstName: z.preprocess(
      (val) => (val === '' ? undefined : val),
      z
        .string({
          required_error: 'Por favor, ingrese sus nombres.',
        })
        .regex(
          /^[a-zA-ZáéíóúÁÉÍÓÚüÜñÑ]+(?: [a-zA-ZáéíóúÁÉÍÓÚüÜñÑ]+)*$/,
          'Los nombres deben contener solo letras.',
        ),
    ).optional(),
    lastName: z.preprocess(
      (val) => (val === '' ? undefined : val),
      z
        .string({
          required_error: 'Por favor, ingrese sus apellidos.',
        })
        .regex(
          /^[a-zA-ZáéíóúÁÉÍÓÚüÜñÑ]+(?: [a-zA-ZáéíóúÁÉÍÓÚüÜñÑ]+)*$/          ,
          'Los apellidos deben contener solo letras.',
        ),
    ).optional(),
  birthDate: z
    .preprocess(
      (val) => (val === null ? undefined : val),
      z
        .string({
          required_error: 'Por favor, ingrese una fecha de nacimiento.',
        })
        .refine((val) => dateFormatRegex.test(val), {
          message:
            'La fecha de nacimiento no es válida, debe tener el formato adecuado (YYYY-MM-DD).',
        })
        .transform((val) => {
          return new Date(val);
        }),
    )
    .optional(),
  phoneNumber: z
    .preprocess(
      (val) => (val === null ? undefined : val),
      z
        .string({
          required_error: 'Por favor, ingrese un número de teléfono.',
        })
        .refine((val) => phoneNumberRegex.test(val), {
          message: 'El número de teléfono no es válido.',
        }),
    )
    .optional(),
});

export type UpdateInfoDto = z.infer<typeof updateUserInfoSchema>;
