import { useState } from 'react';
import {
  PostCheckout,
  ClearCart,
  DeleteCartItem,
} from 'apps/client/src/requests/carts';

interface UseCartActionsReturn {
  createOrder: (user: any, openLoginModal: () => void) => Promise<void>;
  clearCartAction: (cartId: string) => Promise<void>;
  deleteItemAction: (cartItemId: string) => Promise<void>;
  handleCloseDeleteItem: () => void;
  handleCloseClearCart: () => void;
}

export const useCartActions = (
  refreshCart: () => void,
  setAlertProps: (props: {
    isOpen: boolean;
    severity: 'success' | 'error' | 'info' | 'warning';
    message: string;
  }) => void,
  setLoading: (loading: boolean) => void,
  handleCloseDeleteItem: () => void,
  handleCloseClearCart: () => void,
  setCheckoutRequestId: React.Dispatch<React.SetStateAction<string | null>>,
  setOpenCheckoutModal: React.Dispatch<React.SetStateAction<boolean>>,
): UseCartActionsReturn => {
  const createOrder = async (user: any, openLoginModal: () => void) => {
    if (!user) {
      openLoginModal();
      return;
    }
    try {
      setLoading(true);
      const { response, status, error } = await PostCheckout();
      if (status === 'OK') {
        const id = response.data.order.checkout.id;
        setCheckoutRequestId(id);
        setOpenCheckoutModal(true);
      } else if (status === 'ERROR') {
        const errorMessage = error.response?.error || 'Ha ocurrido un error.';
        setAlertProps({
          isOpen: true,
          severity: 'error',
          message: errorMessage,
        });
      }
      if (user.role === 'ADMIN') {
        const errorMessage =
          error.response?.error ||
          'No puedes adquirir boletos del evento como Administrador.';
        setAlertProps({
          isOpen: true,
          severity: 'error',
          message: errorMessage,
        });
      }
    } catch (err) {
      console.error('Error creating order:', err);
    } finally {
      setLoading(false);
    }
  };

  const clearCartAction = async (cartId: string) => {
    if (!cartId) return;
    const { response, status, error } = await ClearCart();
    if (status === 'OK') {
      const successMessage =
        response.data.message || '¡El carrito se vació correctamente!';
      setAlertProps({
        isOpen: true,
        severity: 'success',
        message: successMessage,
      });
      handleCloseClearCart();
      refreshCart();
    } else if (status === 'ERROR') {
      const errorMessage =
        error.response?.data?.message || 'Ha ocurrido un error.';
      setAlertProps({
        isOpen: true,
        severity: 'error',
        message: errorMessage,
      });
    }
  };

  const deleteItemAction = async (cartItemId: string) => {
    if (!cartItemId) return;
    const { response, status, error } = await DeleteCartItem(cartItemId);
    if (status === 'OK') {
      const messageDelete =
        response.data.message || '¡Boleto eliminado correctamente!';
      setAlertProps({
        isOpen: true,
        severity: 'success',
        message: messageDelete,
      });
      handleCloseDeleteItem();
      refreshCart();
    } else if (status === 'ERROR') {
      const messageError =
        error.response?.data?.message || 'Ha ocurrido un error.';
      setAlertProps({
        isOpen: true,
        severity: 'error',
        message: messageError,
      });
    }
  };

  return {
    createOrder,
    clearCartAction,
    deleteItemAction,
    handleCloseDeleteItem,
    handleCloseClearCart,
  };
};
