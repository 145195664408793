import { useAuth } from 'apps/client/src/providers/AuthProvider';
import { useEffect, useState } from 'react';
import FullPageSpinner from '../../components/FullPageSpinner';
import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { getMyEvents } from 'apps/client/src/requests/users';
import { EventEntity } from 'libs/domain/src/lib/entities/Event';
import EventCard from '../../components/EventCard';
import { NoTickets } from '../../components/NoTickets';
import StadiumOutlinedIcon from '@mui/icons-material/StadiumOutlined';
import { BoxMainContainer } from 'apps/client/src/features/cart/components/BoxMainContainer';

interface TicketEntity extends EventEntity {}

export const MyEvents = () => {
  const { user } = useAuth();
  const [tickets, setTickets] = useState<TicketEntity[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (user) {
      getMyEvents()
        .then((response) => {
          setTickets(response.response.data);
          setIsLoading(false);
        })
        .catch((err) => {
          console.error('Error-getMyEvents: ', err);
          setIsLoading(false);
        });
    }
  }, [user]);

  const isEventPaid = (event: TicketEntity): boolean => {
    if (!event.eventDates || event.eventDates.length === 0) return false;
    return event.eventDates.some(
      (eventDate: any) =>
        eventDate.cart_items &&
        eventDate.cart_items.some(
          (item: any) => item.cart && item.cart.status === 'PAID',
        ),
    );
  };

  const paidTickets = tickets.filter(isEventPaid);

  const hasPendingTickets = tickets.some((ticket) =>
    ticket.eventDates?.some((eventDate: any) =>
      eventDate.cart_items?.some(
        (item: any) => item.cart && item.cart.status === 'CANCELLED',
      ),
    ),
  );

  if (isLoading) {
    return <FullPageSpinner />;
  }

  return (
    <React.Fragment>
      <BoxMainContainer>
        <Typography
          variant="h1"
          sx={{
            alignSelf: 'start',
            width: '80%',
            ml: { xs: '5%', md: '10%' },
            textTransform: 'uppercase',
            fontFamily: 'Roboto',
            fontSize: '2.5rem',
            fontWeight: '900',
            color: '#4D012E',
            alignItems: 'bottom',
            mb: 2,
          }}
        >
          <StadiumOutlinedIcon
            sx={{
              fontSize: '2.2rem',
              color: '#4D012E',
            }}
          />
          &nbsp;Mis eventos
        </Typography>

        {hasPendingTickets && (
          <Box
            sx={{
              width: 'auto',
              p: 2,
              backgroundColor: '#fff3cd',
              borderRadius: '10px',
              textAlign: 'center',
            }}
          >
            <Typography
              variant="body1"
              sx={{ color: 'red', fontWeight: 'bold' }}
            >
              ¡Ups! parece que tienes boletos pendientes de pago, por favor
              verifica tu correo.
            </Typography>
          </Box>
        )}

        {paidTickets.length === 0 ? (
          <NoTickets label="Aún no tienes boletos en tu historial. Descubre nuestros eventos y consigue el tuyo ahora." />
        ) : (
          <Box sx={{ flexGrow: 1 }}>
            <Grid
              container
              spacing={{ xs: 12, sm: 2, md: 2, lg: 2, xl: 2 }}
              columns={{ xs: 2, sm: 7, md: 12, lg: 6 }}
              justifyContent="center"
            >
              {paidTickets.map((ticket) => (
                <Grid
                  item
                  key={ticket.id}
                  columns={{ xs: 12, sm: 12, md: 12, lg: 6 }}
                >
                  <EventCard
                    key={ticket.id}
                    event={ticket}
                    buttonUrl={`/mis-eventos/${ticket.id}/boletos`}
                    buttonText="Ver boletos"
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
        )}
      </BoxMainContainer>
    </React.Fragment>
  );
};
