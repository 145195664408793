// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
} from 'react-router-dom';

// import styles from "./app.module.css";
import { Home } from './routes/home';
import AuthProvider from '../providers/AuthProvider';
import ProtectedRoute from './components/ProtectedRoute';
import Layout from './components/Layout';
import CustomTheme from './CustomTheme';

// Admin Pages
import AdminLayout from './components/AdminLayout';
import { Login } from './routes/login';
import { Events } from './routes/events';
import { ConfirmAccount } from './routes/invitation';
import { PreviewRoute } from './routes/preview';
import { QRScanner } from './routes/scanner';
import { ForbiddenPage } from './routes/forbidden';
import { PreviewClients } from './routes/previewClient';
import { SuccessRedirect } from './routes/checkout/SuccessRedirect';
import { SuccessPayment } from './routes/checkout/Success';
import { ErrorPayment } from './routes/checkout/Error';
import { NewCartPage } from './routes/checkout/NewCart';
import { MyEvents } from './routes/buyer/MyEvents';
import { MyTickets } from './routes/buyer/MyTickets';
import { Admins } from './routes/admins';
import { Users } from './routes/users';
import HomeLayout from './components/HomeLayout';
import { PrivacyNotice } from './routes/privacyNotice';
import { TermsConditions } from './routes/termsConditions';
import { SalesPoint } from './routes/sales-point';
import { SaleUsers } from './routes/sales';
import { ScanUsers } from './routes/scanUsers';
import { SalesHistory } from './routes/sales-history';
import { SectionBuilder } from './routes/section-builder/main';

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <HomeLayout>
        <Home />
      </HomeLayout>
    ),
  },
  {
    path: '/mis-eventos',
    element: (
      <ProtectedRoute allowedRoles={['USER']}>
        <Layout>
          <MyEvents />
        </Layout>
      </ProtectedRoute>
    ),
  },
  {
    path: '/mis-eventos/:id/boletos',
    element: (
      <ProtectedRoute allowedRoles={['USER']}>
        <Layout>
          <MyTickets />
        </Layout>
      </ProtectedRoute>
    ),
  },
  {
    path: '/admin/punto-de-venta',
    element: (
      <ProtectedRoute allowedRoles={['SALES']}>
        <Layout>
          <SalesPoint />
        </Layout>
      </ProtectedRoute>
    ),
  },
  {
    path: '/admin/scanner',
    element: (
      <ProtectedRoute allowedRoles={['SCANNER']}>
        <Layout>
          <QRScanner />
        </Layout>
      </ProtectedRoute>
    ),
  },
  {
    path: '/forbidden',
    element: <ForbiddenPage />,
  },
  {
    path: '/admin',
    element: (
      <ProtectedRoute allowedRoles={['ADMIN']} redirectTo="/admin/login">
        <Navigate to="/admin/eventos" />
      </ProtectedRoute>
    ),
  },
  {
    path: '/admin/login',
    element: <Login />,
  },
  {
    path: '/admin/eventos',
    element: (
      <ProtectedRoute allowedRoles={['ADMIN']}>
        <AdminLayout>
          <Events />
        </AdminLayout>
      </ProtectedRoute>
    ),
  },
  {
    path: '/admin/administradores',
    element: (
      <ProtectedRoute allowedRoles={['ADMIN']}>
        <AdminLayout>
          <Admins />
        </AdminLayout>
      </ProtectedRoute>
    ),
  },
  {
    path: '/admin/sales',
    element: (
      <ProtectedRoute allowedRoles={['ADMIN']}>
        <AdminLayout>
          <SaleUsers />
        </AdminLayout>
      </ProtectedRoute>
    ),
  },
  {
    path: '/admin/scan',
    element: (
      <ProtectedRoute allowedRoles={['ADMIN']}>
        <AdminLayout>
          <ScanUsers />
        </AdminLayout>
      </ProtectedRoute>
    ),
  },
  {
    path: '/admin/usuarios',
    element: (
      <ProtectedRoute allowedRoles={['ADMIN']}>
        <AdminLayout>
          <Users />
        </AdminLayout>
      </ProtectedRoute>
    ),
  },
  {
    path: '/admin/historial-de-ventas',
    element: (
      <ProtectedRoute allowedRoles={['ADMIN']}>
        <AdminLayout>
          <SalesHistory />
        </AdminLayout>
      </ProtectedRoute>
    ),
  },
  {
    path: '/admin/eventos/:id/preview',
    element: (
      <ProtectedRoute allowedRoles={['ADMIN']}>
        <AdminLayout>
          <PreviewRoute />
        </AdminLayout>
      </ProtectedRoute>
    ),
  },
  {
    path: '/confirm-account',
    element: <ConfirmAccount />,
  },
  {
    path: '/eventos/:id',
    element: (
      <Layout>
        <PreviewClients />
      </Layout>
    ),
  },
  {
    path: '/carrito',
    element: (
      <Layout>
        <NewCartPage />
      </Layout>
    ),
  },
  {
    path: '/checkout/redirect',
    element: <SuccessRedirect />,
  },
  {
    path: '/checkout/success',
    element: (
      <Layout>
        <SuccessPayment />
      </Layout>
    ),
  },
  {
    path: '/checkout/error',
    element: (
      <Layout>
        <ErrorPayment />
      </Layout>
    ),
  },
  {
    path: '/aviso-privacidad',
    element: (
      <Layout>
        <PrivacyNotice />
      </Layout>
    ),
  },
  {
    path: '/terminos-condiciones',
    element: (
      <Layout>
        <TermsConditions />
      </Layout>
    ),
  },
  {
    path: '/6g3tSAc906z',
    element: <SectionBuilder />,
  },
]);

export function App() {
  return (
    <ThemeProvider theme={CustomTheme}>
      <AuthProvider>
        <RouterProvider router={router} />
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
