import { Box } from '@mui/material';
import Carousel from 'react-material-ui-carousel';
import { EventEntity } from 'libs/domain/src/lib/entities/Event';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';

interface TicketImageSliderProps {
  events: EventEntity[];
  loading?: boolean;
  images?: string[];
}

const styleLandingPage = {
  width: 'auto',
  height: {
    xs: '250px',
    sm: '300px',
    md: '400px',
    lg: '500px',
    xl: '600px',
  },
  backgroundColor: 'rgba(0, 0, 0, 2.5)',
};

export default function TicketImageSlider({
  events,
  images,
}: TicketImageSliderProps) {
  return (
    <Carousel
      navButtonsAlwaysVisible={false}
      indicators={true}
      sx={{
        ...styleLandingPage,
      }}
      stopAutoPlayOnHover
      animation="slide"
      swipe
      fullHeightHover
      duration={1500}
    >
      {events.length > 0 ? (
        events.map((event, index) => <EventImage event={event} />)
      ) : (
        <EventImage image={images ? images[0] : ''} />
      )}
    </Carousel>
  );
}

function EventImage({
  event,
  image,
}: {
  event?: EventEntity | undefined;
  image?: string;
}) {
  let formattedDates = 'Fecha(s) del evento';
  let srcImage = image ? image : '';

  if (event) {
    formattedDates =
      event?.eventDates
        ?.map((eventDate) =>
          format(new Date(eventDate.date), "dd 'de' MMMM yyyy", { locale: es }),
        )
        .slice(0, event?.eventDates.length - 1)
        .join(', ')
        .concat(
          `${event?.eventDates.length > 1 ? ' y ' : ''}`,
          (event?.eventDates.length > 0 &&
            format(
              new Date(event?.eventDates[event?.eventDates.length - 1].date),
              "dd 'de' MMMM yyyy",
              { locale: es },
            )) ||
            'No hay fechas disponibles',
        ) || 'No hay fechas disponibles';

    srcImage = event.bannerImage ?? '';
  }

  return (
    <Box
      sx={{
        maxWidth: 'auto',
        height: {
          xs: '250px',
          sm: '300px',
          md: '400px',
          lg: '500px',
          xl: '600px',
        },
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        m: '0 auto',
      }}
    >
      <Box
        component={'img'}
        src={srcImage}
        alt="imagen"
        sx={{
          width: 'auto',
          height: {
            xs: '250px',
            sm: '300px',
            md: '400px',
            lg: '500px',
            xl: '600px',
          },
          objectFit: 'cover',
        }}
      />
    </Box>
  );
}
