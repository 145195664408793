import React from 'react';
import { Paper, Typography, Divider } from '@mui/material';
import CartResumeItems, { ResumentItem } from './CartResumeItems';
import CartSummary from './CartSummary';
import CartActions from './CartActions';

interface CartReviewSectionProps {
  rowSectionsItems: ResumentItem[] | undefined;
  totalPrice: number;
  createOrder: () => void;
  onOpenClearCartDialog: (cartId: string) => void;
  cartId: string;
  openLogin: () => void;
  loading: boolean;
}

const CartReviewSection: React.FC<CartReviewSectionProps> = ({
  rowSectionsItems,
  totalPrice,
  createOrder,
  onOpenClearCartDialog,
  cartId,
  openLogin,
  loading,
}) => {
  return (
    <Paper
      elevation={2}
      sx={{
        p: 2,
        borderRadius: '10px',
      }}
    >
      <Typography
        sx={{
          fontFamily: 'Roboto',
          fontSize: '1.2rem',
          fontWeight: 900,
          color: 'secondary.dark',
          textAlign: 'center',
          mb: 1,
        }}
      >
        Resumen
      </Typography>
      <Divider sx={{ mb: 2 }} />

      <CartResumeItems items={rowSectionsItems} />

      <CartSummary totalPrice={totalPrice} />

      <CartActions
        loading={loading}
        createOrder={createOrder}
        onOpenClearCartDialog={onOpenClearCartDialog}
        cartId={cartId}
        openLogin={openLogin}
      />
    </Paper>
  );
};

export default CartReviewSection;
