import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Typography,
} from '@mui/material';
import { MouseEvent, useEffect, useState } from 'react';
import { Form, useNavigate } from 'react-router-dom';
import { useAuth } from '../../providers/AuthProvider';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { TransactionDto, transactionSchema } from 'libs/domain/src/lib/dto';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import LoadingButton from '@mui/lab/LoadingButton';
import { PostTransaction } from '../../requests/carts';
import { isAxiosError } from 'axios';
import { useCart } from '../hooks/useCart';

const initialFormData = {
  name: '',
  email: '',
  confirmEmail: '',
};

interface TransactionModalProps {
  isOpen: boolean;
  onCloseModal: () => void;
}

function TransactionModal({ isOpen, onCloseModal }: TransactionModalProps) {
  const {
    state: { cart },
    refreshCart,
  } = useCart();
  const [open, setOpen] = useState(isOpen);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [confirmEmail, setConfirmEmail] = useState('');
  const [success, setSuccess] = useState(false);
  const [isEmptyError, setIsEmptyError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const handleBackdropClick = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
  };

  useEffect(() => {
    setOpen(isOpen);
    setSuccess(false);
    setName('');
    setEmail('');
    setConfirmEmail('');
  }, [isOpen]);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<TransactionDto>({
    resolver: zodResolver(transactionSchema),
  });

  const submit = async (data: TransactionDto) => {
    setIsEmptyError(false);
    try {
      const { response, status, error } = await PostTransaction(data);

      if (status === 'OK') {
        setSuccess(true);
        refreshCart();
        setName('');
        setEmail('');
        setConfirmEmail('');
      }

      if (status === 'ERROR') {
        if (isAxiosError(error)) {
          console.log(
            error.response?.data.message || 'Error al registrar los ítems.',
          );
        }
      }
    } catch (error: any) {
      setErrorMessage(error.message || '');
      setError(true);
      setLoading(false);
      setName('');
      setEmail('');
      setConfirmEmail('');
    }
  };

  useEffect(() => {
    refreshCart();
    setSuccess(false);
    setName('');
    setEmail('');
    setConfirmEmail('');
  }, []);

  return (
    <Modal
      open={open}
      onClose={onCloseModal}
      aria-labelledby="modal-modal-title"
      slotProps={{ backdrop: { onClick: handleBackdropClick } }}
      disableEscapeKeyDown
    >
      <Form onSubmit={handleSubmit(submit)} method="post" action="">
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '10%',
          }}
        >
          <Card
            sx={{
              borderRadius: '10px',
              width: '600px',
              display: 'flex',
              flexDirection: 'column',
              padding: '0px',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <CardHeader
              sx={{
                backgroundColor: '#890050',
                width: '100%',
                height: '10%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <CheckCircleOutlineIcon
                sx={{ fontSize: '2em', marginLeft: '10px' }}
              />
            </CardHeader>
            {success ? (
              <CardContent
                sx={{ width: '100%', height: '100%', padding: '0px' }}
              >
                <Box
                  sx={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'space-evenly',
                  }}
                >
                  <Typography
                    sx={{
                      color: (theme) => theme.palette.success.main,
                      fontSize: '2em',
                      fontWeight: 'bold',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    ¡Gracias por tu compra!{' '}
                    <CheckCircleOutlineIcon
                      sx={{ fontSize: '0.9em', marginLeft: '8px' }}
                    />
                  </Typography>
                  <Button
                    onClick={onCloseModal}
                    sx={{
                      width: '50%',
                      fontWeight: 'bold',
                      background: '#890050',
                      color: (theme) => theme.palette.common.white,
                      '&:hover': {
                        background: (theme) => theme.palette.primary.main,
                        color: (theme) => theme.palette.common.white,
                        border: 'none',
                      },
                    }}
                  >
                    Volver
                  </Button>
                </Box>
              </CardContent>
            ) : (
              <CardContent
                sx={{ width: '100%', height: '100%', padding: '0px' }}
              >
                <Box
                  sx={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'space-evenly',
                  }}
                >
                  <Typography
                    sx={{
                      color: (theme) => theme.palette.primary.main,
                      fontSize: '2em',
                      fontWeight: 'bold',
                    }}
                  >
                    Datos del Comprador
                  </Typography>
                  <Typography sx={{ textAlign: 'center', width: '57%' }}>
                    Ingresa el nombre del comprador y correo al cual serán
                    enviados los boletos.
                  </Typography>
                  <Box
                    sx={{
                      width: '80%',
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'space-evenly',
                    }}
                  >
                    <TextField
                      {...register('name')}
                      fullWidth
                      type="name"
                      id="outlined-required"
                      label="Nombre Completo"
                      size="small"
                      error={!!errors.name}
                      helperText={errors.name?.message}
                      margin="normal"
                      onChange={(e) => setName(e.target.value)}
                      value={name}
                    />
                    <TextField
                      {...register('email')}
                      fullWidth
                      type="email"
                      id="outlined-required"
                      label="Email"
                      size="small"
                      margin="normal"
                      error={!!errors.email}
                      helperText={errors.email?.message}
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                    />
                    <TextField
                      {...register('confirmEmail')}
                      fullWidth
                      type="confirmEmail"
                      id="outlined-required"
                      label="Confirmar Email"
                      size="small"
                      margin="normal"
                      onChange={(e) => setConfirmEmail(e.target.value)}
                      value={confirmEmail}
                      error={!!errors.confirmEmail}
                      helperText={errors.confirmEmail?.message}
                    />
                  </Box>
                  <Button
                    sx={{
                      width: '50%',
                      background: (theme) => theme.palette.common.white,
                      border: '2px solid #48002A',
                      fontWeight: 'bold',
                      margin: '10px',
                      color: '#890050',
                      '&:hover': {
                        background: '#890050',
                        color: (theme) => theme.palette.common.white,
                        border: 'none',
                      },
                    }}
                    onClick={onCloseModal}
                  >
                    Cancelar
                  </Button>
                  <LoadingButton
                    loading={isSubmitting}
                    variant="contained"
                    fullWidth
                    type="submit"
                    sx={{
                      width: '50%',
                      fontWeight: 'bold',
                      marginBottom: '10px',
                      background: '#890050',
                      color: (theme) => theme.palette.common.white,
                      '&:hover': {
                        background: (theme) => theme.palette.primary.main,
                        color: (theme) => theme.palette.common.white,
                        border: 'none',
                      },
                    }}
                  >
                    Enviar
                  </LoadingButton>
                </Box>
              </CardContent>
            )}
          </Card>
        </Box>
      </Form>
    </Modal>
  );
}

export default TransactionModal;
