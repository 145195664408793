/* eslint-disable @typescript-eslint/no-empty-function */
import React, { createContext, useContext } from 'react';
import { useEffect, useState } from 'react';
import FullPageSpinner from '../app/components/FullPageSpinner';
import { axiosRequest } from '../requests/api-client';
import {
  ForgotPasswordDto,
  RegisterDto,
  ResetPasswordDto,
} from 'libs/domain/src/lib/dto';
import { UserEntity } from 'libs/domain/src/lib/entities';

type AuthContextProps = {
  user: User | null;
  isAuth: boolean;
  isBuyer: boolean;
  isAdmin: boolean;
  isSales: boolean;
  isScanner: boolean;
  setUserData: (user?: User) => void;
  setIsAuth: (isAuth: boolean) => void;
  login: (email: string, password: string) => Promise<User | null>;
  logout: () => Promise<void>;
  registerNewUser: (
    data: RegisterDto,
  ) => Promise<{ status: boolean; message?: string }>;
  confirmNewUser: (
    token: string,
  ) => Promise<{ status: boolean; message?: string }>;
  forgotPassword: (
    data: ForgotPasswordDto,
  ) => Promise<{ status: boolean; message?: string }>;
  resetPassword: (
    data: ResetPasswordDto,
  ) => Promise<{ status: boolean; message?: string }>;
  redirectAdminUserToMainPage: () => string;
};

export type User = {
  id: string;
  firstName: string;
  lastName: string;
  fullName: string;
  email: string;
  role: string;
};

export const AuthContext = createContext<AuthContextProps>({
  user: null,
  isAuth: false,
  isBuyer: false,
  isAdmin: false,
  isSales: false,
  isScanner: false,
  setUserData: () => {},
  setIsAuth: () => {},
  login: () => Promise.reject(),
  logout: () => Promise.reject(),
  registerNewUser: () => Promise.resolve({ status: false, message: '' }),
  confirmNewUser: () => Promise.resolve({ status: false, message: '' }),
  forgotPassword: () => Promise.resolve({ status: false, message: '' }),
  resetPassword: () => Promise.resolve({ status: false, message: '' }),
  redirectAdminUserToMainPage: () => '',
});

type Props = {
  children?: React.ReactNode;
};

const AuthProvider: React.FC<Props> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [isAuth, setIsAuth] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isBuyer, setIsBuyer] = useState<boolean>(false);
  const [isScanner, setIsScanner] = useState<boolean>(false);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [isSales, setIsSales] = useState<boolean>(false);

  const setUserData = (user?: User) => {
    if (user) {
      setUser(user);
      setIsAuth(true);
    } else {
      setUser(null);
      setIsAuth(false);
    }
  };

  useEffect(() => {
    const fetchUser = async () => {
      const { response, status } = await axiosRequest({
        method: 'GET',
        url: '/me',
      });

      if (status === 'OK') {
        saveUserData(response.data.user);
      }
      if (status === 'ERROR') {
        setUser(null);
        setIsAuth(false);
      }
      setIsLoading(false);
    };
    fetchUser();
  }, []);

  useEffect(() => {
    if (user) {
      setIsBuyer(user.role === 'USER');
      setIsAdmin(user.role === 'ADMIN');
      setIsSales(user.role === 'SALES');
      setIsScanner(user.role === 'SCANNER');
    } else {
      setIsBuyer(false);
      setIsAdmin(false);
      setIsSales(false);
      setIsScanner(false);
    }
  }, [user]);

  const login = async (email: string, password: string) => {
    const { response, status, error } = await axiosRequest({
      url: '/login',
      method: 'post',
      data: {
        email,
        password,
      },
    });
    if (status === 'OK') {
      saveUserData(response.data.user);
      return response.data.user;
    }
    if (status === 'ERROR') {
      setIsAuth(false);
      const errorMessage =
        error.response.data.message ||
        'Correo electrónico o contraseña incorrectos!';
      throw new Error(errorMessage);
    }
    return null;
  };

  const logout = async () => {
    const { status, error } = await axiosRequest({
      url: '/logout',
      method: 'post',
    });
    if (status === 'OK') {
      setUser(null);
      setIsAuth(false);
    }
    if (status === 'ERROR') {
      const errorMessage =
        error.response.data.message ||
        'Ocurrio un error durante el cierre de sesión.';
      return errorMessage;
    }
  };

  const registerNewUser = async (
    data: RegisterDto,
  ): Promise<{
    status: boolean;
    message?: string;
  }> => {
    const { response, status, error } = await axiosRequest({
      url: '/sign-up',
      method: 'post',
      data,
    });

    if (status === 'ERROR') {
      const errorMessage =
        error.response.data.message ||
        'Ha ocurrido un error durante el registro.';

      return { status: false, message: errorMessage };
    }

    return { status: true, message: response.data.message };
  };

  const confirmNewUser = async (
    token: string,
  ): Promise<{
    status: boolean;
    message?: string;
  }> => {
    const { response, status, error } = await axiosRequest({
      url: `/admin/invitation/${token}`,
      method: 'GET',
    });

    if (status === 'ERROR') {
      setIsAuth(false);

      const errorMessage =
        error.response.data.message ||
        'Ha ocurrido un error durante el registro.';

      return { status: false, message: errorMessage };
    }

    saveUserData(response.data.user);
    setIsAuth(true);

    return { status: true, message: response.data.message };
  };

  const forgotPassword = async (
    data: ForgotPasswordDto,
  ): Promise<{
    status: boolean;
    message?: string;
  }> => {
    const { response, status, error } = await axiosRequest({
      url: '/forgot-password',
      method: 'post',
      data,
    });

    if (status === 'ERROR') {
      setIsAuth(false);

      const errorMessage =
        error.response.data.message ||
        'Ha ocurrido un error durante el proceso.';

      return { status: false, message: errorMessage };
    }

    return { status: true, message: 'Revisa tu bandeja de entrada' };
  };

  const resetPassword = async (
    data: ResetPasswordDto,
  ): Promise<{
    status: boolean;
    message?: string;
  }> => {
    const { response, status, error } = await axiosRequest({
      url: '/reset-password',
      method: 'post',
      data,
    });

    if (status === 'ERROR') {
      setIsAuth(false);

      const errorMessage =
        error.response.data.message ||
        'Ha ocurrido un error durante el proceso.';

      return { status: false, message: errorMessage };
    }

    return { status: true, message: response.data.message };
  };

  const saveUserData = (user: UserEntity) => {
    setUser({
      ...user,
      fullName: `${user.firstName} ${user.lastName}`,
    });
    setIsAuth(true);
  };

  const redirectAdminUserToMainPage = () => {
    if (user?.role === 'ADMIN') {
      return '/admin/eventos';
    }

    if (user?.role === 'SALES') {
      return '/admin/punto-de-venta';
    }

    if (user?.role === 'SCANNER') {
      return '/admin/scanner';
    }

    return '/';
  };

  if (isLoading) {
    return <FullPageSpinner />;
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        isAuth,
        isBuyer,
        isAdmin,
        isSales,
        isScanner,
        setUserData,
        setIsAuth,
        login,
        logout,
        registerNewUser,
        confirmNewUser,
        forgotPassword,
        resetPassword,
        redirectAdminUserToMainPage,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
export function useAuth(): AuthContextProps {
  return useContext(AuthContext);
}
export default AuthProvider;
