import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Skeleton,
  Typography,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { EventEntity } from 'libs/domain/src/lib/entities/Event';
import DefaultImage from '../images/defaultImage.png';
import { useNavigate } from 'react-router-dom';

interface EventCardPreviewProps {
  event: EventEntity;
  loading?: boolean;
  buttonText?: string;
  buttonUrl?: string;
}

export default function EventCard({
  event,
  loading,
  buttonText = 'Ver detalles',
  buttonUrl = '/eventos/',
}: EventCardPreviewProps) {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  let formattedDates = 'No hay fechas disponibles';

  const newFormattedDates = event?.eventDates?.map((eventDate) => {
    const formatter = new Intl.DateTimeFormat('es-ES', {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
      timeZone: 'UTC',
    });

    return formatter.format(new Date(eventDate.date));
  });

  if (newFormattedDates?.length === 1) {
    formattedDates = newFormattedDates[0];
  } else if (newFormattedDates?.length === 2) {
    formattedDates = newFormattedDates.join(' y ');
  } else {
    formattedDates =
      newFormattedDates?.slice(0, -1).join(', ') +
      ' y ' +
      formattedDates.at(-1);
  }

  return (
    <Box>
      {isMobile ? (
        <Container>
          <Grid container spacing={2} justifyContent="center">
            <Card
              sx={{
                width: 320,
                height: 265,
                justifyContent: 'space-evenly',
                display: 'flex',
                boxShadow: 3,
                m: 2,
                backgroundColor: '#d0d1d6',
                borderRadius: '10px',
                flexDirection: 'column',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginTop: '15px',
                  alignSelf: 'center',
                  width: '90%',
                }}
              >
                <CardMedia
                  component="img"
                  sx={{
                    width: 280,
                    height: 135,
                    borderRadius: '10px',
                  }}
                  image={event.cardImage || DefaultImage}
                  title={event.name}
                />
              </Box>
              <Typography
                sx={{
                  color: '#A70050',
                  textAlign: 'center',
                  fontWeight: 'bold',
                  mt: 1,
                  alignSelf: 'center',
                  fontSize: '1rem',
                }}
              >
                {event.name}
              </Typography>
              <Button
                variant="text"
                sx={{
                  width: '50%',
                  background: '#C33186',
                  borderRadius: '10px',
                  color: '#FFFFFF',
                  fontWeight: 600,
                  letterSpacing: '1px',
                  fontFamily: 'Poppins',
                  textTransform: 'none',
                  fontSize: '0.9rem',
                  '&:hover': {
                    backgroundColor: '#890050',
                    color: '#FFFFFF',
                  },
                  alignSelf: 'center',
                  mb: 1,
                }}
                onClick={() => navigate(`${buttonUrl}`)}
              >
                Ver Detalles
              </Button>
            </Card>
          </Grid>
        </Container>
      ) : (
        <Card
          sx={{
            width: 350,
            height: 400,
            maxHeight: 450,
            borderRadius: '10px',
            backgroundColor: '#d0d1d6',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            m: 2,
          }}
        >
          <CardMedia
            sx={{
              borderRadius: '10px',
              cursor: 'pointer',
              height: 200,
              width: 320,
              marginTop: '15px',
              alignSelf: 'center',
            }}
            image={event.cardImage || DefaultImage}
            title={event.name}
          />
          <CardContent>
            {loading ? (
              <Skeleton width={450} height={50} />
            ) : (
              <Typography
                variant="body2"
                sx={{
                  fontFamily: 'Poppins',
                  color: '#656B7E',
                  mb: '6px',
                  fontWeight: '700',
                  fontSize: '0.85rem',
                  fontStyle: 'italic',
                }}
              >
                {formattedDates}
              </Typography>
            )}
            <Typography
              variant="h5"
              sx={{
                display: '-webkit-box',
                WebkitLineClamp: 2,
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                width: '100%',
                height: 'auto',
                fontSize: '1.4rem',
                fontFamily: 'Poppins',
                color: '#890050',
                fontWeight: '750',
              }}
            >
              {event.name}
            </Typography>
            <Typography
              variant="body1"
              sx={{
                display: '-webkit-box',
                WebkitLineClamp: 2,
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                width: '100%',
                fontSize: '0.9rem',
                fontFamily: 'Poppins',
                color: '#000',
                fontWeight: '300',
              }}
            >
              {event.venueName}
            </Typography>
          </CardContent>
          <CardActions
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Button
              size="small"
              sx={{
                width: '100%',
                minHeight: '40px',
                background: '#C33186',
                borderRadius: '10px',
                color: '#FFFFFF',
                fontWeight: 600,
                letterSpacing: '1px',
                fontFamily: 'Poppins',
                textTransform: 'capitalize',
                fontSize: '1rem',
                '&:hover': {
                  backgroundColor: '#890050',
                  color: '#FFFFFF',
                },
                mb: 2,
              }}
              onClick={() => navigate(`${buttonUrl}`)}
            >
              {buttonText}
            </Button>
          </CardActions>
        </Card>
      )}
    </Box>
  );
}
